import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AccountTransferRecordResponse,
  ApiErrorResponse,
  ApiResponse,
  ApplyFxInquiryResponse,
  BalanceListResponse,
  BeneficiaryListResponse,
  BindTotpResponse,
  CreateBeneficiaryResponse,
  CreateVbaResponse,
  ExchangeDetailResponse,
  ExchangeRateResponse,
  ExchangeRecordsResponse,
  FxInquiryResponse,
  LoginResponse,
  NoticeResponse,
  NotificationResponse,
  QueryFeeResponse,
  RechargeRecordsResponse,
  RegisterResponse,
  SecurityStatusResponse,
  SendVerifyCodeResponse,
  SetTrxPasswordResponse,
  TotpResponse,
  TransferByPrincipalResponse,
  VbaListResponse,
  WalletRecordsResponse,
  WithdrawRecordsResponse,
  WithdrawResponse,
} from '../domain/network/response';
import { LocalService } from './local.service';
import { WithdrawForm } from '../domain/form/withdraw';
import { Router } from '@angular/router';
import { DateTimeUtil } from '../common/DateTimeUtil';
import { SessionContext } from '../domain/types/session-context';
import { EventType, SessionService } from './session.service';
import { Md5 } from 'ts-md5';
import { environment } from 'projects/h5app2/src/environments/environment';
import { SecurityUtils } from '../common/SecurityUtils';
import { uuid } from 'uuidv4';
import { ToolUtils } from '../common/ToolUtils';
import { CreateBeneficiary } from '../domain/form/beneficiary';
import { Concurrent } from '../common/Concurrent';

// 請注意，下列接口，將會禁用，請改用新的接口：
// /mapp/api/client/authByPassword
// /mapp/api/client/create
// /mapp/api/client/applyTOTPByPassAndCode
// /mapp/api/client/confirmBindingTOTP

// 新的接口對應如下：

// /mxsvc/login
// /mxsvc/clientregister
// /mxsvc/totpbindingapply
// /mxsvc/totpbindingconfirm

interface KeyValueParams {
  [key: string]: string;
}

export class RequestError extends Error {
  constructor(cause: string, private _refer: string) {
    super(cause);
  }

  public get refer() {
    return this._refer;
  }
}

export class DisconnectError extends Error {
  constructor() {
    super('Disconnected');
  }
}

export class SessionError extends Error {
  constructor() {
    super('Lost Session');
  }
}

export class RequireTotpError extends Error {
  constructor() {
    super('TOTP required');
  }
}

export type PaymentMethod = 'SWIFT' | 'LOCAL';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  // private host = '/proxyserver';
  // private host = "https://app.paytranxee.com";
  // private host = 'http://161.117.189.57:8080';
  // private proxyHost =
  //   'http://testenv.power-gateway.com:9000/matrix-app-proxy/mapp';
  // private appHost =
  //   'http://testenv.power-gateway.com:9000/matrix-app-proxy/mxsvc';
  // private appId = `MATRIX.x89x613d8nhbb3e8gjx3`;

  // private proxyHost = environment.proxyHost;
  // private appHost = environment.appHost;
  private appId = environment.appId;

  private concurrent: Concurrent;

  constructor(
    storage: LocalService,
    private http: HttpClient,
    private router: Router,
    private session: SessionService
  ) {
    // if (storage.DevModes.includes('dev')) {
    //   this.proxyHost = '/proxyserver';
    // } else if (storage.Host !== '') {
    //   this.proxyHost = '/proxyserver';
    // }

    this.concurrent = new Concurrent(10);
  }

  get proxyHost() {
    return environment.proxyHost;
  }

  get appHost() {
    return environment.appHost;
  }

  private get context(): SessionContext | null {
    if (this.session.context) {
      return this.session.context;
    }
    return null;
  }

  // API: 會員互轉紀錄 /api/payment/listAccountTransfer
  public async getTransferRecords(currency?: string, from?: Date, to?: Date) {
    this.ConnectionCheck();

    const requestUrl = `${this.proxyHost}/api/payment/listAccountTransfer`;

    const ago = new Date();
    ago.setDate(ago.getDate() - 90); // 查詢 90天以前

    const params: KeyValueParams = {
      fromDtTm: DateTimeUtil.getSearchDate(from || ago),
      toDtTm: DateTimeUtil.getSearchDate(to || new Date()),
      pageSize: '100',
    };
    if (currency) {
      params['currency'] = currency;
    }

    let resp = await this.get<AccountTransferRecordResponse>(
      requestUrl,
      params
    );
    if (!resp) {
      throw new RequestError('Network Error', requestUrl);
    }
    if (resp.code == '00000000') {
      if (resp.data) {
        return resp.data;
      }
      throw new RequestError('No Data', requestUrl);
    }
    throw new RequestError(resp.msg, requestUrl);
  }

  // API: 標記個人通知已讀 /api/basic/batchMarkRead
  public async markEventAsRead(notifyIds: number[] | string[]) {
    this.ConnectionCheck();

    const requestUrl = `${this.proxyHost}/api/basic/batchMarkRead`;
    const params = notifyIds;

    let resp = await this.post<ApiResponse>(requestUrl, JSON.stringify(params));

    if (resp.code == '00000000') {
      return resp.data;
    } else {
      throw new RequestError(resp.msg, requestUrl);
    }
  }

  // API: 讀取個人通知 /api/basic/listNotifyEvent
  public async listNotifyEvents() {
    this.ConnectionCheck();
    const requestUrl = `${this.proxyHost}/api/basic/listNotifyEvent`;
    const params = {
      pageSize: 100,
    };

    let resp = await this.post<NotificationResponse>(
      requestUrl,
      JSON.stringify(params)
    );

    if (resp.code == '00000000') {
      return resp.data;
    } else if (resp.code == '00000004') {
      return null; // 沒有數據
    } else {
      throw new RequestError(resp.msg, requestUrl);
    }
  }

  // API: 刪除常用收款人 /api/beneficiary/delete/{beneficiaryId}
  public async delBeneficiary(beneficiaryId: number) {
    this.ConnectionCheck();
    const requestUrl = `${this.proxyHost}/api/beneficiary/delete/${beneficiaryId}`;
    let resp = await this.delete<ApiResponse>(requestUrl);
    if (!resp) {
      throw new RequestError('Network Error', requestUrl);
    }
    if (resp.code == '00000000') {
      if (resp.data) {
        return resp.data;
      }
      throw new RequestError(resp.msg, requestUrl);
    }
    throw new RequestError(resp.msg, requestUrl);
  }

  // API: 設定收款人偏好 /api/beneficiary/favorite/{beneficiaryId}
  public async setBeneficiaryFavorite(beneficiaryId: number) {
    this.ConnectionCheck();
    const requestUrl = `${this.proxyHost}/api/beneficiary/favorite/${beneficiaryId}`;
    let resp = await this.put<ApiResponse>(
      `${this.proxyHost}/api/beneficiary/favorite/${beneficiaryId}`
    );
    if (!resp) {
      throw new RequestError('Network Error', requestUrl);
    }
    if (resp.code == '00000000') {
      if (resp.data) {
        return resp.data;
      }
      throw new RequestError(resp.msg, requestUrl);
    }
    throw new RequestError(resp.msg, requestUrl);
  }

  // API: 取消收款人偏好 /api/beneficiary/unfavorite/{beneficiaryId}
  public async setBeneficiaryDisfavor(beneficiaryId: number) {
    this.ConnectionCheck();
    const requestUrl = `${this.proxyHost}/api/beneficiary/unfavorite/${beneficiaryId}`;
    let resp = await this.put<ApiResponse>(
      `${this.proxyHost}/api/beneficiary/unfavorite/${beneficiaryId}`
    );
    if (!resp) {
      throw new RequestError('Network Error', requestUrl);
    }
    if (resp.code == '00000000') {
      if (resp.data) {
        return resp.data;
      }
      throw new RequestError(resp.msg, requestUrl);
    }
    throw new RequestError(resp.msg, requestUrl);
  }

  // API: 創建收款人, /api/beneficiary/create
  public async createeneficiary(params: CreateBeneficiary) {
    this.ConnectionCheck();
    const requestUrl = `${this.proxyHost}//api/beneficiary/create`;
    // 計算 Signture
    const isotime = new Date().toISOString();
    const signture = SecurityUtils.generateSign(
      params,
      isotime,
      this.session.context!.signKey!
    );

    let resp = await this.post<CreateBeneficiaryResponse>(
      `${this.proxyHost}//api/beneficiary/create`,
      JSON.stringify(params),
      { 'X-Timestamp': isotime, 'X-Sign': signture }
    );
    if (resp.code == '00000000') {
      if (resp.data) {
        return resp.data;
      } else {
        throw new RequestError('No Data', requestUrl);
      }
    } else {
      throw new RequestError(resp.msg, requestUrl);
    }
  }

  // API: 列出收款人清單, /api/beneficiary/list
  public async listBeneficiaries() {
    this.ConnectionCheck();
    const requestUrl = `${this.proxyHost}/api/beneficiary/list`;
    let resp = await this.get<BeneficiaryListResponse>(requestUrl);
    if (!resp) {
      throw new RequestError('Network Error', requestUrl);
    }
    if (resp.code == '00000000') {
      if (resp.data) {
        return resp.data;
      }
      throw new RequestError('No Data', requestUrl);
    }
    throw new RequestError(resp.msg, requestUrl);
  }

  // API: 創建充值資訊
  public async requireRechargeBankInfo(
    requestId: string,
    currency: string,
    method: PaymentMethod
  ) {
    this.ConnectionCheck();
    const requestUrl = `${this.proxyHost}/api/vba/create`;
    const params = {
      requestId: requestId,
      currency: currency,
      // region : 'CN',
      // paymentMethod: 'SWIFT',
      // paymentMethod: 'LOCAL',
      paymentMethod: method,
      usage: 'TP',
      // remark: 'Recharge',
    };

    let resp = await this.post<CreateVbaResponse>(
      `${this.proxyHost}/api/vba/create`,
      JSON.stringify(params)
    );

    if (resp.code == '00000000') {
      return resp.data;
    } else {
      throw new RequestError(resp.msg, requestUrl);
    }
  }

  // API: 用戶間轉帳, /api/payment/transferByPrincipal
  public async transferByPrincipal(
    currency: string,
    amount: number,
    creditor: string,
    trxPasswd: string,
    totp: string
  ) {
    this.ConnectionCheck();

    const requestUrl = `${this.proxyHost}/api/payment/transferByPrincipal`;
    const traceId = ToolUtils.getUUID4();

    // 重新計算金鑰
    const security = SecurityUtils.calcSecurityParams(
      this.appId,
      this.session.context!.principal!,
      trxPasswd
    );

    // 組成參數
    const params = {
      currency: currency,
      amount: amount * 100,
      creditorEmail: creditor, // 收款
      debtorEmail: this.session.context!.principal, // 出款
      extTraceId: traceId,
      trxPasswd: security.vhash,
      totp,
    };

    // 計算 Signture
    const signture = SecurityUtils.generateSign(
      params,
      security.time,
      this.session.context!.signKey!
    );

    let resp = await this.post<TransferByPrincipalResponse>(
      requestUrl,
      JSON.stringify(params),
      { 'X-Timestamp': security.time, 'X-Sign': signture }
    );
    if (resp.code == '00000000') {
      if (resp.data) {
        return resp.data;
      } else {
        throw new RequestError('No Data', requestUrl);
      }
    } else {
      throw new RequestError(resp.msg, requestUrl);
    }
  }

  // API: 用戶間轉帳, /api/payment/transferByPrincipal
  public async transferByPrincipalOld(
    currency: string,
    amount: number,
    creditor: string
  ) {
    this.ConnectionCheck();
    const requestUrl = `${this.proxyHost}/api/payment/transferByPrincipal`;
    const traceId = ToolUtils.getUUID4();
    let resp = await this.post<TransferByPrincipalResponse>(
      requestUrl,
      JSON.stringify({
        currency: currency,
        amount: amount * 100,
        creditorEmail: creditor, // 收款
        debtorEmail: this.session.context!.principal, // 出款
        extTraceId: traceId,
      })
    );
    if (resp.code == '00000000') {
      if (resp.data) {
        return resp.data;
      } else {
        throw new RequestError('No Data', requestUrl);
      }
    } else {
      throw new RequestError(resp.msg, requestUrl);
    }
  }

  // API: 公告/系統通知, /api/basic/listSystemNotice
  public async getSystemNotice() {
    this.ConnectionCheck();
    const requestUrl = `${this.proxyHost}/api/basic/listSystemNotice`;
    let resp = await this.post<NoticeResponse>(
      requestUrl,
      JSON.stringify({
        fromDtTm: '2022-01-01T00:00:00+0000',
        toDtTm: DateTimeUtil.getSearchDateWithTimeZone(),
        pageSize: 100,
      })
    );
    if (resp.code == '00000000') {
      if (resp.data) {
        return resp.data;
      } else {
        throw new RequestError('No Data', requestUrl);
      }
    } else {
      throw new RequestError(resp.msg, requestUrl);
    }
  }

  // API: 錢包流水 /api/balance/details{?currency,fromDtTm,pageNum,pageSize,toDtTm}
  public async getWalletRecords(currency: string, from?: Date, to?: Date) {
    this.ConnectionCheck();
    const requestUrl = `${this.proxyHost}/api/balance/details`;
    let ago = new Date();
    ago.setDate(ago.getDate() - 90); // 查詢 90天以前

    let resp = await this.get<WalletRecordsResponse>(requestUrl, {
      currency,
      // fromDtTm: '2023-07-01T12:00:00Z',
      // toDtTm: '2023-10-01T12:00:00Z',
      fromDtTm: DateTimeUtil.getSearchDate(from || ago),
      toDtTm: DateTimeUtil.getSearchDate(to || new Date()),
      pageSize: '100',
    });

    if (!resp) {
      throw new RequestError('Network Error', requestUrl);
    }
    if (resp.code == '00000000') {
      if (resp.data) {
        return resp.data;
      }
      throw new RequestError('No Data', requestUrl);
    } else if (resp.code == '00000004') {
      return {
        pageNum: 1,
        pageSize: 10,
        currSize: 0,
        totalSize: 0,
        rows: [],
      };
    }
    throw new RequestError(resp.msg, requestUrl);
  }

  // API: 出金紀錄詳情

  // API: 入金記錄詳情, /api/collection/get/{transactionId}
  public async getRechangeDetail(transactionId: string) {
    this.ConnectionCheck();
    const requestUrl = `${this.proxyHost}/api/collection/get/${transactionId}`;
    let resp = await this.get<ExchangeDetailResponse>(requestUrl);

    if (!resp) {
      throw new RequestError('Network Error', requestUrl);
    }
    if (resp.code == '00000000') {
      if (resp.data) {
        return resp.data;
      }
      throw new RequestError('No Data', requestUrl);
    }
    throw new RequestError(resp.msg, requestUrl);
  }

  // API: 匯兌記錄詳情 /api/exchange/query{?contractId,requestId}
  public async getExchangeDetail(contractId: string) {
    this.ConnectionCheck();
    const requestUrl = `${this.proxyHost}/api/exchange/query`;
    let resp = await this.get<ExchangeDetailResponse>(
      `${this.proxyHost}/api/exchange/query`,
      { contractId }
    );

    if (!resp) {
      throw new RequestError('Network Error', requestUrl);
    }
    if (resp.code == '00000000') {
      if (resp.data) {
        return resp.data;
      }
      throw new RequestError('No Data', requestUrl);
    }
    throw new RequestError(resp.msg, requestUrl);
  }

  // API: 查詢匯兌記錄, /api/exchange/query/page
  public async getExchangeRecords(from?: Date, to?: Date, currency?: string) {
    this.ConnectionCheck();
    const requestUrl = `${this.proxyHost}/api/exchange/query/page`;
    let ago = new Date();
    ago.setDate(ago.getDate() - 90); // 查詢 90天以前

    let params: KeyValueParams = {
      // fromDtTm: ago.toISOString().replace(/\.\d+Z$/, 'Z'),
      // toDtTm: new Date().toISOString().replace(/\.\d+Z$/, 'Z'),
      fromDtTm: DateTimeUtil.getSearchDate(from || ago),
      toDtTm: DateTimeUtil.getSearchDate(to || new Date()),
    };
    if (currency) {
      params['sourceCur'] = currency;
    }

    let resp = await this.get<ExchangeRecordsResponse>(requestUrl, params);

    if (!resp) {
      throw new RequestError('Network Error', requestUrl);
    }
    if (resp.code == '00000000') {
      if (resp.data) {
        return resp.data;
      }
      throw new RequestError('No Data', requestUrl);
    }
    throw new RequestError(resp.msg, requestUrl);
  }

  // API: 查詢充值紀錄, /api/collection/list
  public async getRechargeRecords(from?: Date, to?: Date, currency?: string) {
    this.ConnectionCheck();
    const requestUrl = `${this.proxyHost}/api/collection/list`;
    let ago = new Date();
    ago.setDate(ago.getDate() - 90); // 查詢 90天以前
    let params: KeyValueParams = {
      // fromDtTm: ago.toISOString().replace(/\.\d+Z$/, 'Z'),
      // toDtTm: new Date().toISOString().replace(/\.\d+Z$/, 'Z'),
      fromDtTm: DateTimeUtil.getSearchDate(from || ago),
      toDtTm: DateTimeUtil.getSearchDate(to || new Date()),
    };
    if (currency) {
      params['currency'] = currency;
    }
    let resp = await this.get<RechargeRecordsResponse>(requestUrl, params);

    if (!resp) {
      throw new RequestError('Network Error', requestUrl);
    }
    if (resp.code == '00000000') {
      if (resp.data) {
        return resp.data;
      }
      throw new RequestError('No Data', requestUrl);
    }
    throw new RequestError(resp.msg, requestUrl);
  }

  // API: 查詢出金紀錄, /api/payment/list
  public async getWithdrawRecords(from?: Date, to?: Date, currency?: string) {
    this.ConnectionCheck();
    const requestUrl = `${this.proxyHost}/api/payment/list`;
    let ago = new Date();
    ago.setDate(ago.getDate() - 90); // 查詢 90天以前

    let params: KeyValueParams = {
      // fromDtTm: ago.toISOString().replace(/\.\d+Z$/, 'Z'),
      // toDtTm: new Date().toISOString().replace(/\.\d+Z$/, 'Z'),
      fromDtTm: DateTimeUtil.getSearchDate(from || ago),
      toDtTm: DateTimeUtil.getSearchDate(to || new Date()),
    };
    if (currency) {
      params['currency'] = currency;
    }

    let resp = await this.get<WithdrawRecordsResponse>(requestUrl, params);

    if (!resp) {
      throw new RequestError('Network Error', requestUrl);
    }
    if (resp.code == '00000000') {
      if (resp.data) {
        return resp.data;
      }
      throw new RequestError('No Data', requestUrl);
    }
    throw new RequestError(resp.msg, requestUrl);
  }

  // API: 查詢交易手續費
  public async QueryFee(
    trxId: string, // uuid
    currency: string,
    amount: number,
    method: string = 'SWIFT'
  ) {
    this.ConnectionCheck();
    const requestUrl = `${this.proxyHost}/api/fee/rate`;
    const params = {
      charge: 'SHA',
      trxCurrency: currency,
      trxMethod: method,
      paymentType: 'CREATE_PAYMENT',
      productCode: 'PAYMENT',
      trxAmount: amount,
      trxId: trxId,
    };

    let resp = await this.post<QueryFeeResponse>(
      requestUrl,
      JSON.stringify(params)
    );

    if (resp.code == '00000000') {
      if (resp.data) {
        return resp.data;
      } else {
        throw new RequestError('No Data', requestUrl);
      }
    } else {
      throw new RequestError(resp.msg, requestUrl);
    }
  }

  // API: 送出出款申請
  public async CreateWithdraw(params: WithdrawForm) {
    this.ConnectionCheck();
    const requestUrl = `${this.proxyHost}/api/payment/create`;
    // 重新計算金鑰
    const security = SecurityUtils.calcSecurityParams(
      this.appId,
      params.principal!,
      params.trxPasswd!
    );
    params.trxPasswd = security.vhash;
    delete params.principal;

    // 計算 Signture
    const signture = SecurityUtils.generateSign(
      params,
      security.time,
      this.session.context!.signKey!
    );

    let resp = await this.post<WithdrawResponse>(
      `${this.proxyHost}/api/payment/create`,
      JSON.stringify(params),
      { 'X-Timestamp': security.time, 'X-Sign': signture }
    );
    if (resp.code == '00000000') {
      if (resp.data) {
        return resp.data;
      } else {
        throw new RequestError('No Data', requestUrl);
      }
    } else {
      throw new RequestError(resp.msg, requestUrl);
    }
  }

  // API: 換匯報價 /api/exchange/inquiry (POST)
  public async FxInquiry(
    requestId: string,
    sourceCurrency: string,
    targetCurrency: string,
    amount: number
  ) {
    this.ConnectionCheck();
    const requestUrl = `${this.proxyHost}/api/exchange/inquiry`;
    const params = {
      requestId: requestId,
      // requestTime: this.getIsoDatetime(),
      requestTime: DateTimeUtil.getIsoDatetimeLit(),
      sourceCur: sourceCurrency,
      targetCur: targetCurrency,
      sourceAmt: amount,
    };

    let resp = await this.post<FxInquiryResponse>(
      `${this.proxyHost}/api/exchange/inquiry`,
      JSON.stringify(params)
    );

    if (resp.code == '00000000') {
      if (resp.data) {
        return resp.data;
      } else {
        throw new RequestError('No Data', requestUrl);
      }
    } else {
      throw new RequestError(resp.msg, requestUrl);
    }
  }

  // API: 換匯下單 /api/exchange/apply
  public async ApplyInquiry(
    requestId: string,
    quoteId: string,
    principal: string,
    trxPasswd: string
  ) {
    this.ConnectionCheck();
    const requestUrl = `${this.proxyHost}/api/exchange/apply`;
    const security = SecurityUtils.calcSecurityParams(
      this.appId,
      principal,
      trxPasswd
    );
    const params = {
      requestId: requestId,
      // requestTime: this.getIsoDatetime(),
      requestTime: DateTimeUtil.getIsoDatetimeLit(),
      quoteId: quoteId,
      trxPasswd: security.vhash,
    };

    // 計算 signture
    const signture = SecurityUtils.generateSign(
      params,
      security.time,
      this.session.context!.signKey!
    );

    let resp = await this.post<ApplyFxInquiryResponse>(
      requestUrl,
      JSON.stringify(params),
      { 'X-Timestamp': security.time, 'X-Sign': signture }
    );

    if (resp.code == '00000000') {
      if (resp.data) {
        return resp.data;
      } else {
        throw new RequestError('No Data', requestUrl);
      }
    } else {
      throw new RequestError(resp.msg, requestUrl);
    }
  }

  // API: 查詢匯率牌價 /api/exchange/price
  public async getFxPrice() {
    this.ConnectionCheck();
    const requestUrl = `${this.proxyHost}/api/exchange/price`;
    let resp = await this.get<ExchangeRateResponse>(requestUrl);

    if (!resp) {
      throw new RequestError('Network Error', requestUrl);
    }
    if (resp.code == '00000000' && resp.data) {
      // 当卖出币种=curPair的前一个币种时，买入金额=卖出金额 * 汇率，
      // 当卖出币种=curPair的后一个币种时，买入金额=卖出金额  / 汇率，
      // ex:
      // {sourceCur: 'CNY', targetCur: 'USD', curPair: 'USDCNY', rate: 7.36341, updateTime: '2023-09-09T22:43:10+0800'}
      // {sourceCur: 'USD', targetCur: 'CNY', curPair: 'USDCNY', rate: 7.406967, updateTime: '2023-09-09T22:43:10+0800'}

      return resp.data.curPairs;
    }
    throw new RequestError(resp.msg, requestUrl);
  }

  // API: 取得已經建立的充值帳戶資訊
  public async getRechargeBankInfo(currency: string) {
    this.ConnectionCheck();
    const requestUrl = `${this.proxyHost}/api/vba/list`;
    let resp = await this.get<VbaListResponse>(requestUrl, {
      currency,
    });

    if (!resp) {
      throw new RequestError('Network Error', requestUrl);
    }
    if (resp.code == '00000000') {
      return resp.data;
    }
    throw new RequestError(resp.msg, requestUrl);
  }

  // API: 取得帳戶餘額列表
  public async balanceList(currency?: string) {
    this.ConnectionCheck();
    const requestUrl = `${this.proxyHost}/api/balance/list`;
    let params = {};
    if (currency) {
      params = { currency: currency };
    }
    let resp = await this.get<BalanceListResponse>(requestUrl, params);
    if (resp.code == '00000000') {
      if (resp.data) {
        return resp.data.account;
      } else {
        throw new RequestError('No Data', requestUrl);
      }
    } else {
      throw new RequestError(resp.msg, requestUrl);
    }
  }

  // API: 設置交易密碼, /api/client/resetTrxPwd
  public async setTransactionPassword(
    principal: string,
    trxPasswd: string,
    verifyCode: string,
    verifyToken: string,
    totp: string
  ) {
    this.ConnectionCheck();
    const requestUrl = `${this.appHost}/resettrxpasswd`;
    const security = SecurityUtils.calcSecurityParams(
      this.appId,
      principal,
      trxPasswd
    );

    const params = {
      principal: principal,
      newTrxPass: security.phash,
      newTrxPasswd: security.phash,
      verifyCode: verifyCode,
      verifyToken: verifyToken,
      totp,
    };

    let resp = await this.post<SetTrxPasswordResponse>(
      requestUrl,
      JSON.stringify(params),
      {
        'X-AppId': this.appId,
      }
    );

    if (resp.code == '00000000') {
      return resp.data;
    } else {
      throw new RequestError(resp.msg, requestUrl);
    }
  }

  // API: 發送驗證碼 /api/basic/sendVerifyCode
  public async sendVerifyCodeViaEmail(email: string, tag: string = 'REGISTER') {
    this.ConnectionCheck();
    const requestUrl = `${this.proxyHost}/api/basic/sendVerifyCode`;
    const params = {
      emial: email,
      method: 'EMAIL',
      tag, // 'BIND_TOTP',
    };

    let resp = await this.post<SendVerifyCodeResponse>(
      `${this.proxyHost}/api/basic/sendVerifyCode`,
      JSON.stringify(params),
      {
        'X-AppId': this.appId,
      }
    );

    if (resp.code == '00000000') {
      if (resp.data?.token) {
        return resp.data?.token;
      }
      throw new RequestError('Invalid Token', requestUrl);
    } else {
      throw new RequestError(resp.msg, requestUrl);
    }
  }

  // API: 驗證 Email/簡訊, 取得 google auth info, /mxsvc/totpbindingapply
  public async getTotpBindingInfo(
    principal: string, // email
    verifyCode: string, // 驗證碼
    verifyToken: string // 驗證碼 Token
  ) {
    this.ConnectionCheck();
    const requestUrl = `${this.appHost}/totpbindingapply`;
    const params = {
      principal,
      verifyCode,
      verifyToken,
    };

    let resp = await this.post<TotpResponse>(
      requestUrl,
      JSON.stringify(params),
      {
        'X-AppId': this.appId,
      }
    );

    if (resp.code == '00000000' && resp.data) {
      return resp.data;
    } else {
      throw new RequestError(resp.msg, requestUrl);
    }
  }

  // API: 綁定 totp, /mxsvc/totpbindingconfirm
  public async bindTotp(
    principal: string, // email
    password: string, // 密碼
    totp: string // Totp
  ) {
    this.ConnectionCheck();
    const requestUrl = `${this.appHost}/totpbindingconfirm`;
    const security = SecurityUtils.calcSecurityParams(
      this.appId,
      principal,
      password
    );

    const params = {
      principal,
      password: security.vhash,
      totp,
    };

    let resp = await this.post<BindTotpResponse>(
      requestUrl,
      JSON.stringify(params),
      {
        'X-AppId': this.appId,
        'X-Timestamp': security.time,
      }
    );

    if (resp.code == '00000000' && resp.data) {
      return resp.data;
    } else {
      throw new RequestError(resp.msg, requestUrl);
    }
  }

  // API: 登出, /mxsvc/logout
  public async logout() {
    const requestUrl = `${this.appHost}/logout`;
    let resp = await this.post<LoginResponse>(requestUrl, '', {
      'X-AppId': this.appId,
      'X-SessionID': this.context?.sessionId ?? '',
    });

    if (resp.code == '00000000') {
      this.session.emit(EventType.LOGOUT);
      return true; // 登出成功
    } else {
      throw new RequestError(resp.msg, requestUrl);
    }
  }

  // API: 登入
  public async login(account: string, password: string, totp?: string) {
    const requestUrl = `${this.appHost}/login`;
    const security = SecurityUtils.calcSecurityParams(
      this.appId,
      account,
      password
    );
    const params = totp
      ? {
          principal: account,
          password: security.vhash,
          totp,
        }
      : {
          principal: account,
          password: security.vhash,
        };
    let resp = await this.post<LoginResponse>(
      requestUrl,
      JSON.stringify(params),
      {
        'X-AppId': this.appId,
        'X-Timestamp': security.time,
      }
    );

    if (resp.code == '00000000') {
      if (resp.sessionId) {
        // generateSignKey
        const sign_key = SecurityUtils.generateSignKey(
          security.phash,
          resp.sessionId
        );
        this.session.emit(EventType.LOGIN_WITH_SESSION, {
          principal: account,
          sessionId: resp.sessionId,
          signKey: sign_key,
        });
        return true; // 登入成功
      }
      throw new SessionError();
    } else if (resp.code == '99009013') {
      throw new RequireTotpError();
    } else {
      throw new RequestError(resp.msg, requestUrl);
    }
  }

  // API: 註冊帳號
  public async register(
    email: string,
    password: string,
    token: string,
    verify: string
  ) {
    const requestUrl = `${this.appHost}/clientregister`;
    const security = SecurityUtils.calcSecurityParams(
      this.appId,
      email,
      password
    );

    const params = {
      // username: email,
      method: 'EMAIL',
      email: email,
      // passwd: password,
      passwd: security.phash,
      verifyCode: verify,
      verifyToken: token,
    };

    let resp = await this.post<RegisterResponse>(
      requestUrl,
      JSON.stringify(params)
    );
    if (resp.code == '00000000') {
      // 註冊成功
      return resp.data;
    } else {
      throw new RequestError(resp.msg, requestUrl);
    }
  }

  // API: 查詢用戶 clientID, /api/client/get/{principal}
  async getClientInfoViaPrincipal(principal: string) {
    const requestUrl = `${this.proxyHost}/api/client/get/${encodeURIComponent(
      principal
    )}`;
    let resp = await this.get<ApiResponse>(requestUrl);
    if (resp.code == '00000000') {
      if (resp.data) {
        return resp.data;
      } else {
        throw new RequestError('No Data', requestUrl);
      }
    } else {
      throw new RequestError(resp.msg, requestUrl);
    }
  }

  // API: 查詢用戶安全配置狀態, /mxsvc/querysecstatus
  async querySecurityStatus() {
    this.ConnectionCheck();
    const requestUrl = `${this.appHost}/querysecstatus`;
    let resp = await this.post<SecurityStatusResponse>(requestUrl, '');
    if (resp.code == '00000000') {
      if (resp.data) {
        return resp.data;
      } else {
        throw new RequestError('No Data', requestUrl);
      }
    } else {
      throw new RequestError(resp.msg, requestUrl);
    }
  }

  // API: 重置登入密碼, /mxsvc/resetpasswd
  async resetPassword(
    principal: string,
    password: string,
    totp: string,
    verifyToken: string,
    verifyCode: string
  ) {
    const requestUrl = `${this.appHost}/resetpasswd`;
    const security = SecurityUtils.calcSecurityParams(
      this.appId,
      principal,
      password
    );
    const params = {
      principal,
      newPasswd: security.phash,
      totp,
      verifyCode,
      verifyToken,
    };
    let resp = await this.post<ApiResponse>(
      requestUrl,
      JSON.stringify(params),
      { 'X-Timestamp': security.time }
    );
    if (resp.code == '00000000') {
      return true;
    } else {
      throw new RequestError(resp.msg, requestUrl);
    }
  }

  // API: 存活測試, hello
  public ping(host: string, timeout: number = 5000): Promise<Boolean> {
    return new Promise(async (resolve, reject) => {
      let tout: NodeJS.Timeout | false = setTimeout(() => {
        tout = false;
        reject('Timeout');
      }, timeout);
      this.get<ApiResponse>(`${host}/matrix-app-proxy/mxsvc/hello`)
        .then((resp) => {
          if (tout) {
            clearTimeout(tout);
          }
          resolve(resp.code == '00000000');
        })
        .catch((err) => reject(err));
    });

    // let resp = await this.get<ApiResponse>(`${host}/mxsvc/hello`);
    // if (resp.code == '00000000') return true;
    // return false;
  }

  private delete<T>(
    url: string,
    params: KeyValueParams = {},
    headerArgs: KeyValueParams = {}
  ): Promise<T> {
    // const headers: KeyValueParams = {};
    if (this.context && this.context.sessionId) {
      headerArgs['X-SessionID'] = this.context.sessionId;
    }
    const headers: KeyValueParams = {
      'X-TrackId': ToolUtils.getUUID4(),
      'X-AppId': this.appId,
      'Content-Type': 'application/json',
      ...headerArgs,
    };

    return new Promise(async (resolve, reject) => {
      const hooker = await this.concurrent.enter();
      this.http
        .delete<T>(url, {
          headers: headers,
          body: JSON.stringify(params),
        })
        .subscribe({
          next: (val: T) => {
            hooker.release(); // 釋放 concurrent;
            resolve(val);
          },
          error: (err) => {
            hooker.release(); // 釋放 concurrent;
            if ('error' in err) {
              const resp = err.error as ApiResponse;
              if (resp && resp.code) {
                // "99009001" => Invalid session !
                // "99009002" => Exceptions on login !
                // "99009003" => Access denied !
                if (resp.code === '99009001') {
                  this.session.emit(EventType.LOGOUT);
                }
                return reject(new ApiErrorResponse(resp.code, resp.msg));
              }
            }
            reject(err);
          },
        });
    });
  }

  private put<T>(
    url: string,
    params: KeyValueParams = {},
    headerArgs: KeyValueParams = {}
  ): Promise<T> {
    // const headers: KeyValueParams = {};
    if (this.context && this.context.sessionId) {
      headerArgs['X-SessionID'] = this.context.sessionId;
    }
    const headers: KeyValueParams = {
      'X-TrackId': ToolUtils.getUUID4(),
      'X-AppId': this.appId,
      'Content-Type': 'application/json',
      ...headerArgs,
    };

    return new Promise(async (resolve, reject) => {
      const hooker = await this.concurrent.enter();
      this.http
        .put<T>(url, JSON.stringify(params), {
          headers: headers,
        })
        .subscribe({
          next: (val: T) => {
            hooker.release(); // 釋放 concurrent
            resolve(val);
          },
          error: (err) => {
            hooker.release(); // 釋放 concurrent
            if ('error' in err) {
              const resp = err.error as ApiResponse;
              if (resp && resp.code) {
                // "99009001" => Invalid session !
                // "99009002" => Exceptions on login !
                // "99009003" => Access denied !
                if (resp.code === '99009001') {
                  this.session.emit(EventType.LOGOUT);
                }
                return reject(new ApiErrorResponse(resp.code, resp.msg));
              }
            }
            reject(err);
          },
        });
    });
  }

  private get<T>(
    url: string,
    params: KeyValueParams = {},
    headerArgs: KeyValueParams = {}
  ): Promise<T> {
    // const headers: KeyValueParams = {};
    if (this.context && this.context.sessionId) {
      headerArgs['X-SessionID'] = this.context.sessionId;
    }
    const headers: KeyValueParams = {
      'X-TrackId': ToolUtils.getUUID4(),
      'X-AppId': this.appId,
      'Content-Type': 'application/json',
      ...headerArgs,
    };

    return new Promise(async (resolve, reject) => {
      const hooker = await this.concurrent.enter();
      this.http
        .get<T>(url, {
          headers: headers,
          params: params,
        })
        .subscribe({
          next: (val: T) => {
            hooker.release(); // 釋放 concurrent
            resolve(val);
          },
          error: (err) => {
            hooker.release(); // 釋放 concurrent
            if ('error' in err) {
              const resp = err.error as ApiResponse;
              if (resp && resp.code) {
                // "99009001" => Invalid session !
                // "99009002" => Exceptions on login !
                // "99009003" => Access denied !
                if (resp.code === '99009001') {
                  this.session.emit(EventType.LOGOUT);
                }
                return reject(new ApiErrorResponse(resp.code, resp.msg));
              }
            }
            reject(err);
          },
        });
    });
  }

  private post<T>(
    url: string,
    body: string,
    headerArgs: KeyValueParams = {}
  ): Promise<T> {
    const headers: KeyValueParams = {
      'X-TrackId': ToolUtils.getUUID4(),
      'X-AppId': this.appId,
      'Content-Type': 'application/json',
      ...headerArgs,
    };
    if (this.context && this.context.sessionId) {
      headers['X-SessionID'] = this.context.sessionId;
    }

    return new Promise(async (resolve, reject) => {
      const hooker = await this.concurrent.enter();
      this.http
        .post<T>(url, body, {
          headers: headers,
        })
        .subscribe({
          next: (val: T) => {
            hooker.release(); // 釋放 concurrent
            // 攔截正常返回的錯誤
            const resp = val as ApiResponse;
            if (resp && resp.code) {
              if (resp.code === '99009001') {
                this.session.emit(EventType.LOGOUT);
                return reject(resp.msg);
              }
            }
            resolve(val);
          },
          error: (err) => {
            hooker.release(); // 釋放 concurrent
            if ('error' in err) {
              const resp = err.error as ApiResponse;
              if (resp && resp.code) {
                // "99009001" => Invalid session !
                // "99009002" => Exceptions on login !
                // "99009003" => Access denied !
                if (resp.code === '99009001') {
                  this.session.emit(EventType.LOGOUT);
                }
                return reject(new ApiErrorResponse(resp.code, resp.msg));
              }
            }
            reject(err);
          },
        });
    });
  }

  // // 取得精簡的 ISO-DateTime string
  // private getIsoDatetime() {
  //   let iso = new Date().toISOString();
  //   iso = iso.replace(/(\.\d+)?Z$/, '+0000');
  //   return iso;
  // }

  private ConnectionCheck() {
    if (!this.session.isLogin) {
      this.router.navigate(['/login'], { replaceUrl: true });
      throw new SessionError();
    }
  }
}
