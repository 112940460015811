<h1 mat-dialog-title class="title">{{title | translate}}</h1>
<div mat-dialog-content>
    <!-- <p>請輸入動態驗證碼</p> -->
    <!-- <mat-form-field>
        <mat-label>Favorite Animal</mat-label>
        <input matInput [(ngModel)]="data.animal">
    </mat-form-field> -->

    <button mat-flat-button color="primary" class="commitCodeBtn" (click)="onClick(true)">{{btnName| translate}}</button>
</div>
<!-- <button class="dialog-close-mini-btn" mat-button (click)="onClick()">X</button> -->
<!-- <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">No Thanks</button>
    <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Ok</button>
</div> -->