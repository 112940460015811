import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatChipListboxChange } from '@angular/material/chips';
import { ClientService } from '../../services/client.service';
import { PromiseUtil } from '../../common/PromiseUtil';
import {
  ApiErrorResponse,
  ApplyFxInquiryResult,
  CurrencyExchangePair,
  FxInquiryResult,
  UserBalance,
} from '../../domain/network/response';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';
import { SessionService } from '../../services/session.service';
import { DialogService } from '../../services/dialog.service';
import { CurrencyMenuItem } from '../../domain/interfaces';

@Component({
  selector: 'app-exchange',
  templateUrl: './exchange.component.html',
  styleUrls: ['./exchange.component.scss'],
})
export class ExchangeComponent {
  stage = '1';
  demo: { name: string; color?: string; selected?: boolean }[] = [
    { name: '1', color: '' },
    { name: '2', color: '' },
    { name: '3', color: '' },
  ];

  stageStack: string[] = [];

  sellCurrencyMenu: CurrencyMenuItem[] = [];
  buyCurrencyMenu: CurrencyMenuItem[] = [];

  date = new FormControl(new Date());

  transPasswordHide = true;

  defaultCurrency?: string;
  sellCurrency = 'USD';
  buyCurrency = 'USD';

  sellCurrencyBalance = 0; // 要賣出的貨幣當前餘額
  buyCurrencyBalance = 0; // 要買入的貨幣當前餘額

  userBalance?: UserBalance[]; // 用戶帳戶餘額

  exchangeRate?: CurrencyExchangePair[]; // 匯率表

  rateUpdating: boolean = false; // 匯率更新狀態
  rateUpadeTime?: string; // 匯率更新時間
  rateDescInfo?: string; // 匯率交易比例
  currentExchangeRate = 0; // 當前幣種組合匯率
  currentExchangeFromCurrency?: string; // 當前匯率來源幣種
  currentExchangeEstimate = 0; // 當前幣種組合換匯試算價

  inputUserExchangeAmount = 0; // 用戶輸入換匯金額

  amountControl: FormControl;

  inquiryRequestId?: string; // 估價單 ID
  inquiryResult?: FxInquiryResult; // 估價單內容

  inquiryValidity = 60000; // 估價有效時間週期 (MIN_1 = 1min = 60000, MIN_30 = 30min = 1800000), 先默認 1min, 以後在動態調整
  inquiryExpireTime = 0; // 估價過期時間 (ts-ms)
  inquirySpinProgressVal = 0;

  applyOrderResult?: ApplyFxInquiryResult; // 提交訂單結果

  inquiryTimer?: NodeJS.Timer;

  trxPassword: FormControl;

  requireTrxPasswd: boolean = false;
  requireOTPasswd: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private client: ClientService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private location: Location,
    private dialog: DialogService,
    private session: SessionService
  ) {
    this.demo.forEach((e) => {
      e.selected = e.name == this.stage;
    });

    this.amountControl = new FormControl('', [
      Validators.required,
      Validators.maxLength(10),
      Validators.min(0),
      Validators.max(99999999),
    ]);

    this.amountControl.statusChanges.subscribe({
      next: (s) => {
        this.inputUserExchangeAmount = 0;
        if (s === 'VALID') {
          this.inputUserExchangeAmount = this.amountControl.value;
        }
        // this.calcExchangeAmount();

        // 同樣幣別
        if (this.sellCurrency === this.buyCurrency) {
          return;
        }
        this.calcExchangeAmountByInFuiry();
      },
    });

    this.trxPassword = new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]);
  }

  // 更換 Demo 分頁
  demoSelected(event: MatChipListboxChange) {
    this.stage = event.value;
    this.demo.forEach((e) => {
      e.selected = e.name == event.value;
    });
  }

  ngOnInit() {
    // 初始化參數
    this.route.params.subscribe((params: Params) => {
      if (params['currency']) {
        this.location.replaceState('/exchange');
        this.sellCurrency = params['currency'];
        this.defaultCurrency = params['currency'];
        this.sellCurrencyBalance = this.getUserBalance(this.sellCurrency);
      }
    });

    this.ontInit();
    this.inquiryTimer = setInterval(() => {
      this.renewChecker();
    }, 100);
  }

  async ontInit() {
    let results = await Promise.all([
      this.client.balanceList(), // 讀取餘額列表
      this.client.getFxPrice(), // 讀取匯率表
    ]);
    this.userBalance = results[0]; // 儲存用戶餘額
    this.exchangeRate = results[1]; // 儲存匯率表

    // 設定預設換匯幣種
    if (this.userBalance) {
      // 預設值
      if (this.defaultCurrency) {
        console.log(`來吧, 預設`, this.defaultCurrency);
        this.sellCurrency = this.defaultCurrency;
        this.buyCurrencyBalance = this.getUserBalance(this.sellCurrency);
        this.buyCurrency = this.defaultCurrency; // 預設都給一樣吧
        this.buyCurrencyBalance = this.buyCurrencyBalance;
      } else {
        // 預設
        this.sellCurrency = this.userBalance[0].currency;
        this.sellCurrencyBalance = 0;
        // 尋找有錢的
        for (let ub of this.userBalance) {
          if (ub.balance > 0) {
            this.sellCurrency = ub.currency;
            this.sellCurrencyBalance = ub.balance;
            this.buyCurrency = ub.currency; // 預設都給一樣吧
            this.buyCurrencyBalance = this.getUserBalance(ub.currency);
            break;
          }
        }
      }
    }

    // 設定幣種選單
    await this.initCurrencyMenu();

    // 設定匯率表現
    this.changeExchangePair();

    // 檢查綁定狀態
    const r = await this.client.querySecurityStatus();
    this.requireTrxPasswd = r.trxPasswd == 0;
    this.requireOTPasswd = r.totp == 0;
  }

  ngOnDestroy() {
    if (this.inquiryTimer) {
      clearInterval(this.inquiryTimer as unknown as number);
    }
  }

  // 點擊返回
  onGoBack() {
    if (this.stageStack.length == 0) {
      this.router.navigate(['/dashboard']);
      return;
    }
    this.stage = this.stageStack.pop()!;
  }

  // 選擇賣出貨幣
  onSellCurrencySelect() {
    const dialogRef = this.dialog.showCurrencySelect(
      this.sellCurrency,
      this.sellCurrencyMenu
    );
    const sub = dialogRef.afterClosed().subscribe((result) => {
      sub.unsubscribe();
      if (!result) return;
      this.sellCurrency = result;
      this.sellCurrencyBalance = this.getUserBalance(result);
      this.changeExchangePair();
    });
  }

  // 選擇購買貨幣
  onBuyCurrencySelect() {
    const dialogRef = this.dialog.showCurrencySelect(
      this.buyCurrency,
      this.buyCurrencyMenu
    );
    const sub = dialogRef.afterClosed().subscribe((result) => {
      sub.unsubscribe();
      if (!result) return;
      this.buyCurrency = result;
      this.buyCurrencyBalance = this.getUserBalance(result);
      this.changeExchangePair();
    });
  }

  // 交換幣別
  onExchangeCurrency() {
    if (this.sellCurrency === this.buyCurrency) {
      return;
    }
    // 交換幣別
    let tmp = this.sellCurrency;
    this.sellCurrency = this.buyCurrency;
    this.buyCurrency = tmp;
    // 更新餘額
    this.sellCurrencyBalance = this.getUserBalance(this.sellCurrency);
    this.buyCurrencyBalance = this.getUserBalance(this.buyCurrency);
    // 重新計算匯率
    this.changeExchangePair();
  }

  // 確認換匯
  async onConfirmInquiry() {
    // console.error(this.inquiryResult);
    if (!this.inquiryResult) {
      this.openSnackBar('無效資料');
      return;
    }

    if (!this.session.context) {
      this.openSnackBar('無效資料');
      return;
    }

    const applyRequestId = `${Date.now()}`;

    try {
      this.applyOrderResult = await this.client.ApplyInquiry(
        applyRequestId,
        this.inquiryResult.quoteId,
        this.session.context!.principal!,
        this.trxPassword.value
      );
      // console.log(`送出匯兌訂單`, this.applyOrderResult);

      this.stageStack.push(this.stage);
      this.stage = '3';
    } catch (err) {
      if (err instanceof ApiErrorResponse) {
        if (err.code === '99009014') {
          // 設定交易密碼
          this.router.navigate(['trxpasswd']);
        } else {
          this.openSnackBar(err.msg);
        }
      } else if (err instanceof Error) {
        this.openSnackBar(err.message);
      } else {
        this.openSnackBar(`${err}`);
      }
    }
  }

  // 結束換匯, 返回大廳
  async onClose() {
    this.router.navigate(['/dashboard'], { replaceUrl: true });
  }

  // 估價完成 - 下一步
  async onInquiry() {
    // 檢查密碼設置
    if (!this.checkPasswordSetting()) {
      return;
    }

    if (
      !(
        this.buyCurrency &&
        this.sellCurrency &&
        this.inputUserExchangeAmount > 0 &&
        this.currentExchangeEstimate > 0 &&
        this.inquiryExpireTime > 0
      )
    ) {
      this.openFormDataErrorSnackBar();
      return;
    }

    // 估價數據都有了, 就下一步吧
    this.showInquiryConfirmPage();

    // // 開始估價
    // this.inquiryRequestId = `${Date.now()}`;
    // try {
    //   let result: FxInquiryResult | undefined = await this.client.FxInquiry(
    //     this.inquiryRequestId,
    //     this.sellCurrency,
    //     this.buyCurrency,
    //     this.inputUserExchangeAmount
    //   );
    //   console.log(`估價結果:`, result);
    // } catch (err) {
    //   if (err instanceof Error) {
    //     this.openSnackBar(err.message);
    //   } else {
    //     this.openSnackBar(`${err}`);
    //   }
    // }
  }

  // 顯示確認頁
  showInquiryConfirmPage() {
    this.stageStack.push(this.stage);
    this.stage = '2';
  }

  private checkPasswordSetting() {
    // 檢查密碼設置
    if (this.requireTrxPasswd) {
      // 尚未設置交易密碼
      this.dialog.showRedirectPopup(
        'Dialog.SetTrxPasswd',
        'Dialog.Goto',
        ['trxpasswd'],
        ['dashboard', 'home']
      );
      return false;
    } else if (this.requireOTPasswd) {
      // 尚未設置谷歌密碼
      this.dialog.showRedirectPopup(
        'Dialog.SetOTPasswd',
        'Dialog.Goto',
        ['verify', 'googleauth'],
        ['dashboard', 'home']
      );
      return false;
    }
    return true;
  }

  // 查出用戶餘額
  private getUserBalance(currency: string) {
    if (this.userBalance) {
      for (let ub of this.userBalance) {
        if (ub.currency === currency) {
          return ub.balance;
        }
      }
    }
    return 0;
  }

  // 初始化貨幣列表
  private async initCurrencyMenu() {
    // if (this.userBalance) {
    //   const tar: string[] = [];
    //   this.userBalance.map((val) => {
    //     if (val.balance > 0) {
    //       this.sellCurrencyMenu.push({
    //         code: val.currency,
    //       });
    //       tar.push(`Currency.${val.currency}`);
    //     }
    //   });
    //   let trans = await PromiseUtil.FromObservable(this.translate.get(tar));
    //   console.error(trans);
    //   for (let i = 0; i < this.sellCurrencyMenu.length; i++) {
    //     this.sellCurrencyMenu[i].name =
    //       trans[`Currency.${this.sellCurrencyMenu[i].code}`];
    //   }
    // }

    // if (this.exchangeRate) {
    //   const tar: string[] = [];
    //   this.exchangeRate.map((val) => {
    //     this.sellCurrencyMenu.push({
    //       code: val.targetCur,
    //     });
    //     tar.push(`Currency.${val.targetCur}`);
    //   });
    //   let trans = await PromiseUtil.FromObservable(this.translate.get(tar));
    //   console.error(trans);
    //   for (let i = 0; i < this.sellCurrencyMenu.length; i++) {
    //     this.sellCurrencyMenu[i].name =
    //       trans[`Currency.${this.sellCurrencyMenu[i].code}`];
    //   }
    // }

    // 將可兌換列表的放出來
    if (this.exchangeRate) {
      const trans: string[] = [];
      const sellSet: string[] = [];
      const buySet: string[] = [];
      this.exchangeRate.map((val) => {
        if (!sellSet.includes(val.sourceCur)) {
          sellSet.push(val.sourceCur);
          this.sellCurrencyMenu.push({
            code: val.sourceCur,
          });
          trans.push(`Currency.${val.sourceCur}`);
        }
        if (!buySet.includes(val.targetCur)) {
          buySet.push(val.targetCur);
          this.buyCurrencyMenu.push({
            code: val.targetCur,
          });
          trans.push(`Currency.${val.targetCur}`);
        }
      });

      // 翻譯填字
      let transAns = await PromiseUtil.FromObservable(
        this.translate.get(trans)
      );
      for (let i = 0; i < this.sellCurrencyMenu.length; i++) {
        this.sellCurrencyMenu[i].name =
          transAns[`Currency.${this.sellCurrencyMenu[i].code}`];
      }
      for (let i = 0; i < this.buyCurrencyMenu.length; i++) {
        this.buyCurrencyMenu[i].name =
          transAns[`Currency.${this.buyCurrencyMenu[i].code}`];
      }

      // // 設定預設幣別
      // if (this.sellCurrencyMenu.length > 0) {
      //   this.sellCurrency = this.sellCurrencyMenu[0].code;
      // }
      // if (this.buyCurrencyMenu.length > 0) {
      //   this.buyCurrency = this.buyCurrencyMenu[0].code;
      // }

      // 更新餘額
      this.sellCurrencyBalance = this.getUserBalance(this.sellCurrency);
      this.buyCurrencyBalance = this.getUserBalance(this.buyCurrency);
    }
  }

  // 設定匯率轉換
  private changeExchangePair() {
    // console.error(`換匯 ${this.sellCurrency} --> ${this.buyCurrency}`);

    // 初始化資訊
    this.rateUpadeTime = undefined;
    this.rateDescInfo = undefined;
    this.currentExchangeEstimate = 0;
    this.currentExchangeFromCurrency = undefined;
    this.currentExchangeRate = 0;

    // if (this.exchangeRate) {
    //   for (let fx of this.exchangeRate) {
    //     if (
    //       fx.sourceCur === this.sellCurrency &&
    //       fx.targetCur === this.buyCurrency
    //     ) {
    //       // 計算時間
    //       let dd = new Date(fx.updateTime);
    //       this.rateUpadeTime = dd.toLocaleTimeString();
    //       // 更新換匯資訊, 記錄當前組合匯率
    //       this.currentExchangeRate = fx.rate;
    //       if (fx.curPair.startsWith(fx.sourceCur)) {
    //         this.rateDescInfo = `1 ${fx.sourceCur} = ${fx.rate} ${fx.targetCur}`;
    //         this.currentExchangeFromCurrency = fx.sourceCur;
    //       } else {
    //         this.rateDescInfo = `1 ${fx.targetCur} = ${fx.rate} ${fx.sourceCur}`;
    //         this.currentExchangeFromCurrency = fx.targetCur;
    //       }

    //       break;
    //     }
    //   }
    // }

    // 同樣幣別略過
    if (this.sellCurrency === this.buyCurrency) {
      return;
    }

    // 更新一下換匯金額試算
    // this.calcExchangeAmount();
    this.calcExchangeAmountByInFuiry();
  }

  // 換匯金額試算 - 透過牌價表
  // private calcExchangeAmount() {
  //   this.currentExchangeEstimate = 0;
  //   // 有效換匯資料
  //   if (
  //     this.currentExchangeFromCurrency &&
  //     this.currentExchangeRate &&
  //     this.inputUserExchangeAmount > 0
  //   ) {
  //     if (this.sellCurrency === this.currentExchangeFromCurrency) {
  //       this.currentExchangeEstimate =
  //         this.inputUserExchangeAmount * this.currentExchangeRate;
  //     } else {
  //       this.currentExchangeEstimate =
  //         this.inputUserExchangeAmount / this.currentExchangeRate;
  //     }
  //   }
  // }

  // 換匯金額試算 - 透過估價單查詢
  private async calcExchangeAmountByInFuiry() {
    // 清除當前訂單資訊
    this.currentExchangeEstimate = 0;
    this.inquiryExpireTime = 0;

    // 有效換匯資料
    if (
      // this.currentExchangeFromCurrency &&
      // this.currentExchangeRate &&
      this.inputUserExchangeAmount > 0
    ) {
      // 開始估價
      this.inquiryRequestId = `${Date.now()}`;
      try {
        let result: FxInquiryResult | undefined = await this.client.FxInquiry(
          this.inquiryRequestId,
          this.sellCurrency,
          this.buyCurrency,
          this.inputUserExchangeAmount * 100 // API 接口計價單位是 1/100, 所以要放大 100
        );
        console.log(`估價結果:`, result);
        // 判斷有資料, 且是最近一筆查詢
        if (result && result.requestId === this.inquiryRequestId) {
          this.currentExchangeEstimate = result.targetAmt;
          this.currentExchangeRate = result.clientRate;
          this.inquiryExpireTime = new Date(result.validityTo).getTime();
          this.rateUpadeTime = new Date(
            result.requestTime
          ).toLocaleTimeString();
          this.inquiryResult = result; // 儲存資料
          console.log(this.inquiryResult);

          // 更新匯率資訊
          if (this.inquiryResult) {
            // clientRate: 7.88113;
            // curPair: 'EURCNY';
            // quoteId: 'RFQ20231109162012353DRG5DCR44CQ';
            // requestId: '1699518012069';
            // requestTime: '2023-11-09T16:20:12+0800';
            // sourceAmt: 23400;
            // sourceCur: 'CNY';
            // targetAmt: 2969;
            // targetCur: 'EUR';
            // tenor: 'T0';
            // validity: 'MIN_1';
            // validityTo: '2023-11-09T16:21:12+0800';
            this.currentExchangeRate = this.inquiryResult.clientRate;
            if (
              this.inquiryResult.curPair.startsWith(
                this.inquiryResult.sourceCur
              )
            ) {
              this.rateDescInfo = `1 ${this.inquiryResult.sourceCur} = ${this.inquiryResult.clientRate} ${this.inquiryResult.targetCur}`;
              this.currentExchangeFromCurrency = this.inquiryResult.sourceCur;
            } else {
              this.rateDescInfo = `1 ${this.inquiryResult.targetCur} = ${this.inquiryResult.clientRate} ${this.inquiryResult.sourceCur}`;
              this.currentExchangeFromCurrency = this.inquiryResult.targetCur;
            }
          }
        }
      } catch (err) {
        if (err instanceof Error) {
          this.openSnackBar(err.message);
        } else {
          this.openSnackBar(`${err}`);
        }
      }
    }
  }

  private async openFormDataErrorSnackBar() {
    const msg = await PromiseUtil.FromObservable(
      this.translate.get('SnackBar.InvalidFormData')
    );
    this.openSnackBar(msg);
  }

  private openSnackBar(message: string, duration: number = 1000) {
    this.snackBar.open(message, 'OK', {
      duration: duration,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  // 刷新估價單
  private renewChecker() {
    if (
      this.inquiryExpireTime > 0 &&
      this.currentExchangeFromCurrency &&
      this.currentExchangeRate &&
      this.inputUserExchangeAmount > 0
    ) {
      const remain = this.inquiryExpireTime - Date.now();
      if (remain > 0) {
        this.inquirySpinProgressVal =
          100 - Math.ceil((remain / this.inquiryValidity) * 100);
      } else {
        this.inquirySpinProgressVal = 0;
        console.error(`重新報價`);
        this.calcExchangeAmountByInFuiry();
      }
    }
  }
}
