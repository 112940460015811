<div class="top">
    <div class="backBtn" (click)="backToHome()">
        <img class="backBtnImg" alt="image" src="/assets/images/icons/arrow-down-sign-to-navigate.png" />
        <div class="backBtnTxt">{{'Dashboard.Record'|translate}}</div>
    </div>
</div>

<div class="body">
    <!-- 選單 -->
    <div class="block-menu">

        <div matRipple centered radius="60" class="menu-button" (click)="onOpenRechargeRecord()">
            <div class="menu-button-content">
                <div class="menu-button-msg">
                    <div class="menu-button-msg-title">{{'Dashboard.RecordTab.RechargeRecord'|translate}}</div>
                    <div class="menu-button-msg-subtitle">{{'Dashboard.RecordTab.RechargeSubtitle'|translate}}</div>
                </div>
                <img class="menu-button-img" src="/assets/images/dashboard/recharge_record.png">
            </div>
        </div>

        <div matRipple centered radius="60" class="menu-button" (click)="onOpenExchangeRecord()">
            <div class="menu-button-content">
                <div class="menu-button-msg">
                    <div class="menu-button-msg-title">{{'Dashboard.RecordTab.ExchangeRecord'|translate}}</div>
                    <div class="menu-button-msg-subtitle">{{'Dashboard.RecordTab.ExchangeSubtitle'|translate}}</div>
                </div>
                <img class="menu-button-img" src="/assets/images/dashboard/exchange_record.png">
            </div>
        </div>

        <div matRipple centered radius="60" class="menu-button" (click)="onOpenWithdrawRecord()">
            <div class="menu-button-content">
                <div class="menu-button-msg">
                    <div class="menu-button-msg-title">{{'Dashboard.RecordTab.WithdrawRecord'|translate}}</div>
                    <div class="menu-button-msg-subtitle">{{'Dashboard.RecordTab.WithdrawSubtitle'|translate}}</div>
                </div>
                <img class="menu-button-img" src="/assets/images/dashboard/withdraw_record.png">
            </div>
        </div>

         <div matRipple centered radius="60" class="menu-button" (click)="onOpenTransferRecord()">
            <div class="menu-button-content">
                <div class="menu-button-msg">
                    <div class="menu-button-msg-title">{{'Dashboard.RecordTab.TransferRecord'|translate}}</div>
                    <div class="menu-button-msg-subtitle">{{'Dashboard.RecordTab.TransferSubtitle'|translate}}</div>
                </div>
                <img class="menu-button-img" src="/assets/images/dashboard/transfer_record.png">
            </div>
        </div>

    </div>

</div>