<div class="container">
    <!-- <p>login works!</p> -->

    <div class="top">
        <div class="backBtn" routerLink="/">
            <img class="backBtnImg" alt="image" src="/assets/images/icons/arrow-down-sign-to-navigate.png" />
            <div class="backBtnTxt">{{'TransPassword.Title'|translate}}</div>
        </div>

    </div>

    <div class="body">
        <div class="block-create-order">
            <div class="pay-box pay-box-with-hit">
                <form [formGroup]="setTransPassword">
                    <div class="pay-box-title style-4">{{'TransPassword.EnterPassword'|translate}}</div>
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <input matInput placeholder="{{'Common.TransPassword'|translate}}"
                            [type]="hide ? 'password' : 'text'" formControlName="password" style="border-radius: 25px;"
                            required>
                        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' :
                            'visibility'}}</mat-icon>
                        <!-- <mat-hint *ngIf="!passwordInput?.value">Enter your password</mat-hint> -->
                    </mat-form-field>

                    <div class="pay-box-title style-4">{{'TransPassword.ConfirmPassword'|translate}}</div>
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <input matInput placeholder="{{'TransPassword.ConfirmPassword'|translate}}"
                            [type]="hide ? 'password' : 'text'" formControlName="password" style="border-radius: 25px;"
                            required>
                        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' :
                            'visibility'}}</mat-icon>
                        <!-- <mat-hint *ngIf="!passwordInput?.value">Enter your password</mat-hint> -->
                    </mat-form-field>

                </form>
            </div>


            <div class="pay-box">
                <button mat-flat-button color="primary"
                    class="save-password-btn">{{'TransPassword.SavePassword'|translate}}</button>
            </div>

        </div>

    </div>

</div>