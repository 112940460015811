import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalService } from './core/services/local.service';
import { ClientService, RequestError } from './core/services/client.service';
import { SessionService, SessionState } from './core/services/session.service';
import { Router } from '@angular/router';
import { NotifyService } from './core/services/notify.service';
import { Subscription } from 'rxjs';
import { Concurrent } from './core/common/Concurrent';

declare global {
  interface Window {
    logout: any;
    cc: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'PayTranxee App';

  sessionStateSubscription?: Subscription; // 訂閱

  constructor(
    public translate: TranslateService,
    public storage: LocalService,
    private session: SessionService,
    private router: Router,
    private client: ClientService,
    private notify: NotifyService
  ) {
    translate.addLangs(['zh-tw', 'zh-cn']);
    translate.setDefaultLang(storage.Lang || 'zh-cn');

    window.logout = () => {
      this.client.logout();
    };

    window.cc = () => {
      new Concurrent(30).test(15, 10, async () => {
        try {
          if (!this.session.context?.principal) {
            console.log(`已經遺失 Session, 請重新登入`);
            return false;
          }

          let r;
          switch (Math.floor(Math.random() * 10)) {
            case 0:
            case 1:
            case 2:
              r = await this.client.balanceList();
              break;
            case 3:
            case 4:
            case 5:
              r = await this.client.getSystemNotice();
              break;
            default:
              r = await this.client.getFxPrice();
          }
          // console.log(r);
          return true;
        } catch (err) {
          let referUrl = '?';
          if (err instanceof RequestError) {
            referUrl = err.refer;
          }
          console.error(`<${referUrl}>`);
          console.error(`Ｓession 錯誤!!!`, err);
          return false;
        }
      });
    };

    // new Concurrent(30).test();
  }

  ngOnInit() {
    // 監控狀態, 如果登出就讓他回到登入頁
    this.sessionStateSubscription = this.session.stateChanges.subscribe({
      next: (state: SessionState) => {
        if (state === SessionState.LOGOUT) {
          this.router.navigate(['/login']);
        }
      },
    });
  }

  ngOnDestroy() {
    // 解除訂閱
    if (this.sessionStateSubscription) {
      this.sessionStateSubscription.unsubscribe();
    }
  }
}
