import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipListboxChange } from '@angular/material/chips';
import { ClientService } from '../../services/client.service';
import { UserDataService } from '../../services/user-data.service';
import { PromiseUtil } from '../../common/PromiseUtil';
import {
  BeneficiaryRecord,
  FeeInfo,
  UserBalance,
} from '../../domain/network/response';
import { CountryCode } from '../../definition/CountryCode';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';
import { LocalService } from '../../services/local.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';
import { SessionService } from '../../services/session.service';
import { CreateBeneficiary } from '../../domain/form/beneficiary';

@Component({
  selector: 'app-beneficiary-detail',
  templateUrl: './beneficiary-detail.component.html',
  styleUrls: ['./beneficiary-detail.component.scss'],
})
export class BeneficiaryDetailComponent {
  private beneficiaryId: number = 0;

  beneficiary?: BeneficiaryRecord;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private client: ClientService,
    private userData: UserDataService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private location: Location,
    private local: LocalService,
    private session: SessionService
  ) {}

  ngOnInit() {
    // 初始化參數
    this.route.params.subscribe((params: Params) => {
      this.beneficiaryId = parseInt(params['id'], 10);
      if (!this.beneficiaryId) {
        this.router.navigate(['/beneficiaries']);
        return;
      }
    });

    this.onInit();
  }

  async onInit() {
    console.log(`id:`, this.beneficiaryId);

    // 貨幣選單
    try {
      let beneficiaries = await this.client.listBeneficiaries();
      console.log(beneficiaries);
      this.beneficiary = beneficiaries.find(
        (item) => item.beneficiaryId == this.beneficiaryId
      );
      console.log(this.beneficiary);
    } catch (err) {}

    // // 讀取出金帳戶列表
    // try {
    //   this.beneficiaryList = [];
    //   this.beneficiaryRecord = [];
    //   this.beneficiaryList = await this.client.listBeneficiaries(); // TODO: 讀取列表
    //   this.onFilterChange();
    // } catch (err) {}
  }

  async onFavorite() {
    if (!this.beneficiary) {
      return;
    }
    await this.client.setBeneficiaryFavorite(this.beneficiaryId);
    this.beneficiary.favorite = true;
  }

  async onDisfavor() {
    if (!this.beneficiary) {
      return;
    }
    await this.client.setBeneficiaryDisfavor(this.beneficiaryId);
    this.beneficiary.favorite = false;
  }

  async onDelete() {
    if (!this.beneficiary) {
      return;
    }
    await this.client.delBeneficiary(this.beneficiaryId);
    this.onGoBack();
  }

  // 點擊返回
  onGoBack() {
    this.router.navigate(['/beneficiaries']);
  }
}
