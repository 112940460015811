import { Inject } from '@angular/core';
import { WINDOW } from '../provider/window.provider';

export class EnvironmentUtil {
  private static _instance?: EnvironmentUtil;

  private constructor(@Inject(WINDOW) public window: Window) {}

  public static get isIOS() {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }

  public static get isStandalone() {
    if (
      'standalone' in window.navigator && // Check if "standalone" property exists
      window.navigator.standalone
    ) {
      // Test if using standalone navigator
      // Web page is loaded via app mode (full-screen mode)
      // (window.navigator.standalone is TRUE if user accesses website via App Mode)
      console.error(`standalone`);
      return true;
    } else {
      // Web page is loaded via standard Safari mode
      // (window.navigator.standalone is FALSE if user accesses website in standard safari)
      return false;
    }
  }

  public static isDevelopSite(uri: string) {
    if (/^http:\/\/(localhost:4300|192\.168\.0\.24:4300)\//.test(uri)) {
      console.log(`isDevelopSite?? ${uri}`);
      return true;
    }
    return false;
  }
}
