<div class="container">

    <div class="top">
        <div class="backBtn" routerLink="/dashboard/setting">
            <img class="backBtnImg" alt="image" src="/assets/images/icons/arrow-down-sign-to-navigate.png" />
            <div class="backBtnTxt">{{'AuthenticatorBind.Title'|translate}}</div>
        </div>

        <!-- <mat-chip-listbox aria-label="Fish selection" style="display: flex;align-items: center;"
            (change)="demoSelected($event)">
            <mat-chip-option *ngFor="let chip of demo" [selected]="chip.selected" [color]="chip.color">
                {{chip.name}}
            </mat-chip-option>
        </mat-chip-listbox> -->

    </div>

    <div class="body">

        <div class="block-content" *ngIf="stage=='1'">

            <div class="pay-box style-8" style="word-wrap: break-word;display: inline;">
                {{'AuthenticatorBind.LauchAuthApp'|translate}}<a href="#" class="style-8"
                    style="color: #5655B9;">(Google
                    Authenticator)</a><br />
                {{'AuthenticatorBind.ScanAndBind'|translate}}
            </div>

            <div class="pay-box" style="justify-content: center;align-items: center;" *ngIf="qrcodeUrl">
                <qrcode style="display: flex;" [qrdata]="qrcodeUrl" [width]="256" [errorCorrectionLevel]="'M'">
                </qrcode>
            </div>

            <div class="pay-box" style="margin-top: 20px;">
                <div class="style-4">{{'AuthenticatorBind.SecretCode'|translate}}</div>
                <div class="display-box"> 
                    <div class="totp-code">{{qrcodeUrl}}</div>
                    <div matRipple centered radius="60" class="copy" [cdkCopyToClipboard]="qrcodeUrl" (click)="onCopy()">
                        <mat-icon>content_copy</mat-icon>
                    </div>
                </div>
            </div>

            <mat-divider style="margin: 20px 40px 30px 40px;"></mat-divider>

            <div class="pay-box">
                <div class="pay-box-title style-4">{{'AuthenticatorBind.EnterVerifyCode'|translate}}</div>
                <div class=" pay-box-row" style="width: 100%;">
                    <mat-form-field appearance="outline" style="border-radius: 15px;width: 100%;">
                        <input matInput maxlength="6" [formControl]="verifyCodeCtl" placeholder="{{'AuthenticatorBind.DigitCount'|translate}}">
                    </mat-form-field>
                </div>
            </div>

            <div class="pay-box">
                <div class="pay-box-title style-4">{{'AuthenticatorBind.EnterLoginPasswd'|translate}}</div>
                <div class=" pay-box-row" style="width: 100%;">
                    <mat-form-field appearance="outline" style="border-radius: 15px;width: 100%;">
                        <input matInput [formControl]="passwordCtl" [type]="hidePasswd ? 'password' : 'text'" placeholder="{{'AuthenticatorBind.LoginPassword'|translate}}">
                        <mat-icon matSuffix (click)="hidePasswd = !hidePasswd">{{hidePasswd ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </mat-form-field>
                </div>
            </div>

            <div class="pay-box" style="margin-bottom: 20px;">
                <button mat-flat-button color="primary" [disabled]="verifyCodeCtl.status !== 'VALID' || passwordCtl.status !== 'VALID'" (click)="onBind()" class="verify-bind-btn">{{'AuthenticatorBind.VerifyBind'|translate}}</button>
            </div>

        </div>

        <!-- 完成畫面 -->
        <div class="block-content" *ngIf="stage=='2'">

            <img class="banner-img" src="/assets/images/authenticator_bind/banner.png" />

            <div class="pay-box">
                <div class="style-5" style="text-align: center; margin-top: 30px;">
                    {{'AuthenticatorBind.BindSuccess'|translate}}
                </div>
                <div class="style-3" style="text-align: center; margin-top: 30px;">
                    {{'AuthenticatorBind.BindSuccessInfo-1'|translate}}<br />
                    {{'AuthenticatorBind.BindSuccessInfo-2'|translate}}
                </div>
            </div>

            <div class="pay-box pay-box-with-hit" style="margin-top: 30px;">
                <button mat-flat-button color="primary" class="next-btn"
                    routerLink="/dashboard/setting">{{'Common.OK'|translate}}</button>
            </div>
        </div>

    </div>

</div>