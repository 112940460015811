<div class="container">

    <div class="top">
        <div class="backBtn" routerLink="/records/{{recordType}}">
            <img class="backBtnImg" alt="image" src="/assets/images/icons/arrow-down-sign-to-navigate.png" />
            <div class="backBtnTxt">{{transTitle! | translate}}</div>
        </div>
    </div>

    <div class="body">
        <div class="card">
            <div class="flex" style="margin-bottom: 20px;display: flex;">
                <button mat-button color="accent" routerLink="/records/{{recordType}}">{{'Common.Return'|translate}}</button>
            </div>
            <!-- <button mat-flat-button color="primary" class="copyInfo">{{'Records.CopyDetail'|translate}}</button> -->
            <mat-divider style="margin: 15px 40px;width: 90%;"></mat-divider>
            <div class="details">
                <div class="row" *ngFor="let info of infomations">
                    <div class="subject">{{info.fieldName|translate}}</div>
                    <div class="content" *ngIf="info.i18n" [innerHTML]="info.fieldContent|translate|safeHtml"></div>
                    <div class="content" *ngIf="!info.i18n" [innerHTML]="info.fieldContent|safeHtml"></div>
                </div>
            </div>
        </div>
    </div>
</div>