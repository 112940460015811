<div class="container">
    <!-- <p>login works!</p> -->

    <div class="top">
        <div class="backBtn" >
            <!-- <img class="backBtnImg" alt="image" src="/assets/images/navi_arrow_back.svg" /> -->
            <div class="backBtnTxt">{{'Register.Register'|translate}}</div>
        </div>
    </div>

    <div class="body">

        <div class="titleBox">
            <div class="title">{{'Register.Welcome-1'|translate}}</div>
            <div class="subtitle">{{'Register.Welcome-2'|translate}}</div>
        </div>
        <img class="banner" src="/assets/images/register_banner.svg" />

        <form [formGroup]="signin" style="width: 80%; margin-top: 30px;">

            <mat-form-field appearance="outline" style="width: 100%;">
                <input matInput placeholder="{{'Register.EnterEmail'|translate}}" [type]="'text'"
                    formControlName="email" required>
                <!-- <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon> -->
                <!-- <mat-hint *ngIf="!passwordInput?.value">Enter your password</mat-hint> -->
            </mat-form-field>

            <!-- 驗證碼 -->
            <div class="row">
                <mat-form-field appearance="outline" style="width: 100%;">
                    <input matInput placeholder="{{'Register.EnterVerify'|translate}}" [type]="'text'" maxlength="6"
                        formControlName="verify" required>
                    <!-- <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon> -->
                    <!-- <mat-hint *ngIf="!passwordInput?.value">Enter your password</mat-hint> -->
                </mat-form-field>
                <button mat-flat-button color="primary" class="sendCodeBtn" [disabled]="sendVerifyCodeFreeze || verifyCodeTimeout>0" (click)="onSendVerifyCode()">{{verifyCodeTimeout>0?remainTime:'Register.Send'|translate}}</button>
            </div>

            <mat-form-field appearance="outline"  class="row">
                <input matInput placeholder="{{'Common.Password'|translate}}" [type]="hide ? 'password' : 'text'"
                    formControlName="password" style="border-radius: 25px;" required>
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                <!-- <mat-hint *ngIf="!passwordInput?.value">Enter your password</mat-hint> -->
            </mat-form-field>

            <mat-checkbox  class="row"
                [formControl]="agreeControl">{{'Register.Aggrement-1'|translate}}<br />{{'Register.Aggrement-2'|translate}}<a
                href="#">{{'Register.Aggrement-3'|translate}}</a></mat-checkbox>
        </form>
        <button mat-flat-button color="primary" class="registerBtn" [disabled]="(registerBtnFreeze || !agreement)" (click)="register()">{{'Register.Register'|translate}}</button>
        <div class="footer">
            <div style="display: flex;">{{'Register.HaveAccount' | translate}}</div>
            <a href="#" routerLink="/login" >{{'Login.Login' | translate}}</a>
        </div>
    </div>
</div>