import { Component, Inject, Input, inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-link-snackbar',
  templateUrl: './link-snackbar.component.html',
  styleUrls: ['./link-snackbar.component.scss'],
})
export class LinkSnackbarComponent {
  hrefName: string;
  hrefUrl: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: { url: string; name: string }
  ) {
    this.hrefName = data.name;
    this.hrefUrl = data.url;
  }
  snackBarRef = inject(MatSnackBarRef);
}
