<div class="container">
  <div class="top">
    <div class="backBtn" routerLink="/dashboard/setting">
      <img class="backBtnImg" alt="image" src="/assets/images/icons/arrow-down-sign-to-navigate.png" />
      <div class="backBtnTxt">{{title | translate}}</div>
    </div>
  </div>

  <div class="body">

    <div class="block-content">
      <div class="subtitle" style="margin-top: 30px;">输入验证码</div>
      <form style="width: 85%; margin-top: 10px;">
        <div class="row">
            <mat-form-field appearance="outline" style="width: 100%;">
                <input matInput placeholder="{{'Register.EnterVerify'|translate}}" [type]="'text'"
                    [formControl]="verifyCode" [maxLength]="6" required>
                <!-- <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon> -->
                <!-- <mat-hint *ngIf="!passwordInput?.value">Enter your password</mat-hint> -->
            </mat-form-field>
            <button mat-flat-button color="primary" class="sendCodeBtn" [disabled]="verifyCodeTimeout>0" (click)="onSendVerifyCode()">{{verifyCodeTimeout>0 ? (remainTime|CountdownTime) : ('Register.Send'|translate)}}</button>
        </div>
      </form>

      <div class="desc" style="margin-top: 30px;">我们向您的email/手机号发送验证码</div>
      <div class="desc2" style="margin-top: 5px;">{{userEmail ?? '' | PrincipalMask}}</div>
      <div class="desc" style="margin-top: 20px;">验证码将在收到此消息后 10 分钟后过期。如果您仍没有收到消息，请点击重发。</div>
      <button mat-flat-button color="primary" class="verify-btn" [disabled]="!verifyCodeValid || !vToken" (click)="onVerify()">{{verifyBtnName|translate}}</button>
    </div>
  </div>
</div>