<div class="container">

    <h3>{{'Dev.Lang' | translate}}</h3>
    <mat-form-field>
        <mat-label>{{'Dev.Lang' | translate}}</mat-label>
        <mat-select [(value)]="selectLang">
            <mat-option *ngFor="let lang of langs" [value]="lang.code">
                {{lang.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <h3>{{'Dev.Page' | translate}}</h3>
    <div class="example-button-row">
        <!-- <button mat-stroked-button>Basic</button>
        <button mat-stroked-button color="primary">Primary</button>
        <button mat-stroked-button color="accent">Accent</button>
        <button mat-stroked-button color="warn">Warn</button>
        <button mat-stroked-button disabled>Disabled</button> -->

        <a mat-stroked-button routerLink="/startup">{{'Dev.StartupPage' | translate}}</a>
        <!-- <a mat-stroked-button routerLink="/guide">導引頁</a> -->
        <a mat-stroked-button routerLink="/login">{{'Dev.LoginPage' | translate}}</a>
        <a mat-stroked-button routerLink="/register">{{'Dev.RegisterPage' | translate}}</a>
        <a mat-stroked-button routerLink="/2fabind">{{'Dev.GoogleAuthPage' | translate}}</a>
        <a mat-stroked-button routerLink="/dashboard">{{'Dev.DashboardPage' | translate}}</a>
        <a mat-stroked-button routerLink="/withdraw">{{'Dev.WithdrawPage' | translate}}</a>
        <a mat-stroked-button routerLink="/recharge">{{'Dev.RechargePage' | translate}}</a>
        <a mat-stroked-button routerLink="/trans-password">{{'Dev.TransPassword' | translate}}</a>
        <a mat-stroked-button routerLink="/exchange">{{'Dev.ExchangePage' | translate}}</a>
        <a mat-stroked-button routerLink="/announcement">{{'Dev.AnnouncementPage' | translate}}</a>
        <a mat-stroked-button routerLink="/records">{{'Dev.WithdrawRecord' | translate}}</a>
        <a mat-stroked-button routerLink="/notifications">{{'Dev.Notifications' | translate}}</a>
        <a mat-stroked-button routerLink="/binding-mobile">{{'Dev.BindingMobile' | translate}}</a>
        <a mat-stroked-button routerLink="/wallet">錢包</a>

    </div>
</div>