import { Component } from '@angular/core';
import { LocalService } from '../../services/local.service';
import { SessionService } from '../../services/session.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-launcher',
  templateUrl: './launcher.component.html',
  styleUrls: ['./launcher.component.scss'],
})
export class LauncherComponent {
  constructor(
    private local: LocalService,
    private session: SessionService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    // this.route.params.subscribe((params: Params) => {});

    // 第一次啟動
    if (this.local.isFirstTimeApp) {
      this.local.setAppFired();
      this.router.navigate(['startup']);
      return;
    }

    // 檢查 Session, 已經登入就導去首頁
    if (this.session.context?.sessionId) {
      this.router.navigate(['dashboard']);
      return;
    }

    // 預設導去登入頁
    this.router.navigate(['login']);
  }

  ngOnDestroy() {}
}
