import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface CurrencyDialogData {
  currency: {
    code: string;
    name: string;
    selected: boolean;
  }[];
}

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent {
  constructor(
    public dialogRef: MatDialogRef<LanguageSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CurrencyDialogData
  ) {
    // console.log(`Dialog Data:`, data);
  }

  onSelect(code: string): void {
    this.dialogRef.close(code);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
