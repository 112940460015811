import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StartupComponent } from './pages/startup/startup.component';
import { DevGuideComponent } from './pages/dev-guide/dev-guide.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { WithdrawComponent } from './pages/withdraw/withdraw.component';
import { RechargeComponent } from './pages/recharge/recharge.component';
import { AuthenticatorBindComponent } from './pages/authenticator-bind/authenticator-bind.component';
import { DevComponent } from './pages/dev/dev.component';
import { TransPasswordComponent } from './pages/trans-password/trans-password.component';
import { ExchangeComponent } from './pages/exchange/exchange.component';
import { AnnouncementDetailComponent } from './pages/announcement-detail/announcement-detail.component';
import { RecordDetailComponent } from './pages/record-detail/record-detail.component';
import { RecordListComponent } from './pages/record-list/record-list.component';
import { NotifiyListComponent } from './pages/notifiy-list/notifiy-list.component';
import { BindingMobileComponent } from './pages/binding-mobile/binding-mobile.component';
import { InitComponent } from './pages/init/init.component';
import { SessionGuard } from './provider/session-guard.provider';
import { WalletComponent } from './pages/wallet/wallet.component';
import { VerifyComponent } from './pages/verify/verify.component';
import { SetTrxPasswordComponent } from './pages/set-trx-password/set-trx-password.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { BeneficiaryListComponent } from './pages/beneficiary-list/beneficiary-list.component';
import { AddBeneficiaryComponent } from './pages/add-beneficiary/add-beneficiary.component';
import { BeneficiaryDetailComponent } from './pages/beneficiary-detail/beneficiary-detail.component';
import { LauncherComponent } from './pages/launcher/launcher.component';

const routes: Routes = [
  // { path: "", component: InitComponent },
  { path: '', component: LauncherComponent },
  { path: 'dev', component: DevComponent },
  { path: 'startup', component: StartupComponent },
  { path: 'guide', component: DevGuideComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [SessionGuard],
  },
  {
    path: 'dashboard/:tab',
    component: DashboardComponent,
    canActivate: [SessionGuard],
  },
  {
    path: 'totp-bind/:totp',
    component: AuthenticatorBindComponent,
    canActivate: [SessionGuard],
  },
  {
    path: 'totp-bind',
    component: AuthenticatorBindComponent,
    canActivate: [SessionGuard],
  },
  {
    path: 'trxpasswd',
    component: SetTrxPasswordComponent,
    canActivate: [SessionGuard],
  },
  {
    path: 'trans-password',
    component: TransPasswordComponent,
    canActivate: [SessionGuard],
  },
  {
    path: 'recharge',
    component: RechargeComponent,
    canActivate: [SessionGuard],
  },
  {
    path: 'recharge/:currency',
    component: RechargeComponent,
    canActivate: [SessionGuard],
  },
  {
    path: 'exchange',
    component: ExchangeComponent,
    canActivate: [SessionGuard],
  },
  {
    path: 'exchange/:currency',
    component: ExchangeComponent,
    canActivate: [SessionGuard],
  },
  {
    path: 'withdraw',
    component: WithdrawComponent,
    canActivate: [SessionGuard],
  },
  {
    path: 'withdraw/:currency',
    component: WithdrawComponent,
    canActivate: [SessionGuard],
  },
  {
    path: 'announcement/:id',
    component: AnnouncementDetailComponent,
    canActivate: [SessionGuard],
  },
  {
    path: 'records/:type',
    component: RecordListComponent,
    canActivate: [SessionGuard],
  },
  {
    path: 'forgot/:type',
    component: ForgotPasswordComponent,
    canActivate: [SessionGuard],
  },
  {
    path: 'forgot',
    component: ForgotPasswordComponent,
    // canActivate: [SessionGuard],
  },
  // { path: 'record-detail', component: RecordDetailComponent },
  {
    path: 'record-detail/:type/:id',
    component: RecordDetailComponent,
    canActivate: [SessionGuard],
  },
  {
    path: 'notifications',
    component: NotifiyListComponent,
    canActivate: [SessionGuard],
  },
  {
    path: 'binding-mobile',
    component: BindingMobileComponent,
    canActivate: [SessionGuard],
  },
  {
    path: 'wallet',
    component: WalletComponent,
    canActivate: [SessionGuard],
  },
  {
    path: 'verify/:type',
    component: VerifyComponent,
    canActivate: [SessionGuard],
  },
  {
    path: 'beneficiaries',
    component: BeneficiaryListComponent,
    canActivate: [SessionGuard],
  },
  {
    path: 'add-beneficiary',
    component: AddBeneficiaryComponent,
    canActivate: [SessionGuard],
  },
  {
    path: 'beneficiary/:id',
    component: BeneficiaryDetailComponent,
    canActivate: [SessionGuard],
  },
  { path: '**', redirectTo: 'startup' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [SessionGuard],
})
export class CoreRoutingModule {}
