import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ClientService } from '../../services/client.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SessionService } from '../../services/session.service';
import { EmailVerifyPair, LocalService } from '../../services/local.service';
import { EquealWordsValidator } from '../../common/Validators';
import { PromiseUtil } from '../../common/PromiseUtil';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  // 1. 注册：email验证通过 -> 注册成功
  // 2. 登录：登录密码 + 谷歌验证
  // 3. 忘记登录密码/交易密码：email验证 + 谷歌验证 -> 输入新密码
  // 4. 绑定谷歌验证：email验证 → 绑定谷歌验证
  // 5. 付款：交易密码 + 谷歌验证 -> 送出付款交易
  // 6. 汇兑：交易密码 -> 送出汇兑交易
  // 7. 设置交易密码

  hide = true;

  type: string = 'fogot';
  title: string = 'ForgotPassword.Title';
  backBtn: string = '/login';

  userEmail: FormControl;
  verifyCode: FormControl;
  newPasswd: FormControl;
  confirmPasswd: FormControl;
  totpCode: FormControl;
  group: FormGroup;

  // 發送信件冷卻時間
  // verifyCodeTimeout = 0;

  // 冷卻倒數
  remainTime = 0;
  countdownTimer?: NodeJS.Timer;

  stage = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private client: ClientService,
    private snackBar: MatSnackBar,
    private session: SessionService,
    private local: LocalService,
    private location: Location,
    private translate: TranslateService
  ) {
    this.verifyCode = new FormControl('', [
      Validators.required,
      Validators.pattern(/^[0-9]{6}$/),
    ]);
    this.userEmail = new FormControl('', [
      Validators.required,
      Validators.email,
    ]);

    this.newPasswd = new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]);
    this.confirmPasswd = new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      EquealWordsValidator(this.newPasswd),
    ]);

    this.totpCode = new FormControl('', [
      Validators.required,
      Validators.pattern(/^[0-9]{6}$/),
    ]);

    this.newPasswd.valueChanges.subscribe({
      next: () => {
        this.confirmPasswd.updateValueAndValidity();
      },
    });
    this.group = new FormGroup({
      verify: this.verifyCode,
      trxpasswd: this.newPasswd,
      trxconfirm: this.confirmPasswd,
      totp: this.totpCode,
    });
  }

  ngOnInit() {
    // 初始化參數
    this.route.params.subscribe((params: Params) => {
      console.log('忘記密碼啟動', params);
      if (params && 'type' in params) {
        this.type = params['type'];
        if (this.type === 'reset') {
          this.title = 'ForgotPassword.Title2';
          this.backBtn = '/dashboard/setting';
        }
      }
      this.location.replaceState('/forgot');
      // this.noticeId = params['id'];
      // 找不到公告
      // this.router.navigate(['dashboard', 'announcement']);
    });

    // 初始計時器
    this.countdownTimer = setInterval(() => {
      this.countDownHandler();
    }, 100);
  }

  ngOnDestroy() {
    // 清理計時器
    if (this.countdownTimer) {
      clearInterval(this.countdownTimer as unknown as number);
    }
  }

  // 驗證用的 token
  get vToken(): string | undefined {
    return this.verifyCodeParams.token;
  }

  get verifyCodeTimeout(): number {
    return this.verifyCodeParams.timeout;
  }

  // 發送信件冷卻時間
  get verifyCodeParams(): EmailVerifyPair {
    const data = this.local.GetEmailVerifyToken('FORGOT_PASSWORD');
    if (!data) {
      return { token: undefined, timeout: 0 };
    }
    return data;
  }

  // 發送信件冷卻時間
  set verifyCodeParams(data: EmailVerifyPair) {
    // if (this.userEmail.status !== 'VALID') {
    //   return;
    // }
    // const email = this.userEmail.value;
    // this.local.SetEmailVerifyLimiter('FORGOT_PASSWORD', val);
    this.local.SetEmailVerifyToken('FORGOT_PASSWORD', data.timeout, data.token);
  }

  public onSendVerifyCode() {
    if (this.verifyCodeParams.timeout > 0) {
      return;
    }

    this.sendVerifyCode();
  }

  public async onVerify() {
    if (this.group.status !== 'VALID') {
      return;
    }

    try {
      const resp = await this.client.resetPassword(
        this.userEmail.value,
        this.newPasswd.value,
        this.totpCode.value,
        this.vToken!,
        this.verifyCode.value
      );
      if (resp) {
        // 修改成功訊息
        const trans = await PromiseUtil.FromObservable(
          this.translate.get('ForgotPassword.PasswordResetComp')
        );
        this.showSnackbar(trans);
        await PromiseUtil.wait(500);
        this.router.navigate(['login']);
      }
    } catch (err) {
      console.error(`壞掉了....`, err);
      if (err instanceof Error) {
        this.showSnackbar(err.message);
      } else {
        this.showSnackbar(`${err}`);
      }
    }
  }

  // 發送驗證碼
  private async sendVerifyCode() {
    if (!this.userEmail.value) {
      return;
    }
    this.verifyCodeParams = { timeout: Date.now() + 600 * 1000 }; // 預設 CD 10min
    try {
      const token = await this.client.sendVerifyCodeViaEmail(
        this.userEmail.value,
        'FORGOT_PWD'
      );
      // 更新 Token
      this.verifyCodeParams = {
        token,
        timeout: Date.now() + 600 * 1000,
      }; // 預設 CD 10min
      if (this.stage == 0) {
        this.stage = 1;
      }
    } catch (err) {
      console.error(err);
      this.verifyCodeParams = { timeout: Date.now() + 60 * 1000 }; // 異常 CD 1min
      if (err instanceof Error) {
        this.showSnackbar(err.message);
      } else {
        this.showSnackbar(`${err}`);
      }
    }
  }

  private countDownHandler() {
    if (this.verifyCodeParams.timeout > 0) {
      let t = Date.now() - this.verifyCodeParams.timeout;
      if (t > 0) {
        this.verifyCodeParams = { timeout: 0 }; // 清空
        this.remainTime = 0;
      } else {
        this.remainTime = Math.floor(t / -1000);
      }
    }
  }

  private showSnackbar(message: string) {
    this.snackBar.open(message, 'OK', {
      duration: 1000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
