<div class="container">

    <div class="top">
        <div class="backBtn" (click)="onGoBack()">
            <img class="backBtnImg" alt="image" src="/assets/images/icons/arrow-down-sign-to-navigate.png" />
            <div class="backBtnTxt">{{'Beneficiary.Title3'|translate}}</div>
        </div>
    </div>

    <div class="body">

      <div class="block-content">
        <!-- 出金帳戶收到 -->
        <div class="block col justify-content-between">
            <div class="row justify-content-between align-top">
                <div class="style-4">{{'新增日期'}}</div>
                <div class="style-8 text-align-right">{{(beneficiary?.createTime ?? '????-??-??') | datePruner}}</div>
            </div>
        </div>

        <div class="block col justify-content-between">
            <div class="row justify-content-between align-top">
                <div class="style-4">{{'收款币别'}}</div>
                <div class="style-8 text-align-right">{{beneficiary?.accountInfo?.accountCurrency}}</div>
            </div>
        </div>

        <div class="block col justify-content-between">
            <div class="row justify-content-between align-top">
                <div class="style-4">{{'帐户类型'}}</div>
                <!-- <div class="style-9 text-align-right">{{ beneficiary?.beneficiaryType | accountTypeName | async}}</div> -->
                <div class="style-9 text-align-right">{{ beneficiary?.accountInfo?.accountType| accountTypeName | async}}</div>
            </div>
        </div>

        <div class="block col justify-content-between">
            <div class="row justify-content-between align-top">
                <div class="style-4">{{'帐户名'}}</div>
                <div class="style-8 text-align-right">{{beneficiary?.accountInfo?.accountName}}</div>
            </div>
        </div>

        <div class="block col justify-content-between">
            <div class="row justify-content-between align-top">
                <div class="style-4">{{'SWIFT号'}}</div>
                <div class="style-8 text-align-right">{{beneficiary?.bankInfo?.clearingSysNumber}}</div>
            </div>
        </div>

        <div class="block col justify-content-between">
            <div class="row justify-content-between align-top">
                <div class="style-4">{{'银行帐号'}}</div>
                <div class="style-8 text-align-right">{{beneficiary?.accountInfo?.accountNumber}}</div>
            </div>
        </div>

        <div class="block col justify-content-between">
            <div class="row justify-content-between align-top">
                <div class="style-4">{{'备注'}}</div>
                <div class="style-8 text-align-right">{{beneficiary?.beneficiaryNickname}}</div>
            </div>
        </div>

        <mat-divider style="width: 100%; margin:10px 0"></mat-divider>

        <!-- 送出/下一步 -->
        <div class="col" *ngIf="beneficiary">
            <button mat-flat-button color="primary" *ngIf="!beneficiary!.favorite" (click)="onFavorite()" class="favorite-btn">{{'Beneficiary.SetFavorite'|translate}}</button>
            <button mat-flat-button color="Disabled" *ngIf="beneficiary!.favorite" (click)="onDisfavor()" class="disfavor-btn">{{'Beneficiary.CancelFavorite'|translate}}</button>
        </div>

         <div class="col items-center">
            <div matRipple centered radius="60" class="del-btn" (click)="onDelete()">刪除</div>
        </div>

      </div>

    </div>
</div>