import { Component } from '@angular/core';

@Component({
  selector: 'app-dev-guide',
  templateUrl: './dev-guide.component.html',
  styleUrls: ['./dev-guide.component.scss']
})
export class DevGuideComponent {
  title = 'h5app2';
}
