<div class="top" #eref>
    <div class="head-info">
        <img class="head-img" alt="image" src="/assets/images/head.png" />
        <div class="head-text">{{'Dashboard.Hello'|translate}}</div>
    </div>

    <div class="badage" routerLink="/notifications">
        <mat-icon matBadge="{{notifyBadgeCount}}" matBadgeHidden="{{notifyBadgeCount==0}}" matBadgeColor="warn">notifications</mat-icon>
        <span class="cdk-visually-hidden">
            Example with a home icon with overlaid badge showing the number 15
        </span>
    </div>
</div>

<div class="body">
    <div class="info-block">
        <div class="info-title">
            <div class="currency-title">{{'Dashboard.TotalBalance'|translate}}</div>

            <button mat-button class="currency-btn" color="primary" (click)="showCurrencySelect()">{{fxCurrency}}
                <mat-icon iconPositionEnd>expand_more</mat-icon>
            </button>

        </div>
        <p class="info-main">{{totalByCurrency | walletCurrency}}</p>
        <p class="info-sub">≈ {{totalByUSD | walletCurrency}} USD</p>

    </div>

    <div class="announcement" *ngIf="announcementMarqueeContents.length>0">
        <!-- <mat-icon>campaign</mat-icon> -->
        <img class="notice-icon" src="/assets/images/icons/pepicons-pop_bulletin-notice.png">
        <!-- <div>這是跑馬燈公告！!</div> -->
        <div class="marquee-container">
            <!-- <div class="marquee-content" *ngFor="let text of announcementMarqueeContents">
                {{text}}
            </div> -->
            <div class="marquee-content" [ngClass]="{'fade-in':animateFadeIn, 'fade-out':animateFadeOut}">
                {{announcementMarqueeContent}}
            </div>
        </div>
    </div>
    <div class="menu">
        <div class="menu-item" routerLink="/exchange">
            <img src="/assets/images/icons/devices.png"  />
            <div class="desc">{{'Common.Exchange'|translate}}</div>
        </div>
        <div class="menu-item" routerLink="/recharge">
            <img src="/assets/images/icons/pig.png" />
            <div class="desc">{{'Common.Recharge'|translate}}</div>
        </div>
        <div class="menu-item" routerLink="/withdraw">
            <img src="/assets/images/icons/creditcard.png" />
            <div class="desc">{{'Common.Withdraw'|translate}}</div>
        </div>
    </div>
    <div class="menu" >
        <div class="menu-item" routerLink="/wallet">
            <img src="/assets/images/icons/wallet.png" />
            <div class="desc">{{'Common.Wallet'|translate}}</div>
        </div>
        <div class="menu-item" routerLink="/records/wallet" >
            <img src="/assets/images/icons/paragraph.png" />
            <div class="desc">{{'Dashboard.BalanceAccount'|translate}}</div>
        </div>
        <div class="menu-item" routerLink="/beneficiaries">
            <img src="/assets/images/icons/contacts.png" />
            <div class="desc">{{'Dashboard.WithdrawAccount'|translate}}</div>
        </div>
    </div>
    <div class="menu" >
        <div class="menu-item" (click)="onChangeTab('deal')">
            <img src="/assets/images/icons/deal.png" />
            <div class="desc">{{'Common.Deal'|translate}}</div>
        </div>
        <div class="menu-item" routerLink="" style="display: none;" >
            <img src="/assets/images/icons/paragraph.png" />
            <div class="desc">{{'Dashboard.BalanceAccount'|translate}}</div>
        </div>
        <div class="menu-item" routerLink="" style="display: none;" >
            <img src="/assets/images/icons/contacts.png" />
            <div class="desc">{{'Dashboard.WithdrawAccount'|translate}}</div>
        </div>
    </div>
</div>