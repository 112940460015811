import { Injectable } from '@angular/core';
import { ClientService } from './client.service';

export interface UserContext {
  clientId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  private userContext: UserContext;

  constructor(private client: ClientService) {
    this.userContext = {};
  }

  public set ClientId(val: string | undefined) {
    this.userContext.clientId = val;
  }

  public get ClientId(): string | undefined {
    return this.userContext.clientId;
  }

  public async getUserBalances(currency?: string) {
    try {
      return (await this.client.balanceList(currency)).filter(
        (b) => b.balance > 0
      );
    } catch (err) {}
    return [];
  }
}
