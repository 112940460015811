import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipListboxChange } from '@angular/material/chips';
import { ClientService } from '../../services/client.service';
import { UserDataService } from '../../services/user-data.service';
import { PromiseUtil } from '../../common/PromiseUtil';
import { FeeInfo, UserBalance } from '../../domain/network/response';
import { CountryCode } from '../../definition/CountryCode';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';
import { LocalService } from '../../services/local.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';
import { SessionService } from '../../services/session.service';
import { CreateBeneficiary } from '../../domain/form/beneficiary';

interface FormControlMap {
  currency: string;
  accountName: string;
  bankAccount: string;
  bankAddress1: string;
  bankAddress2: string;
  bankAddress3: string;
  bankLocation: string;
  bankName: string;
  beneficiaryLocation: string;
  beneficiaryAddress1: string;
  beneficiaryAddress2: string;
  beneficiaryAddress3: string;
  beneficiaryName: string;
  swiftCode: string;
  withdrawAccountType: string;
  additionalInfo: string;
  beneficiaryNickname: string;
  favors: boolean;
}

@Component({
  selector: 'app-add-beneficiary',
  templateUrl: './add-beneficiary.component.html',
  styleUrls: ['./add-beneficiary.component.scss'],
})
export class AddBeneficiaryComponent {
  stage = '1';
  stages: { name: string; color?: string; selected?: boolean }[] = [
    { name: '1' }, // 輸入
    { name: '2' }, // 完成畫面
  ];

  stageStack: string[] = [];

  withdrawCurrencyMenu: string[] = []; // 出款幣別清單
  withdrawCurrency: string = 'USD'; // 出款幣別

  beneficiaryNameCtl?: FormControl;

  withdrawFormCtl: FormGroup = new FormGroup({
    // 支付幣別
    currency: new FormControl(this.withdrawCurrency, [
      Validators.minLength(2),
      Validators.required,
    ]),
    // 帳號名
    accountName: new FormControl('', [
      Validators.minLength(2),
      Validators.required,
    ]),
    // 銀行帳號
    bankAccount: new FormControl('', [
      Validators.minLength(6),
      Validators.required,
    ]),
    // 銀行名
    bankName: new FormControl('', [
      Validators.minLength(2),
      Validators.required,
    ]),
    // 銀行地址 -1
    bankAddress1: new FormControl('', [
      Validators.minLength(10),
      Validators.maxLength(35),
      Validators.required,
    ]),
    // 銀行地址 -2
    bankAddress2: new FormControl('', [Validators.maxLength(35)]),
    // 銀行地址 -3
    bankAddress3: new FormControl('', [Validators.maxLength(35)]),
    // 銀行所在地/國碼
    bankLocation: new FormControl('', [
      Validators.minLength(2),
      Validators.maxLength(2),
      Validators.required,
    ]),
    // SWIFT
    swiftCode: new FormControl('', [
      Validators.minLength(2),
      Validators.maxLength(30),
      Validators.required,
    ]),
    // 帳戶類型 (personal, business)
    withdrawAccountType: new FormControl('', [
      Validators.minLength(2),
      Validators.maxLength(16),
      Validators.required,
    ]),
    // 收款人名
    beneficiaryName: new FormControl('', [
      Validators.minLength(2),
      Validators.required,
    ]),
    beneficiaryLocation: new FormControl('', [
      Validators.minLength(2),
      Validators.maxLength(2),
    ]),
    // 收款人地址 -1
    beneficiaryAddress1: new FormControl('', [
      Validators.minLength(10),
      Validators.maxLength(35),
      Validators.required,
    ]),
    // 收款人地址 -2
    beneficiaryAddress2: new FormControl('', [Validators.maxLength(35)]),
    // 收款人地址 -3
    beneficiaryAddress3: new FormControl('', [Validators.maxLength(35)]),
    // 備註訊息
    beneficiaryNickname: new FormControl('', [
      Validators.pattern(/^[\u4e00-\u9fa5a-zA-Z0-9\s]*$/),
      Validators.maxLength(35),
    ]),
    // 常用出金帳號
    favors: new FormControl(false, []),
  });

  beneficiaryForm?: CreateBeneficiary;

  // 國家地區選單
  countryCodeMenu: { code: string; name: string }[] = [];

  // 出款帳戶類型選單
  accountTypeMenu = [
    { code: 'BUSINESS', name: 'Common.AccountType.Business' },
    // { code: 'PERSONAL', name: 'Common.AccountType.Personal' },
    { code: 'INDIVIDUAL', name: 'Common.AccountType.Personal' },
  ];

  // 是否為個人收款人
  isIndividualBeneficiary = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private client: ClientService,
    private userData: UserDataService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private location: Location,
    private local: LocalService,
    private session: SessionService
  ) {
    this.stages.forEach((e) => {
      e.selected = e.name == this.stage;
    });
  }

  ngOnInit() {
    // 初始化參數
    this.route.params.subscribe((params: Params) => {
      // if (params['currency']) {
      //   this.location.replaceState('/withdraw');
      //   this.withdrawCurrency = params['currency'];
      //   this.defaultCurrency = params['currency'];
      //   this.updateWithdrawBalance();
      // }
    });

    this.onInit();

    // 灌假資料, 才不會測到死
    if (this.local.DevModes.includes('withdraw')) {
      const fake = {
        accountName: '伊莉莎白',
        bankAccount: '123456789',
        bankAddress1: '三民路三段161號C棟',
        bankAddress2: '台中市北區',
        bankAddress3: '',
        bankLocation: 'TW',
        bankName: '王道銀行',
        beneficiaryLocation: 'TW',
        beneficiaryAddress1: '台中市西區民權路87號',
        beneficiaryAddress2: '',
        beneficiaryAddress3: '',
        beneficiaryName: '伊莉莎白',
        swiftCode: 'RUBY-5678',
        withdrawAccountType: 'BUSINESS',
        beneficiaryNickname: '缺錢請找我',
      };
      for (let [k, v] of Object.entries(fake)) {
        this.withdrawFormCtl.get(k)?.setValue(v);
      }
    }
  }

  ngOnDestroy() {}

  // 異步初始化流程
  async onInit() {
    // 初始化國家地區表單
    this.countryCodeMenu = [];
    for (let [key, value] of Object.entries(CountryCode)) {
      this.countryCodeMenu.push({
        code: key,
        name: `ConutryCode.${value}`,
      });
    }
    this.countryCodeMenu.sort((a, b) => a.code.localeCompare(b.code));

    // 取得支援幣種
    const balances = await this.client.balanceList();
    this.withdrawCurrencyMenu = [];
    if (balances.length > 0) {
      this.withdrawCurrency = this.withdrawCurrency || balances[0].currency;
      for (let b of balances) {
        this.withdrawCurrencyMenu.push(b.currency);
      }
    }

    // end
  }

  // 是否有收款人名字格式錯誤
  get hasBeneficiaryNameError() {
    return (
      this.isIndividualBeneficiary &&
      this.beneficiaryNameCtl &&
      !this.beneficiaryNameCtl!.valid &&
      this.beneficiaryNameCtl.touched
    );
  }

  // 更改收款人類型
  onBeneficiaryTypeChanged(val: string) {
    const type = this.withdrawFormCtl.get('withdrawAccountType')?.value;
    this.isIndividualBeneficiary = false;
    this.beneficiaryNameCtl = undefined;

    if (val == 'BUSINESS') {
      this.withdrawFormCtl.setControl(
        'beneficiaryName',
        new FormControl('', [Validators.minLength(2), Validators.required])
      );
    } else if (val == 'INDIVIDUAL') {
      // 個人
      this.isIndividualBeneficiary = true;
      this.beneficiaryNameCtl = new FormControl('', [
        Validators.minLength(5),
        Validators.required,
        Validators.pattern(/^[a-zA-Z]+\s+[a-zA-Z]+$/), // 英文字兩段, 中間空白間隔
      ]);
      this.withdrawFormCtl.setControl(
        'beneficiaryName',
        this.beneficiaryNameCtl
      );
    }
  }

  // 送出填寫表單
  async onCommitWithdrawForm() {
    if (!this.session.context) {
      return;
    }

    try {
      // 組建請求表單
      const requestId = `${Date.now()}`;
      const formValue: FormControlMap = this.withdrawFormCtl.value;
      console.log(formValue);
      // return;

      this.beneficiaryForm = {
        requestId,
        beneficiaryNickname: formValue.beneficiaryNickname,
        // contactEmail: '',
        // contactMobileNo: '',
        favorite: formValue.favors,
        beneficiaryName: formValue.beneficiaryName,
        beneficiaryType: formValue.withdrawAccountType, // 'INDIVIDUAL',
        beneficiaryCountryCode: formValue.beneficiaryLocation,
        beneficiaryAddressLine1: formValue.bankAddress1,
        beneficiaryAddressLine2: formValue.bankAddress2,
        beneficiaryAddressLine3: formValue.bankAddress3,
        accountInfo: {
          accountType: formValue.withdrawAccountType, // 'INDIVIDUAL',
          accountCurrency: formValue.currency,
          accountName: formValue.accountName,
          accountNumber: formValue.bankAccount, // 收款人的银行账号或者收款卡号
        },
        bankInfo: {
          bankName: formValue.bankName,
          // bankBranch: 'CITIC Bank NameOf HongKong',
          bankCountryCode: formValue.bankLocation,
          bankAddressLine1: formValue.bankAddress1,
          clearingSysNumber: formValue.swiftCode,
          clearingSysType: 'SWIFT',
          bankAddressLine2: formValue.bankAddress2,
          bankAddressLine3: formValue.bankAddress3,
        },
      };

      try {
        const result = await this.client.createeneficiary(
          this.beneficiaryForm!
        );
        // this.stageStack.push(this.stage);  // 不給回頭
        this.stage = '2';
      } catch (err) {
        if (err instanceof Error) {
          this.openSnackBar(err.message);
        } else {
          this.openSnackBar(`${err}`);
        }
      }

      // this.stageStack.push(this.stage);
      // this.stage = '5';
    } catch (err) {
      if (err instanceof Error) {
        this.openSnackBar(err.message);
      }
    }
  }

  // 點擊返回
  onGoBack() {
    if (this.stageStack.length == 0) {
      this.router.navigate(['/beneficiaries']);
      return;
    }
    this.stage = this.stageStack.pop()!;
  }

  // 關閉視窗
  onClose() {
    this.router.navigate(['/beneficiaries'], { replaceUrl: true });
  }

  private openSnackBar(message: string, duration: number = 1000) {
    this.snackBar.open(message, 'OK', {
      duration: duration,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
