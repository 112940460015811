<div class="container">

    <div class="top">
        <div class="backBtn" (click)="backToHome()">
            <img class="backBtnImg" alt="image" src="/assets/images/icons/arrow-down-sign-to-navigate.png" />
            <div class="backBtnTxt">{{'Beneficiary.Title' | translate}}</div>
        </div>
        <div class="addBeneficiary" routerLink="/add-beneficiary">
            {{'Beneficiary.AddBeneficiary' | translate}}
        </div>
    </div>

    <div class="body">

        <div class="block-content">

            <div class="search">
                <div class="datetime-range">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <input matInput [matDatepicker]="pickerBegin" [formControl]="dateFrom" >
                        <mat-datepicker-toggle matIconSuffix [for]="pickerBegin"></mat-datepicker-toggle>
                        <mat-datepicker #pickerBegin></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <input matInput [matDatepicker]="pickerEnd" [formControl]="dateTo" >
                        <mat-datepicker-toggle matIconSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                        <mat-datepicker #pickerEnd></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div class="wallet">
                <div matRipple centered radius="60" class="seachBtn" (click)="onSearch()">
                    <img src="/assets/images/icons/search.png">
                </div>
                <mat-form-field appearance="outline">
                    <mat-label>{{'Beneficiary.BeneficiaryType'|translate}}</mat-label>
                    <mat-select [(value)]="selectedAccountType" (selectionChange)="onFilterChange()">
                        <mat-option></mat-option>
                        <mat-option value="INDIVIDUAL">{{ 'Beneficiary.Type.Individual' | translate }}</mat-option>
                        <mat-option value="BUSINESS">{{ 'Beneficiary.Type.Business' | translate }}</mat-option>
                    </mat-select>
                    <!-- <mat-icon matSuffix style="position: absolute;">domain</mat-icon> -->
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{'Common.Currency'|translate}}</mat-label>
                    <mat-select [(value)]="selectedCurrency" (selectionChange)="onFilterChange()">
                        <mat-option></mat-option>
                        <mat-option *ngFor="let curreny of currencyMenu" value="{{curreny}}">{{ curreny }}</mat-option>
                    </mat-select>
                    <!-- <mat-icon matSuffix style="position: absolute;">domain</mat-icon> -->
                </mat-form-field>
            </div>

            <div class="records">

                <div>
                    <!-- 查無數據 -->
                    <div class="record nodata info-style" *ngIf="!hasData">
                        {{'Dashboard.AnnoTab.Nodata'|translate}}
                    </div>

                    <!-- 數據列表 (錢包帳變)-->
                    <div matRipple centered radius="60" class="record" *ngFor="let record of beneficiaryRecord" routerLink="/beneficiary/{{record.beneficiaryId}}">
                        <div class="flex row between">
                            <div class="flex col">
                                <div class="text-row">
                                    <div class="title">{{'Beneficiary.AccountName' | translate}}</div>
                                    <div class="info">{{ record.name }}</div>
                                </div>
                            </div>
                            <mat-icon *ngIf="!record.favor" style="color:#898989">star_border</mat-icon>
                            <mat-icon *ngIf="record.favor" style="color:#FFAF2A">grade</mat-icon>
                        </div>
                        <div class="flex row between">
                            <div class="text-row">
                                <div class="title">{{ 'Beneficiary.CreateDate' | translate}}</div>
                                <div class="info">{{ record.createTime | datePruner }}</div>
                            </div>
                            <div class="text-row">
                                <div class="title">{{ 'Beneficiary.BeneficiaryType' | translate }}</div>
                                <div *ngIf="record.type == 'BUSINESS'" class="type-business">{{ 'Beneficiary.Type.Business' | translate }}</div>
                                <div *ngIf="record.type == 'INDIVIDUAL'" class="type-individual">{{ 'Beneficiary.Type.Individual' | translate }}</div>
                            </div>
                        </div>
                        <div class="flex row-reverse between">
                            <div class="text-row">
                                <div class="title">{{ 'Beneficiary.Note' | translate }}</div>
                                <div class="note">{{ record.note }}</div>
                            </div>
                            <div class="text-row">
                                <div class="title">{{ 'Beneficiary.Currency' | translate }}</div>
                                <div class="currency">{{ record.currency }}</div>
                            </div>
                        </div>
                    </div>


                    <!-- 沒有更多數據 -->
                    <div class="no-more-data info-style" *ngIf="hasData">{{'Records.NoMoreData'|translate}}</div>

                </div>

            </div>

        </div>

    </div>

</div>