import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ClientService } from '../../services/client.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateTimeUtil } from '../../common/DateTimeUtil';
import { SessionService } from '../../services/session.service';
import { validate } from 'uuid';
import { EquealWordsValidator } from '../../common/Validators';
import { TranslateService } from '@ngx-translate/core';
import { PromiseUtil } from '../../common/PromiseUtil';
import { EmailVerifyPair, LocalService } from '../../services/local.service';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-set-trx-password',
  templateUrl: './set-trx-password.component.html',
  styleUrls: ['./set-trx-password.component.scss'],
})
export class SetTrxPasswordComponent {
  // 1. 注册：email验证通过 -> 注册成功
  // 2. 登录：登录密码 + 谷歌验证
  // 3. 忘记登录密码/交易密码：email验证 + 谷歌验证 -> 输入新密码
  // 4. 绑定谷歌验证：email验证 → 绑定谷歌验证
  // 5. 付款：交易密码 + 谷歌验证 -> 送出付款交易
  // 6. 汇兑：交易密码 -> 送出汇兑交易
  // 7. 设置交易密码

  hide = true;

  verifyCode: FormControl;
  trxPassword: FormControl;
  trxPasswordConfirm: FormControl;
  totp: FormControl;
  group: FormGroup;

  verifyCodeValid = false;

  // 發送信件冷卻時間
  // verifyCodeTimeout = 0;

  // 冷卻倒數
  remainTime = 0;
  countdownTimer?: NodeJS.Timer;

  userEmail?: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private client: ClientService,
    private snackBar: MatSnackBar,
    private session: SessionService,
    private translate: TranslateService,
    private local: LocalService,
    private dialog: DialogService
  ) {
    this.verifyCode = new FormControl('', [
      Validators.required,
      Validators.pattern(/^[0-9]{6}$/),
    ]);
    this.trxPassword = new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]);
    this.trxPasswordConfirm = new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      EquealWordsValidator(this.trxPassword),
    ]);
    this.totp = new FormControl('', [
      Validators.required,
      Validators.pattern(/^[0-9]{6}$/),
    ]);

    this.trxPassword.valueChanges.subscribe({
      next: () => {
        this.trxPasswordConfirm.updateValueAndValidity();
      },
    });

    this.group = new FormGroup({
      verify: this.verifyCode,
      trxpasswd: this.trxPassword,
      trxconfirm: this.trxPasswordConfirm,
      totp: this.totp,
    });

    if (this.session.context) {
      this.userEmail = this.session.context.principal;
    }
  }

  ngOnInit() {
    // // 初始化參數
    // this.route.params.subscribe((params: Params) => {
    //   this.verifyType = params['type'];
    //   if (
    //     !this.verifyType ||
    //     !['googleauth', 'resetpassword'].includes(this.verifyType)
    //   ) {
    //     this.router.navigate(['dashboard']);
    //     return;
    //   }
    //   this.initVerifyTypes();
    // });

    // 監控輸入
    this.verifyCode.statusChanges.subscribe({
      next: (valid: string) => {
        this.verifyCodeValid = valid === 'VALID';
      },
    });

    // 初始計時器
    this.countdownTimer = setInterval(() => {
      this.countDownHandler();
    }, 100);

    this.onInit();
  }

  async onInit() {
    // 檢查設置密碼
    const r = await this.client.querySecurityStatus();
    if (r.totp == 0) {
      // 尚未設置谷歌密碼
      this.dialog.showRedirectPopup(
        'Dialog.SetOTPasswd',
        'Dialog.Goto',
        ['verify', 'googleauth'],
        ['dashboard', 'setting']
      );
    }
  }

  ngOnDestroy() {
    // 清理計時器
    if (this.countdownTimer) {
      clearInterval(this.countdownTimer as unknown as number);
    }
  }

  // 驗證用 token
  get vToken(): string | undefined {
    return this.verifyCodeParams.token;
  }

  // 發送信件冷卻時間
  get verifyCodeTimeout() {
    if (!this.session.context) return -1;
    return this.verifyCodeParams.timeout;
  }

  // 發送信件冷卻時間
  set verifyCodeTimeout(val: number) {
    if (!this.session.context) return;
    this.verifyCodeParams = { timeout: val };
  }

  // 發送信件冷卻時間
  get verifyCodeParams(): EmailVerifyPair {
    const data = this.local.GetEmailVerifyToken('SET_TRX_PASSWORD');
    if (!data) {
      return { token: undefined, timeout: 0 };
    }
    return data;
  }

  // 發送信件冷卻時間
  set verifyCodeParams(data: EmailVerifyPair) {
    this.local.SetEmailVerifyToken(
      'SET_TRX_PASSWORD',
      data.timeout,
      data.token
    );
  }

  public onSendVerifyCode() {
    if (this.verifyCodeTimeout > 0) {
      return;
    }
    this.sendVerifyCode();
  }

  public async onSetTrxPassword() {
    if (this.group.status !== 'VALID') {
      return;
    }

    if (!this.session.context) {
      return;
    }

    try {
      const succ = await this.client.setTransactionPassword(
        this.session.context!.principal!,
        this.trxPassword.value!,
        this.verifyCode.value,
        this.vToken!,
        this.totp.value
      );

      if (succ) {
        const msg = await PromiseUtil.FromObservable(
          this.translate.get('TrxPassword.Success')
        );
        this.snackBar.open(msg, 'OK', {
          duration: 1000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        await PromiseUtil.wait(1000);
        this.router.navigate(['dashboard', 'setting']);
      }
      // console.log(toptp); // "otpauth://totp/Paytranxee:netbuswhs%40gmail.com?secret=5zk6xv6rpglljz26teogsqnpisfgoecn&issuer=Paytranxee"
    } catch (err) {
      console.error(err);
      this.snackBar.open('' + err, 'OK', {
        duration: 1000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  // 發送驗證碼
  private async sendVerifyCode() {
    if (!this.userEmail) {
      return;
    }
    this.verifyCodeTimeout = Date.now() + 600 * 1000; // 預設 CD 10min
    try {
      const token = await this.client.sendVerifyCodeViaEmail(
        this.userEmail,
        'TRX_PASSWD'
      );
      this.verifyCodeParams = {
        token,
        timeout: Date.now() + 600 * 1000,
      };
    } catch (err) {
      console.error(err);
      this.verifyCodeTimeout = Date.now() + 60 * 1000; // 異常 CD 1min
    }
  }

  private countDownHandler() {
    if (this.verifyCodeTimeout > 0) {
      let t = Date.now() - this.verifyCodeTimeout;
      if (t > 0) {
        this.verifyCodeTimeout = 0;
      } else {
        this.remainTime = Math.floor(t / -1000);
      }
    }
  }
}
