import { v4 as uuidv4 } from 'uuid';

interface KeyValue {
  k: string;
  v: number | string | boolean;
}

export class ToolUtils {
  // environment varriables
  static isPlatformBrowser = true;
  static isInApp = false;
  static environment = 'prod';
  static isCrossSiteMode = false;

  static getUUID4(): string {
    return uuidv4();
  }
}
