import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ClientService } from '../../services/client.service';
import { UserDataService } from '../../services/user-data.service';
import { EmailVerifyPair, LocalService } from '../../services/local.service';
import { Router } from '@angular/router';
import { PromiseUtil } from '../../common/PromiseUtil';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DateTimeUtil } from '../../common/DateTimeUtil';
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
  agreeControl = new FormControl(false, Validators.requiredTrue);

  signin: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    verify: new FormControl('', [
      Validators.minLength(6),
      Validators.maxLength(6),
      Validators.required,
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
  });

  hide = true;

  // 發送信件冷卻時間
  // sendVerifyCodeTimeout = 0;

  // 冷卻倒數
  remainTime = '';

  countdownTimer?: NodeJS.Timer;

  // 凍結發送鈕
  sendVerifyCodeFreeze = true;

  // 凍結註冊鈕
  registerBtnFreeze = true;

  // 條款
  agreement = false;

  constructor(
    private client: ClientService,
    private userData: UserDataService,
    private local: LocalService,
    private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private session: SessionService
  ) {
    this.signin.statusChanges.subscribe({
      next: (valid) => {
        this.registerBtnFreeze = valid !== 'VALID';
      },
    });
    let email: FormControl<any> | null;
    if ((email = this.signin.get('email') as FormControl)) {
      email.statusChanges.subscribe({
        next: (valid) => {
          this.sendVerifyCodeFreeze = valid !== 'VALID';
        },
      });
    }
    this.agreeControl.statusChanges.subscribe({
      next: (valid) => {
        this.agreement = valid === 'VALID';
      },
    });
  }

  ngOnInit() {
    // 初始計時器
    this.countdownTimer = setInterval(() => {
      this.countDownHandler();
    }, 100);
  }

  ngOnDestroy() {
    // 清理計時器
    if (this.countdownTimer) {
      clearInterval(this.countdownTimer as unknown as number);
    }
  }

  // 驗證用的 token
  get vToken(): string | undefined {
    return this.verifyCodeParams.token;
  }

  // 發送信件冷卻時間
  get verifyCodeTimeout() {
    return this.verifyCodeParams.timeout;
  }

  // 發送信件冷卻時間
  set verifyCodeTimeout(val: number) {
    this.verifyCodeParams = { timeout: val };
  }

  // 發送信件冷卻時間
  get verifyCodeParams(): EmailVerifyPair {
    const data = this.local.GetEmailVerifyToken('REGISTER');
    if (!data) {
      return { token: undefined, timeout: 0 };
    }
    return data;
  }

  // 發送信件冷卻時間
  set verifyCodeParams(data: EmailVerifyPair) {
    this.local.SetEmailVerifyToken('REGISTER', data.timeout, data.token);
  }

  async register() {
    const email = this.signin.get('email')?.value;
    const password = this.signin.get('password')?.value;
    const verify = this.signin.get('verify')?.value;

    if (!this.vToken) {
      return;
    }

    // "verifyCode":"048302",
    //    "verifyToken":"8612edd2-7442-4935-b581-7675a307f18c"

    // console.log(
    //   `account=${email}, password=${password}, token=${this.vToken}, verify=${verify}`
    // );

    try {
      const reqResult = await this.client.register(
        email,
        password,
        this.vToken || '',
        verify
      );
      if (reqResult) {
        // 創建帳號成功, 紀錄 & 設定為當前 User
        // this.local.setUserClientId(email, clientId);
        // this.userData.ClientId = clientId;
        // this.router.navigate(['/dashboard'], { replaceUrl: true });

        const msg = await PromiseUtil.FromObservable(
          this.translate.get('Register.RegSuccessInfo')
        );
        console.log(msg);
        this.openNoticeSnackBar(msg);
        await PromiseUtil.wait(1000);
        this.router.navigate(['/login'], { replaceUrl: true });
      }
    } catch (err) {
      this.openNoticeSnackBar(`${err}`);
    }
  }

  async onSendVerifyCode() {
    if (this.verifyCodeTimeout > 0) {
      return;
    }
    try {
      this.verifyCodeTimeout = Date.now() + 600 * 1000; // 預設 CD 10min
      const email = this.signin.get('email')?.value;
      const token = await this.client.sendVerifyCodeViaEmail(email, 'REGISTER');
      this.verifyCodeParams = {
        token,
        timeout: Date.now() + 600 * 1000,
      };
    } catch (err) {
      this.verifyCodeTimeout = Date.now() + 60 * 1000; // 異常 CD 1min
    }
  }

  openNoticeSnackBar(msg: string) {
    this.snackBar.open(msg, 'OK', {
      duration: 1000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  private countDownHandler() {
    if (this.verifyCodeTimeout > 0) {
      let t = Date.now() - this.verifyCodeTimeout;
      if (t > 0) {
        this.verifyCodeTimeout = 0;
      } else {
        this.remainTime = DateTimeUtil.formatSeconds(Math.floor(t / -1000));
      }
    }
  }
}
