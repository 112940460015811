import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-trans-password',
  templateUrl: './trans-password.component.html',
  styleUrls: ['./trans-password.component.scss']
})
export class TransPasswordComponent {
  hideRequiredControl = new FormControl(false);
  setTransPassword: FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.min(3)]),
    hideRequired: this.hideRequiredControl,
  });
  hide = true;
  get emailInput() { return this.setTransPassword.get('email'); }
  get passwordInput() { return this.setTransPassword.get('password'); }
}
