export class ApiErrorResponse extends Error {
  constructor(public code: string, public msg: string) {
    super(msg);
  }
}

export interface ApiResponse {
  code: string; // 訊息代碼
  msg: string; // 執行訊息
  data?: any; // 回傳內容
}

export interface AuthorizeResponse extends ApiResponse {
  data?: {
    expireIn: number; // token 時效, 秒
    token: string; // token 內容
  };
}

export interface LoginResponse extends ApiResponse {
  data?: boolean;
  sessionId?: string;
}

export interface UserBalance {
  currency: string;
  balance: number;
  updateTime: string;
}

export interface BalanceListResponse extends ApiResponse {
  data?: {
    account: UserBalance[];
  };
}

export interface RegisterResponse extends ApiResponse {
  data?: {
    clientId: string;
    mobile?: string;
    username?: string;
    email?: string;
  };
}

export interface VirtualBankInfo {
  infoId: number; // 23718;
  vbaId: string; // 'VBA20230908000001';
  region: string; //'US';
  currency: string; //'USD';
  bic: string; //'DBSHKGXXXXX';
  accountName: string; // 账户名称
  accountNumber: string; //'2003000591'; // 收款账号
  bankInfoId: number;
  financialInstName: string; //'HadSund-BANK';
  financialInstAddress: string; //'HadSund-Address';
  clearingSysType: string; //'USABA';
  clearingSysNumber: string; //'430987';
  remittanceCode: string; //'MTXB9NBCAF'; // 交易附言
  remitTips: string; // 支持通过SWIFT清算网络付款，一般在3个工作日内到账。 // 汇款提示信息
  createTime: string; // '2023-09-08 16:41:51';
  updateTime: string; //'2023-09-08 16:41:51';
}

export interface VirtualBankAccount {
  vbaId: string; // 'VBA20230908000001';
  clientId: string; // 'CPSG000831';
  remittanceCode: string; // 'MTXB9NBCAF';
  bizMode: string; // 'TP';
  requestId: string; // '1694162511270';
  region: string; // 'HK';
  currency: string; //  'USD';
  paymentMethod: string; //  'SWIFT';
  infos: VirtualBankInfo[];

  // infos: [
  //   {
  //     infoId: 23718;
  //     vbaId: 'VBA20230908000001';
  //     region: 'US';
  //     currency: 'USD';
  //     bic: 'DBSHKGXXXXX';
  //     accountNumber: '2003000591';
  //     financialInstName: 'HadSund-BANK';
  //     financialInstAddress: 'HadSund-Address';
  //     clearingSysType: 'USABA';
  //     clearingSysNumber: '430987';
  //     remittanceCode: 'MTXB9NBCAF';
  //     createTime: '2023-09-08 16:41:51';
  //     updateTime: '2023-09-08 16:41:51';
  //   },
  //   {
  //     infoId: 23719;
  //     vbaId: 'VBA20230908000001';
  //     region: 'US';
  //     currency: 'USD';
  //     accountNumber: '2003000591';
  //     financialInstName: 'HadSund-BANK';
  //     financialInstAddress: 'HadSund-Address';
  //     clearingSysType: 'SWIFT';
  //     clearingSysNumber: 'DBSHKGXXXXX';
  //     remittanceCode: 'MTXB9NBCAF';
  //     createTime: '2023-09-08 16:41:51';
  //     updateTime: '2023-09-08 16:41:51';
  //   }
  // ];
  status: string; // 'ACTIVE';
  boundStatus: string; // 'BOUND';
  boundTime: string; // '2023-09-08 16:41:51';
  createTime: string; //  '2023-09-08 16:41:51';
  updateTime: string; //  '2023-09-08 16:41:51';
}

export interface VbaListResponse extends ApiResponse {
  data?: VirtualBankAccount[];
}

export interface CreateVbaResponse extends ApiResponse {
  data?: VirtualBankAccount;
}

export interface CurrencyExchangePair {
  sourceCur: string;
  targetCur: string;
  curPair: string;
  rate: number;
  updateTime: string;
}

export interface ExchangeRateResponse extends ApiResponse {
  data?: {
    curPairs: CurrencyExchangePair[];
  };
}

export interface FxInquiryResult {
  requestId: string; // '1694426292022';
  requestTime: string; // '2023-09-11T17:58:12+0800';
  sourceCur: string; // 'CNY';
  targetCur: string; // 'USD';
  sourceAmt: number; // 賣出金額
  targetAmt: number; // 買入金額
  validity: string; // 报价时效，不传默认 MIN_1,可用值:MIN_1-1分钟,MIN_30-30分钟
  tenor: string; // 交割模式，不传默认 T0,可用值:T0
  validityTo: string; // 报价到期时间, ex: '2023-09-11T17:59:12+0800'
  curPair: string; // 货币对, 參考匯率表, ex: USDCNY
  clientRate: number; // 客户汇率, ex: 7.31286
  quoteId: string; // 報價ID: RFQ20230911175812213K4OQP6JVMUU
}

export interface FxInquiryResponse extends ApiResponse {
  data?: FxInquiryResult;
}

export interface ApplyFxInquiryResult {
  requestId: string; // '1694488661480';
  requestTime: string; //'2023-09-12T11:17:41+0800';
  sourceCur: string; //'CNY';
  targetCur: string; //'USD';
  sourceAmt: number; // 1030;  **放大 100 倍
  targetAmt: number; // 141;  **放大 100 倍
  curPair: string; // 'USDCNY';
  clientRate: number; // 7.30639;
  tenor: string; // 'T0';
  conversionDate: string; // 交割日期, yyyy-MM-dd, ex: '2023-09-12';
  quoteId: string; // 報價單號 'RFQ20230912111714322LAHKG887MRB';
  contractId: string; // 合同号 '202309121117417173J64WD08J1P';
  status: string; // 订单状态,可用值:SUBMITTED-已提交,PROCESSING-处理中,SETTLED-已完成,FAILED-处理失败
  msg: string; // 说明: '已提交';
}

export interface ApplyFxInquiryResponse extends ApiResponse {
  data?: ApplyFxInquiryResult;
}

export interface UserInfoResult {
  email: string; // 'admin@matrix.sg';
  mobile: string; // '8618019497080';
  username: string; // 'testuser1';
  clientName?: string; // null;
  entityType: string; // 'INDIVIDUAL';
  country: string; // 'SG';
  idType?: string; // null;
  idNumber?: string; // null;
  accessCode: string; // 'ef9616dff2d6b58ecd4281d41916cf05';
  secretKey: string; // 'b4phc9acoae6f2ukkh0ssagav91ba7sos83muge5y81yuy6m2l2p56fk44mhqea87swoi7cu9t4i71x4cf00dmrwy6cc3t5mfak0qr3dbhqqofhbf6sbzyx3ynvqzgzvja3uhmhtglp27jcun4fbkhrepih3kx978xr5h3nwhs45nygr43nbxe6mov8zf616tqh00ickx7zsryrw0po1rr3qsatunt2qowkei26mawj56l4lzpx6ummmk0k5axm1nkn40sv60c5smza2kr7dp6qbd821n6efwjt6nyu80wbrlje6x1eigc5xo1shjbj14ir6pqo2sft3shpwsxtxv1cz47eb0e4gpc9c7ejrln1txwzzb6vxzidyjlv8xaary8vdmtzsdx165y33tfec8qqjfe9aziiq14fufbv9ykezapz4qc55og168oo7wm9r0o9b3m86qf0klzb5lv7pepn4r277idiywk978uunwejeahzmgz2z87lse7qhchsq';
}

export interface UserInfoResponse extends ApiResponse {
  data?: UserInfoResult;
}

export interface FeeInfo {
  chargeNode: number;
  chargeType: number;
  clientId: string;
  feeAmount: number; // 交易手續費
  feeCurrency: string;
  feeOrderId: number;
  fxRate: number;
  memberCode: string;
  stageAmount: number;
  trxAmount: number;
  trxCurrency: string;
  trxId: string;
}

export interface QueryFeeResponse extends ApiResponse {
  data?: FeeInfo;
}

export interface WithdrawResult {
  requestId: string;
  transactionId: string;
  status: string;
  errMsg: string;
  paymentDetails: {
    amount: number;
    currency: string;
    method: string;
    purpose: string;
    remittanceInfo: string;
  };
}

export interface WithdrawResponse extends ApiResponse {
  data?: WithdrawResult;
}

export interface WithdrawRecord {
  createTime: string; // 創建時間
  requestId: string; // '1694685465491';
  transactionId: string; // '20230914000002';
  status: string; // 'PROCESSING';
  errMsg?: string;
  paymentDetails: {
    currency: string; // 'CNY';
    amount: number; // 400;
    fee: number; // 手續費
    feeCur: string; // 手續費幣種
    purpose: string; // 'payment_for_goods', 'payment_for_servises_software';
    method: string; // 'SWIFT';
    remittanceInfo?: string;
    creditorName: string;
    creditorAccountNumber: string;
  };
}

export interface WithdrawRecordBook {
  pageNum: number; // 1;
  pageSize: number; // 10;
  currSize: number; // 8;
  totalSize: number; // 8;
  rows: WithdrawRecord[];
}

export interface WithdrawRecordsResponse extends ApiResponse {
  data?: WithdrawRecordBook;
}

export interface RechargeRecord {
  amount: number; // 10300027;
  currency: string; // 'USD';
  debtorAccount: string; // '70240000091252501';
  debtorName: string; // 'ZHANGSAN';
  details: string; // 'payment for PBO MTXB9NBCAF YYYYY';
  pendingAmt: number; // 0;
  transactionId: number; // 20230909000002;
  transactionTime: string; // '2023-09-09 23:01:43';
}

export interface RechargeRecordBook {
  pageNum: number; // 1;
  pageSize: number; // 10;
  currSize: number; // 8;
  totalSize: number; // 8;
  rows: RechargeRecord[];
}

export interface RechargeRecordsResponse extends ApiResponse {
  data?: RechargeRecordBook;
}

export interface ExchangeRecord {
  clientRate: number; // 7.406967;
  contractId: string; // '20230909232938890VI839DJYANR';
  conversionDate: string; // '2023-09-09';
  curPair: string; // 'USDCNY';
  msg: string; // '已交割';
  quoteId: string; // 'RFQ20230909232925227E8S5IPTGJ4K';
  requestId: string; // '10014';
  requestTime: string; // '2021-06-01T10:30:00+0800';
  sourceAmt: number; // 134738;
  sourceCur: string; // 'USD';
  status: string; // 'SETTLED';
  targetAmt: number; // 998000;
  targetCur: string; // 'CNY';
  tenor: string; // 'T0';
}

export interface ExchangeRecordBook {
  pageNum: number; // 1;
  pageSize: number; // 10;
  currSize: number; // 8;
  totalSize: number; // 8;
  rows: ExchangeRecord[];
}

export interface ExchangeRecordsResponse extends ApiResponse {
  data?: ExchangeRecordBook;
}

export interface ExchangeDetail {
  clientRate: number; // 7.30639;
  contractId: string; // '202309121117417173J64WD08J1P';
  conversionDate: string; // '2023-09-12';
  curPair: string; // 'USDCNY';
  msg: string; // '已交割';
  quoteId: string; // 'RFQ20230912111714322LAHKG887MRB';
  requestId: string; // '1694488661480';
  requestTime: string; // '2023-09-12T11:17:41+0800';
  sourceAmt: number; // 1030;
  sourceCur: string; // 'CNY';
  status: string; // 'SETTLED';
  targetAmt: 141;
  targetCur: string; // 'USD';
  tenor: string; // 'T0';
}

export interface ExchangeDetailResponse extends ApiResponse {
  data?: ExchangeDetail;
}

export interface WalletRecord {
  currency: string; // 'USD';
  acctTxn: {
    txnId: number; // 202206061721;
    txnType: string; // TOP_UP:充值, PAYMENT:付款, INTERNAL_TRANSFER:內部會員互轉;
    txnTime: string; // '2022-05-25T08:30:00+0800';
    amount: number; // 18900;
    cdtDbtInd: string; // 'CREDIT – 出账 DEBIT – 入账';
    balance: number; // 1123608;
    updateTime: string; // '2022-05-31T21:30:00+0800';
  };
}

export interface WalletRecordBook {
  pageNum: number;
  pageSize: number;
  currSize: number;
  totalSize: number;
  rows: WalletRecord[];
}

export interface WalletRecordsResponse extends ApiResponse {
  data?: WalletRecordBook;
}

export interface Notice {
  createTime: string;
  expireTime: string;
  noticeContent: string; // '七夕情人节放假一天';
  noticeId: number; // 20240818;
  noticeTitle: string; // '2024节假日安排通知';
  noticeType: string; // '1:公告，2:通知';
  status: string;
}

export interface NoticeBook {
  pageNum: number;
  pageSize: number;
  currSize: number;
  totalSize: number;
  rows: Notice[];
}

export interface NoticeResponse extends ApiResponse {
  data?: NoticeBook;
}

export interface SendVerifyCodeResponse extends ApiResponse {
  data?: {
    method?: string;
    token: string; //'10c4528d-34a4-4422-8286-407433d9f670';
    emial?: string;
    mobile?: string;
    tag?: string;
  };
}

export interface TotpResponse extends ApiResponse {
  data?: string; // "otpauth://totp/Paytranxee:netbuswhs%40gmail.com?secret=5zk6xv6rpglljz26teogsqnpisfgoecn&issuer=Paytranxee"
}

export interface BindTotpResponse extends ApiResponse {
  data?: boolean; // true: 成功
}

export interface SetTrxPasswordResponse extends ApiResponse {
  data?: boolean;
}

export interface SecurityStatusResponse extends ApiResponse {
  data?: {
    totp: number; // 0:未設定
    trxPasswd: number; // 0:未設定
  };
}

export interface TransferByPrincipalResponse extends ApiResponse {
  data?: {
    amount: number; // 91245;
    createTime: string; // 转账时间, '2023-11-18 22:57:35';
    creditorEmail: string; // 'payee@sg.matrix';
    creditorMobile: number; // 18000008888;
    creditorUsername: string; // 'username_payee';
    currency: string; // 'USD';
    debtorEmail: string; // 'payer@sg.matrix';
    debtorMobile: number; // 18088889999;
    debtorUsername: string; // 'username_payer';
    extTraceId: number; // 2025010100001111;
    notes: string; // '佣金';
    status: string; // 转账状态: 'POSTED';
    transferId: number; // 20231118100001;
  };
}

export interface BeneficiaryRecord {
  requestId: string; //'21a67238ed0b47c1b006138ef5c60349';
  beneficiaryId: number; // 20231128000008;
  beneficiaryName: string; //'沙影';
  beneficiaryType: string; //'BUSINESS';
  idType?: string;
  idNumber?: string;
  beneficiaryCountryCode: string; //'CN';
  contactMobileNo: string; //null;
  contactEmail: string; //null;
  beneficiaryAddressLine1: string; //'沙影村影辦公室頂樓沙影議事廳';
  beneficiaryAddressLine2: string; //'天元大陸';
  beneficiaryAddressLine3: string; //'';
  accountInfo: {
    accountType: string; //'BUSINESS';
    accountCurrency: string; //'USD';
    accountName: string; //'我愛羅';
    accountNumber: string; //'123456789';
  };
  bankInfo: {
    bankName: string; //'沙影合作金庫';
    bankBranch?: string; //null;
    bankCountryCode: string; //'JP';
    bankAddressLine1: string; //'沙影村影辦公室頂樓沙影議事廳';
    bankAddressLine2?: string; //'天元大陸';
    bankAddressLine3?: string; //'';
    clearingSysType: string; //'SWIFT';
    clearingSysNumber: string; //'AKB47';
  };
  beneficiaryNickname?: string;
  additionalInfo?: string; //null;
  favorite: boolean; // true;
  status: string; //'Approved';
  createTime: string; //null;
  updateTime: string; //null;
}

export interface BeneficiaryListResponse extends ApiResponse {
  data?: BeneficiaryRecord[];
}

export interface CreateBeneficiaryResponse extends ApiResponse {
  data?: {
    // requestId: null;
    beneficiaryId: number; // 20231128000008; 收款人編號
    // beneficiaryName: null;
    // beneficiaryType: null;
    // idType: null;
    // idNumber: null;
    // beneficiaryCountryCode: null;
    // contactMobileNo: null;
    // contactEmail: null;
    // beneficiaryAddressLine1: null;
    // beneficiaryAddressLine2: null;
    // beneficiaryAddressLine3: null;
    // accountInfo: null;
    // bankInfo: null;
    // additionalInfo: null;
    // favorite: false;
    status: string; // 'Approved';
    // createTime: null;
    // updateTime: null;
  };
}

// 通知: 換匯結果內容
export interface NotifyExchangeResultContent {
  eventId: string; // '460e624d-05ee-4a73-9863-edbb64057671';
  eventName: 'exchange.apply.result';
  data: {
    msg: string; // '已交割';
    curPair: string; // 'USDCNY';
    sourceCur: string; // 'CNY';
    targetCur: string; // 'USD';
    quoteId: string; // 'RFQ20231205164028217PFMUEB3679N';
    sourceAmt: number; // 32100;
    tenor: string; // 'T0';
    targetAmt: number; // 4476;
    contractId: string; // '20231205164035585LHVWF33KP66';
    clientRate: number; // 7.17093;
    requestTime: string; // '2023-12-05T16:40:35+0800';
    conversionDate: string; // '2023-12-05';
    conversionTime: string; // '2023-12-05T16:40:38+0800';
    status: string; // 'SETTLED';
  };
}

// 通知: 汇入汇款已到账通知 付款結果內容
export interface NotifyPaymentResultContent {
  eventId: string; // '572d79629af6434b96c54d97b11f757d';
  eventName: 'payment.result';
  data: {
    requestId: '';
    transactionId: '';
    status: '';
    errMsg: '';
    paymentDetails: {
      amount: 0;
      currency: '';
      method: '';
      purpose: '';
      remittanceInfo: '';
    };
  };
}

// 通知: 汇入汇款已到账通知
export interface NotifyRemittanceReceivedContent {
  eventName: 'remittance.received';
  eventId: string; // 'a11aa5a4-d91a-43d8-8413-6b70bc34d845';
  data: {
    amount: number; // 100000;
    currency: string; // 'USD';
    pendingAmt: number; // 0;
    additionalInfo: {
      debtorInfo: {
        name: string; // 'Nocold test';
        accountNumber: string; // '2222333322223333';
        remittanceInfo: string; // '';
      };
    };
    transactionTime: string; // '2023-12-13T13:52:42+0800';
    transactionId: number; // 20231213000036;
  };
}

// 通知: 收款账户已激活通知
export interface NotifyVirtualBankAccountActivedContent {
  requestId: '';
  currency: '';
  country: '';
  paymentMethod: '';
  accountNumber: '';
  bic: '';
  iban: '';
  clearingSysType: '';
  clearingSysNumber: '';
  status: '';
}

// 通知: 會員互轉
export interface NotifyAccountTransferContent {
  eventId: string; // '572d79629af6434b96c54d97b11f757d';
  eventName: 'account.tranfer.result';
  data: {
    amount: number; // 1200;
    createTime: string; // '2023-12-18T12:19:55+0800';
    creditorEmail: string; // 'nocold.gg@gmail.com';
    creditorUsername: string; // 'nocold.gg@gmail.com';
    currency: string; // 'USD';
    debtorEmail: string; // 'netbuswhs@gmail.com';
    debtorUsername: string; // 'netbuswhs@gmail.com';
    extTraceId: string; // 'ce849d4e-1c2f-4974-b5c9-8e74640d3350';
    status: string; // 'POSTED';
    transferId: number; // 20231218000022;
    updateTime: string; // '2023-12-18T12:19:55+0800';
  };
}

export interface Notification {
  id: number; // 12345
  clientId: string;
  eventId: string; // 113890ace52342da2323
  eventName: string; // "payment.result"
  // 事件名称 枚举值 (
  //   businessEntity.audit.result-客户信息审核结果;
  //   ga.active-收款账户已激活;
  //   remittance.received-汇入汇款已到账;
  //   trade.settle.result-解付结果通知;
  //   exchange.apply.result-换汇申请结果;
  //   beneficiary.result-收款人审核结果;
  //   payment.result-付款申请结果
  // )
  // memberCode: string; // "MESG0834"
  notifyContent:
    | NotifyExchangeResultContent // 换汇申请结果通知
    | NotifyPaymentResultContent // 付款申请结果通知
    | NotifyRemittanceReceivedContent // 汇入汇款已到账通知
    | NotifyVirtualBankAccountActivedContent // 收款账户已激活通知
    | NotifyAccountTransferContent; // 會員互轉收款通知
  readed: boolean;
  src: string; // "MTX_SVC_TRANSATION"
  createTime: string;
  updateTime: string;
}

// {
//   "pageNum": 1,
//   "pageSize": 10,
//   "id": 33230,
//   "eventId": "572d79629af6434b96c54d97b11f757d",
//   "eventName": "payment.result",
//   "memberCode": "MESG0834",
//   "clientId": "CISG000197",
//   "src": "MTX_SVC_TRANSACTION",
//   "notifyContent": {
//       "eventId": "572d79629af6434b96c54d97b11f757d",
//       "data": {
//           "requestId": "1701937818270",
//           "processing": false,
//           "failed": false,
//           "paymentDetails": {
//               "amount": 12300,
//               "method": "SWIFT",
//               "purpose": "payment_for_servises_software",
//               "currency": "CNY"
//           },
//           "transactionId": "20231207000048",
//           "status": "PROCESSED"
//       },
//       "eventName": "payment.result"
//   },
//   "read": false,
//   "createTime": "2023-12-07 16:41:14",
//   "updateTime": "2023-12-07 16:41:14"
// }

export interface NotificationRecordBook {
  pageNum: number; // 1;
  pageSize: number; // 10;
  currSize: number; // 8;
  totalSize: number; // 8;
  rows: Notification[];
}

export interface NotificationResponse extends ApiResponse {
  data?: NotificationRecordBook;
}

export interface AccountTransferRecord {
  amount: number; // 100;
  createTime: string; // '2023-12-18 15:38:40';
  creditorEmail: string; // 'erinlin9688@gmail.com';
  creditorMobile: string; // '';
  creditorUsername: string; // 'erinlin9688@gmail.com';
  currency: string; // 'USD';
  debtorEmail: string; // 'erinlin0968@gmail.com';
  debtorUsername: string; // 'erinlin0968@gmail.com';
  extTraceId: string; // 'a4bed5f2-658e-448e-9690-46dc1b168db3';
  status: string; // 'POSTED';
  transferId: number; // 20231218000027;
  updateTime: string; // '2023-12-18 15:38:40';
}

export interface AccountTransferRecordBook {
  pageNum: number; // 1;
  pageSize: number; // 10;
  currSize: number; // 8;
  totalSize: number; // 8;
  rows: AccountTransferRecord[];
}

export interface AccountTransferRecordResponse extends ApiResponse {
  data?: AccountTransferRecordBook;
}
