import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipListboxChange } from '@angular/material/chips';
import { ClientService } from '../../services/client.service';
import { UserDataService } from '../../services/user-data.service';
import { PromiseUtil } from '../../common/PromiseUtil';
import {
  BeneficiaryRecord,
  FeeInfo,
  UserBalance,
} from '../../domain/network/response';
import { CountryCode } from '../../definition/CountryCode';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';
import { WithdrawForm } from '../../domain/form/withdraw';
import { LocalService } from '../../services/local.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';
import { SessionService } from '../../services/session.service';
import { DialogService } from '../../services/dialog.service';

interface FormControlMap {
  accountName: string;
  bankAccount: string;
  bankAddress1: string;
  bankAddress2: string;
  bankAddress3: string;
  bankLocation: string;
  bankName: string;
  beneficiaryAddress1: string;
  beneficiaryAddress2: string;
  beneficiaryAddress3: string;
  beneficiaryName: string;
  swiftCode: string;
  withdrawAccountType: string;
  additionalInfo: string;
  beneficiaryNickname: string;
  favorite: boolean;
}

interface BeneficiaryItem {
  beneficiaryId: number;
  display: string; // 顯示名稱
  favors: boolean; // 最愛
  raw: BeneficiaryRecord;
}

@Component({
  selector: 'app-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrls: ['./withdraw.component.scss'],
})
export class WithdrawComponent {
  date = new FormControl(new Date());
  serializedDate = new FormControl(new Date().toISOString());
  transPasswordHide = true;

  isIndividualBeneficiary = false; // 是否為個人收款人

  beneficiaryNameCtl?: FormControl;

  beneficiaryEmailCtl: FormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]); // 會員收款人email

  stage = '1';
  stages: { name: string; color?: string; selected?: boolean }[] = [
    { name: '1' }, // 選擇收款帳戶方案 (新建/紀錄)
    { name: '2' }, // 填出款幣別與金額
    { name: '3' }, // 新創收款帳戶資訊
    { name: '4' }, // 選擇收款帳戶
    { name: '5' }, // 確認出款內容(含交易費用)
    { name: '6' }, // 出款申請完成
    { name: '7' }, // 選擇系統用戶
  ];

  stageStack: string[] = [];

  countryCodeMenu: { code: string; name: string }[] = []; // 國家地區選單

  // 出款帳戶類型選單
  accountTypeMenu = [
    { code: 'BUSINESS', name: 'Common.AccountType.Business' },
    // { code: 'PERSONAL', name: 'Common.AccountType.Personal' },
    { code: 'INDIVIDUAL', name: 'Common.AccountType.Personal' },
  ];

  // 出款方式選單
  withdrawMethodMenu = [
    {
      code: 'LOCAL',
      name: 'Common.Method.Local',
    },
    {
      code: 'SWIFT',
      name: 'Common.Method.Swift',
    },
  ];

  withdrawFormCtl: FormGroup = new FormGroup({
    // 帳號名
    accountName: new FormControl('', [
      Validators.minLength(2),
      Validators.required,
    ]),
    // 銀行帳號
    bankAccount: new FormControl('', [
      Validators.minLength(6),
      Validators.required,
    ]),
    // 銀行名
    bankName: new FormControl('', [
      Validators.minLength(2),
      Validators.required,
    ]),
    // 銀行地址 -1
    bankAddress1: new FormControl('', [
      Validators.minLength(10),
      Validators.maxLength(35),
      Validators.required,
    ]),
    // 銀行地址 -2
    bankAddress2: new FormControl('', [Validators.maxLength(35)]),
    // 銀行地址 -3
    bankAddress3: new FormControl('', [Validators.maxLength(35)]),
    // 銀行所在地/國碼
    bankLocation: new FormControl('', [
      Validators.minLength(2),
      Validators.maxLength(2),
      Validators.required,
    ]),
    // SWIFT
    swiftCode: new FormControl('', [
      Validators.minLength(2),
      Validators.maxLength(30),
      Validators.required,
    ]),
    // 帳戶類型 (personal, business)
    withdrawAccountType: new FormControl('', [
      Validators.minLength(2),
      Validators.maxLength(16),
      Validators.required,
    ]),
    // 收款人名
    beneficiaryName: new FormControl('', [
      Validators.minLength(2),
      Validators.required,
    ]),
    // 收款人地址 -1
    beneficiaryAddress1: new FormControl('', [
      Validators.minLength(10),
      Validators.maxLength(35),
      Validators.required,
    ]),
    // 收款人地址 -2
    beneficiaryAddress2: new FormControl('', [Validators.maxLength(35)]),
    // 收款人地址 -3
    beneficiaryAddress3: new FormControl('', [Validators.maxLength(35)]),
    // 備註訊息 (帳戶暱稱)
    beneficiaryNickname: new FormControl('', [
      Validators.pattern(/^[\u4e00-\u9fa5a-zA-Z0-9\s]*$/),
      Validators.maxLength(35),
    ]),
    // 常用出金帳號
    favorite: new FormControl(false, []),
  });

  trxPasswdCtl: FormControl;
  totpPasswdCtl: FormControl;
  passwdGrp: FormGroup;

  private userBalances?: UserBalance[]; // 用戶帳戶列表

  defaultCurrency: string = 'USD'; // 參數預設出金帳戶

  withdrawCurrencyMenu: string[] = [];
  withdrawCurrency?: string = 'USD'; // 出款幣別
  selectedCurrencyBalance: number = 0; // 出金帳戶餘額

  inputUserWithdrawAmount = 0; // 用戶輸入出款金額
  amountControl: FormControl;

  feeInfo?: FeeInfo;

  withdrawForm?: WithdrawForm;

  beneficiaryMethod: string = ''; // 出款對象, 清單(outer-select)/創建(outer-create)/內轉(inner)

  innerBeneficiarySelect: string = ''; // 選擇內轉的帳戶
  innerBeneficiaryPrincipal?: string; // 內轉的帳戶 email

  beneficiaryList: BeneficiaryItem[] = []; // 出款人清單
  selectedBeneficiaryId: number = 0;
  selectedBeneficiary?: BeneficiaryRecord;
  beneficiarySummary?: string; // 出款人資訊預覽

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private client: ClientService,
    private userData: UserDataService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private location: Location,
    private local: LocalService,
    private session: SessionService,
    private dialog: DialogService
  ) {
    this.stages.forEach((e) => {
      e.selected = e.name == this.stage;
    });

    // 出款金額控制
    this.amountControl = new FormControl('', [
      Validators.required,
      Validators.maxLength(10),
      Validators.min(0),
      Validators.max(99999999),
    ]);
    this.amountControl.statusChanges.subscribe({
      next: (s) => {
        this.inputUserWithdrawAmount = 0;
        if (s === 'VALID') {
          this.inputUserWithdrawAmount = this.amountControl.value;
        }
      },
    });

    this.trxPasswdCtl = new FormControl('', [
      Validators.minLength(6),
      Validators.required,
    ]);
    this.totpPasswdCtl = new FormControl('', [
      Validators.pattern(/^[0-9]{6}$/),
      Validators.required,
    ]);
    this.passwdGrp = new FormGroup({
      trxpasswd: this.trxPasswdCtl,
      totp: this.totpPasswdCtl,
    });
  }

  ngOnInit() {
    // 初始化參數
    this.route.params.subscribe((params: Params) => {
      if (params['currency']) {
        this.location.replaceState('/withdraw');
        this.withdrawCurrency = params['currency'];
        this.defaultCurrency = params['currency'];
        this.updateWithdrawBalance();
      }
    });

    this.onInit();

    // 灌假資料, 才不會測到死
    if (this.local.DevModes.includes('withdraw')) {
      const fake = {
        accountName: '我愛羅',
        bankAccount: '123456789',
        bankAddress1: '沙影村影辦公室頂樓沙影議事廳',
        bankAddress2: '天元大陸',
        bankAddress3: '',
        bankLocation: 'JP',
        bankName: '沙影合作金庫',
        beneficiaryAddress1: '沙影村中央大道11號2F',
        beneficiaryAddress2: '天元大陸',
        beneficiaryAddress3: '',
        beneficiaryName: '沙影',
        swiftCode: 'AKB47',
        withdrawAccountType: 'BUSINESS',
      };
      for (let [k, v] of Object.entries(fake)) {
        this.withdrawFormCtl.get(k)?.setValue(v);
      }
    }
  }

  ngOnDestroy() {}

  // 更換 Demo 分頁
  demoSelected(event: MatChipListboxChange) {
    this.stage = event.value;
    this.stages.forEach((e) => {
      e.selected = e.name == event.value;
    });
  }

  // 異步初始化流程
  async onInit() {
    // 初始化國家地區表單
    this.countryCodeMenu = [];
    for (let [key, value] of Object.entries(CountryCode)) {
      this.countryCodeMenu.push({
        code: key,
        name: `ConutryCode.${value}`,
      });
    }
    this.countryCodeMenu.sort((a, b) => a.code.localeCompare(b.code));

    // 取得用戶帳戶餘額
    this.userBalances = await this.userData.getUserBalances();
    this.withdrawCurrencyMenu = [];
    if (this.userBalances.length > 0) {
      this.withdrawCurrency =
        this.withdrawCurrency || this.userBalances[0].currency;
      for (let b of this.userBalances) {
        if (b.balance > 0) {
          this.withdrawCurrencyMenu.push(b.currency);
          if (b.currency === this.withdrawCurrency) {
            this.selectedCurrencyBalance = b.balance;
          }
        }
      }
    }

    if (this.withdrawCurrencyMenu.length == 0) {
      this.withdrawCurrencyMenu.push(this.defaultCurrency);
      this.withdrawCurrency = this.defaultCurrency;
      this.selectedCurrencyBalance = 0;
    }

    // 檢查綁定狀態
    const r = await this.client.querySecurityStatus();
    if (r.trxPasswd == 0) {
      // 尚未設置交易密碼
      this.dialog.showRedirectPopup(
        'Dialog.SetTrxPasswd',
        'Dialog.Goto',
        ['trxpasswd'],
        ['dashboard', 'home']
      );
    } else if (r.totp == 0) {
      // 尚未設置谷歌密碼
      this.dialog.showRedirectPopup(
        'Dialog.SetOTPasswd',
        'Dialog.Goto',
        ['verify', 'googleauth'],
        ['dashboard', 'home']
      );
    }

    // end
  }

  // 點擊返回
  onGoBack() {
    if (this.stageStack.length == 0) {
      this.router.navigate(['/dashboard']);
      return;
    }
    this.stage = this.stageStack.pop()!;
  }

  // 選擇出款帳戶(貨幣)
  onWithdrawCurrencySelect() {
    this.updateWithdrawBalance();
    // this.selectedCurrencyBalance = 0;
    // if (this.userBalances && this.userBalances.length > 0) {
    //   for (let b of this.userBalances) {
    //     if (b.currency === this.withdrawCurrency) {
    //       this.selectedCurrencyBalance = b.balance;
    //       return;
    //     }
    //   }
    // }
  }

  // 選擇出款對象來源
  onSetBeneficiaryMethod(method: string) {
    // outer-select, outer-create, inner
    this.beneficiaryMethod = method;
    this.stageStack.push(this.stage);
    this.stage = '2';
  }

  // 檢查餘額
  async onCheckWithdrawAmount() {
    if (this.inputUserWithdrawAmount * 100 > this.selectedCurrencyBalance) {
      const msg = await PromiseUtil.FromObservable(
        this.translate.get('SnackBar.InsufficientBalance')
      );
      this.openSnackBar(msg);
      return;
    }

    // 移往輸入收款人畫面
    switch (this.beneficiaryMethod) {
      case 'outer-select': {
        this.selectedBeneficiaryId = 0;
        this.selectedBeneficiary = undefined;
        this.beneficiarySummary = undefined;
        await this.loadBeneficiaryList(); // 讀取列表
        this.stageStack.push(this.stage);
        this.stage = '4';
        break;
      }
      case 'outer-create': {
        this.stageStack.push(this.stage);
        this.stage = '3';
        break;
      }
      case 'inner': {
        this.stageStack.push(this.stage);
        this.stage = '7';
        break;
      }
    }
  }

  // 送出填寫表單
  async onCommitWithdrawForm() {
    if (!this.session.context) {
      return;
    }

    try {
      // 查詢手續費
      const trxId = uuidv4();
      this.feeInfo = await this.client.QueryFee(
        trxId,
        this.withdrawCurrency!,
        this.inputUserWithdrawAmount * 100
      );

      // 組建請求表單
      const requestId = `${Date.now()}`;
      const formValue: FormControlMap = this.withdrawFormCtl.value;
      this.withdrawForm = {
        requestId: requestId,
        paymentDetails: {
          currency: this.withdrawCurrency!,
          amount: this.inputUserWithdrawAmount * 100,
          purpose: 'payment_for_servises_software', // TRANSFER_TO_OWN_ACCOUNT, PAYMENT_FOR_GOODS, payment_for_goods, payment_for_servises_software
          method: 'SWIFT',
        },
        beneficiary: {
          details: {
            beneficiaryName: formValue.beneficiaryName,
            beneficiaryAddressLine1: formValue.beneficiaryAddress1,
            beneficiaryAddressLine2: formValue.beneficiaryAddress2,
            beneficiaryAddressLine3: formValue.beneficiaryAddress3,
            accountInfo: {
              accountType: formValue.withdrawAccountType,
              accountCurrency: this.withdrawCurrency!,
              accountName: formValue.accountName,
              accountNumber: formValue.bankAccount,
            },
            bankInfo: {
              bankName: formValue.bankName,
              bankCountryCode: formValue.bankLocation,
              bankAddressLine1: formValue.bankAddress1,
              clearingSysNumber: formValue.swiftCode,
              clearingSysType: 'SWIFT',
              bankAddressLine2: formValue.bankAddress2,
              bankAddressLine3: formValue.bankAddress3,
            },
            beneficiaryNickname: formValue.beneficiaryNickname,
            favorite: formValue.favorite,
          },
        },
      };

      // 出金帳戶資訊總結
      this.beneficiarySummary = await this.generateBeneficiaryPreview(
        this.withdrawForm.beneficiary.details as unknown as BeneficiaryRecord
      );

      this.stageStack.push(this.stage);
      this.stage = '5';
    } catch (err) {
      if (err instanceof Error) {
        this.openSnackBar(err.message);
      }
    }
  }

  // 選擇了出款人清單
  async onSelectBeneficiary(val: number) {
    this.selectedBeneficiaryId = 0;
    this.beneficiarySummary = undefined;
    this.selectedBeneficiary = undefined;
    const beneficiary = this.beneficiaryList.find(
      (item) => item.beneficiaryId == val
    );
    if (!beneficiary) return;
    this.selectedBeneficiaryId = val;
    this.selectedBeneficiary = beneficiary.raw;
    this.beneficiarySummary = await this.generateBeneficiaryPreview(
      beneficiary.raw
    );
  }

  // 完成選擇出款對象, 下一步, 確認畫面
  async onSelectBeneficiaryNext() {
    if (!this.session.context) {
      return;
    }

    if (!this.selectedBeneficiaryId) {
      return;
    }

    try {
      // 查詢手續費
      const trxId = uuidv4();
      this.feeInfo = await this.client.QueryFee(
        trxId,
        this.withdrawCurrency!,
        this.inputUserWithdrawAmount * 100
      );

      // 組建請求表單
      const requestId = `${Date.now()}`;
      const formValue: FormControlMap = this.withdrawFormCtl.value;
      this.withdrawForm = {
        requestId: requestId,
        paymentDetails: {
          currency: this.withdrawCurrency!,
          amount: this.inputUserWithdrawAmount * 100,
          purpose: 'payment_for_servises_software', // TRANSFER_TO_OWN_ACCOUNT, PAYMENT_FOR_GOODS, payment_for_goods, payment_for_servises_software
          method: 'SWIFT',
        },
        beneficiary: {
          beneficiaryId: this.selectedBeneficiaryId,
        },
      };

      // TODO

      // // 出金帳戶資訊總結
      // const tmp = [
      //   formValue.beneficiaryName,
      //   formValue.bankName,
      //   formValue.bankAccount,
      //   formValue.swiftCode,
      //   `${formValue.bankAddress1} ${formValue.bankAddress2} ${formValue.bankAddress3}`,
      //   `${formValue.beneficiaryAddress1} ${formValue.beneficiaryAddress2} ${formValue.beneficiaryAddress3}`,
      // ];
      // this.withdrawSummary = tmp.join('<br>');

      this.stageStack.push(this.stage);
      this.stage = '5';
    } catch (err) {
      if (err instanceof Error) {
        this.openSnackBar(err.message);
      }
    }
  }

  // 產生出款人資訊預覽
  private async generateBeneficiaryPreview(beneficiary: BeneficiaryRecord) {
    const tmp2 = Object.entries(CountryCode).find(
      ([k, v]) => k == beneficiary.bankInfo.bankCountryCode
    );
    if (!tmp2) {
      return;
    }
    const countryCode3 = tmp2[1];

    const trans = await PromiseUtil.FromObservable(
      this.translate.get([
        'Beneficiary.Type.Individual',
        'Beneficiary.Type.Business',
        `ConutryCode.${countryCode3}`,
      ])
    );
    const typeMapper: { [k: string]: string } = {
      INDIVIDUAL: trans['Beneficiary.Type.Individual'],
      BUSINESS: trans['Beneficiary.Type.Business'],
    };

    const countryName = trans[`ConutryCode.${countryCode3}`];

    let tmp = [];
    tmp.push(typeMapper[beneficiary.accountInfo.accountType]); // 帳戶類型
    tmp.push(beneficiary.accountInfo.accountNumber); // 帳戶號碼
    tmp.push(beneficiary.accountInfo.accountName); // 帳戶名稱
    tmp.push(beneficiary.bankInfo.bankName); // 銀行名稱
    tmp.push(beneficiary.bankInfo.bankAddressLine1); // 銀行地址
    tmp.push(beneficiary.bankInfo.bankAddressLine2); // 銀行地址
    tmp.push(beneficiary.bankInfo.bankAddressLine3); // 銀行地址
    tmp.push(`${countryName} (${beneficiary.bankInfo.bankCountryCode})`); // 銀行國家地區
    tmp.push(beneficiary.bankInfo.clearingSysNumber); // 銀行清算代碼
    tmp.push(beneficiary.beneficiaryName); // 收款人名稱
    tmp.push(beneficiary.beneficiaryAddressLine1); // 收款人地址
    tmp.push(beneficiary.beneficiaryAddressLine2); // 收款人地址
    tmp.push(beneficiary.beneficiaryAddressLine3); // 收款人地址
    tmp = tmp.filter((str) => str && str.length > 0);
    return tmp.join('<br/>');
  }

  // 送出交易
  async onCommitTransaction() {
    if (!this.session.context) {
      return;
    }

    switch (this.beneficiaryMethod) {
      case 'outer-create': {
        await this.executeOuterWithdrawWithCreate();
        break;
      }
      case 'outer-select': {
        await this.executeOuterWithdrawWithBeneficiary();
        break;
      }
      case 'inner': {
        await this.executeInnerTransfer();
        break;
      }
    }
  }

  // 執行內部會員互轉
  async executeInnerTransfer() {
    try {
      const result = await this.client.transferByPrincipal(
        this.withdrawCurrency!,
        this.amountControl.value,
        this.innerBeneficiaryPrincipal!,
        this.trxPasswdCtl.value,
        this.totpPasswdCtl.value
      );
      this.stageStack.push(this.stage);
      this.stage = '6';
    } catch (err) {
      if (err instanceof Error) {
        this.openSnackBar(err.message);
      } else {
        this.openSnackBar(`${err}`);
      }
    }
  }

  // 執行出款(創建收款人)
  async executeOuterWithdrawWithCreate() {
    if (!this.withdrawForm) {
      return;
    }
    try {
      this.withdrawForm.trxPasswd = this.trxPasswdCtl.value; // 交易密碼
      this.withdrawForm.totp = this.totpPasswdCtl.value; // 動態驗證碼
      this.withdrawForm.principal = this.session.context!.principal; // 帳號
      const result = await this.client.CreateWithdraw(this.withdrawForm!);
      this.stageStack.push(this.stage);
      this.stage = '6';
    } catch (err) {
      if (err instanceof Error) {
        this.openSnackBar(err.message);
      } else {
        this.openSnackBar(`${err}`);
      }
    }
  }

  // 執行出款 (既有收款人)
  async executeOuterWithdrawWithBeneficiary() {
    if (
      !(
        this.selectedBeneficiaryId &&
        this.selectedBeneficiary &&
        this.withdrawForm
      )
    ) {
      return;
    }
    try {
      this.withdrawForm.trxPasswd = this.trxPasswdCtl.value; // 交易密碼
      this.withdrawForm.totp = this.totpPasswdCtl.value; // 動態驗證碼
      this.withdrawForm.principal = this.session.context!.principal; // 帳號
      const result = await this.client.CreateWithdraw(this.withdrawForm!);
      this.stageStack.push(this.stage);
      this.stage = '6';
    } catch (err) {
      if (err instanceof Error) {
        this.openSnackBar(err.message);
      } else {
        this.openSnackBar(`${err}`);
      }
    }
  }

  onBeneficiaryTypeChanged(val: string) {
    const type = this.withdrawFormCtl.get('withdrawAccountType')?.value;
    this.isIndividualBeneficiary = false;
    this.beneficiaryNameCtl = undefined;

    if (val == 'BUSINESS') {
      this.withdrawFormCtl.setControl(
        'beneficiaryName',
        new FormControl('', [Validators.minLength(2), Validators.required])
      );
    } else if (val == 'INDIVIDUAL') {
      // 個人
      this.isIndividualBeneficiary = true;
      this.beneficiaryNameCtl = new FormControl('', [
        Validators.minLength(5),
        Validators.required,
        Validators.pattern(/^[a-zA-Z]+\s+[a-zA-Z]+$/), // 英文字兩段, 中間空白間隔
      ]);
      this.withdrawFormCtl.setControl(
        'beneficiaryName',
        this.beneficiaryNameCtl
      );
    }
  }

  // 選擇內轉用戶列表
  onInnerBeneficiarySelect() {
    if (this.innerBeneficiarySelect === '') {
      // console.log('選了');
    }
  }

  // 輸入完成內轉用戶
  onInnerBeneficiarSelected() {
    if (this.innerBeneficiarySelect === '') {
      this.innerBeneficiaryPrincipal = this.beneficiaryEmailCtl.value;
    } else {
      this.innerBeneficiaryPrincipal = this.innerBeneficiarySelect;
    }
    // 前往下一個場景, 確認頁
    // console.log(`email = `, this.innerBeneficiaryPrincipal);
    // this.withdrawSummary = this.innerBeneficiaryPrincipal;
    this.beneficiarySummary = this.innerBeneficiaryPrincipal;
    this.stageStack.push(this.stage);
    this.stage = '5';
  }

  // 關閉視窗
  onClose() {
    this.router.navigate(['/dashboard'], { replaceUrl: true });
  }

  // 是否有收款人名字格式錯誤
  get hasBeneficiaryNameError() {
    return (
      this.isIndividualBeneficiary &&
      this.beneficiaryNameCtl &&
      !this.beneficiaryNameCtl!.valid &&
      this.beneficiaryNameCtl.touched
    );
  }

  // 讀取收款人列表
  private async loadBeneficiaryList() {
    let list = await this.client.listBeneficiaries();
    this.beneficiaryList = list
      .filter(
        (item) => item.accountInfo.accountCurrency === this.withdrawCurrency
      )
      .map((item) => {
        return {
          beneficiaryId: item.beneficiaryId,
          display: item.beneficiaryNickname ?? item.accountInfo.accountName,
          favors: true,
          raw: item,
        };
      });
  }

  private openSnackBar(message: string, duration: number = 1000) {
    this.snackBar.open(message, 'OK', {
      duration: duration,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  private updateWithdrawBalance() {
    this.selectedCurrencyBalance = 0;
    if (this.userBalances && this.userBalances.length > 0) {
      for (let b of this.userBalances) {
        if (b.currency === this.withdrawCurrency) {
          this.selectedCurrencyBalance = b.balance;
          return;
        }
      }
    }
  }

  // end
}
