import { PromiseUtil } from './PromiseUtil';

export class Concurrent {
  private queue: ((v: ConcurrentHooker) => void)[] = [];

  private hook: { [k: number]: any } = {};
  private hookerId = 0;

  private count = 0;

  constructor(private cc: number = 1) {}

  public async enter() {
    return new Promise<ConcurrentHooker>((resolve, reject) => {
      if (this.count < this.cc) {
        this.count++;
        return resolve(this.genHooker());
      }
      this.queue.push(resolve);
    });
  }

  private genHooker() {
    const id = ++this.hookerId;
    const h = new ConcurrentHooker(() => {
      if (!this.hook[id]) return;
      delete this.hook[id];
      this.count--;
      this.next();
    });
    this.hook[id] = h;
    return h;
  }

  private next() {
    if (this.count < this.cc && this.queue.length > 0) {
      const f = this.queue.shift();
      if (f) {
        this.count++;
        f(this.genHooker());
      }
    }
  }

  public test2() {
    for (let i = 1; i <= 100; i++) {
      this.test2_exe(i, 100);
    }
  }

  private async test2_exe(id: number, t: number) {
    for (; t > 0; t--) {
      await PromiseUtil.wait(Math.ceil(Math.random() * 20) + 30);
      const hook = await this.enter();
      console.log(`${id} enter, concurrent=${this.count}/${this.cc}`);
      await PromiseUtil.wait(Math.ceil(Math.random() * 10) + 10);
      hook.release();
      console.log(`${id} release, concurrent=${this.count}/${this.cc}`);
    }
  }

  public test(c: number, times: number, call: () => Promise<boolean>) {
    for (let i = 1; i <= c; i++) {
      this.exe(times, call);
    }
  }

  private async exe(times: number, call: () => Promise<boolean>) {
    for (; times > 0; times--) {
      const hook = await this.enter();
      try {
        const _continue = await call();
        if (!_continue) break;
      } catch (err) {
        throw err;
      } finally {
        hook.release();
      }
    }
  }
}

export class ConcurrentHooker {
  constructor(public release: () => void) {}
}
