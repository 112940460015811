import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QRCodeModule } from 'angularx-qrcode';
import { CoreRoutingModule } from './core-routing.module';
import { TranslateModule } from '@ngx-translate/core';

import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { StartupComponent } from './pages/startup/startup.component';
import { DevGuideComponent } from './pages/dev-guide/dev-guide.component';
import { LoginComponent } from './pages/login/login.component';
import { InitComponent } from './pages/init/init.component';
import { GoogleAuthComponent } from './dialog/google-auth/google-auth.component';
import { RegisterComponent } from './pages/register/register.component';
import { NotifiySnackbarComponent } from './components/notifiy-snackbar/notifiy-snackbar.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { WithdrawComponent } from './pages/withdraw/withdraw.component';
import { RechargeComponent } from './pages/recharge/recharge.component';
import { AlertComponent } from './dialog/alert/alert.component';
import { DevComponent } from './pages/dev/dev.component';
import { TransPasswordComponent } from './pages/trans-password/trans-password.component';
import { ExchangeComponent } from './pages/exchange/exchange.component';
import { CurrencySelectorComponent } from './dialog/currency-selector/currency-selector.component';
import { DashboardHomeComponent } from './components/dashboard-home/dashboard-home.component';
import { DashboardRecordComponent } from './components/dashboard-record/dashboard-record.component';
import { DashboardAnnouncementComponent } from './components/dashboard-announcement/dashboard-announcement.component';
import { AnnouncementDetailComponent } from './pages/announcement-detail/announcement-detail.component';
import { RecordDetailComponent } from './pages/record-detail/record-detail.component';
import { RecordListComponent } from './pages/record-list/record-list.component';
import { NotifiyListComponent } from './pages/notifiy-list/notifiy-list.component';
import { DashboardSettingComponent } from './components/dashboard-setting/dashboard-setting.component';
import { BindingMobileComponent } from './pages/binding-mobile/binding-mobile.component';
import { LinkSnackbarComponent } from './components/link-snackbar/link-snackbar.component';
import { AuthenticatorBindComponent } from './pages/authenticator-bind/authenticator-bind.component';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { WINDOW_PROVIDERS } from './provider/window.provider';
import { WalletCurrencyPipe } from './pipes/wallet-currency.pipe';
import { CountryNamePipe } from './pipes/country-name.pipe';
import { AccountTypeNamePipe } from './pipes/account-type-name.pipe';
import { PrincipalMaskPipe } from './pipes/principal-mask.pipe';
import { LanguageSelectorComponent } from './dialog/language-selector/language-selector.component';
import { WalletComponent } from './pages/wallet/wallet.component';
import { VerifyComponent } from './pages/verify/verify.component';
import { CountdownTimePipe } from './pipes/countdown-time.pipe';
import { SetTrxPasswordComponent } from './pages/set-trx-password/set-trx-password.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { BeneficiaryListComponent } from './pages/beneficiary-list/beneficiary-list.component';
import { AddBeneficiaryComponent } from './pages/add-beneficiary/add-beneficiary.component';
import { BeneficiaryDetailComponent } from './pages/beneficiary-detail/beneficiary-detail.component';
import { DatePrunerPipe } from './pipes/date-pruner.pipe';
import { RedirectPopupComponent } from './dialog/redirect-popup/redirect-popup.component';
import { LauncherComponent } from './pages/launcher/launcher.component';
import { DashboardDealComponent } from './components/dashboard-deal/dashboard-deal.component';
import { QuantityComponent } from './dialog/quantity/quantity.component';

@NgModule({
  declarations: [
    StartupComponent,
    DevGuideComponent,
    LoginComponent,
    InitComponent,
    GoogleAuthComponent,
    RegisterComponent,
    DashboardComponent,
    NotifiySnackbarComponent,
    AlertComponent,
    WithdrawComponent,
    RechargeComponent,
    AuthenticatorBindComponent,
    DevComponent,
    TransPasswordComponent,
    ExchangeComponent,
    CurrencySelectorComponent,
    DashboardHomeComponent,
    DashboardRecordComponent,
    DashboardAnnouncementComponent,
    AnnouncementDetailComponent,
    SafeHtmlPipe,
    WalletCurrencyPipe,
    RecordDetailComponent,
    RecordListComponent,
    NotifiyListComponent,
    DashboardSettingComponent,
    BindingMobileComponent,
    LinkSnackbarComponent,
    WalletCurrencyPipe,
    CountryNamePipe,
    AccountTypeNamePipe,
    PrincipalMaskPipe,
    LanguageSelectorComponent,
    WalletComponent,
    VerifyComponent,
    CountdownTimePipe,
    SetTrxPasswordComponent,
    ForgotPasswordComponent,
    BeneficiaryListComponent,
    AddBeneficiaryComponent,
    BeneficiaryDetailComponent,
    DatePrunerPipe,
    RedirectPopupComponent,
    LauncherComponent,
    DashboardDealComponent,
    QuantityComponent,
  ],
  imports: [
    CommonModule,
    CoreRoutingModule,
    FormsModule,
    QRCodeModule,
    TranslateModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatCheckboxModule,
    MatSelectModule,
    MatBadgeModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatRippleModule,
    MatTooltipModule,
    MatDividerModule,
    MatExpansionModule,
    ClipboardModule,
  ],
  providers: [WINDOW_PROVIDERS],
})
export class CoreModule {}
