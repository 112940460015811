<div class="container">
    <!-- <p>login works!</p> -->

    <div class="top">
        <div class="backBtn" (click)="onGoBack()">
            <img class="backBtnImg" alt="image" src="/assets/images/icons/arrow-down-sign-to-navigate.png" />
            <div class="backBtnTxt">{{'Beneficiary.Title2'|translate}}</div>
        </div>

        <!-- Debug 用選單 -->
        <!-- <mat-chip-listbox aria-label="Fish selection" style="display: flex;align-items: center;"
            (change)="demoSelected($event)">
            <mat-chip-option *ngFor="let chip of stages" [selected]="chip.selected" [color]="chip.color">
                {{chip.name}}
            </mat-chip-option>
        </mat-chip-listbox> -->
    </div>

    <div class="body">

      <!-- 新增帳戶 -->
      <form [formGroup]="withdrawFormCtl" class="block-content" *ngIf="stage=='1'">

          <!-- 出款幣別清單 -->
          <div class="col">
            <div class="subtitle">{{'Withdraw.WithdrawCurrency'|translate}} *</div>
            <div>
                <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-select formControlName="currency">
                        <mat-option *ngFor="let balance of withdrawCurrencyMenu" value="{{balance}}">{{balance}}</mat-option>
                    </mat-select>
                    <!-- <mat-icon matSuffix style="position: absolute;">domain</mat-icon> -->
                </mat-form-field>
            </div>
            <!-- <div class="pay-box-hint hint-color-blue">{{'Common.Balance'|translate}}：{{selectedCurrencyBalance | walletCurrency}}</div> -->
          </div>

          <!-- 收款人所在地 -->
          <!-- <div class="col">
              <div class="subtitle">{{'Withdraw.BeneficiaryLocation'|translate}} *</div>
              <div>
                  <mat-form-field appearance="outline" style="width: 100%;">
                      <mat-select [value]="''" formControlName="beneficiaryLocation">
                          <mat-option value="">-</mat-option>
                          <mat-option *ngFor="let item of countryCodeMenu" value="{{item.code}}">{{item.code}}, {{ item.name| translate }}</mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
          </div> -->

          <!-- 出金帳戶名稱 -->
          <div class="col">
              <div class="subtitle">{{'Withdraw.AccountName'|translate}} *</div>
              <div>
                  <mat-form-field appearance="outline" style="border-radius: 20px;width: 100%;">
                      <input matInput maxlength="64" formControlName="accountName" placeholder="{{'Withdraw.EnterAccountName'|translate}}">
                  </mat-form-field>
              </div>
          </div>
          
          <!-- 銀行賬戶號碼 -->
          <div class="col">
              <div class="subtitle">{{'Withdraw.BankAccount'|translate}} *</div>
              <div >
                  <mat-form-field appearance="outline" style="border-radius: 20px;width: 100%;">
                      <input matInput type="number" formControlName="bankAccount" placeholder="{{'Withdraw.EnterBankAccount'|translate}}">
                  </mat-form-field>
              </div>
          </div>

          <!-- 銀行名稱 -->
          <div class="col">
              <div class="subtitle">{{'Withdraw.BankName'|translate}} *</div>
              <div >
                  <mat-form-field appearance="outline" style="border-radius: 20px;width: 100%;">
                      <input matInput maxlength="40" formControlName="bankName" placeholder="{{'Withdraw.EnterBankName'|translate}}">
                  </mat-form-field>
              </div>
          </div>

          <!-- 銀行地址 1 -->
          <div class="col">
              <div class="subtitle">{{'Withdraw.BankAddress'|translate}} (1/3) *</div>
              <div >
                  <mat-form-field appearance="outline" style="border-radius: 20px;width: 100%;">
                      <input matInput [maxLength]="'35'" formControlName="bankAddress1" required placeholder="{{'Withdraw.EnterBankAddress'|translate}}">
                  </mat-form-field>
              </div>
          </div>

          <!-- 銀行地址 2 -->
          <div class="col">
              <div class="subtitle">{{'Withdraw.BankAddress'|translate}} (2/3)</div>
              <div >
                  <mat-form-field appearance="outline" style="border-radius: 20px;width: 100%;">
                      <input matInput [maxLength]="'35'" formControlName="bankAddress2" placeholder="{{'Withdraw.EnterBankAddress'|translate}}">
                  </mat-form-field>
              </div>
          </div>

          <!-- 銀行地址 3 -->
          <div class="col">
              <div class="subtitle">{{'Withdraw.BankAddress'|translate}} (3/3)</div>
              <div>
                  <mat-form-field appearance="outline" style="border-radius: 20px;width: 100%;">
                      <input matInput [maxLength]="'35'" formControlName="bankAddress3" placeholder="{{'Withdraw.EnterBankAddress'|translate}}">
                  </mat-form-field>
              </div>
          </div>

          <!-- 出金帐户所在地 -->
          <div class="col">
              <div class="subtitle">{{'Withdraw.RecvBankLocation'|translate}} *</div>
              <div>
                  <mat-form-field appearance="outline" style="width: 100%;">
                      <mat-select [value]="''" formControlName="bankLocation">
                          <mat-option value="">-</mat-option>
                          <mat-option *ngFor="let item of countryCodeMenu" value="{{item.code}}">{{item.code}}, {{ item.name| translate }}</mat-option>
                      </mat-select>
                      <!-- <mat-icon matSuffix style="position: absolute;">domain</mat-icon> -->
                  </mat-form-field>
              </div>
          </div>

          <!-- SWIFT Code -->
          <div class="col">
              <div class="subtitle">{{'Common.SwiftCode'|translate}} *</div>
              <div>
                  <mat-form-field appearance="outline" style="width: 100%;">
                      <input matInput maxlength="16" formControlName="swiftCode" placeholder="{{'Withdraw.EnterSwiftCode'|translate}}">
                  </mat-form-field>
              </div>
          </div>

          <!-- 收款帳戶類型 -->
          <div class="col">
              <div class="subtitle">{{'Withdraw.WithdrawAccountType'|translate}} *</div>
              <div>
                  <mat-form-field appearance="outline" style="width: 100%;">
                      <mat-select [value]="''" formControlName="withdrawAccountType" (valueChange)="onBeneficiaryTypeChanged($event)">
                          <mat-option value="">-</mat-option>
                          <mat-option *ngFor="let type of accountTypeMenu" value="{{type.code}}">{{type.name | translate}}</mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
          </div>

          <!-- 收款人名稱 -->
          <div class="col">
              <div class="subtitle">{{'Withdraw.BeneficiaryName'|translate}} *</div>
              <div>
                  <mat-form-field appearance="outline" style="border-radius: 20px;width: 100%;">
                      <input matInput [maxLength]="'60'" formControlName="beneficiaryName" placeholder="{{'Withdraw.EnterBeneficiaryName'|translate}}">
                      <!-- <mat-hint >{{'Withdraw.BeneficiaryNameFormat' | translate}}</mat-hint> -->
                      <mat-error *ngIf="isIndividualBeneficiary">{{'Withdraw.BeneficiaryNameFormat' | translate}}</mat-error>
                  </mat-form-field>
              </div>
          </div>

          <!-- 收款人所在地 -->
          <div class="col">
              <div class="subtitle">{{'Withdraw.BeneficiaryLocation'|translate}}</div>
              <div>
                  <mat-form-field appearance="outline" style="width: 100%;">
                      <mat-select formControlName="beneficiaryLocation">
                          <mat-option>-</mat-option>
                          <mat-option *ngFor="let item of countryCodeMenu" value="{{item.code}}">{{item.code}}, {{ item.name| translate }}</mat-option>
                      </mat-select>
                      <!-- <mat-icon matSuffix style="position: absolute;">domain</mat-icon> -->
                  </mat-form-field>
              </div>
          </div>

          <!-- 收款人地址 1 -->
          <div class="col" [ngClass]="{'mt30':hasBeneficiaryNameError}" >
              <div class="subtitle">{{'Withdraw.BeneficiaryAddress'|translate}} (1/3)*</div>
              <div>
                  <mat-form-field appearance="outline" style="border-radius: 20px;width: 100%;">
                      <input matInput formControlName="beneficiaryAddress1" placeholder="{{'Withdraw.EnterBeneficiaryAddress'|translate}}">
                  </mat-form-field>
              </div>
          </div>

          <!-- 收款人地址 2 -->
          <div class="col">
              <div class="subtitle">{{'Withdraw.BeneficiaryAddress'|translate}} (2/3)</div>
              <div>
                  <mat-form-field appearance="outline" style="border-radius: 20px;width: 100%;">
                      <input matInput formControlName="beneficiaryAddress2" placeholder="{{'Withdraw.EnterBeneficiaryAddress'|translate}}">
                  </mat-form-field>
              </div>
          </div>

          <!-- 收款人地址 3 -->
          <div class="col">
              <div class="subtitle">{{'Withdraw.BeneficiaryAddress'|translate}} (3/3)</div>
              <div>
                  <mat-form-field appearance="outline" style="border-radius: 20px;width: 100%;">
                      <input matInput formControlName="beneficiaryAddress3" placeholder="{{'Withdraw.EnterBeneficiaryAddress'|translate}}">
                  </mat-form-field>
              </div>
          </div>

          <!-- <div class="pay-box" style="margin-top: 20px;flex-direction: row;color: #ffaf2a;align-items: end;">
              <mat-icon>info</mat-icon>
              <div class="pay-box-hint" style="margin-left: 5px;">韩国地区于2023/07/04休市。</div>
          </div> -->

          <!-- 加入常用出金帳號 -->
          <div class="col" style="margin-bottom: 22px ;">
              <mat-checkbox formControlName="favors">{{'Withdraw.AddFavors'|translate}}</mat-checkbox>
          </div>

          <!-- 添加帳戶備註訊息 -->
          <div class="col">
            <div class="subtitle">{{'Withdraw.FavorsComment'|translate}}</div>
            <div >
              <mat-form-field appearance="outline" style="border-radius: 20px;width: 100%;">
                <input matInput formControlName="beneficiaryNickname" placeholder="{{'Withdraw.AddFavorsComment'|translate}}">
              </mat-form-field>
            </div>
          </div>

          <!-- 送出/下一步 -->
          <div class="col">
              <button mat-flat-button color="primary" [disabled]="withdrawFormCtl.status !== 'VALID'" (click)="onCommitWithdrawForm()" class="add-btn">{{'Common.NextStep'|translate}}</button>
          </div>

      </form>

      <!-- 完成畫面 -->
      <div class="block-create-order" *ngIf="stage=='2'">
          <img class="banner-img" src="/assets/images/withdraw/banner.png" />

          <div class="pay-box">
              <div class="style-5" style="text-align: center; margin-top: 30px;">
                  {{'Beneficiary.AddSuccess'|translate}}</div>
              <div class="style-3" style="text-align: center; margin-top: 30px;">
                  {{'Beneficiary.AddSuccessMore-1'|translate}}<br />
                  {{'Beneficiary.AddSuccessMore-2'|translate}}</div>
          </div>

          <div class="pay-box pay-box-with-hit" style="margin-top: 30px;">
              <button mat-flat-button color="primary" class="next-btn" (click)="onClose()">{{'Common.Confirm'|translate}}</button>
          </div>

      </div>

    </div>

</div>