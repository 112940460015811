import { EventEmitter, Injectable } from '@angular/core';
import { SessionContext } from '../domain/types/session-context';
import { ClientService } from './client.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { SessionService, SessionState } from './session.service';
import { UserDataService } from './user-data.service';
import { LocalService } from './local.service';
import {
  Notification as NotificationItem,
  NotifyAccountTransferContent,
  NotifyExchangeResultContent,
  NotifyPaymentResultContent,
  NotifyRemittanceReceivedContent,
} from '../domain/network/response';
import { PromiseUtil } from '../common/PromiseUtil';
import { StringUtil } from '../common/StringUtil';
import { CurrencyUtil } from '../common/CurrencyUtil';

export interface Notification {
  notifyId: number;
  eventId: string;
  read: boolean;
  message: string;
  time: string;
  link?: string;
}

export interface NotificationState {}

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  private timer: NodeJS.Timeout;
  private nextTs: number = 0;
  private busy = false;
  private hasNew = false;
  private notifications: Notification[] = [];

  private stateChangeEmitter = new EventEmitter<NotificationState>();

  constructor(
    private client: ClientService,
    private userData: UserDataService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private location: Location,
    private local: LocalService,
    private session: SessionService
  ) {
    this.timer = setInterval(() => {
      this.hook();
    }, 100);

    this.session.stateChanges.subscribe({
      next: (state) => {
        switch (state) {
          case SessionState.LOGIN:
          case SessionState.LOGOUT:
            this.serviceInit();
            break;
        }
      },
    });

    // 測試
    // this.client.listNotifyEvents();
  }

  private serviceInit() {
    this.nextTs = 0;
    this.busy = false;
    this.hasNew = false;
    this.notifications = [];
  }

  // 通知列表事件
  public get stateChanges() {
    return this.stateChangeEmitter.asObservable();
  }

  get hasNewNotification() {
    return this.hasNew;
  }

  // 是否有未讀的通知
  get hasUnreadNotification() {
    for (let n of this.notifications) {
      if (!n.read) return true;
    }
    return false;
  }

  // 讀取未讀通知列表
  get unreadList(): Notification[] {
    const list: Notification[] = this.notifications.filter((n) => !n.read);
    return list;
  }

  // 讀取通知列表
  get notificationList(): Notification[] {
    return this.notifications;
  }

  // 設定通知已讀
  async setRead(id: string) {
    const n = this.notifications.find((n) => n.eventId === id);
    if (!n) return false;

    try {
      await this.client.markEventAsRead([n.notifyId]);
      // 標記已讀
      n.read = true;
      // 判斷已經都已讀, 就設為沒有新訊息
      if (!this.hasUnreadNotification) {
        this.hasNew = false;
      }
      // 通知資料異動
      this.stateChangeEmitter.emit({});
      return true;
    } catch (err) {
      console.error(err);
    }

    return false;
  }

  closeNewNotify() {
    this.hasNew = false;
  }

  private async hook() {
    if (this.busy) return; // 忙碌中
    const ts_now = Date.now();
    if (this.nextTs > ts_now) return; // CD 未到
    if (!this.session.isLogin) return; // 尚未登入

    this.busy = true;
    try {
      const table = await this.client.listNotifyEvents();
      if (table) {
        for (const event of table.rows) {
          await this.procEvent(event);
        }
      }

      // this.notifications.push({
      //   notifyId: event.id,
      //   eventId: event.eventId,
      //   read: event.readed,
      //   message,
      //   time: event.createTime,
      //   link: '/records/transfer',
      // });

      // // 測試流程
      // this.notifications.push({
      //   notifyId: 1234,
      //   eventId: Date.now().toString(),
      //   read: false,
      //   message: `現在時間: ${new Date().toString()}，你說該怎麼辦呢?我真的不知道該拿你怎麼辦才好呀`,
      //   time: 'xxx-xx-xx',

      //   // notifyId: event.id,
      //   // eventId: event.eventId,
      //   // read: event.readed,
      //   // message,
      //   // time: event.createTime,
      //   link: '/records/withdraw',
      // });
      // this.notifications.push({
      //   notifyId: 1235,
      //   eventId: Date.now().toString(),
      //   read: false,
      //   message: `等級提升了`,
      //   time: 'xxx-xx-xx',

      //   // notifyId: event.id,
      //   // eventId: event.eventId,
      //   // read: event.readed,
      //   // message,
      //   // time: event.createTime,
      //   link: '/records/withdraw',
      // });
      // this.hasNew = true;
      // this.stateChangeEmitter.emit({});
    } catch (err) {
      console.error(err);
    } finally {
      this.busy = false;
      // this.nextTs = Date.now() + 300 * 1000; // 5min
      this.nextTs = Date.now() + 60 * 1000; // 60s
    }
  }

  private async procEvent(event: NotificationItem) {
    const n = this.notifications.find((n) => n.eventId === event.eventId);
    if (n) return;

    // console.log(`add`, event);
    let count = 0;
    switch (event.eventName) {
      // 出款完成
      case 'payment.result': {
        const tr = await PromiseUtil.FromObservable(
          this.translate.get('Notification.PaymentResult')
        );
        const content: NotifyPaymentResultContent =
          event.notifyContent as NotifyPaymentResultContent;
        const message = StringUtil.format(
          tr,
          CurrencyUtil.amountFormat(content.data.paymentDetails.amount, 100),
          content.data.paymentDetails.currency
        );

        this.notifications.push({
          notifyId: event.id,
          eventId: event.eventId,
          read: event.readed,
          message,
          time: event.createTime,
          link: '/records/withdraw',
        });
        count++;
        break;
      }
      // 完成匯兌
      case 'exchange.apply.result': {
        // NotifyExchangeResultContent
        const tr = await PromiseUtil.FromObservable(
          this.translate.get('Notification.ExchangeApplyResult')
        );
        const content: NotifyExchangeResultContent =
          event.notifyContent as NotifyExchangeResultContent;
        // 结算通知：您申请 9,000 HKD兑换为USD，已结算完成。
        const message = StringUtil.format(
          tr,
          CurrencyUtil.amountFormat(content.data.sourceAmt, 100),
          content.data.sourceCur,
          content.data.targetCur
        );
        this.notifications.push({
          notifyId: event.id,
          eventId: event.eventId,
          read: event.readed,
          message,
          time: event.createTime,
          link: '/records/exchange',
        });
        count++;
        break;
      }
      // 充值到帳通知
      case 'remittance.received': {
        const tr = await PromiseUtil.FromObservable(
          this.translate.get('Notification.ReceiveResult')
        );
        const content: NotifyRemittanceReceivedContent =
          event.notifyContent as NotifyRemittanceReceivedContent;
        // 入款通知：您有 18,000 USD，已到账。
        const message = StringUtil.format(
          tr,
          CurrencyUtil.amountFormat(content.data.amount, 100),
          content.data.currency
        );
        this.notifications.push({
          notifyId: event.id,
          eventId: event.eventId,
          read: event.readed,
          message,
          time: event.createTime,
          link: '/records/recharge',
        });
        count++;
        break;
      }
      // 會員互轉 (轉出通知)
      case 'account.tranfer.result': {
        const content: NotifyAccountTransferContent =
          event.notifyContent as NotifyAccountTransferContent;

        const isCreditor =
          content.data.creditorEmail === this.session.context?.principal;

        let tr = await PromiseUtil.FromObservable(
          this.translate.get(
            isCreditor
              ? 'Notification.TransferReceiveResult' // 收款方 (creditor)
              : 'Notification.TransferApplyResult' // 出款方 (debtor)
          )
        );

        // 会员转账通知：您申请的 ${0} ${1}转账指定会员，已结算完成。
        const message = StringUtil.format(
          tr,
          CurrencyUtil.amountFormat(content.data.amount, 100),
          content.data.currency
        );
        this.notifications.push({
          notifyId: event.id,
          eventId: event.eventId,
          read: event.readed,
          message,
          time: event.createTime,
          link: '/records/transfer',
        });
        count++;
        break;
      }
    }

    // 有新增, 就推播通知
    if (count > 0) {
      if (!event.readed) {
        this.hasNew = true;
      }
      this.stateChangeEmitter.emit({});
    }
  }
}
