import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-google-auth',
  templateUrl: './google-auth.component.html',
  styleUrls: ['./google-auth.component.scss'],
})
export class GoogleAuthComponent {
  verifyCodeCtl: FormControl;

  constructor(
    public dialogRef: MatDialogRef<GoogleAuthComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.verifyCodeCtl = new FormControl('', [
      Validators.required,
      Validators.pattern(/^[0-9]{6}$/),
    ]);
  }

  onNoClick(): void {
    this.dialogRef.close(this.verifyCodeCtl.value);
  }
}
