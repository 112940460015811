<div class="container">
    <!-- <p>login works!</p> -->

    <div class="top">
        <div class="backBtn" (click)="onGoBack()">
            <img class="backBtnImg" alt="image" src="/assets/images/icons/arrow-down-sign-to-navigate.png" />
            <div class="backBtnTxt">{{'Withdraw.Title'|translate}}</div>
        </div>

        <!-- Debug 用選單 -->
        <!-- <mat-chip-listbox aria-label="Fish selection" style="display: flex;align-items: center;"
            (change)="demoSelected($event)">
            <mat-chip-option *ngFor="let chip of stages" [selected]="chip.selected" [color]="chip.color">
                {{chip.name}}
            </mat-chip-option>
        </mat-chip-listbox> -->
    </div>

    <div class="body">
        <!-- 出金帳戶模式選單 -->
        <div class="block-menu" *ngIf="stage=='1'">

            <!-- 選擇常用 -->
            <div class="menu-button" matRipple centered radius="1" (click)="onSetBeneficiaryMethod('outer-select')">
               <div class="menu-button-content">
                    <div class="menu-button-msg">
                        <div class="menu-button-msg-title">{{'Withdraw.SelectWithdrawAccount' | translate}}</div>
                        <div class="menu-button-msg-subtitle">{{'Withdraw.SelectWithdrawAccountTips' | translate}}</div>
                    </div>
                    <img class="menu-button-img" src="/assets/images/withdraw/banner_select.png">
                </div>
            </div>

            <!-- 新建出金帳號 -->
            <div class="menu-button" matRipple centered radius="60" (click)="onSetBeneficiaryMethod('outer-create')">
                <div class="menu-button-content">
                    <div class="menu-button-msg">
                        <div class="menu-button-msg-title">{{'Withdraw.CreateWithdrawAccount' | translate}}</div>
                        <div class="menu-button-msg-subtitle">{{'Withdraw.CreateWithdrawAccountTips' | translate}}</div>
                    </div>
                    <img class="menu-button-img" src="/assets/images/withdraw/banner_create.png">
                </div>
            </div>

            <!-- 帳戶互轉 -->
            <div class="menu-button" matRipple centered radius="60" (click)="onSetBeneficiaryMethod('inner')">
                <div class="menu-button-content">
                    <div class="menu-button-msg">
                        <div class="menu-button-msg-title">{{'Withdraw.TransferAccount' | translate}}</div>
                        <div class="menu-button-msg-subtitle">{{'Withdraw.TransferAccountTips' | translate}}</div>
                    </div>
                    <img class="menu-button-img" src="/assets/images/withdraw/banner_transfer.png">
                </div>
            </div>

        </div>

        <!-- 設定出金金額 -->
        <div class="block-create-order" *ngIf="stage=='2'">
            <img class="banner-img" src="/assets/images/withdraw/banner.png" />

            <div class="pay-box pay-box-with-hit">
                <div class="pay-box-title">您支付</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="border-radius: 20px;">
                        <input matInput type="number" min="0" placeholder="输入金额" [formControl]="amountControl">
                    </mat-form-field>

                    <!-- 帳戶清單 -->
                    <mat-form-field appearance="outline" style="margin-left: 10px; width: 100px;">
                        <mat-select [(value)]="withdrawCurrency" (selectionChange)="onWithdrawCurrencySelect()">
                            <mat-option *ngFor="let balance of withdrawCurrencyMenu" value="{{balance}}">{{balance}}</mat-option>
                        </mat-select>
                        <!-- <mat-icon matSuffix style="position: absolute;">domain</mat-icon> -->
                    </mat-form-field>
                </div>
                <div class="pay-box-hint hint-color-blue">{{'Common.Balance'|translate}}：{{selectedCurrencyBalance | walletCurrency}}</div>
            </div>

            <!-- 出金方式 -->
            <!-- <div class="pay-box">
                <div class="pay-box-title">{{'Withdraw.WithdrawMethod'|translate}}</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-select [value]="''">
                            <mat-option value="">-</mat-option>
                            <mat-option *ngFor="let method of withdrawMethodMenu" value="{{method.code}}"> {{method.name | translate}} </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div> -->

            <!-- <div class="pay-box pay-box-with-hit">
                <div class="row justify-content-between">
                    <div class="style-1">{{'Common.CurrencyExchangeRate'|translate}}</div>
                    <div class="style-2">1 USD = 112 KRM</div>
                </div>
                <div class="row-rev ">
                    <div class="style-3">{{'Common.UpdateFrom'|translate}} 20:07:13</div>
                    <mat-spinner style="width: 15px;height: 15px;"></mat-spinner>
                </div>
                <div class="row justify-content-between">
                    <div class="style-4">{{'Common.Cost'|translate}}</div>
                    <div class="style-5">10.33 USD</div>
                </div>
            </div> -->

            <!-- 匯率資訊 -->
            <!-- <div class="pay-box pay-box-with-hit">
                <div class="row justify-content-between align-top">
                    <div class="style-1">{{'Common.CurrencyExchangeRate'|translate}}</div>
                    <div class="col">
                        <div class="style-6 text-align-right">{{ rateDescInfo ? rateDescInfo : ('Exchange.RateNotFound'|translate)}}</div>
                        <div class="row-rev" style="margin-top: 5px;" >
                            <div class="style-3" *ngIf="rateUpadeTime">{{'Common.UpdateFrom'|translate}} {{ rateUpadeTime }}</div>
                            <div class="style-3" *ngIf="!rateUpadeTime">-- : -- : --</div>
                            <div style="display: block;">
                                <div class="spin-background-circle"></div>
                                <mat-progress-spinner mode="determinate" [value]="inquirySpinProgressVal" color="accent" style="width: 15px;height: 15px;"></mat-progress-spinner>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

            <!-- <div class="pay-box">
                <div class="pay-box-title">{{'Withdraw.WithdrawReveive'|translate}}</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="border-radius: 20px;">
                        <input matInput disabled placeholder="0" value="{{currentExchangeEstimate | walletCurrency}}" class="exchange-preview">
                    </mat-form-field>

                    <mat-form-field appearance="outline" style="margin-left: 10px; width: 100px;">
                        <mat-select [value]="'KRM'">
                            <mat-option value="USDT">USDT</mat-option>
                            <mat-option value="USD">USD</mat-option>
                            <mat-option value="KRM">KRM</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div> -->

            <!-- <div class="pay-box pay-box-with-hit">
                <div class="pay-box-title">{{'Common.WithdrawDate'|translate}}</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <input matInput [matDatepicker]="picker1" [formControl]="date">
                        <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="pay-box-hint hint-color-gray">{{'Withdraw.Tips-1'|translate}}</div>
            </div> -->

            <!-- <div class="pay-box" style="margin-top: 20px;flex-direction: row;color: #ffaf2a;align-items: end;">
                <mat-icon>info</mat-icon>
                <div class="pay-box-hint" style="margin-left: 5px;">{{'Withdraw.Tips-2'|translate}}</div>
            </div> -->

            <div class="pay-box pay-box-with-hit">
                <button mat-flat-button color="primary" class="next-btn" (click)="onCheckWithdrawAmount()" [disabled]="inputUserWithdrawAmount == 0" >{{'Common.NextStep'|translate}}</button>
            </div>
        </div>

        <!-- 出金帳戶信息 / 立即新增帳戶 -->
        <form [formGroup]="withdrawFormCtl" class="block-create-order" *ngIf="stage=='3'">
            <img class="banner-img" src="/assets/images/withdraw/banner.png" />

            <!-- 出金帳戶名稱 -->
            <div class="pay-box">
                <div class="pay-box-title">{{'Withdraw.AccountName'|translate}} *</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="border-radius: 20px;width: 100%;">
                        <input matInput maxlength="64" formControlName="accountName" placeholder="{{'Withdraw.EnterAccountName'|translate}}">
                    </mat-form-field>
                </div>
            </div>
            
            <!-- 銀行賬戶號碼 -->
            <div class="pay-box">
                <div class="pay-box-title">{{'Withdraw.BankAccount'|translate}} *</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="border-radius: 20px;width: 100%;">
                        <input matInput type="number" formControlName="bankAccount" placeholder="{{'Withdraw.EnterBankAccount'|translate}}">
                    </mat-form-field>
                </div>
            </div>

            <!-- 銀行名稱 -->
            <div class="pay-box">
                <div class="pay-box-title">{{'Withdraw.BankName'|translate}} *</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="border-radius: 20px;width: 100%;">
                        <input matInput maxlength="40" formControlName="bankName" placeholder="{{'Withdraw.EnterBankName'|translate}}">
                    </mat-form-field>
                </div>
            </div>

            <!-- 銀行地址 1 -->
            <div class="pay-box">
                <div class="pay-box-title">{{'Withdraw.BankAddress'|translate}} (1/3) *</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="border-radius: 20px;width: 100%;">
                        <input matInput [maxLength]="'35'" formControlName="bankAddress1" required placeholder="{{'Withdraw.EnterBankAddress'|translate}}">
                    </mat-form-field>
                </div>
            </div>

            <!-- 銀行地址 2 -->
            <div class="pay-box">
                <div class="pay-box-title">{{'Withdraw.BankAddress'|translate}} (2/3)</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="border-radius: 20px;width: 100%;">
                        <input matInput [maxLength]="'35'" formControlName="bankAddress2" placeholder="{{'Withdraw.EnterBankAddress'|translate}}">
                    </mat-form-field>
                </div>
            </div>

            <!-- 銀行地址 3 -->
            <div class="pay-box">
                <div class="pay-box-title">{{'Withdraw.BankAddress'|translate}} (3/3)</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="border-radius: 20px;width: 100%;">
                        <input matInput [maxLength]="'35'" formControlName="bankAddress3" placeholder="{{'Withdraw.EnterBankAddress'|translate}}">
                    </mat-form-field>
                </div>
            </div>

            <!-- 出金帐户所在地 -->
            <div class="pay-box">
                <div class="pay-box-title">{{'Withdraw.RecvBankLocation'|translate}} *</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-select [value]="''" formControlName="bankLocation">
                            <mat-option value="">-</mat-option>
                            <mat-option *ngFor="let item of countryCodeMenu" value="{{item.code}}">{{item.code}}, {{ item.name| translate }}</mat-option>
                        </mat-select>
                        <!-- <mat-icon matSuffix style="position: absolute;">domain</mat-icon> -->
                    </mat-form-field>
                </div>
            </div>

            <!-- SWIFT Code -->
            <div class="pay-box">
                <div class="pay-box-title">{{'Common.SwiftCode'|translate}} *</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <input matInput maxlength="16" formControlName="swiftCode" placeholder="{{'Withdraw.EnterSwiftCode'|translate}}">
                    </mat-form-field>
                </div>
            </div>

            <!-- 收款帳戶類型 -->
            <div class="pay-box">
                <div class="pay-box-title">{{'Withdraw.WithdrawAccountType'|translate}} *</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-select [value]="''" formControlName="withdrawAccountType" (valueChange)="onBeneficiaryTypeChanged($event)">
                            <mat-option value="">-</mat-option>
                            <mat-option *ngFor="let type of accountTypeMenu" value="{{type.code}}">{{type.name | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <!-- 收款人名稱 -->
            <div class="pay-box">
                <div class="pay-box-title">{{'Withdraw.BeneficiaryName'|translate}} *</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="border-radius: 20px;width: 100%;">
                        <input matInput [maxLength]="'60'" formControlName="beneficiaryName" placeholder="{{'Withdraw.EnterBeneficiaryName'|translate}}">
                        <!-- <mat-hint >{{'Withdraw.BeneficiaryNameFormat' | translate}}</mat-hint> -->
                        <mat-error *ngIf="isIndividualBeneficiary">{{'Withdraw.BeneficiaryNameFormat' | translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <!-- 收款人地址 1 -->
            <div class="pay-box" [ngClass]="{'mt30':hasBeneficiaryNameError}" >
                <div class="pay-box-title">{{'Withdraw.BeneficiaryAddress'|translate}} (1/3)*</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="border-radius: 20px;width: 100%;">
                        <input matInput formControlName="beneficiaryAddress1" placeholder="{{'Withdraw.EnterBeneficiaryAddress'|translate}}">
                    </mat-form-field>
                </div>
            </div>

            <!-- 收款人地址 2 -->
            <div class="pay-box">
                <div class="pay-box-title">{{'Withdraw.BeneficiaryAddress'|translate}} (2/3)</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="border-radius: 20px;width: 100%;">
                        <input matInput formControlName="beneficiaryAddress2" placeholder="{{'Withdraw.EnterBeneficiaryAddress'|translate}}">
                    </mat-form-field>
                </div>
            </div>

            <!-- 收款人地址 3 -->
            <div class="pay-box">
                <div class="pay-box-title">{{'Withdraw.BeneficiaryAddress'|translate}} (3/3)</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="border-radius: 20px;width: 100%;">
                        <input matInput formControlName="beneficiaryAddress3" placeholder="{{'Withdraw.EnterBeneficiaryAddress'|translate}}">
                    </mat-form-field>
                </div>
            </div>

            <!-- <div class="pay-box" style="margin-top: 20px;flex-direction: row;color: #ffaf2a;align-items: end;">
                <mat-icon>info</mat-icon>
                <div class="pay-box-hint" style="margin-left: 5px;">韩国地区于2023/07/04休市。</div>
            </div> -->

            <!-- 加入常用出金帳號 -->
            <div class="pay-box">
                <mat-checkbox formControlName="favorite">{{'Withdraw.AddFavors'|translate}}</mat-checkbox>
            </div>

            <!-- 帳戶備註訊息 -->
            <div class="pay-box" style="margin-top: 20px;">
                <div class="pay-box-title">{{'Withdraw.FavorsComment'|translate}}</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="border-radius: 20px;width: 100%;">
                        <input matInput formControlName="beneficiaryNickname" placeholder="{{'Withdraw.AddFavorsComment'|translate}}">
                    </mat-form-field>
                </div>
            </div>

            <div class="pay-box pay-box-with-hit">
                <button mat-flat-button color="primary" [disabled]="withdrawFormCtl.status !== 'VALID'" (click)="onCommitWithdrawForm()" class="next-btn">{{'Common.NextStep'|translate}}</button>
            </div>

        </form>

        <!-- 常用出金帳戶 / 出金帳戶信息 -->
        <div class="block-create-order" *ngIf="stage=='4'">
            <img class="banner-img" src="/assets/images/withdraw/banner.png" />

            <div class="pay-box">
                <div class="pay-box-title">{{'Withdraw.ChoseFavorsAccount'|translate}}</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>{{'Withdraw.ChoseWithdrawAccount'|translate}}</mat-label>
                        <mat-select [value]="selectedBeneficiaryId" (valueChange)="onSelectBeneficiary($event)">
                            <mat-option *ngFor="let beneficiary of beneficiaryList" value="{{beneficiary.beneficiaryId}}">{{ beneficiary.display }}</mat-option>
                        </mat-select>
                        <!-- <mat-icon matSuffix style="position: absolute;">domain</mat-icon> -->
                    </mat-form-field>
                </div>
            </div>

            <!-- 預覽出款人資訊 -->
            <div class="pay-box pay-box-with-hit" *ngIf="beneficiarySummary">
                <div class="row justify-content-between align-top">
                    <div class="style-4">{{'Withdraw.WithdrawAccountInfo'|translate}}</div>
                    <div class="style-6" [innerHTML]="beneficiarySummary | safeHtml"></div>
                </div>
            </div>

            <!-- <div class="pay-box" style="margin-top: 20px;flex-direction: row;color: #ffaf2a;align-items: end;">
                <mat-icon>info</mat-icon>
                <div class="pay-box-hint" style="margin-left: 5px;">韩国地区于2023/07/04休市。</div>
            </div> -->

            <!-- <div class="pay-box" style="margin-top: 20px;">
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="border-radius: 20px;width: 100%;">
                        <input matInput #input maxlength="10" placeholder="'請輸入常用出金帳戶備註'">
                    </mat-form-field>
                </div>
            </div> -->

            <div class="pay-box pay-box-with-hit">
                <button mat-flat-button color="primary" [disabled]="!selectedBeneficiaryId" class="next-btn" (click)="onSelectBeneficiaryNext()">{{'Common.NextStep'|translate}}</button>
            </div>

        </div>

        <!-- 確認出金明細 -->
        <div class="block-create-order" *ngIf="stage=='5'">
            <img class="banner-img" src="/assets/images/withdraw/banner.png" />

            <!-- 出款金額 -->
            <div class="pay-box pay-box-with-hit">
                <div class="row justify-content-between align-top">
                    <div class="style-4">{{'Withdraw.Payment'|translate}}</div>
                    <div class="col">
                        <div class="style-7 text-align-right">{{inputUserWithdrawAmount | walletCurrency:1}} {{withdrawCurrency}}</div>
                        <div class="style-3">{{'Common.AvailableBalance'|translate}}：{{selectedCurrencyBalance | walletCurrency}} {{withdrawCurrency}}</div>
                    </div>
                </div>
            </div>

            <!-- 匯率 -->
            <!-- <div class="pay-box pay-box-with-hit">
                <div class="row justify-content-between align-top">
                    <div class="style-4">{{'Common.ExchangeRate'|translate}}</div>
                    <div class="col">
                        <div class="style-6 text-align-right">1 USD = 1122 KRM</div>
                        <div class="row-rev" style="margin-top: 5px;">
                            <div class="style-3">{{'Common.UpdateFrom'|translate}} 20:07:13</div>
                            <mat-spinner style="width: 15px;height: 15px;"></mat-spinner>
                        </div>
                    </div>
                </div>
            </div> -->

            <!-- 交易手續費 -->
            <div class="pay-box pay-box-with-hit">
                <div class="row justify-content-between align-top">
                    <div class="style-4">{{'Common.Cost'|translate}}</div>
                    <div class="style-5 text-align-right">{{feeInfo?.feeAmount || 0 | walletCurrency}} {{withdrawCurrency}}</div>
                </div>
            </div>

            <!-- 出金帳戶收到 -->
            <div class="pay-box pay-box-with-hit">
                <div class="row justify-content-between align-top">
                    <div class="style-4">{{'Withdraw.WithdrawReveive'|translate}}</div>
                    <div class="style-7 text-align-right">{{inputUserWithdrawAmount | walletCurrency:1}} {{withdrawCurrency}}</div>
                </div>
            </div>

            <!-- 銀行所在地 -->
            <div class="pay-box pay-box-with-hit" *ngIf="beneficiaryMethod!=='inner'">
                <div class="row justify-content-between align-top">
                    <div class="style-4">{{'Withdraw.WithdrawAccountLocation'|translate}}</div>
                    <div class="style-6 text-align-right" *ngIf="!!withdrawForm?.beneficiary?.details">{{withdrawForm?.beneficiary?.details?.bankInfo?.bankCountryCode | countryName | async}}</div>
                    <div class="style-6 text-align-right" *ngIf="!!selectedBeneficiary">{{selectedBeneficiary!.bankInfo!.bankCountryCode | countryName | async}}</div>
                </div>
            </div>

            <!-- 出金帳戶類型 -->
            <div class="pay-box pay-box-with-hit">
                <div class="row justify-content-between align-top">
                    <div class="style-4">{{'Withdraw.WithdrawAccountType'|translate}}</div>
                    <div *ngIf="beneficiaryMethod!=='inner' && withdrawForm?.beneficiary?.details" class="style-6 text-align-right">{{withdrawForm?.beneficiary?.details?.accountInfo?.accountType | accountTypeName | async}}</div>
                    <div *ngIf="beneficiaryMethod!=='inner' && selectedBeneficiary" class="style-6 text-align-right">{{selectedBeneficiary!.accountInfo!.accountType | accountTypeName | async}}</div>
                    <div *ngIf="beneficiaryMethod==='inner'" class="style-6 text-align-right">{{'Withdraw.InnerMember' | translate}}</div><!-- 会员 -->
                </div>
            </div>

            <!-- 出金方式 -->
            <div class="pay-box pay-box-with-hit">
                <div class="row justify-content-between align-top">
                    <div class="style-4">{{'Withdraw.WithdrawMethod'|translate}}</div>
                    <div *ngIf="beneficiaryMethod!=='inner'" class="style-6 text-align-right">SWIFT</div>
                    <div *ngIf="beneficiaryMethod==='inner'" class="style-6 text-align-right">{{'Withdraw.InnerTransfer' | translate}}</div><!-- 钱包转账 -->
                </div>
            </div>

            <!-- 出金帳戶資訊 -->
            <div class="pay-box pay-box-with-hit">
                <div class="row justify-content-between align-top">
                    <div class="style-4">{{'Withdraw.WithdrawAccountInfo'|translate}}</div>
                    <div class="style-6 text-align-right" [innerHTML]="beneficiarySummary ?? '' | safeHtml"></div>
                </div>
            </div>

            <!-- 出金日 -->
            <!-- <div class="pay-box pay-box-with-hit">
                <div class="row justify-content-between align-top">
                    <div class="style-4">{{'Common.WithdrawDate'|translate}}</div>
                    <div class="col">
                        <div class="style-6 text-align-right">2023年7月3日 (周一)</div>
                        <div class="style-4">{{'Withdraw.Tips-3'|translate}}</div>
                    </div>
                </div>
            </div> -->

            <div class="pay-box" style="margin-top: 20px;">
                <div class="pay-box-title">{{'Common.TransPassword'|translate}}</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="border-radius: 20px;width: 100%;">
                        <input matInput placeholder="{{'Common.Password'|translate}}"
                            [formControl]="trxPasswdCtl"
                            [type]="transPasswordHide ? 'password' : 'text'" style="border-radius: 25px;" required>
                        <mat-icon matSuffix (click)="transPasswordHide = !transPasswordHide">{{transPasswordHide ?
                            'visibility_off' : 'visibility'}}</mat-icon>
                    </mat-form-field>
                </div>
            </div>

            <div class="pay-box" style="margin-top: 20px;">
                <div class="pay-box-title">{{'Common.DynamicPassword'|translate}}</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="border-radius: 20px;width: 100%;">
                        <input matInput #input maxlength="6" [formControl]="totpPasswdCtl" placeholder="{{'Common.EnterVerifyCode'|translate}}">
                    </mat-form-field>
                </div>
            </div>

            <div class="pay-box" style="margin-top: 20px;flex-direction: row;color: #ffaf2a;align-items: end;">
                <mat-icon>info</mat-icon>
                <div class="pay-box-hint" style="margin-left: 5px;">{{'Withdraw.Tips-1'|translate}}</div>
            </div>

            <!-- 執行出金 -->
            <div class="pay-box pay-box-with-hit">
                <button mat-flat-button color="primary" [disabled]="passwdGrp.status !=='VALID'"
                    class="next-btn" (click)="onCommitTransaction()">{{'Withdraw.WithdrawConfirm'|translate}}</button>
            </div>

        </div>

        <!-- 完成畫面 -->
        <div class="block-create-order" *ngIf="stage=='6'">
            <img class="banner-img" src="/assets/images/withdraw/banner.png" />

            <div class="pay-box">
                <div class="style-5" style="text-align: center; margin-top: 30px;">
                    {{'Withdraw.CompleteTitle'|translate}}</div>
                <div class="style-3" style="text-align: center; margin-top: 30px;">
                    {{'Withdraw.CompleteDesc-1'|translate}}<br />
                    {{'Withdraw.CompleteDesc-2'|translate}}</div>
            </div>

            <div class="pay-box pay-box-with-hit" style="margin-top: 30px;">
                <button mat-flat-button color="primary" class="next-btn" (click)="onClose()">{{'Common.Confirm'|translate}}</button>
            </div>

        </div>

        <!-- 系統內會員互轉 -->
        <div class="block-create-order" *ngIf="stage=='7'">
            <img class="banner-img" src="/assets/images/withdraw/banner.png" />

            <!-- 選單: 常用/手動輸入 -->
            <div class="pay-box" *ngIf="false">
                <div class="pay-box-title">{{'Withdraw.ChoseTransferAccount'|translate}}</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>{{'Withdraw.ChoseTransferAccount'|translate}}</mat-label>
                        <mat-select [(value)]="innerBeneficiarySelect" (selectionChange)="onInnerBeneficiarySelect()">
                            <mat-option value="">{{'Withdraw.EnterPrincipal' | translate}}</mat-option>
                            <!-- <mat-option value="1">我精彩花旗號</mat-option>
                            <mat-option value="2">我愛一條才</mat-option> -->
                        </mat-select>
                        <!-- <mat-icon matSuffix style="position: absolute;">domain</mat-icon> -->
                    </mat-form-field>
                </div>
            </div>

            <!-- 手動輸入會員帳號 -->
            <div class="pay-box" *ngIf="innerBeneficiarySelect===''">
                <div class="pay-box-title">{{'Withdraw.EnterMemberEmail'|translate}} *</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <input matInput maxlength="64" [formControl]="beneficiaryEmailCtl" placeholder="{{'Withdraw.EnterWithdrawMemberEmail'|translate}}">
                    </mat-form-field>
                </div>
            </div>

            <!-- <div class="pay-box" style="margin-top: 20px;flex-direction: row;color: #ffaf2a;align-items: end;">
                <mat-icon>info</mat-icon>
                <div class="pay-box-hint" style="margin-left: 5px;">韩国地区于2023/07/04休市。</div>
            </div> -->

            <!-- <div class="pay-box" style="margin-top: 20px;">
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="border-radius: 20px;width: 100%;">
                        <input matInput #input maxlength="10" placeholder="'請輸入常用出金帳戶備註'">
                    </mat-form-field>
                </div>
            </div> -->

            <!-- 下一步 -->
            <div class="pay-box pay-box-with-hit">
                <button mat-flat-button color="primary" [disabled]="innerBeneficiarySelect=='' && !beneficiaryEmailCtl.valid" class="next-btn" (click)="onInnerBeneficiarSelected()">{{'Common.NextStep'|translate}}</button>
            </div>

        </div>

    </div>

</div>