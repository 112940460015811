import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PromiseUtil } from '../common/PromiseUtil';

@Pipe({
  name: 'accountTypeName',
})
export class AccountTypeNamePipe implements PipeTransform {
  // 帳戶類型對照表
  private static accountTypeMap = {
    PERSONAL: 'Common.AccountType.Personal',
    INDIVIDUAL: 'Common.AccountType.Personal',
    BUSINESS: 'Common.AccountType.Business',
  };

  constructor(private translate: TranslateService) {}

  async transform(value: unknown, ...args: unknown[]) {
    return new Promise<string>(async (resolve, reject) => {
      if (value) {
        for (let [k, v] of Object.entries(AccountTypeNamePipe.accountTypeMap)) {
          if (k === value) {
            const str = await PromiseUtil.FromObservable(this.translate.get(v));
            return resolve(str);
          }
        }
      }
      resolve('Unknown');
    });
  }
}
