import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotifiySnackbarComponent } from '../../components/notifiy-snackbar/notifiy-snackbar.component';
import { TranslateService } from '@ngx-translate/core';
import { ClientService } from '../../services/client.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { SessionService } from '../../services/session.service';
import {
  NotifyService,
  Notification as NotificationItem,
} from '../../services/notify.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dasbhoard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  @ViewChild('eref', { read: ViewContainerRef }) eref?: ViewContainerRef;

  // 分頁控制
  focusTab = 'home';
  Tabs = [
    {
      code: 'home',
      name: 'Dashboard.Home',
      focusImg: '/assets/images/icons/home-w.png',
      img: '/assets/images/icons/home-d.png',
    },
    {
      code: 'record',
      name: 'Dashboard.Record',
      focusImg: '/assets/images/icons/stat-w.png',
      img: '/assets/images/icons/stat-d.png',
    },
    {
      code: 'announcement',
      name: 'Dashboard.Announcement',
      focusImg: '/assets/images/icons/file-w.png',
      img: '/assets/images/icons/file-d.png',
    },
    {
      code: 'setting',
      name: 'Dashboard.Setting',
      focusImg: '/assets/images/icons/gear-w.png',
      img: '/assets/images/icons/gear-d.png',
    },
  ];

  notificationSubscription?: Subscription; // 訂閱

  notificationList: NotificationItem[] = [];
  notificationAnchor: string = ''; // event id
  notificationTitle: string = '';
  notificationPosition: number = 0;
  notificationLink = '';

  hideRequiredControl = new FormControl(false);
  signin: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    password: new FormControl('', [Validators.required, Validators.min(3)]),
    hideRequired: this.hideRequiredControl,
  });
  hide = true;

  selectedCurrency = 'CNY';
  balanceList?: { currency: string; balance: number; updateTime: string }[];
  focusCurrencyBalance: number = 0;

  constructor(
    private _snackBar: MatSnackBar,
    public translate: TranslateService,
    private client: ClientService,
    private route: ActivatedRoute,
    private location: Location,
    private session: SessionService,
    private notification: NotifyService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      let tab = params['tab'];
      if (tab) {
        this.focusTab = tab;
        // this.location.replaceState('/dashboard');
      }
    });
    this.notificationSubscription = this.notification.stateChanges.subscribe({
      next: (e) => {
        this.onNotificationChanges();
      },
    });
    this.onNotificationChanges();
  }

  ngOnDestroy() {
    if (this.notificationSubscription) {
      this.notificationSubscription.unsubscribe();
    }
  }

  get emailInput() {
    return this.signin.get('email');
  }

  get passwordInput() {
    return this.signin.get('password');
  }

  get hasNewNotify() {
    // return Math.ceil(Date.now() / 1000) % 10 > 2;
    // return true;
    // return this.notificationList.length > 0;
    return this.notification.hasNewNotification;
  }

  onNotifyBefore() {
    if (!this.hasNewNotify) return;
    if (this.notificationPosition <= 0) return;
    // console.log('onNotifyBefore');
    this.notificationPosition--;
    this.setNotificationFocus();
  }

  onNotifyNext() {
    if (!this.hasNewNotify) return;
    if (this.notificationPosition >= this.notificationList.length - 1) return;
    // console.log('onNotifyNext');
    this.notificationPosition++;
    this.setNotificationFocus();
  }

  onNotifyClose() {
    if (!this.hasNewNotify) return;
    // console.log('onNotifyClose');
    // this.notification.setRead(this.notificationAnchor);
    this.notification.closeNewNotify();
  }

  onNotifyDetail() {
    if (!this.hasNewNotify) return;
    this.notification.setRead(this.notificationAnchor);
  }

  private setNotificationFocus() {
    const position = this.notificationPosition;
    this.notificationAnchor = this.notificationList[position].eventId;
    this.notificationTitle = this.notificationList[position].message!;
    this.notificationLink = this.notificationList[position].link ?? '';
  }

  openSnackBar() {
    this._snackBar.openFromComponent(NotifiySnackbarComponent, {
      duration: 1000,
      viewContainerRef: this.eref,
    });
  }

  setFocusTab(name: string) {
    // if (name === 'announcement') return;
    this.focusTab = name;
  }

  // 通知更新
  private onNotificationChanges() {
    this.notificationList = this.notification.unreadList;
    if (this.notificationList.length > 0) {
      const n = this.notificationList.find((n, idx) => {
        if (n.eventId == this.notificationAnchor) {
          this.notificationPosition = idx;
          return true;
        }
        return false;
      });
      if (!n) {
        this.notificationPosition = 0;
        this.setNotificationFocus();
      }
    }
  }
}
