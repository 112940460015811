import { EventEmitter, Injectable } from '@angular/core';
import { LocalService } from './local.service';
import { ClientService } from './client.service';
import { PromiseUtil } from '../common/PromiseUtil';
import { SessionContext } from '../domain/types/session-context';

export enum SessionState {
  INIT = 0,
  LOGOUT = 1,
  LOGIN = 2,
}

export enum EventType {
  LOGIN_WITH_SESSION,
  LOGOUT,
}

interface EventItem {
  type: EventType;
  params: any;
}

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  // 狀態
  private state: SessionState;

  private eventQueue: EventItem[];

  private lock = false;

  private stateChangeEmitter = new EventEmitter<SessionState>();

  private session?: SessionContext;

  constructor(private local: LocalService) {
    this.eventQueue = [];
    this.state = SessionState.INIT;
    this.stateChangeEmitter.emit(SessionState.INIT);
  }

  public emit(event: EventType, params?: any) {
    this.eventQueue.push({
      type: event,
      params,
    });

    this.execute();
  }

  public get stateChanges() {
    return this.stateChangeEmitter.asObservable();
  }

  // 獲取登入狀態 Context
  public get context(): SessionContext | undefined {
    if (this.session) {
      return this.session;
    }
    return undefined;
  }

  public get isLogin() {
    return this.state === SessionState.LOGIN;
  }

  private async execute() {
    if (this.lock) return;
    this.lock = true;
    try {
      while (this.eventQueue.length > 0) {
        const e = this.eventQueue.shift();
        await this.eventHandler(e!);
      }
    } catch (err) {
      console.error(err);
    } finally {
      if (this.eventQueue.length > 0) {
        setTimeout(() => {
          this.execute();
        }, 50);
      }
      this.lock = false;
    }
  }

  private async eventHandler(event: EventItem) {
    const prev = this.state;
    switch (event.type) {
      case EventType.LOGIN_WITH_SESSION: {
        // 登入事件
        if (this.state === SessionState.LOGOUT) {
          this.session = event.params; // 登入, 儲存資料
          this.local.SessionContext = this.session!; // 添加 localstorage
          this.state = SessionState.LOGIN;
          // console.log(`登入成功`, this.session);
        }
        break;
      }
      case EventType.LOGOUT: {
        // 登出事件
        // console.log(`收到登出, 當前狀態:`, this.state);
        if (this.state === SessionState.LOGIN) {
          this.session = undefined; // 清除 session 資料
          this.local.SessionContext = false; // 清除 localstorage
          this.state = SessionState.LOGOUT;
        }
        break;
      }
    }
    if (prev !== this.state) {
      // console.log(`狀態變更 ${prev} => ${this.state}`);
      this.stateChangeEmitter.emit(this.state);
    }
  }

  private async taskLoadSession() {
    const sess = this.local.SessionContext;
    if (sess) {
      this.session = sess;
      this.state = SessionState.LOGIN;
    } else {
      this.state = SessionState.LOGOUT;
    }
    this.stateChangeEmitter.emit(this.state);
  }

  // 初始化入口
  async init() {
    const timeout = Date.now() + 200;
    await this.taskLoadSession();

    // while (Date.now() < timeout) {
    //   await PromiseUtil.wait(50);
    // }
  }
}
