import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ClientService } from '../../services/client.service';
import {
  NotifyService,
  Notification as NotificationItem,
} from '../../services/notify.service';
import { Subscription } from 'rxjs';

export interface NotifyRecord {
  title: string;
  time: string;
}

@Component({
  selector: 'app-notifiy-list',
  templateUrl: './notifiy-list.component.html',
  styleUrls: ['./notifiy-list.component.scss'],
})
export class NotifiyListComponent {
  statePresent = ['Common.Processing', 'Common.Complete', 'Common.Fail'];

  notificationSubscription?: Subscription; // 訂閱
  notificationList: NotificationItem[] = [];

  date = new FormControl(new Date());

  records: NotifyRecord[] = [
    // {
    //   title: '入款通知：您有500 CNY，已到账。',
    //   time: '2023-07-01 17:55:30',
    // },
    // {
    //   title: '入款通知：您有 18,000 USD，已到账。',
    //   time: '2023-07-01 17:55:30',
    // },
    // {
    //   title: '结算通知：您申请 9,000 HKD兑换为USD，已结算完成。',
    //   time: '2023-07-01 17:55:30',
    // },
    // {
    //   title: '结算通知：您申请的出金 1,300 USD，已结算完成。',
    //   time: '2023-07-01 17:55:30',
    // },
    // {
    //   title:
    //     '结算通知：您申请 9,000 HKD兑换为USD，已结算完成。HKD兑换为USD，已结算完成。HKD兑换为USD，已结算完成。HKD兑换为USD，已结算完成。aaaavvvv',
    //   time: '2023-07-01 17:55:30',
    // },
  ];

  constructor(
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    public translate: TranslateService,
    private client: ClientService,
    private notification: NotifyService
  ) {}

  ngOnInit() {
    // 訂閱通知
    this.notificationSubscription = this.notification.stateChanges.subscribe({
      next: (n) => this.onNotificationChanges(),
    });
    this.notificationList = this.notification.notificationList;
  }

  ngOnDestroy() {
    // 取消訂閱通知
    if (this.notificationSubscription) {
      this.notificationSubscription.unsubscribe();
    }
  }

  private onNotificationChanges() {
    this.notificationList = this.notification.notificationList;
    if (this.notificationList.length > 0) {
    }
  }

  onMoreDetail(eventId: string) {
    this.notification.setRead(eventId);
  }
}
