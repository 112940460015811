import { Component } from '@angular/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ClientService } from '../../services/client.service';

@Component({
  selector: 'app-init',
  templateUrl: './init.component.html',
  styleUrls: ['./init.component.scss'],
  // standalone: true,
  // imports: [MatProgressSpinnerModule],
})
export class InitComponent {

  constructor(client: ClientService) {
    // Promise.all()
  }
}
