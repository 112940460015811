import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '../provider/window.provider';

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  private constructor(@Inject(WINDOW) private window: Window) {}

  public get location() {
    return this.window.location;
  }
}
