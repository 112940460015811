import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ClientService } from '../../services/client.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../services/session.service';

interface BalanceRecord {
  currency: string;
  amount: number;
}

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss'],
})
export class WalletComponent {
  balanceRecords: BalanceRecord[] = [];

  principal: string = '';

  panelOpenState = false;

  openedCurrency = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private client: ClientService,
    private snackBar: MatSnackBar,
    private session: SessionService
  ) {}

  ngOnInit() {
    this.principal = this.session.context!.principal!;
    // this.principal = this.client.principal;

    // // 初始化參數
    // this.route.params.subscribe((params: Params) => {
    //   this.recordType = params['type'];
    //   if (
    //     !this.recordType ||
    //     !['recharge', 'withdraw', 'exchange'].includes(this.recordType)
    //   ) {
    //     this.router.navigate(['dashboard', 'record']);
    //     return;
    //   }
    // });

    this.fetchBalances();
  }

  onSetCurrency(val: string) {
    this.openedCurrency = val;
  }

  async fetchBalances() {
    try {
      this.balanceRecords = [];
      let resp = await this.client.balanceList();
      resp.sort((a, b) => {
        if (a.balance == b.balance) {
          return a.currency.localeCompare(b.currency);
        } else {
          return b.balance - a.balance;
        }
      });
      resp.forEach((val) => {
        this.balanceRecords.push({
          currency: val.currency,
          amount: val.balance,
        });
      });
      // console.log(resp);
    } catch (err) {}
  }

  onRechargeClick(currency: string) {
    this.router.navigate(['recharge', currency]);
  }

  onWithdrawClick(currency: string) {
    // 錢不夠不理他
    if (!this.checkBalance(currency)) {
      return;
    }
    this.router.navigate(['withdraw', currency]);
  }

  onExchangeClick(currency: string) {
    // 錢不夠不理他
    if (!this.checkBalance(currency)) {
      return;
    }
    this.router.navigate(['exchange', currency]);
  }

  backToHome() {
    this.router.navigate(['dashboard']);
  }

  private checkBalance(currency: string) {
    for (let b of this.balanceRecords) {
      if (b.currency === currency) {
        return b.amount > 0;
      }
    }
    return false;
  }

  private openSnackBar(message: string, duration: number = 1000) {
    this.snackBar.open(message, 'OK', {
      duration: duration,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
