<div class="container">

    <div class="top">
        <div class="backBtn" (click)="backToHome()">
            <img class="backBtnImg" alt="image" src="/assets/images/icons/arrow-down-sign-to-navigate.png" />
            <div class="backBtnTxt">{{transTitle | translate}}</div>
        </div>

    </div>

    <div class="body">

        <div class="block-content">

            <div class="search">
                <div class="datetime-range">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <input matInput [matDatepicker]="pickerBegin" [formControl]="dateFrom" (dateInput)="onChangeDatetimePicker('from', 'input', $event)" (dateChange)="onChangeDatetimePicker('from', 'change', $event)">
                        <mat-datepicker-toggle matIconSuffix [for]="pickerBegin"></mat-datepicker-toggle>
                        <mat-datepicker #pickerBegin></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <input matInput [matDatepicker]="pickerEnd" [formControl]="dateTo" (dateInput)="onChangeDatetimePicker('to', 'input', $event)" (dateChange)="onChangeDatetimePicker('to', 'change', $event)">
                        <mat-datepicker-toggle matIconSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                        <mat-datepicker #pickerEnd></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div class="wallet">
                <div matRipple centered radius="60" class="seachBtn" (click)="onSearch()">
                    <img src="/assets/images/icons/search.png">
                </div>

                <mat-form-field appearance="outline" *ngIf="recordType==='transfer'">
                    <mat-label>{{'Common.IncomeAndExpenditure' | translate}}</mat-label>
                    <mat-select [(value)]="selectedTransferType" (selectionChange)="onTransferTypeChanged()">
                        <!-- <mat-option value="all">{{'Records.TransferAll' | translate}}</mat-option>
                        <mat-option value="income">{{'Common.Income' | translate}}</mat-option>
                        <mat-option value="expenditure">{{'Common.Expenditure' | translate}}</mat-option> -->
                        <mat-option *ngFor="let ttype of transferTypeMenu" value="{{ttype.value}}">{{ ttype.title }}</mat-option>
                        
                    </mat-select>
                    <!-- <mat-icon matSuffix style="position: absolute;">domain</mat-icon> -->
                </mat-form-field>

                <mat-form-field appearance="outline" *ngIf="true">
                    <mat-label>{{'Common.Wallet'|translate}}</mat-label>
                    <mat-select [(value)]="selectedCurrency" (selectionChange)="onCurrencyChanged()" [disabled]="isDisableCurrency">
                        <mat-option value="all">{{'Records.CurrencyAll' | translate}}</mat-option>
                        <mat-option *ngFor="let curreny of currencyMenu" value="{{curreny}}">{{ curreny }}</mat-option>
                    </mat-select>
                    <!-- <mat-icon matSuffix style="position: absolute;">domain</mat-icon> -->
                </mat-form-field>

            </div>

            <div class="records">

                <div>

                    <!-- 查無數據 -->
                    <div class="record nodata info-style" *ngIf="!hasData">
                        {{'Dashboard.AnnoTab.Nodata'|translate}}
                    </div>

                    <!-- 數據列表 (錢包帳變)-->
                    <div class="record" *ngFor="let record of walletRecords">
                        <div class="flex row between">
                            <div class="flex col">
                                <div class="text-row">
                                    <div class="title">{{'Records.TxTime'|translate}}</div>
                                    <div class="info">{{record.transactionTime}}</div>
                                </div>
                                <!-- <div class="text-row">
                                    <div class="title">{{'Records.WithdrawTime'|translate}}</div>
                                    <div class="info">{{record.executeTime}}</div>
                                </div> -->
                            </div>
                            <div class="state recharge" *ngIf="record.transactionType==='TOP_UP'">{{'Records.TxnType.Recharge' | translate}}</div>
                            <div class="state payment" *ngIf="record.transactionType==='PAYMENT'">{{'Records.TxnType.Payment' | translate}}</div>
                            <div class="state conversion" *ngIf="record.transactionType==='CONVERSION'">{{'Records.TxnType.Conversion' | translate}}</div>
                            <div class="state internal" *ngIf="record.transactionType==='INTERNAL_TRANSFER'">{{'Records.TxnType.InnerTxn' | translate}}</div>
                        </div>
                        <div class="flex row">
                            <div class="title">{{'Records.Currency' | translate}}</div>
                            <div class="currency-info">{{ record.currency }}</div>
                        </div>
                        <div class="flex row-reverse between">
                            <div class="text-row">
                                <div class="title">{{'Records.TxBalance'|translate}}</div>
                                <div class="currency-2">{{record.remain| walletCurrency}}</div>
                            </div>
                            <div class="text-row">
                                <div class="title">{{'Records.TxAmount'|translate}}</div>
                                <div [ngClass]="{'currency-2':record.amount>0, 'currency-1':record.amount<=0}">{{record.amount | walletCurrency}}</div>
                            </div>
                        </div>
                    </div>

                    <!-- 數據列表 (出金記錄)-->
                    <div class="record" *ngFor="let record of withdrawRecords">
                        <div class="flex row between">
                            <div class="flex col">
                                <div class="text-row">
                                    <div class="title">{{'Records.OrderId'|translate}}</div>
                                    <div class="info">{{record.transactionId}}</div>
                                </div>
                                <div class="text-row">
                                    <div class="title">{{'Records.CreateTime'|translate}}</div>
                                    <div class="info">{{record.createTime}}</div>
                                </div>
                            </div>
                            <div class="state"
                                [ngClass]="{ 'proc':record.state==0, 'comp':record.state==1, 'fail': record.state==2 }">
                                {{statePresent[record.state]|translate}}</div>
                        </div>
                        <div class="flex row-reverse between">
                            <!-- <div class="text-row">
                                <div class="title">{{'Records.Expend'|translate}}</div>
                                <div class="currency-1">{{record.amount | walletCurrency}} {{record.currency}}</div>
                            </div> -->
                            <div class="text-row">
                                <div class="title">{{'Records.Pay'|translate}}</div>
                                <div class="currency-1">{{record.amount | walletCurrency}} {{record.currency}}</div>
                            </div>
                        </div>
                    </div>

                    <!-- 數據列表 (匯兌記錄)-->
                    <div matRipple centered radius="60" class="record" *ngFor="let record of exchangeRecords" routerLink="/record-detail/exchange/{{record.contractId}}">
                        <div class="flex row between">
                            <div class="flex col">
                                <div class="text-row">
                                    <div class="title">{{'Records.RequestTime'|translate}}</div>
                                    <div class="info">{{record.requestTime}}</div>
                                </div>
                                <div class="text-row">
                                    <div class="title">{{'Records.WithdrawTime'|translate}}</div>
                                    <div class="info">{{record.executeTime ?? '-'}}</div>
                                </div>
                            </div>
                            <div class="state" [ngClass]="{'proc':record.state==0, 'comp':record.state==1, 'fail': record.state==2}">{{record.stateMsg | translate}}</div>
                        </div>
                        <div class="flex row between">
                            <div class="text-row">
                                <div class="title">{{'Records.Sell'|translate}}</div>
                                <div class="currency-1">{{record.sourceAmount | walletCurrency}} {{record.sourceCurrency}}</div>
                            </div>
                            <div class="text-row">
                                <div class="title">{{'Records.Buy'|translate}}</div>
                                <div class="currency-2">{{record.targetAmount | walletCurrency}} {{record.targetCurrency}}</div>
                            </div>
                        </div>
                    </div>

                    <!-- 數據列表 (入金記錄)-->
                    <div class="record" *ngFor="let record of rechargeRecords">
                        <div class="flex row between">
                            <div class="flex col" style="align-items: start;">
                                <div class="text-row">
                                    <div class="title">{{'Records.OrderId'|translate}}</div>
                                    <div class="info">{{record.transactionId}}</div>
                                </div>
                                <div class="text-row">
                                    <div class="title">{{'Records.TransactionTime'|translate}}</div>
                                    <div class="info">{{record.executeTime}}</div>
                                </div>
                            </div>
                            <div class="state comp" >{{'Records.Status.Settled' | translate}}</div>
                        </div>
                        <div class="flex row-reverse between">
                            <div class="text-row">
                                <div class="title">{{'Common.Amount'|translate}}</div>
                                <div class="currency-1">{{record.amount | walletCurrency}} {{record.currency}}</div>
                            </div>
                        </div>
                    </div>

                    <!-- 數據列表 (轉帳記錄)-->
                    <div class="record" style="height: 66px;" *ngFor="let record of transferRecords">
                        <div class="flex row between">
                            <div class="flex col" style="align-items: start;">
                                <div class="text-row">
                                    <div *ngIf="record.isDebtor" class="title2 proc">{{'Records.TransferPaymentOrderId'|translate}}</div>
                                    <div *ngIf="record.isCreditor" class="title2 comp">{{'Records.TransferReceiveOrderId'|translate}}</div>
                                    <div class="info2">{{record.transferId}}</div>
                                </div>
                                <div class="text-row" *ngIf="record.isCreditor">
                                    <div class="title">{{'Records.Debtor'|translate}}</div>
                                    <div class="info">{{record.debtorEmail}}</div>
                                </div>
                                <div class="text-row" *ngIf="record.isDebtor">
                                    <div class="title">{{'Records.Creditor'|translate}}</div>
                                    <div class="info">{{record.creditorEmail}}</div>
                                </div>
                            </div>
                            <div class="state" [ngClass]="record.status.css" >{{record.status.translate | translate}}</div>
                        </div>
                        <div class="flex row-reverse between">
                            <div class="text-row">
                                <!-- <div class="title">{{'Common.Amount'|translate}}</div> -->
                                <div class="currency-4">{{record.amount | walletCurrency}} {{record.currency}}</div>
                            </div>
                            <div class="text-row">
                                <div class="title">{{'Records.UpdateTime'|translate}}</div>
                                <div class="info">{{record.updateTime}}</div>
                            </div>
                            
                        </div>
                    </div>

                    <!-- 沒有更多數據 -->
                    <div class="no-more-data info-style" *ngIf="hasData">{{'Records.NoMoreData'|translate}}</div>

                </div>

            </div>

        </div>

    </div>

</div>