<div class="container">

    <div class="top">
        <div class="backBtn" routerLink="/dashboard">
            <img class="backBtnImg" alt="image" src="/assets/images/icons/arrow-down-sign-to-navigate.png" />
            <div class="backBtnTxt">{{'Common.Notify'|translate}}</div>
        </div>

    </div>

    <div class="body">

        <div class="records">

            <!-- 查無數據 -->
            <div *ngIf="notificationList.length==0" class="record nodata info-style">
                {{'Dashboard.AnnoTab.Nodata'|translate}}
            </div>

            <!-- 數據列表 -->
            <div class="record" *ngFor="let record of notificationList">
                <div class="dot" *ngIf="!record.read"></div>
                <div class="title" >{{record.message}}</div>
                <div class="flex row between" style="align-items: center;">
                    <div class="text-row datetime">
                        <div style="margin-right: 10px;">{{'Common.Time'|translate}}</div>
                        <div>{{record.time}}</div>
                    </div>
                    <div class="text-row">
                        <div matRipple centered radius="60" class="goto" (click)="onMoreDetail(record.eventId)" routerLink="{{record.link}}">前往確認</div>
                    </div>
                </div>

            </div>

            <!-- 沒有更多數據 -->
            <div *ngIf="notificationList.length>0" class="no-more-data info-style">{{'Records.NoMoreData'|translate}}</div>

        </div>

    </div>

</div>