export const environment = {
  production: false,
  version: '1.0.0',
  build: '20240128140313',
  proxyHost: 'http://testenv.power-gateway.com:9100/matrix-app-proxy/mapp',
  appHost: 'http://testenv.power-gateway.com:9100/matrix-app-proxy/mxsvc',
  appId: 'MATRIX.x89x613d8nhbb3e8gjx3',
  hostCandidate: [
    'http://testenv.power-gateway.com:9000',
    'http://testenv.power-gateway.com:9100',
  ],
};
