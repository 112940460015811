import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ClientService } from '../../services/client.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PromiseUtil } from '../../common/PromiseUtil';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateTimeUtil } from '../../common/DateTimeUtil';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { BeneficiaryRecord } from '../../domain/network/response';

export interface BeneficiaryItem {
  beneficiaryId: number;
  name: string; // 收款人名稱
  type: string; // 收款帳戶類型(公司/個人)
  createTime: string; // 創建時間
  updateTime: string; // 更新時間
  currency: string; // 幣種
  favor: boolean; // 是否為最愛
  note: string; // 備註
}

@Component({
  selector: 'app-beneficiary-list',
  templateUrl: './beneficiary-list.component.html',
  styleUrls: ['./beneficiary-list.component.scss'],
})
export class BeneficiaryListComponent {
  // 日期
  dateFrom = new FormControl(new Date());
  dateTo = new FormControl(new Date());

  currencyMenu = ['USD'];
  selectedCurrency?: string;
  selectedAccountType?: string;

  beneficiaryList: BeneficiaryRecord[] = [];
  beneficiaryRecord: BeneficiaryItem[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private client: ClientService,
    private snackBar: MatSnackBar,
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string
  ) {
    this._locale = 'ja-JP';
    this._adapter.setLocale(this._locale);

    // 初始化日期選擇器時間
    this.dateFrom = new FormControl(DateTimeUtil.getShiftDate(-60, new Date()));
    this.dateTo = new FormControl(new Date());
  }

  ngOnInit() {
    // 初始化參數
    this.route.params.subscribe((params: Params) => {
      // this.recordType = params['type'];
      // if (
      //   !this.recordType ||
      //   !['recharge', 'withdraw', 'exchange', 'wallet'].includes(
      //     this.recordType
      //   )
      // ) {
      //   this.router.navigate(['dashboard', 'record']);
      //   return;
      // }
    });

    this.onInit();
  }

  async onInit() {
    // this.setupRecordType();

    // 貨幣選單
    try {
      let cc = await this.client.balanceList();
      cc.sort((a, b) => {
        if (a.balance == b.balance) {
          return a.currency.localeCompare(b.currency);
        }
        return b.balance - a.balance;
      });
      this.currencyMenu = [];
      cc.forEach((item) => {
        if (item.updateTime || true) {
          this.currencyMenu.push(item.currency);
        }
      });
    } catch (err) {}

    // 讀取出金帳戶列表
    try {
      this.beneficiaryList = [];
      this.beneficiaryRecord = [];
      this.beneficiaryList = await this.client.listBeneficiaries();
      this.onFilterChange();
    } catch (err) {}
  }

  get hasData() {
    return this.beneficiaryRecord.length > 0;
  }

  // 套用篩選規則
  onFilterChange() {
    const filterStart = DateTimeUtil.getLocalDateString(this.dateFrom.value!);
    const filterEnd = DateTimeUtil.getLocalDateString(this.dateTo.value!);
    this.beneficiaryRecord = this.beneficiaryList
      .map((val) => {
        return {
          beneficiaryId: val.beneficiaryId,
          name: val.accountInfo.accountName,
          type: val.accountInfo.accountType,
          createTime: val.createTime,
          updateTime: val.updateTime,
          currency: val.accountInfo.accountCurrency,
          favor: val.favorite,
          note: val.beneficiaryNickname ?? '',
        };
      })
      .filter((val) => {
        return !this.selectedCurrency || val.currency === this.selectedCurrency;
      })
      .filter((val) => {
        return (
          !this.selectedAccountType || val.type === this.selectedAccountType
        );
      })
      .filter((val) => {
        // console.log(val);
        if (!val.createTime) return true;
        const date = val.createTime.replace(/^(\d+\-\d+\-\d+)\s+.*/, '$1');
        if (filterStart.localeCompare(date) > 0) return false;
        if (filterEnd.localeCompare(date) < 0) return false;
        return true;
      })
      .sort((a, b) => {
        if (a.favor !== b.favor) {
          if (a.favor) return -1;
          return 1;
        }
        if (a.updateTime && b.updateTime) {
          return b.updateTime.localeCompare(a.updateTime);
        }
        return 0;
      });
  }

  // 按下篩選/搜尋
  onSearch() {
    this.onFilterChange();
  }

  backToHome() {
    this.router.navigate(['dashboard']);
  }
}
