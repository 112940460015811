import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'PrincipalMask',
})
export class PrincipalMaskPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    const vv =
      value.substring(0, 4) + '******' + value.substring(value.length - 6);
    return vv;
  }
}
