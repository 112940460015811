import { Component, EventEmitter, Output } from '@angular/core';
import { ClientService } from '../../services/client.service';
import { LocalService } from '../../services/local.service';
import { Router } from '@angular/router';
import { CacheService } from '../../services/cache.service';
import { DateTimeUtil } from '../../common/DateTimeUtil';

interface Announcement {
  id: number;
  title: string;
  date: string;
  read: boolean;
}

@Component({
  selector: 'app-dashboard-announcement',
  templateUrl: './dashboard-announcement.component.html',
  styleUrls: ['./dashboard-announcement.component.scss'],
})
export class DashboardAnnouncementComponent {
  @Output() changeTab = new EventEmitter<string>();

  backToHome() {
    this.changeTab.emit('home');
  }

  announcements: Announcement[] = [
    // { title: '韩国7月份休市公告', date: '2023-7-24', read: false },
    // {
    //   title: '如果公告标题很长的话，就只好换行！就只好换行！就只好换行！',
    //   date: '2023-7-24',
    //   read: false,
    // },
    // { title: '韩国7月份公告', date: '2023-7-24', read: false },
    // { title: '韩国7月份公告', date: '2023-7-24', read: true },
    // { title: '韩国7月份公告', date: '2023-7-24', read: false },
    // { title: '韩国7月份公告', date: '2023-7-24', read: true },
    // { title: '韩国7月份公告', date: '2023-7-24', read: false },
    // { title: '韩国7月份公告', date: '2023-7-24', read: true },
    // { title: '韩国7月份公告', date: '2023-7-24', read: true },
    // { title: '韩国7月份公告', date: '2023-7-24', read: true },
    // { title: '韩国7月份公告', date: '2023-7-24', read: true },
    // { title: '韩国7月份公告22222', date: '2023-7-24', read: true },
  ];

  isLoading = true;

  constructor(
    private client: ClientService,
    private local: LocalService,
    private router: Router,
    private cache: CacheService
  ) {}

  ngOnInit() {
    this.loadSystemNotice();
  }

  onMoreDetail(noticeId: number) {
    // 設定已讀
    const reads = [];
    for (const n of this.announcements) {
      if (n.id === noticeId) {
        n.read = true;
      }
      if (n.read) {
        reads.push(`${n.id}`);
      }
    }
    this.local.NoticeReads = reads;

    // 導航 announcement
    this.router.navigate(['announcement', noticeId]);
  }

  private async loadSystemNotice() {
    this.isLoading = true;
    try {
      const notices = await this.client.getSystemNotice();
      this.cache.put('announcement', notices.rows, 30 * 60 * 1000); // 快取 30 分鐘
      const reads = this.local.NoticeReads;
      this.announcements = [];
      notices.rows.forEach((item) => {
        // if (item.noticeType !== '1') {
        //   return;
        // }
        this.announcements.push({
          id: item.noticeId,
          title: item.noticeTitle,
          date: DateTimeUtil.getDate(item.createTime),
          read: reads.includes(`${item.noticeId}`),
        });
      });
    } catch (err) {
    } finally {
      this.isLoading = false;
    }
  }
}
