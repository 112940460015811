// export function matchingWords(
//   passwordKey: string,
//   confirmPasswordKey: string
// ) {
//   return (
//     group: ControlGroup
//   ): {
//     [key: string]: any;
//   } => {
//     let password = group.controls[passwordKey];
//     let confirmPassword = group.controls[confirmPasswordKey];

//     if (password.value !== confirmPassword.value) {
//       return {
//         mismatchedPasswords: true,
//       };
//     }
//   };
// }

import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export function EquealWordsValidator(target: FormControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    // console.log(`comp ${value}, ${target.value}`);
    if (!value) {
      return null;
    }
    if (value === target.value) return null;
    return { diff: true };
  };
}
