import { APP_INITIALIZER, INJECTOR, NgModule, inject } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SessionService } from './core/services/session.service';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { LocalService } from './core/services/local.service';
import { InitializeService } from './core/services/initialize.service';

// Factory function required during AOT compilation
export function httpTranslateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    '/assets/i18n/',
    '.json?release=' + environment.build
  );
}

function initializeAppFactory(
  initialize: InitializeService
): () => Promise<void> {
  return () => initialize.initializeApp();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      // useFactory: () => initializeApp,
      // // useClass: SessionService,
      // deps: [SessionService],
      // multi: true,
      // useFactory: () => {
      //   return inject(INJECTOR).get(SessionService).Init();
      // },
      useFactory: initializeAppFactory,
      deps: [InitializeService, SessionService, LocalService, HttpClient],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
