<div class="container">

    <div class="top">
        <div class="backBtn" routerLink="/dashboard/announcement">
            <img class="backBtnImg" alt="image" src="/assets/images/icons/arrow-down-sign-to-navigate.png" />
            <div class="backBtnTxt">{{'Announcement.Title'|translate}}</div>
        </div>

    </div>

    <div class="body">

        <!-- 完成畫面 -->
        <div class="block-content">

            <div class="subject" >{{title}}</div>

            <div class="publish-time">
                <div class="desc">{{'Announcement.PublishDate' | translate}}</div>
                <div class="date">{{date}}</div>
            </div>

            <div class="announcement-body" [innerHTML]="body | safeHtml">
            </div>

            <mat-divider style="margin: 20px 40px 10px 40px;"></mat-divider>

            <div class="pay-box" style="margin-top: 00px;">
                <button mat-flat-button color="primary" class="next-btn" (click)="onContractUs()">{{'Announcement.Contact'|translate}}</button>
            </div>

        </div>

    </div>

</div>