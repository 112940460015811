import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { PromiseUtil } from '../common/PromiseUtil';
import { environment } from 'projects/h5app2/src/environments/environment';
import { ClientService } from './client.service';

@Injectable({
  providedIn: 'root',
})
export class InitializeService {
  // 主機列表
  private hosts: string[] = [];

  constructor(private session: SessionService, private client: ClientService) {
    environment.appHost;
  }

  async initializeApp() {
    // const candidate = environment.hostCandidate;
    const candidate = [
      ...environment.hostCandidate,
      // 'https://aaa.ccc.sddd',
      // 'http://192.168.0.0:80',
      // 'http://127.0.0.1:4300',
    ];

    try {
      const t0 = Date.now();
      const host = await this.hostsConnectionTest(candidate, (report) => {
        // TODO: 連線測試回報
        // console.log(`report:`, report, Date.now());
      });
      console.log(`select: ${host}, rtt: ${Date.now() - t0}`);

      // proxyHost: 'http://testenv.power-gateway.com:9000/matrix-app-proxy/mapp',
      // appHost: 'http://testenv.power-gateway.com:9000/matrix-app-proxy/mxsvc',
      environment.appHost = `${host}/matrix-app-proxy/mxsvc`;
      environment.proxyHost = `${host}/matrix-app-proxy/mapp`;
    } catch (err) {
      console.error(err);
    }

    await this.session.init();
  }

  // 線路測試
  private hostsConnectionTest(hosts: string[], cb?: (report: any) => void) {
    return new Promise((resolve, reject) => {
      let resolved = false;
      let counter = hosts.length;
      const result = hosts.map((host) => {
        const p = this.client.ping(host);
        const context = {
          host, // 主機域名
          promise: p,
          state: 0, //0: 等待中, 1:成功, 2:失敗
          begin: Date.now(),
          finish: 0,
          error: undefined,
        };
        p.then((val) => {
          context.finish = Date.now();
          context.state = val ? 1 : 2;
          counter--;
          if (!resolved) {
            resolved = true;
            resolve(context.host);
          }
        })
          .catch((err) => {
            context.finish = Date.now();
            context.state = 2;
            context.error = err;
            counter--;
          })
          .finally(() => {
            if (counter === 0) {
              if (cb) {
                cb(result);
              }
              if (!resolved) {
                reject('Connection Failed');
              }
            }
          });

        return context;
      });
    });
  }
}
