import { Md5 } from 'ts-md5';

export interface SecurityResult {
  principal: string;
  password: string;
  phash: string;
  time: string;
  vhash: string;
}

interface KeyValue {
  k: string;
  v: number | string | boolean;
}

export class SecurityUtils {
  // 計算替換用密碼
  public static calcSecurityParams(
    appId: string,
    principal: string,
    password: string
  ): SecurityResult {
    // 1. 獲取當前時搓 `timestamp` = `2023-09-28T17:29:44.080Z`
    // 2. 計算 `ptxt` = `MATRIX.x89x613d8nhbb3e8gjx3+123456`
    // 3. 計算 `phash` = `md5(ptxt)` = `85733433a268b053e38dbceb7a37a545`
    // 4. 計算 `vtxt` = `85733433a268b053e38dbceb7a37a545+2023-09-28T17:29:44.080Z`
    // 5. 計算 `verify_hash` = `md5(vtxt)` = `66e2cec80457e8fdc8469b83b04bbffb`
    const isotime = new Date().toISOString();
    let md5 = new Md5();
    const ptxt = `${appId}+${principal}+${password}`;
    const phash = md5.appendStr(ptxt).end() as string;
    // console.log(`ptxt='${ptxt}', phash='${phash}'`);
    md5 = new Md5();
    const vtxt = `${phash}+${isotime}`;
    const vhash = md5.appendStr(vtxt).end() as string;
    // console.log(`vtxt='${vtxt}', vhash='${vhash}'`);
    return {
      principal,
      time: isotime,
      phash,
      vhash,
      password,
    };
  }

  // 計算本文 Signature
  public static generateSign(data: any, datetime: string, sign_key: string) {
    const params: string[] = this.jsonFlat(data);
    params.push(`t=${datetime}`);
    params.push(`k=${sign_key}`);
    let md5 = new Md5();
    const str = params.join('&');
    const signture = md5.appendStr(str).end() as string;
    // console.log(`signture: ${signture}, ${str}`);
    return signture;
  }

  // 計算產生 Sign Key
  public static generateSignKey(phash: string, sessionId: string) {
    let md5 = new Md5();
    const sign_key = md5.appendStr(`${phash}+${sessionId}`).end() as string;
    return sign_key;
  }

  // 輸出扁平 json 結構
  private static jsonFlat(data: any, params?: KeyValue[], prefix?: string) {
    let root = false;
    if (!params) {
      params = [];
      root = true;
    }

    const type = Object.prototype.toString.call(data);
    switch (type) {
      case '[object Object]': {
        const keys = Object.keys(data);
        for (const k of keys) {
          const lk = k.toLowerCase();
          this.jsonFlat(data[k], params, prefix ? `${prefix}.${lk}` : lk);
        }
        break;
      }
      case '[object Array]': {
        for (const i in data) {
          this.jsonFlat(data[i], params, prefix ? `${prefix}[${i}]` : `[${i}]`);
        }
        break;
      }
      case '[object String]': {
        params.push({
          k: prefix ?? '/',
          v: data.replace(/\r\n/g, '\\n').replace(/\n/g, '\\n'),
        });
        break;
      }
      case '[object Number]':
      case '[object Boolean]': {
        params.push({ k: prefix ?? '/', v: data });
        break;
      }
      default: {
        params.push({ k: prefix ?? '/', v: data ?? '' });
      }
    }

    if (root) {
      params.sort((a, b) => a.k.localeCompare(b.k));
      return params.map((item) => `${item.k}=${item.v}`);
    }
    return [];
  }
}
