import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ClientService } from '../../services/client.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PromiseUtil } from '../../common/PromiseUtil';
import { DateTimeUtil } from '../../common/DateTimeUtil';
import { CurrencyUtil } from '../../common/CurrencyUtil';

export interface InformationField {
  fieldName: string;
  fieldContent: string;
  i18n?: boolean;
}

@Component({
  selector: 'app-record-detail',
  templateUrl: './record-detail.component.html',
  styleUrls: ['./record-detail.component.scss'],
})
export class RecordDetailComponent {
  infomations: InformationField[] = [
    // { fieldName: 'Records.RequestTime', fieldContent: '2023-07-01  17:00:03' }, // 申請時間
    // { fieldName: 'Records.WithdrawTime', fieldContent: '2023-07-02' }, // 交割日期
    // { fieldName: 'Records.TradeStatus', fieldContent: '处理中' }, // 交易状态
    // {
    //   fieldName: 'Records.TradeOrderId',
    //   fieldContent: '805a175e69c34ac0b105b315ba9a270c',
    // }, // 交易号
    // { fieldName: 'Records.AcceptRate', fieldContent: '1122.000001' }, // 成交汇率
    // { fieldName: 'Records.Pay', fieldContent: '1,000 USD' }, // 支付
    // { fieldName: 'Common.Cost', fieldContent: '10.33 USD' }, // 费用
    // { fieldName: 'Withdraw.WithdrawReveive', fieldContent: '1,110,409.74 KRM' }, // 出金帐户收到
    // { fieldName: 'Withdraw.WithdrawAccountLocation', fieldContent: '香港' }, // 出金帐户所在地
    // { fieldName: 'Withdraw.WithdrawAccountType', fieldContent: '公司' }, // 出金帐户类型
    // { fieldName: 'Withdraw.WithdrawMethod', fieldContent: '银行转账' }, // 出金方式
    // {
    //   fieldName: 'Withdraw.WithdrawAccountInfo',
    //   fieldContent: '精采abc有限公司<br/>花旗银行<br/>1111222233334444',
    // }, // 出金帐户资讯
  ];

  transTitle?: string;

  recordType?: string;
  recordId?: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private client: ClientService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    // 初始化參數
    this.route.params.subscribe((params: Params) => {
      console.error('參數!!', params);

      this.recordType = params['type'];
      this.recordId = params['id'];

      // 沒有分頁類型, 返回紀錄大廳
      if (
        !this.recordType ||
        !['recharge', 'withdraw', 'exchange'].includes(this.recordType)
      ) {
        this.router.navigate(['dashboard', 'record'], {
          replaceUrl: true,
        });
        return;
      }

      // 沒有紀錄資訊, 返回紀錄列表
      if (!this.recordId) {
        this.router.navigate(['records', this.recordType], {
          replaceUrl: true,
        });
        return;
      }
    });

    this.onInit();
  }

  async onInit() {
    this.setupRecordType();
  }

  setupRecordType() {
    // 初始化標籤
    this.transTitle = this.getTitleTransTag(this.recordType!);

    // 要來載入資料囉
    switch (this.recordType) {
      case 'recharge':
        return this.fetchRechargeRecordDetail();
      case 'withdraw':
        return this.fetchWithdrawRecordDetail();
      case 'exchange':
        return this.fetchExchangeRecordDetail();
    }
  }

  private getTitleTransTag(type: string) {
    switch (type) {
      case 'recharge':
        return 'Records.Detail.Recharge';
      case 'withdraw':
        return 'Records.Detail.Withdraw';
      case 'exchange':
        return 'Records.Detail.Exchange';
    }
    return '';
  }

  private fetchWithdrawRecordDetail() {}

  // 充值資料
  private async fetchRechargeRecordDetail() {
    try {
      const detail = await this.client.getRechangeDetail(this.recordId!);
      console.log(detail);

      this.infomations = [
        {
          fieldName: 'Records.RequestTime',
          fieldContent: detail.requestTime,
        }, // 申請時間
        {
          fieldName: 'Records.WithdrawTime',
          fieldContent: detail.conversionDate + '11111',
        }, // 交割日期
        {
          fieldName: 'Records.TradeStatus',
          fieldContent: this.exchangeStatusName(detail.status),
          i18n: true,
        }, // 交易状态
        {
          fieldName: 'Records.TradeOrderId',
          fieldContent: detail.requestId,
        }, // 交易号
        {
          fieldName: 'Records.AcceptRate',
          fieldContent: `${detail.clientRate}`,
        }, // 成交汇率
        {
          fieldName: 'Records.Sell',
          fieldContent: `${CurrencyUtil.amountFormat(detail.sourceAmt)} ${
            detail.sourceCur
          }`,
        }, // 賣出
        {
          fieldName: 'Records.Buy',
          fieldContent: `${CurrencyUtil.amountFormat(detail.targetAmt)} ${
            detail.targetCur
          }`,
        }, // 買入

        // { fieldName: 'Common.Cost', fieldContent: '10.33 USD' }, // 费用
        // {
        //   fieldName: 'Withdraw.WithdrawReveive',
        //   fieldContent: '1,110,409.74 KRM',
        // }, // 出金帐户收到
        // { fieldName: 'Withdraw.WithdrawAccountLocation', fieldContent: '香港' }, // 出金帐户所在地
        // { fieldName: 'Withdraw.WithdrawAccountType', fieldContent: '公司' }, // 出金帐户类型
        // { fieldName: 'Withdraw.WithdrawMethod', fieldContent: '银行转账' }, // 出金方式
        // {
        //   fieldName: 'Withdraw.WithdrawAccountInfo',
        //   fieldContent: '精采abc有限公司<br/>花旗银行<br/>1111222233334444',
        // }, // 出金帐户资讯
      ];
    } catch (err) {
      if (err instanceof Error) {
        console.error(err.message);
      } else {
        console.error(`${err}`);
      }
    }
  }

  // 匯兌資料
  private async fetchExchangeRecordDetail() {
    try {
      const detail = await this.client.getExchangeDetail(this.recordId!);
      console.log(detail);

      // clientRate: number; // 7.30639;
      // contractId: string; // '202309121117417173J64WD08J1P';
      // conversionDate: string; // '2023-09-12';
      // curPair: string; // 'USDCNY';
      // msg: string; // '已交割';
      // quoteId: string; // 'RFQ20230912111714322LAHKG887MRB';
      // requestId: string; // '1694488661480';
      // requestTime: string; // '2023-09-12T11:17:41+0800';
      // sourceAmt: number; // 1030;
      // sourceCur: string; // 'CNY';
      // status: string; // 'SETTLED';
      // targetAmt: 141;
      // targetCur: string; // 'USD';
      // tenor: string; // 'T0';

      this.infomations = [
        {
          fieldName: 'Records.RequestTime',
          // fieldContent: DateTimeUtil.getDisplayDatetime(detail.requestTime),
          // fieldContent: DateTimeUtil.convertToLocalTime(detail.requestTime),
          fieldContent: DateTimeUtil.getDisplayDatetime(
            DateTimeUtil.convertToLocalTime(detail.requestTime)
          ),
        }, // 申請時間
        {
          fieldName: 'Records.WithdrawTime',
          fieldContent: detail.conversionDate,
        }, // 交割日期
        {
          fieldName: 'Records.TradeStatus',
          fieldContent: this.exchangeStatusName(detail.status),
          i18n: true,
        }, // 交易状态
        {
          fieldName: 'Records.TradeOrderId',
          fieldContent: detail.requestId,
        }, // 交易号
        {
          fieldName: 'Records.AcceptRate',
          fieldContent: `${detail.clientRate}`,
        }, // 成交汇率
        {
          fieldName: 'Records.Sell',
          fieldContent: `${CurrencyUtil.amountFormat(detail.sourceAmt)} ${
            detail.sourceCur
          }`,
        }, // 賣出
        {
          fieldName: 'Records.Buy',
          fieldContent: `${CurrencyUtil.amountFormat(detail.targetAmt)} ${
            detail.targetCur
          }`,
        }, // 買入

        // { fieldName: 'Common.Cost', fieldContent: '10.33 USD' }, // 费用
        // {
        //   fieldName: 'Withdraw.WithdrawReveive',
        //   fieldContent: '1,110,409.74 KRM',
        // }, // 出金帐户收到
        // { fieldName: 'Withdraw.WithdrawAccountLocation', fieldContent: '香港' }, // 出金帐户所在地
        // { fieldName: 'Withdraw.WithdrawAccountType', fieldContent: '公司' }, // 出金帐户类型
        // { fieldName: 'Withdraw.WithdrawMethod', fieldContent: '银行转账' }, // 出金方式
        // {
        //   fieldName: 'Withdraw.WithdrawAccountInfo',
        //   fieldContent: '精采abc有限公司<br/>花旗银行<br/>1111222233334444',
        // }, // 出金帐户资讯
      ];
    } catch (err) {
      if (err instanceof Error) {
        console.error(err.message);
      } else {
        console.error(`${err}`);
      }
    }
  }

  // 轉換匯兌狀態資訊為文字表達(i18n)
  private exchangeStatusName(status: string): string {
    switch (status) {
      case 'SUBMITTED':
        return 'Records.Status.Submitted';
      case 'PROCESSING':
        return 'Records.Status.Processing';
      case 'SETTLED':
        return 'Records.Status.Settled';
      case 'FAILED':
        return 'Records.Status.Failed';
    }
    return 'Records.Status.UNKNOWN';
  }

  // end
}
