import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ClientService } from '../../services/client.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, Validators } from '@angular/forms';
import { DateTimeUtil } from '../../common/DateTimeUtil';
import { SessionService } from '../../services/session.service';
import { EmailVerifyPair, LocalService } from '../../services/local.service';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
})
export class VerifyComponent {
  // 1. 注册：email验证通过 -> 注册成功
  // 2. 登录：登录密码 + 谷歌验证
  // 3. 忘记登录密码/交易密码：email验证 + 谷歌验证 -> 输入新密码
  // 4. 绑定谷歌验证：email验证 → 绑定谷歌验证
  // 5. 付款：交易密码 + 谷歌验证 -> 送出付款交易
  // 6. 汇兑：交易密码 -> 送出汇兑交易
  // 7. 设置交易密码

  // 驗證資料類型
  private verifyType?: string;

  title: string = 'Unknown';
  verifyBtnName: string = 'Unknown';

  verifyCode: FormControl;

  verifyCodeValid = false;

  // 發送信件冷卻時間
  // verifyCodeTimeout = 0;

  // 冷卻倒數
  remainTime = 0;
  countdownTimer?: NodeJS.Timer;

  userEmail?: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private client: ClientService,
    private snackBar: MatSnackBar,
    private session: SessionService,
    private local: LocalService
  ) {
    this.verifyCode = new FormControl('', [
      Validators.required,
      Validators.pattern(/^[0-9]{6}$/),
    ]);

    if (this.session.context) {
      this.userEmail = this.session.context.principal;
    }
  }

  ngOnInit() {
    // 初始化參數
    this.route.params.subscribe((params: Params) => {
      this.verifyType = params['type'];
      if (
        !this.verifyType ||
        !['googleauth', 'resetpassword'].includes(this.verifyType)
      ) {
        this.router.navigate(['dashboard']);
        return;
      }
      this.initVerifyTypes();
    });

    // 監控輸入
    this.verifyCode.statusChanges.subscribe({
      next: (valid: string) => {
        this.verifyCodeValid = valid === 'VALID';
      },
    });

    // 初始計時器
    this.countdownTimer = setInterval(() => {
      this.countDownHandler();
    }, 100);
  }

  ngOnDestroy() {
    // 清理計時器
    if (this.countdownTimer) {
      clearInterval(this.countdownTimer as unknown as number);
    }
  }

  // 驗證用的 token
  get vToken(): string | undefined {
    return this.verifyCodeParams.token;
  }

  // 發送信件冷卻時間
  get verifyCodeTimeout() {
    if (!this.session.context) return -1;
    return this.verifyCodeParams.timeout;
  }

  // 發送信件冷卻時間
  get verifyCodeParams(): EmailVerifyPair {
    const data = this.local.GetEmailVerifyToken('VERIFY');
    if (!data) {
      return { token: undefined, timeout: 0 };
    }
    return data;
  }

  // 發送信件冷卻時間
  set verifyCodeParams(data: EmailVerifyPair) {
    // if (this.userEmail.status !== 'VALID') {
    //   return;
    // }
    // const email = this.userEmail.value;
    // this.local.SetEmailVerifyLimiter('VERIFY', val);
    this.local.SetEmailVerifyToken('VERIFY', data.timeout, data.token);
  }

  private initVerifyTypes() {
    switch (this.verifyType) {
      case 'googleauth':
        this.title = 'Verify.Title.GoogleAuth';
        this.verifyBtnName = 'Verify.Bind';
        break;
    }
  }

  public onSendVerifyCode() {
    if (this.verifyCodeTimeout > 0) {
      return;
    }

    // // TODO: 測試用
    // this.vToken = 'test-test';
    // return;

    this.sendVerifyCode();
  }

  public async onVerify() {
    if (this.verifyCode.status !== 'VALID') {
      return;
    }

    try {
      const toptp = await this.client.getTotpBindingInfo(
        this.userEmail!,
        this.verifyCode.value as string,
        this.vToken!
      );
      // console.log(toptp); // "otpauth://totp/Paytranxee:netbuswhs%40gmail.com?secret=5zk6xv6rpglljz26teogsqnpisfgoecn&issuer=Paytranxee"
      this.router.navigate(['totp-bind', toptp]);
    } catch (err) {
      console.error(err);
    }
  }

  // 發送驗證碼
  private async sendVerifyCode() {
    if (!this.userEmail) {
      return;
    }
    this.verifyCodeParams = { timeout: Date.now() + 600 * 1000 }; // 預設 CD 10min
    try {
      const token = await this.client.sendVerifyCodeViaEmail(
        this.userEmail,
        'GOOGLE_AUTH'
      );
      this.verifyCodeParams = {
        token,
        timeout: Date.now() + 600 * 1000,
      }; // 預設 CD 10min
    } catch (err) {
      console.error(err);
      this.verifyCodeParams = { timeout: Date.now() + 60 * 1000 }; // 異常 CD 1min
    }
  }

  private countDownHandler() {
    if (this.verifyCodeTimeout > 0) {
      let t = Date.now() - this.verifyCodeTimeout;
      if (t > 0) {
        this.verifyCodeParams = { timeout: 0 };
      } else {
        this.remainTime = Math.floor(t / -1000);
      }
    }
  }
}
