import { Component } from '@angular/core';
import { ClientService } from '../../services/client.service';
import { LocalService } from '../../services/local.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CacheService } from '../../services/cache.service';
import { Notice } from '../../domain/network/response';
import { DateTimeUtil } from '../../common/DateTimeUtil';

@Component({
  selector: 'app-announcement-detail',
  templateUrl: './announcement-detail.component.html',
  styleUrls: ['./announcement-detail.component.scss'],
})
export class AnnouncementDetailComponent {
  noticeId: number = 0;
  notice?: Notice;

  title = '';
  body = '';
  date = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private client: ClientService,
    private local: LocalService,
    private snackBar: MatSnackBar,
    private cache: CacheService
  ) {}

  ngOnInit() {
    // 初始化參數
    this.route.params.subscribe((params: Params) => {
      this.noticeId = params['id'];

      for (;;) {
        if (!this.noticeId || this.noticeId === 0) {
          break;
        }

        const announcements: Notice[] = this.cache.get('announcement', []);
        if (!announcements || announcements.length == 0) {
          break;
        }

        for (let anno of announcements) {
          if (anno.noticeId == this.noticeId) {
            this.notice = anno;
            this.title = anno.noticeTitle;
            this.body = anno.noticeContent;
            this.date = DateTimeUtil.getDate(anno.createTime);
            return;
          }
        }
        break;
      }
      // 找不到公告
      this.router.navigate(['dashboard', 'announcement']);
    });

    // this.onInit();
  }

  onContractUs() {
    window.open('https://t.me/PTcs001', '_blank');
  }
}
