import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  btnName: string;
}

@Component({
  selector: 'app-redirect-popup',
  templateUrl: './redirect-popup.component.html',
  styleUrls: ['./redirect-popup.component.scss'],
})
export class RedirectPopupComponent {
  title: string;
  btnName: string;

  constructor(
    public dialogRef: MatDialogRef<RedirectPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.title = data.title;
    this.btnName = data.btnName;
  }

  onClick(goto: boolean = false): void {
    this.dialogRef.close(goto);
  }
}
