import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ClientService } from '../../services/client.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateTimeUtil } from '../../common/DateTimeUtil';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SessionService } from '../../services/session.service';
import { RandomUtil } from '../../common/RandomUtil';
import { PromiseUtil } from '../../common/PromiseUtil';
import { TranslateService } from '@ngx-translate/core';
import { KeyValue } from '@angular/common';
import { Dictionary } from '../../domain/interfaces';

export interface StatusDesc {
  origin: string;
  css?: string;
  translate: string;
}

export interface WalletRecord {
  // transactionId: string;
  transactionTime: string;
  transactionType: string;
  currency: string;
  amount: number;
  remain: number;
}

export interface WithdrawRecord {
  transactionId: string;
  // requestTime: string;
  // executeTime: string;
  createTime: string;
  currency: string;
  amount: number;
  // remain: number;
  state: number; // 0:處理中, 1:已完成, 2:失敗
}

export interface ExchangeRecord {
  requestTime: string;
  executeTime?: string;
  sourceCurrency: string;
  sourceAmount: number;
  targetCurrency: string;
  targetAmount: number;
  state: number; // 0:處理中, 1:已完成, 2:失敗
  stateMsg: string; // 狀態描述
  contractId: string;
}

export interface RechargeRecord {
  executeTime: string;
  currency: string;
  amount: number;
  transactionId: string;
}

export interface TransferRecord {
  creditorEmail: string; // 'erinlin9688@gmail.com';  // 入金方
  debtorEmail: string; // 'erinlin0968@gmail.com';  // 出金方
  amount: number; // 100;
  currency: string; // 'USD';
  createTime: string; // '2023-12-18 15:38:40';
  updateTime: string; // '2023-12-18 15:38:40';
  extTraceId: string; // 'a4bed5f2-658e-448e-9690-46dc1b168db3';
  status: StatusDesc; // 'POSTED';
  transferId: number; // 20231218000027;
  isDebtor: boolean; // 出款方
  isCreditor: boolean; // 收款方
}

@Component({
  selector: 'app-record-list',
  templateUrl: './record-list.component.html',
  styleUrls: ['./record-list.component.scss'],
})
export class RecordListComponent {
  transTitle: string = ''; // 分頁標籤名稱

  statePresent = ['Common.Processing', 'Common.Complete', 'Common.Fail'];

  // 轉帳訂單狀態
  private transferStatusMap: { [k: string]: StatusDesc } = {
    CREATED: {
      origin: 'CREATED',
      translate: 'Records.Status.Created',
      css: 'created',
    },
    POSTED: {
      origin: 'POSTED',
      translate: 'Records.Status.Posted',
      css: 'posted',
    },
    DEPRECATED: {
      origin: 'DEPRECATED',
      translate: 'Records.Status.Deprecated',
      css: 'deprecated',
    },
    POST_FAILED: {
      origin: 'POST_FAILED',
      translate: 'Records.Status.PostFailed',
      css: 'fail',
    },
  };

  dateFrom = new FormControl(new Date());
  dateTo = new FormControl(new Date());

  currencyMenu = ['USD'];
  selectedCurrency: string = 'all'; // 貨幣類型

  selectedTransferType?: string; // 收支類型
  transferTypeMenu = [
    {
      value: 'all',
      title: 'Records.TransferAll',
    },
    {
      value: 'income',
      title: 'Common.Income',
    },
    {
      value: 'expenditure',
      title: 'Common.Expenditure',
    },
  ];

  titleTranslateMap: Dictionary<string> = {
    recharge: 'Records.Title.Recharge',
    withdraw: 'Records.Title.Withdraw',
    exchange: 'Records.Title.Exchange',
    wallet: 'Records.Title.Wallet',
    transfer: 'Records.Title.Transfer',
  };

  // 錢包帳變
  walletRecords: WalletRecord[] = [
    // {
    //   transactionTime: '2023-07-06  17:00:03',
    //   transactionType: '換匯',
    //   currency: 'USD',
    //   amount: -4567,
    //   remain: 5432344,
    // },
    // {
    //   transactionTime: '2023-07-02  16:00:03',
    //   transactionType: '充值',
    //   currency: 'CNY',
    //   amount: 123456,
    //   remain: 99999999,
    // },
  ];

  // 出款紀錄
  withdrawRecords: WithdrawRecord[] = [];

  // 充值紀錄
  rechargeRecords: RechargeRecord[] = [];

  // 匯兌記錄
  exchangeRecords: ExchangeRecord[] = [];

  // 會員轉帳紀錄
  transferRecords: TransferRecord[] = [];

  recordType?: string;

  // 禁用篩選幣種
  isDisableCurrency = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private client: ClientService,
    private snackBar: MatSnackBar,
    private _adapter: DateAdapter<any>,
    private session: SessionService,
    private translate: TranslateService,
    @Inject(MAT_DATE_LOCALE) private _locale: string
  ) {
    this._locale = 'ja-JP';
    this._adapter.setLocale(this._locale);

    // 初始化日期選擇器時間
    this.dateFrom = new FormControl(DateTimeUtil.getShiftDate(-90, new Date()));
    this.dateTo = new FormControl(new Date());
  }

  ngOnInit() {
    // 初始化參數
    this.route.params.subscribe((params: Params) => {
      this.recordType = params['type'];
      if (
        !this.recordType ||
        !['recharge', 'withdraw', 'exchange', 'wallet', 'transfer'].includes(
          this.recordType
        )
      ) {
        this.router.navigate(['dashboard', 'record']);
        return;
      }
    });

    this.selectedTransferType = 'all';

    this.onInit();
  }

  async onInit() {
    this.setupRecordType();

    // 交易類型/方向選單
    if (this.recordType === 'transfer') {
      this.selectedTransferType = 'all';
      const tmp = this.transferTypeMenu.map((item) => item.title);
      const tmap = await PromiseUtil.FromObservable(this.translate.get(tmp));
      this.transferTypeMenu.forEach((item) => (item.title = tmap[item.title]));
      // this.transferTypeMenu;
    }

    // 貨幣選單
    try {
      let cc = await this.client.balanceList();
      cc.sort((a, b) => {
        if (a.balance == b.balance) {
          return a.currency.localeCompare(b.currency);
        }
        return b.balance - a.balance;
      });
      this.currencyMenu = [];
      cc.forEach((item) => {
        if (item.updateTime) {
          this.currencyMenu.push(item.currency);
        }
      });

      // this.selectedCurrency = this.currencyMenu[0];
      // this.updateRecords();
      if (this.recordType === 'wallet') {
        this.selectedCurrency = this.currencyMenu[0];
        this.updateRecords();
      }
    } catch (err) {}
  }

  // 根據開啟資料類型, 初始化資料
  async setupRecordType() {
    // 初始化標籤
    this.transTitle = this.titleTranslateMap[this.recordType!] ?? '';

    // // 設定禁用貨幣篩選
    // switch (this.recordType) {
    //   case 'wallet':
    //     this.isDisableCurrency = false;
    //     break;
    //   default:
    //     this.isDisableCurrency = true;
    // }

    if (this.recordType !== 'wallet') {
      this.updateRecords();
    }
  }

  get hasData() {
    return (
      this.walletRecords.length > 0 ||
      this.withdrawRecords.length > 0 ||
      this.rechargeRecords.length > 0 ||
      this.exchangeRecords.length > 0 ||
      this.transferRecords.length > 0
    );
  }

  onCurrencyChanged() {
    this.updateRecords();
  }

  onTransferTypeChanged() {
    this.updateRecords();
  }

  onChangeDatetimePicker(
    target: string,
    type: string,
    event: MatDatepickerInputEvent<Date>
  ) {
    console.log(target, type, event);
  }

  onSearch() {
    this.updateRecords();
  }

  backToHome() {
    if (this.recordType === 'wallet') {
      this.router.navigate(['dashboard']);
      return;
    }
    this.router.navigate(['dashboard', 'record']);
  }

  private updateRecords() {
    this.withdrawRecords = [];
    switch (this.recordType) {
      case 'withdraw':
        return this.fetchWithdrawRecords();
      case 'recharge':
        return this.fetchRechargeRecords();
      case 'exchange':
        return this.fetchExchangeRecords();
      case 'wallet':
        return this.fetchWalletRecords(this.selectedCurrency || 'USD');
      case 'transfer':
        return this.fetchTransferRecords();
    }
    return null;
  }

  // 讀取錢包流水
  private async fetchWalletRecords(currency: string) {
    try {
      this.walletRecords = [];
      const book = await this.client.getWalletRecords(
        currency,
        this.dateFrom.value!,
        DateTimeUtil.getShiftDate(1, this.dateTo.value!) // 往後面推一天, 才抓得到當天的
      );
      for (const rec of book.rows) {
        this.walletRecords.push({
          // transactionTime: rec.acctTxn.txnTime,
          transactionTime: DateTimeUtil.datetimeFormat(rec.acctTxn.txnTime),
          transactionType: rec.acctTxn.txnType, // CONVERSION, PAYMENT
          currency: rec.currency,
          amount:
            rec.acctTxn.amount * (rec.acctTxn.cdtDbtInd === 'CREDIT' ? -1 : 1), // 出帳類型就設定為負數
          remain: rec.acctTxn.balance,
        });
      }
    } catch (err) {
      if (err instanceof Error) {
        this.openSnackBar(err.message);
      } else {
        this.openSnackBar(`${err}`);
      }
    }
  }

  // 讀取轉帳記錄
  private async fetchTransferRecords(currency?: string) {
    try {
      this.transferRecords = [];
      const currency =
        this.selectedCurrency &&
        this.selectedCurrency !== '' &&
        this.selectedCurrency !== 'all'
          ? this.selectedCurrency
          : undefined; // 幣別
      const book = await this.client.getTransferRecords(
        currency,
        this.dateFrom.value!,
        // this.dateTo.value!
        DateTimeUtil.getShiftDate(1, this.dateTo.value!) // 往後面推一天, 才抓得到當天的
      );
      console.error(book.rows);
      for (const rec of book.rows) {
        // rec.status = RandomUtil.chose(Object.keys(this.transferStatusMap));
        const record = {
          creditorEmail: rec.creditorEmail,
          debtorEmail: rec.debtorEmail,
          amount: rec.amount,
          currency: rec.currency,
          createTime: rec.createTime,
          updateTime: rec.updateTime,
          extTraceId: rec.extTraceId,
          status: this.transferStatusMap[rec.status],
          transferId: rec.transferId,
          isDebtor: this.session.context?.principal === rec.debtorEmail,
          isCreditor: this.session.context?.principal === rec.creditorEmail,
        };

        if (this.selectedTransferType === 'income' && record.isDebtor) {
          continue;
        }
        if (this.selectedTransferType === 'expenditure' && record.isCreditor) {
          continue;
        }

        if (record.isCreditor || record.isDebtor) {
          this.transferRecords.push(record);
        }
      }
    } catch (err) {
      if (err instanceof Error) {
        this.openSnackBar(err.message);
      } else {
        this.openSnackBar(`${err}`);
      }
    }
  }

  // 讀取匯兌記錄
  private async fetchExchangeRecords(currency?: string) {
    try {
      this.exchangeRecords = [];
      const book = await this.client.getExchangeRecords(
        this.dateFrom.value!,
        // this.dateTo.value!
        DateTimeUtil.getShiftDate(1, this.dateTo.value!), // 往後面推一天, 才抓得到當天的
        this.selectedCurrency &&
          this.selectedCurrency !== '' &&
          this.selectedCurrency !== 'all'
          ? this.selectedCurrency
          : undefined // 幣別
      );
      for (const rec of book.rows) {
        const s: { status: number; msg: string } = this.exchangeStatusCode(
          rec.status
        ); // 0:處理中, 1:已完成, 2:失敗
        this.exchangeRecords.unshift({
          contractId: rec.contractId,
          // requestTime: rec.requestTime.replace('T', ' ').replace(/\+\d+$/, ''),
          requestTime: DateTimeUtil.datetimeFormat(rec.requestTime),
          executeTime: rec.conversionDate,
          sourceAmount: rec.sourceAmt,
          sourceCurrency: rec.sourceCur,
          targetCurrency: rec.targetCur,
          targetAmount: rec.targetAmt,
          state: s.status,
          stateMsg: s.msg,

          // requestTime: string;
          // executeTime: string;
          // currency: string;
          // amount: number;
          // remain: number;
          // state: number; // 0:處理中, 1:已完成, 2:失敗
        });
      }
    } catch (err) {
      if (err instanceof Error) {
        this.openSnackBar(err.message);
      } else {
        this.openSnackBar(`${err}`);
      }
    }
  }

  // 讀取出款紀錄
  private async fetchWithdrawRecords() {
    try {
      this.withdrawRecords = [];
      const book = await this.client.getWithdrawRecords(
        this.dateFrom.value!,
        // this.dateTo.value!
        DateTimeUtil.getShiftDate(1, this.dateTo.value!), // 往後面推一天, 才抓得到當天的
        this.selectedCurrency &&
          this.selectedCurrency !== '' &&
          this.selectedCurrency !== 'all'
          ? this.selectedCurrency
          : undefined // 幣別
      );
      for (const rec of book.rows) {
        this.withdrawRecords.push({
          transactionId: `${rec.transactionId}`,
          // requestTime: '??????',
          // executeTime: '??????',
          amount: rec.paymentDetails.amount,
          currency: rec.paymentDetails.currency,
          createTime: rec.createTime,
          // remain: 0,
          state: this.withdrawStatusCode(rec.status), // 0:處理中, 1:已完成, 2:失敗
        });
      }
    } catch (err) {
      if (err instanceof Error) {
        this.openSnackBar(err.message);
      } else {
        this.openSnackBar(`${err}`);
      }
    }
  }

  // 讀取充值紀錄
  private async fetchRechargeRecords() {
    try {
      this.rechargeRecords = [];
      const book = await this.client.getRechargeRecords(
        this.dateFrom.value!,
        // this.dateTo.value!
        DateTimeUtil.getShiftDate(1, this.dateTo.value!), // 往後面推一天, 才抓得到當天的
        this.selectedCurrency &&
          this.selectedCurrency !== '' &&
          this.selectedCurrency !== 'all'
          ? this.selectedCurrency
          : undefined // 幣別
      );
      for (const rec of book.rows) {
        this.rechargeRecords.push({
          executeTime: rec.transactionTime,
          currency: rec.currency,
          amount: rec.amount,
          transactionId: `${rec.transactionId}`,
        });
      }
    } catch (err) {
      if (err instanceof Error) {
        this.openSnackBar(err.message);
      } else {
        this.openSnackBar(`${err}`);
      }
    }
  }

  // 轉換匯兌訂單狀態 -> 代碼
  private exchangeStatusCode(status: string) {
    // SUBMITTED-已提交,PROCESSING-处理中,SETTLED-已完成,FAILED-处理失败
    switch (status) {
      case 'PROCESSING': // 已扣款, 處理中
        return { status: 0, msg: 'Records.Status.Processing' };
      case 'SETTLED':
      case 'PROCESSED': // 完成, 以處理
        return { status: 1, msg: 'Records.Status.Settled' };
      case 'FAILED': // 失敗
        return { status: 2, msg: 'Records.Status.Failed' };
      case 'CANCEL': // 取消交易/已撤销
        return { status: 2, msg: 'Records.Status.Failed' };
    }
    return { status: 0, msg: 'Records.Status.Processing' };
  }

  // 轉換訂單狀態 -> 代碼
  private withdrawStatusCode(status: string) {
    // 0:處理中, 1:已完成, 2:失敗
    switch (status) {
      case 'SUBMITTED': // 已提交
      case 'PROCESSING': // 已扣款, 處理中
        return 0;
      case 'SETTLED':
      case 'PROCESSED': // 完成, 以處理
        return 1;
      case 'FAILED': // 失敗
      case 'CANCEL': // 取消交易/已撤销
        return 2;
    }
    return 0;
  }

  private openSnackBar(message: string, duration: number = 1000) {
    this.snackBar.open(message, 'OK', {
      duration: duration,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
