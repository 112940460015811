import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-notifiy-snackbar',
  templateUrl: './notifiy-snackbar.component.html',
  styleUrls: ['./notifiy-snackbar.component.scss'],
})
export class NotifiySnackbarComponent {
  @Output() changeTab = new EventEmitter<string>();

  backToHome() {
    this.changeTab.emit('home');
  }
}
