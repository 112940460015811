import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalService } from '../../services/local.service';

@Component({
  selector: 'app-dev',
  templateUrl: './dev.component.html',
  styleUrls: ['./dev.component.scss'],
})
export class DevComponent {
  constructor(
    public translate: TranslateService,
    public storage: LocalService
  ) {
    this._currentLang = storage.Lang || 'zh-cn';
  }

  langs = [
    {
      code: 'zh-tw',
      name: '繁體中文',
    },
    {
      code: 'zh-cn',
      name: '简体中文',
    },
  ];

  _currentLang: string = 'zh-cn';
  get selectLang(): string {
    return this._currentLang;
  }
  set selectLang(val: string) {
    this._currentLang = val;
    this.translate.use(val);
    this.storage.Lang = val;
  }

  translateLanguageTo(lang: string) {
    this.translate.use(lang);
  }
}
