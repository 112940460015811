<div class="container">
    <!-- <p>login works!</p> -->

    <div class="top">
        <div class="backBtn" >
            <!-- <img class="backBtnImg" alt="image" src="/assets/images/navi_arrow_back.svg" /> -->
            <div class="backBtnTxt">{{'Login.Title' | translate}}</div>
        </div>

    </div>

    <div class="body">

        <div class="titleBox">
            <div class="title">{{'Login.WelcomeBack' | translate}}</div>
            <div class="subtitle">{{'Login.ContinueLogin' | translate}}</div>
        </div>
        <img class="banner" src="/assets/images/login_banner.svg" />

        <form [formGroup]="signin" style="width: 80%; margin-top: 30px;">

            <mat-form-field appearance="outline" style="width: 100%;">
                <input matInput placeholder="{{'Login.EnterEmail' | translate}}" [type]="'text'" formControlName="email"
                    required>
                <!-- <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon> -->
                <!-- <mat-hint *ngIf="!passwordInput?.value">Enter your password</mat-hint> -->
            </mat-form-field>

            <mat-form-field appearance="outline" style="width: 100%;">
                <input matInput placeholder="{{'Common.Password' | translate}}" [type]="hide ? 'password' : 'text'"
                    formControlName="password" style="border-radius: 25px;" required>
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                <!-- <mat-hint *ngIf="!passwordInput?.value">Enter your password</mat-hint> -->
            </mat-form-field>
            <p class="forgetPasswd" routerLink="/forgot" >{{'Login.ForgetPassword' | translate}}</p>
        </form>

        <button mat-flat-button color="primary" class="loginBtn" (click)="login()" [disabled]="signin.status!=='VALID'" >{{'Login.Login' |
            translate}}</button>

        <div class="footer">
            <div style="display: flex;">{{'Login.HaventAccount' | translate}}</div>
            <!-- <p style="display: flex;">{{'Login.Register' | translate}}</p> -->
            <a href="#" routerLink="/register">{{'Login.Register' | translate}}</a>
        </div>

    </div>

</div>