<div class="component-container">
  <div class="component-frame">
    <img alt="image" src="/assets/images/startup_bg.png" class="component-background" />

    <div matRipple centered radius="60" class="component-button-start" (click)="clickNext()">
      {{'Startup.Start' |translate}}
    </div>


    <div class="component-content">
      <span class="component-content-title"><span>PayTranxee APP</span></span>
      <span class="component-content-body">
        <span>
          <span>How Getting Financially</span>
          <br />
          <span>Literate Helps You Be Richer</span>
        </span>
      </span>
    </div>
  </div>
</div>