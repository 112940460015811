import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from '../../services/client.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../services/session.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../../services/dialog.service';
import { UserBalance } from '../../domain/network/response';
import { PromiseUtil } from '../../common/PromiseUtil';

export interface DealRecord {
  owner: string;
  price: number;
  currency: string;
  dealAmount: number;
  dealCurrency: string;
  minimumTrade: number;
  maximumTrade: number;
  completeAmount: number;
}

@Component({
  selector: 'app-dashboard-deal',
  templateUrl: './dashboard-deal.component.html',
  styleUrls: ['./dashboard-deal.component.scss'],
})
export class DashboardDealComponent {
  @Output() changeTab = new EventEmitter<string>();

  dealRecords: DealRecord[] = [];

  sortOption: string = 'price';

  sortOptionMenu = [
    { name: '价格', value: 'price' },
    { name: '交易', value: 'trade' },
  ];

  userBanlances: UserBalance[] = []; // 用戶資產

  currencyMenu: { code: string; name: string; selected: boolean }[] = []; // 幣種;
  currencySelected: string = 'USD';

  filterQuantity: number | false = false; // 篩選交易限額

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private client: ClientService,
    private snackBar: MatSnackBar,
    private session: SessionService,
    private translate: TranslateService,
    private dialog: DialogService
  ) {
    this.fillFakeData();
  }

  ngOnInit() {
    this.onInit();
  }

  async onInit() {
    const currencies: string[] = [];

    this.userBanlances = (await this.client.balanceList()).filter(
      (item) => item.updateTime !== null
    );

    this.userBanlances.forEach((item) => currencies.push(item.currency));

    // 翻譯
    const trans = await PromiseUtil.FromObservable(
      this.translate.get(currencies.map((c) => `Currency.${c}`))
    );
    // 建立清單
    this.currencyMenu = [];
    currencies.forEach((currency) => {
      this.currencyMenu.push({
        code: currency,
        name: trans[`Currency.${currency}`] ?? currency,
        selected: currency === this.currencySelected,
      });
    });
  }

  fillFakeData() {
    this.dealRecords.push({
      owner: 'jafjuah....',
      price: 3400,
      currency: 'CNY',
      dealAmount: 643200,
      dealCurrency: 'USDT',
      minimumTrade: 5000,
      maximumTrade: 10000,
      completeAmount: 533400,
    });
  }

  get hasData() {
    return this.dealRecords.length > 0;
  }

  async onChangeCurrency() {
    const currency = await this.dialog.showCurrencySelect2(
      this.currencySelected,
      this.currencyMenu
    );
    if (this.currencySelected === currency) return;
    this.currencySelected = currency;

    // TODO: 重新撈取掛單資訊
  }

  onSortOptionSelect() {
    console.log(`排序模式: ${this.sortOption}`);
    // TODO: 重新排序
  }

  async onChangeQuantityFilter() {
    const currency = await this.dialog.showEnterQuantity(123);
    const val = parseInt(currency);
    this.filterQuantity = val > 0 ? val : false;
    if (!this.filterQuantity) return;

    // TODO: 重新撈取/摋選交易限額
  }

  onBackToHome() {
    this.changeTab.emit('home');
    // this.router.navigate(['dashboard', 'home']);
  }
}
