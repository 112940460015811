<h1 mat-dialog-title class="title">{{'Login.GoogleAuthOTP' | translate}}</h1>
<div mat-dialog-content>
    <!-- <p>請輸入動態驗證碼</p> -->
    <!-- <mat-form-field>
        <mat-label>Favorite Animal</mat-label>
        <input matInput [(ngModel)]="data.animal">
    </mat-form-field> -->

    <mat-form-field appearance="outline" style="width: 100%; margin-top: 5px;">
        <mat-label>{{'Login.EnterOtp' | translate}}</mat-label>
        <input matInput [type]="'text'" maxlength="6" [formControl]="verifyCodeCtl" style="border-radius: 25px;" required>
        <!-- <mat-hint *ngIf="!passwordInput?.value">Enter your password</mat-hint> -->
    </mat-form-field>

    <button mat-flat-button color="primary" [disabled]="verifyCodeCtl.status !== 'VALID'" class="commitCodeBtn" (click)="onNoClick()">{{'Login.SendOtp' | translate}}</button>
</div>
<button class="dialog-close-mini-btn" mat-button (click)="onNoClick()">X</button>
<!-- <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">No Thanks</button>
    <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Ok</button>
</div> -->