import { Component, EventEmitter, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { PromiseUtil } from '../../common/PromiseUtil';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard-record',
  templateUrl: './dashboard-record.component.html',
  styleUrls: ['./dashboard-record.component.scss'],
})
export class DashboardRecordComponent {
  @Output() changeTab = new EventEmitter<string>();

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
    // record-list
  }

  onOpenRechargeRecord() {
    this.router.navigate(['records', 'recharge']);
  }

  onOpenWithdrawRecord() {
    this.router.navigate(['records', 'withdraw']);
  }

  onOpenExchangeRecord() {
    this.router.navigate(['records', 'exchange']);
  }

  onOpenTransferRecord() {
    this.router.navigate(['records', 'transfer']);
  }

  backToHome() {
    this.changeTab.emit('home');
  }

  async openNotImplSnackBar() {
    const msg = await PromiseUtil.FromObservable(
      this.translate.get('SnackBar.NotImplement')
    );
    this.openSnackBar(msg);
  }

  private openSnackBar(message: string, duration: number = 1000) {
    this.snackBar.open(message, 'OK', {
      duration: duration,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
