<div class="container">

    <div class="footer">
        <div *ngFor="let tab of Tabs" class="footer-item" [ngClass]="{'enable':(focusTab==tab.code)}" (click)="setFocusTab(tab.code)">
            <img src="{{focusTab==tab.code?tab.focusImg:tab.img}}" >
            <div class="subtitle">{{tab.name|translate}}</div>
        </div>
        <!-- 通知彈窗 -->
        <div class="notification" [ngClass]="{'fade-in':hasNewNotify, 'fade-out':!hasNewNotify}">
            <div class="row">
                <div class="title"><div class="text">{{notificationTitle}}</div></div>
                <mat-icon matRipple centered radius="60" class="close" (click)="onNotifyClose()">close</mat-icon>
            </div>
            <div class="row">
                <div class="sub-row">
                    <mat-icon matRipple centered radius="60" class="arrow" (click)="onNotifyBefore()">navigate_before</mat-icon>
                    <div>{{notificationPosition+1}}/{{notificationList.length}}</div>
                    <mat-icon matRipple centered radius="60" class="arrow" (click)="onNotifyNext()">navigate_next</mat-icon>
                </div>
                <div matRipple centered radius="60" class="more" (click)="onNotifyDetail()" routerLink="{{notificationLink}}">前往確認</div>
            </div>
        </div>
    </div>

    <div class="tabContainer">
        <!-- 首頁 -->
        <app-dashboard-home class="tab-page tabHome" *ngIf="focusTab=='home'" (changeTab)="setFocusTab($event)"></app-dashboard-home>

        <!-- 紀錄頁 -->
        <app-dashboard-record class="tab-page" *ngIf="focusTab=='record'" (changeTab)="setFocusTab($event)"></app-dashboard-record>

        <!-- 公告 -->
        <app-dashboard-announcement class="tab-page" *ngIf="focusTab=='announcement'" (changeTab)="setFocusTab($event)"></app-dashboard-announcement>

        <!-- 設定 -->
        <app-dashboard-setting class="tab-page tabSetting" *ngIf="focusTab=='setting'" (changeTab)="setFocusTab($event)"></app-dashboard-setting>

        <!-- 隱藏菜單: 虛擬幣掛單列表 -->
        <app-dashboard-deal class="tab-page" *ngIf="focusTab=='deal'" (changeTab)="setFocusTab($event)"></app-dashboard-deal>

    </div>

    

</div>