<div class="container">
    <!-- <p>login works!</p> -->

    <div class="top">
        <div class="backBtn" (click)="onGoBack()">
            <img class="backBtnImg" alt="image" src="/assets/images/icons/arrow-down-sign-to-navigate.png" />
            <div class="backBtnTxt">{{'Exchange.Title'|translate}}</div>
        </div>

        <!-- 測試分頁 -->
        <!-- <mat-chip-listbox style="display: flex;align-items: center;" (change)="demoSelected($event)">
            <mat-chip-option *ngFor="let chip of demo" [selected]="chip.selected" [color]="chip.color">
                {{chip.name}}
            </mat-chip-option>
        </mat-chip-listbox> -->
    </div>

    <div class="body">

        <!-- 設定交易 -->
        <div class="block-content" *ngIf="stage=='1'">

            <img class="banner-img" src="/assets/images/exchange/banner.png" />

            <!-- 賣出貨幣選項 -->
            <div class="pay-box">
                <div class="pay-box-title">{{'Common.Sell'|translate}}</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="border-radius: 20px;">
                        <input matInput type="number" min="0" [formControl]="amountControl" placeholder="{{'Exchange.EnterAmount'|translate}}" >
                    </mat-form-field>

                    <!-- <mat-form-field appearance="outline" style="margin-left: 10px; width: 100px;">
                        <mat-select [value]="'USDT'">
                            <mat-option value="USDT">USDT</mat-option>
                            <mat-option value="USD">USD</mat-option>
                        </mat-select>
                        <mat-icon matSuffix style="position: absolute;">domain</mat-icon>
                    </mat-form-field> -->

                    <button mat-stroked-button class="currency-select-btn" (click)="onSellCurrencySelect()">
                        <div class="label">
                            <div class="text">{{sellCurrency}}</div>
                            <img class="icon" src="/assets/images/icons/arrow-double.png" />
                        </div>
                    </button>

                </div>
                <div class="pay-box-hint hint-color-blue">{{'Common.AvailableBalance'|translate}}：{{sellCurrencyBalance | walletCurrency }}</div>
            </div>

            <div  class="pay-box" style="align-items: center;">
                <img matRipple centered radius="60" class="exchange-img" src="/assets/images/exchange/exchange.png" (click)="onExchangeCurrency()" />
            </div>

            <!-- 買入貨幣選單 -->
            <div class="pay-box">
                <div class="pay-box-title">{{'Common.Buy'|translate}}</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="border-radius: 20px;">
                        <input matInput disabled placeholder="0" value="{{currentExchangeEstimate | walletCurrency}}" class="exchange-preview">
                    </mat-form-field>

                    <!-- <mat-form-field appearance="outline" style="margin-left: 10px; width: 100px;">
                        <mat-select [value]="'USDT'">
                            <mat-option value="USDT">USDT</mat-option>
                            <mat-option value="USD">USD</mat-option>
                        </mat-select>
                    </mat-form-field> -->
                    <button mat-stroked-button class="currency-select-btn" (click)="onBuyCurrencySelect()">
                        <div class="label">
                            <div class="text">{{buyCurrency}}</div>
                            <img class="icon" src="/assets/images/icons/arrow-double.png" />
                        </div>
                    </button>
                </div>
                <div class="pay-box-hint hint-color-blue">{{'Common.Balance'|translate}}：{{buyCurrencyBalance | walletCurrency}}</div>
            </div>

            <!-- 匯率資訊 -->
            <div class="pay-box">
                <div class="row justify-content-between align-top">
                    <div class="style-1">{{'Common.CurrencyExchangeRate'|translate}}</div>
                    <div class="col">
                        <div class="style-6 text-align-right">{{ rateDescInfo ? rateDescInfo : ('Exchange.RateNotFound'|translate)}}</div>
                        <div class="row-rev" style="margin-top: 5px;" >
                            <div class="style-3" *ngIf="rateUpadeTime">{{'Common.UpdateFrom'|translate}} {{ rateUpadeTime }}</div>
                            <div class="style-3" *ngIf="!rateUpadeTime">-- : -- : --</div>
                            <div style="display: block;">
                                <div class="spin-background-circle"></div>
                                <mat-progress-spinner mode="determinate" [value]="inquirySpinProgressVal" color="accent" style="width: 15px;height: 15px;"></mat-progress-spinner>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <mat-divider style="margin: 10px 40px;"></mat-divider>

            <!-- 交割日 - 暫時無用 -->
            <div class="pay-box" *ngIf="false">
                <div class="pay-box-title">{{'Exchange.ExchangeDate'|translate}}</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <input matInput [matDatepicker]="picker1" [formControl]="date">
                        <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="pay-box-hint hint-color-gray">{{'Exchange.ChoseExchangeDate'|translate}}</div>
            </div>

            <!-- 發送請求估價 -->
            <div class="pay-box pay-box-with-hit">
                <button mat-flat-button color="primary" class="next-btn" [disabled]="currentExchangeEstimate==0" (click)="onInquiry()">{{'Common.NextStep'|translate}}</button>
            </div>

        </div>

        <!-- 确认换汇 -->
        <div class="block-content" *ngIf="stage=='2'">
            <img class="banner-img" src="/assets/images/withdraw/banner.png" />

            <!-- 賣出 -->
            <div class="pay-box pay-box-with-hit">
                <div class="row justify-content-between align-top">
                    <div class="style-4">{{'Common.Sell'|translate}}</div>
                    <div class="col">
                        <div class="style-7 text-align-right">{{inputUserExchangeAmount | number:'1.0':'en-US'}} {{sellCurrency}}</div>
                        <div class="style-3">{{'Common.AvailableBalance'|translate}}：{{sellCurrencyBalance | walletCurrency}} {{sellCurrency}}</div>
                    </div>
                </div>
            </div>

            <!-- 買入 -->
            <div class="pay-box pay-box-with-hit">
                <div class="row justify-content-between align-top">
                    <div class="style-4">{{'Common.Buy'|translate}}</div>
                    <div class="col">
                        <div class="style-7 text-align-right">{{currentExchangeEstimate | walletCurrency}}  {{buyCurrency}}</div>
                        <div class="style-3">{{'Common.AvailableBalance'|translate}}：{{buyCurrencyBalance | walletCurrency}} {{buyCurrency}}</div>
                    </div>
                </div>
            </div>

            <!-- 匯率資訊 -->
            <div class="pay-box pay-box-with-hit">
                <div class="row justify-content-between align-top">
                    <div class="style-1">{{'Common.CurrencyExchangeRate'|translate}}</div>
                    <div class="col">
                        <div class="style-6 text-align-right">{{ rateDescInfo ? rateDescInfo : ('Exchange.RateNotFound'|translate)}}</div>
                        <div class="row-rev" style="margin-top: 5px;" >
                            <div class="style-3" *ngIf="rateUpadeTime">{{'Common.UpdateFrom'|translate}} {{ rateUpadeTime }}</div>
                            <div class="style-3" *ngIf="!rateUpadeTime">-- : -- : --</div>
                            <div style="display: block;">
                                <div class="spin-background-circle"></div>
                                <mat-progress-spinner mode="determinate" [value]="inquirySpinProgressVal" color="accent" style="width: 15px;height: 15px;"></mat-progress-spinner>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- 交割日 -->
            <!-- <div class="pay-box">
                <div class="row justify-content-between align-top">
                    <div class="style-4">{{'Exchange.ExchangeDate'|translate}}</div>
                    <div class="col">
                        <div class="style-6 text-align-right">2023年7月3日 (周一)</div>
                    </div>
                </div>
            </div> -->

            <mat-divider style="margin: 20px 40px;"></mat-divider>

            <div class="pay-box" style="margin-top: 0px;margin-bottom: -20px;">
                <div class="pay-box-title">{{'Common.TransPassword'|translate}}</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="border-radius: 20px;width: 100%;">
                        <input matInput placeholder="{{'Common.Password'|translate}}" [formControl]="trxPassword"
                            [type]="transPasswordHide ? 'password' : 'text'" style="border-radius: 25px;" required>
                        <mat-icon matSuffix (click)="transPasswordHide = !transPasswordHide">{{transPasswordHide ?
                            'visibility_off' : 'visibility'}}</mat-icon>
                    </mat-form-field>
                </div>
            </div>

            <div class="pay-box" style="margin-top: 20px;flex-direction: row;color: #ffaf2a;align-items: end;">
                <mat-icon>info</mat-icon>
                <div class="pay-box-hint" style="margin-left: 5px;">{{'Exchange.ConfirmCannotCancel'|translate}}</div>
            </div>

            <div class="pay-box">
                <button mat-flat-button color="primary" (click)="onConfirmInquiry()" [disabled]="trxPassword.status !== 'VALID'"
                    class="next-btn">{{'Exchange.ConfirmExchange'|translate}}</button>
            </div>

            <div class="pay-box">
                <button mat-button color="accent" (click)="onGoBack()">{{'Common.Return' | translate}}</button>
            </div>

        </div>

        <!-- 完成畫面 -->
        <div class="block-content" *ngIf="stage=='3'">

            <img class="banner-img" src="/assets/images/exchange/banner2.png" />

            <div class="pay-box">
                <div class="style-5" style="text-align: center; margin-top: 30px;">{{'Exchange.SubmitTitle'|translate}}
                </div>
                <div class="style-3" style="text-align: center; margin-top: 30px;">
                    {{'Exchange.SubmitInfo'|translate}}<br />
                    {{applyOrderResult?.sourceAmt ?? 0 | walletCurrency}} {{applyOrderResult?.sourceCur}} {{'Exchange.Transfer' | translate}} {{applyOrderResult?.targetAmt??0 | walletCurrency}} {{applyOrderResult?.targetCur}}！
                </div>
            </div>

            <div class="pay-box" style="margin-top: 30px;">
                <button mat-flat-button color="primary" class="next-btn" (click)="onClose()">{{'Common.Confirm'|translate}}</button>
            </div>

        </div>

    </div>

</div>