<div class="top">
    <div class="backBtn" (click)="backToHome()">
        <img class="backBtnImg" alt="image" src="/assets/images/icons/arrow-down-sign-to-navigate.png" />
        <div class="backBtnTxt">{{'Dashboard.Announcement'|translate}}</div>
    </div>

    <button class="allRead" mat-button target="_blank">{{'Dashboard.AnnoTab.SetAllRead'|translate}}</button>
</div>

<div class="body">
    <div class="block-announcement">
        <div *ngIf="!isLoading && announcements.length==0">
            <div class="announcement ann-none">
                {{'Dashboard.AnnoTab.Nodata'|translate}}
            </div>
        </div>
        <div matRipple centered radius="60" class="announcement" (click)="onMoreDetail(anno.id)" *ngFor="let anno of announcements">
            <div class="flex row space-between">
                <div class="ann-title">{{anno.title}}</div>
                <div class="ann-state" [ngClass]="{'read':anno.read}">
                    {{'Dashboard.AnnoTab.'+(anno.read?'Read':'Unread')|translate}}</div>
            </div>
            <div class="flex row space-between">
                <div class="ann-date">{{'Dashboard.AnnoTab.PublishDate'|translate}} {{anno.date}}</div>
                <div class="ann-detail">{{'Dashboard.AnnoTab.MoreDetail'|translate}}</div>
            </div>
        </div>

    </div>
</div>