<div class="container">
    <!-- <p>login works!</p> -->

    <div class="top">
        <div class="backBtn" routerLink="/">
            <img class="backBtnImg" alt="image" src="/assets/images/icons/arrow-down-sign-to-navigate.png" />
            <div class="backBtnTxt">{{'Binding.BindingMobile'|translate}}</div>
        </div>

        <mat-chip-listbox aria-label="Fish selection" style="display: flex;align-items: center;"
            (change)="demoSelected($event)">
            <mat-chip-option *ngFor="let chip of demo" [selected]="chip.selected" [color]="chip.color">
                {{chip.name}}
            </mat-chip-option>
        </mat-chip-listbox>

    </div>

    <div class="body">

        <!-- 輸入綁定手機 -->
        <div class="block" *ngIf="demoPage=='1'">

            <div class="box">
                <div class="box-title">{{'Binding.EnterYourPhone'|translate}}</div>
                <div class="box-row">
                    <mat-form-field appearance="outline" class="country-code">
                        <mat-select [value]="'86'">
                            <mat-option value="86">+86</mat-option>
                            <mat-option value="886">+886</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" style="border-radius: 20px;">
                        <input matInput [type]="'number'" #input maxlength="10"
                            placeholder="{{'Binding.EnterPhone'|translate}}">
                    </mat-form-field>
                </div>

            </div>

            <div class="box">
                <div class="desc">{{'Binding.SendingVerifyCode'|translate}}</div>
            </div>

            <div class="box">
                <button mat-flat-button color="primary" class="send-btn"
                    style="margin-top: 20px;">{{'Binding.SendVerifyCode'|translate}}</button>
            </div>

        </div>

        <!-- 回填驗證碼 -->
        <div class="block" *ngIf="demoPage=='2'">
            <div class="box">
                <div class="box-title">{{'Binding.EnterVerifyCode'|translate}}</div>
                <div class="box-row-nohit">
                    <mat-form-field appearance="outline" style="border-radius: 20px;margin-right: 10px;">
                        <input matInput [type]="'number'" #input maxlength="6"
                            placeholder="{{'Binding.VerifyCode'|translate}}">
                    </mat-form-field>

                    <button mat-flat-button color="primary"
                        class="re-send-btn">{{'Binding.ResendCode'|translate}}</button>
                </div>
            </div>

            <div class="box">
                <div class="desc">{{'Binding.SendingVerifyCode'|translate}}</div>
                <div class="desc-highlight">+86123456789</div>
            </div>

            <div class="box">
                <div class="desc">{{'Binding.VerifyCodeNotice'|translate}}</div>
            </div>

            <div class="box">
                <button mat-flat-button color="primary" class="send-btn"
                    style="margin-top: 20px;">{{'Common.Binding'|translate}}</button>
            </div>

            <div class="box center">
                <div class="change-phone-btn" matRipple centered radius="50">{{'Binding.ChangePhone'|translate}}</div>
            </div>

        </div>

        <!-- 完成畫面 -->
        <div class="block" *ngIf="demoPage=='3'">
            <img class="banner-img" src="/assets/images/binding/banner-1.png" />

            <div class="box">
                <div class="complete-title" style="text-align: center; margin-top: 30px;">
                    {{'Binding.BindSuccess'|translate}}</div>
                <div class="complete-subtitle" style="text-align: center; margin-top: 30px;">
                    {{'Binding.BindReady'|translate}}</div>
            </div>

            <div class="box" style="margin-top: 30px;">
                <button mat-flat-button color="primary" class="next-btn">{{'Common.OK'|translate}}</button>
            </div>
        </div>
    </div>

</div>