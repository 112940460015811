import { Observable } from 'rxjs';

export class StringUtil {
  // 格式化
  static format(templete: string, ...args: unknown[]) {
    let output = templete;
    const matches = templete.matchAll(/\$\{(\d+)\}/g);
    for (let m of matches) {
      const idx = parseInt(m[1]);
      if (idx < args.length) {
        output = output.replace(m[0], args[idx] as string);
      }
    }
    return output;
  }
}
