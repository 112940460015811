import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GoogleAuthComponent } from '../../dialog/google-auth/google-auth.component';
import { ClientService, RequireTotpError } from '../../services/client.service';
import { Router } from '@angular/router';
import { LocalService } from '../../services/local.service';
import { UserDataService } from '../../services/user-data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../services/session.service';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  signin: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
  });

  get emailInput() {
    return this.signin.get('email');
  }
  get passwordInput() {
    return this.signin.get('password');
  }

  hide = true;

  loginAccount: string = '';
  loginPassword: string = '';
  loginTotp?: string;

  constructor(
    // public dialog: MatDialog,
    private dialog: DialogService,
    private client: ClientService,
    private router: Router,
    private local: LocalService,
    private userData: UserDataService,
    private snackBar: MatSnackBar,
    private session: SessionService
  ) {
    if (this.local.DevModes.includes('login')) {
      const pattern = this.local.LoginPattern;
      if (pattern && pattern.length == 2) {
        this.emailInput?.setValue(pattern[0]);
        this.passwordInput?.setValue(pattern[1]);
      }
      // this.emailInput?.setValue('testuser1');
      // this.passwordInput?.setValue('mypass');
      // this.passwordInput?.setValue('aa123456');
      // this.emailInput?.setValue('erinlina01@test.com');
      // this.emailInput?.setValue('erinlina11@test.com');
      // this.emailInput?.setValue('netbuswhs@gmail.com');
      // this.passwordInput?.setValue('aaa123456');
      // this.passwordInput?.setValue('1qaz2wsx');
    }
  }

  ngOnInit() {
    this.onInit();
  }

  ngOnDestroy() {}

  async onInit() {}

  async login() {
    const account = this.emailInput?.value;
    const password = this.passwordInput?.value;
    try {
      const succ = await this.client.login(account, password, this.loginTotp);
      if (succ) {
        // 轉向大聽
        this.router.navigate(['/dashboard'], { replaceUrl: true });
      }
    } catch (err) {
      if (err instanceof RequireTotpError) {
        this.openTotpDialog();
      } else {
        this.openNoticeSnackBar(`${err}`);
      }
      this.loginTotp = undefined;
    }
  }

  openTotpDialog(): void {
    this.loginTotp = undefined;
    const dialogRef = this.dialog.showGoogleVerify();
    const sub = dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loginTotp = result;
        this.login();
        sub.unsubscribe();
      }
    });
  }

  openNoticeSnackBar(msg: string) {
    this.snackBar.open(msg, 'OK', {
      duration: 1000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
