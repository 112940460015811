<div class="container">

  <div class="top">
    <div class="backBtn" (click)="backToHome()">
        <img class="backBtnImg" alt="image" src="/assets/images/icons/arrow-down-sign-to-navigate.png" />
        <div class="backBtnTxt">{{'Wallet.Title' | translate}}</div>
    </div>
  </div>

  <div class="head-block">
    <div class="head">
      <img src="/assets/images/head.png" />
      <div class="name">{{ principal | PrincipalMask}}</div>
    </div>
  </div>

  <div class="body">

    <div class="block-content">
      <div class="records">
        <div>

          <!-- 查無數據 -->
          <div class="record nodata info-style" *ngIf=" balanceRecords.length==0 ">
              {{'Dashboard.AnnoTab.Nodata'|translate}}
          </div>

          <div class="record" *ngFor="let account of balanceRecords">
            <mat-expansion-panel [expanded]="openedCurrency === account.currency" (opened)="onSetCurrency(account.currency)">
              <mat-expansion-panel-header style="height: auto;">
                <!-- <mat-panel-title>
                  This is the expansion title
                </mat-panel-title>
                <mat-panel-description>
                  This is a summary of the content
                </mat-panel-description> -->
                <div class="expansion-header">
                  <div class="currency">{{account.currency}}</div>
                  <div class="balance-row">
                    <div class="balance-desc">{{'Wallet.Remain' | translate}}</div>
                    <div class="balance-amount">{{account.amount | walletCurrency}}</div>
                  </div>
                </div>
              </mat-expansion-panel-header>

              <div class="expansion-body">
                <div matRipple centered radius="60" class="button red" (click)="onRechargeClick(account.currency)">{{'Common.Recharge' | translate}}</div>
                <div matRipple centered radius="60" class="button" (click)="onWithdrawClick(account.currency)" [ngClass]="{'blue':account.amount>0, 'gray':account.amount<=0}">{{'Common.Withdraw' | translate}}</div>
                <div matRipple centered radius="60" class="button" (click)="onExchangeClick(account.currency)" [ngClass]="{'yellow':account.amount>0, 'gray':account.amount<=0}">{{'Common.Exchange' | translate}}</div>
              </div>

              <!-- <p>This is the primary content of the panel.</p> -->
            </mat-expansion-panel>
          </div>
          
          <!-- 沒有更多數據 -->
          <div class="no-more-data info-style" >{{'Records.NoMoreData'|translate}}</div>

        </div>
      </div>
    </div>
  </div>
</div>