// 二碼 vs 三碼, 國名翻譯用 3碼當 Key
const CountryCode = {
  AF: 'AFG',
  AL: 'ALB',
  DZ: 'DZA',
  AD: 'AND',
  AO: 'AGO',
  AI: 'AIA',
  AG: 'ATG',
  AE: 'ARE',
  AR: 'ARG',
  AM: 'ARM',
  AW: 'ABW',
  AU: 'AUS',
  AT: 'AUT',
  AZ: 'AZE',
  BS: 'BHS',
  BH: 'BHR',
  BD: 'BGD',
  BB: 'BRB',
  BY: 'BLR',
  BE: 'BEL',
  BZ: 'BLZ',
  BJ: 'BEN',
  BM: 'BMU',
  BT: 'BTN',
  BO: 'BOL',
  BA: 'BIH',
  BW: 'BWA',
  BR: 'BRA',
  BN: 'BRN',
  BG: 'BGR',
  BF: 'BFA',
  BI: 'BDI',
  KH: 'KHM',
  CM: 'CMR',
  CA: 'CAN',
  CV: 'CPV',
  KY: 'CYM',
  CF: 'CAF',
  TD: 'TCD',
  CL: 'CHL',
  HK: 'HKG',
  CN: 'CHN',
  CC: 'CCK',
  CO: 'COL',
  KM: 'COM',
  CG: 'COG',
  CD: 'COD',
  CK: 'COK',
  CR: 'CRI',
  HR: 'HRV',
  CU: 'CUB',
  CY: 'CYP',
  CZ: 'CZE',
  SK: 'SVK',
  DK: 'DNK',
  DJ: 'DJI',
  DM: 'DMA',
  DO: 'DOM',
  EC: 'ECU',
  EG: 'EGY',
  SV: 'SLV',
  GQ: 'GNQ',
  ER: 'ERI',
  EE: 'EST',
  ET: 'ETH',
  FK: 'FLK',
  FO: 'FRO',
  FJ: 'FJI',
  FI: 'FIN',
  FR: 'FRA',
  PF: 'PYF',
  GA: 'GAB',
  GM: 'GMB',
  GE: 'GEO',
  DE: 'DEU',
  GH: 'GHA',
  GI: 'GIB',
  GR: 'GRC',
  GL: 'GRL',
  GD: 'GRD',
  GT: 'GTM',
  GG: 'GGY',
  GN: 'GIN',
  GW: 'GNB',
  GY: 'GUY',
  HT: 'HTI',
  HN: 'HND',
  HU: 'HUN',
  IS: 'ISL',
  IN: 'IND',
  ID: 'IDN',
  IR: 'IRN',
  IQ: 'IRQ',
  IE: 'IRL',
  IM: 'IMN',
  IL: 'ISR',
  IT: 'ITA',
  CI: 'CIV',
  JM: 'JAM',
  JP: 'JPN',
  JE: 'JEY',
  JO: 'JOR',
  KZ: 'KAZ',
  KE: 'KEN',
  KI: 'KIR',
  KW: 'KWT',
  KG: 'KGZ',
  LA: 'LAO',
  LV: 'LVA',
  LB: 'LBN',
  LS: 'LSO',
  LR: 'LBR',
  LY: 'LBY',
  LI: 'LIE',
  LT: 'LTU',
  LU: 'LUX',
  MO: 'MAC',
  MK: 'MKD',
  MG: 'MDG',
  MW: 'MWI',
  MY: 'MYS',
  MV: 'MDV',
  ML: 'MLI',
  MT: 'MLT',
  MH: 'MHL',
  MQ: 'MTQ',
  MR: 'MRT',
  MU: 'MUS',
  MX: 'MEX',
  MD: 'MDA',
  MC: 'MCO',
  MN: 'MNG',
  ME: 'MNE',
  MS: 'MSR',
  MA: 'MAR',
  MZ: 'MOZ',
  MM: 'MMR',
  NA: 'NAM',
  NR: 'NRU',
  NP: 'NPL',
  NL: 'NLD',
  NC: 'NCL',
  NZ: 'NZL',
  NI: 'NIC',
  NE: 'NER',
  NG: 'NGA',
  KP: 'PRK',
  MP: 'MNP',
  NO: 'NOR',
  OM: 'OMN',
  PK: 'PAK',
  PW: 'PLW',
  PA: 'PAN',
  PG: 'PNG',
  PY: 'PRY',
  PE: 'PER',
  PH: 'PHL',
  PL: 'POL',
  PT: 'PRT',
  PR: 'PRI',
  QA: 'QAT',
  RO: 'ROU',
  RU: 'RUS',
  RW: 'RWA',
  RE: 'REU',
  SH: 'SHN',
  KN: 'KNA',
  WS: 'WSM',
  SM: 'SMR',
  ST: 'STP',
  SA: 'SAU',
  SN: 'SEN',
  RS: 'SRB',
  SC: 'SYC',
  SL: 'SLE',
  SG: 'SGP',
  SI: 'SVN',
  SB: 'SLB',
  SO: 'SOM',
  ZA: 'ZAF',
  KR: 'KOR',
  ES: 'ESP',
  LK: 'LKA',
  LC: 'LCA',
  VC: 'VCT',
  SD: 'SDN',
  SR: 'SUR',
  SZ: 'SWZ',
  SE: 'SWE',
  CH: 'CHE',
  SY: 'SYR',
  TW: 'TWN',
  TJ: 'TJK',
  TZ: 'TZA',
  TH: 'THA',
  TL: 'TLS',
  TG: 'TGO',
  TO: 'TON',
  TT: 'TTO',
  TN: 'TUN',
  TR: 'TUR',
  TM: 'TKM',
  TC: 'TCA',
  TV: 'TUV',
  UG: 'UGA',
  UA: 'UKR',
  GB: 'GBR',
  US: 'USA',
  UY: 'URY',
  UZ: 'UZB',
  VU: 'VUT',
  VA: 'VAT',
  VE: 'VEN',
  VN: 'VNM',
  VG: 'VGB',
  EH: 'ESH',
  YE: 'YEM',
  ZM: 'ZMB',
  ZW: 'ZWE',
};

export { CountryCode };

// const ccc = [
//   ['AF', 'AFG', '阿富汗', 'Afghanistan'], //
//   ['AL', 'ALB', '阿尔巴尼亚', 'Albania'], //
//   ['DZ', 'DZA', '阿尔及利亚', 'Algeria'], //
//   ['AD', 'AND', '安道尔', 'Andorra'], //
//   ['AO', 'AGO', '安哥拉', 'Angola'], //
//   ['AI', 'AIA', '安圭拉', 'Anguilla'], //
//   ['AG', 'ATG', '安提瓜和巴布达岛', 'Antigua and Barbuda'], //
//   ['AE', 'ARE', '阿联酋', 'United Arab Emirates'], //
//   ['AR', 'ARG', '阿根廷', 'Argentina'], //
//   ['AM', 'ARM', '亚美尼亚', 'Armenia'], //
//   ['AW', 'ABW', '阿鲁巴', 'Aruba'], //
//   ['AU', 'AUS', '澳大利亚', 'Australia'], //
//   ['AT', 'AUT', '奥地利', 'Austria'], //
//   ['AZ', 'AZE', '阿塞拜疆', 'Azerbaijan'], //
//   ['BS', 'BHS', '巴哈马', 'Bahamas'], //
//   ['BH', 'BHR', '巴林', 'Bahrain'], //
//   ['BD', 'BGD', '孟加拉国', 'Bangladesh'], //
//   ['BB', 'BRB', '巴巴多斯', 'Barbados'], //
//   ['BY', 'BLR', '白俄罗斯', 'Belarus'], //
//   ['BE', 'BEL', '比利时', 'Belgium'], //
//   ['BZ', 'BLZ', '伯利兹', 'Belize'], //
//   ['BJ', 'BEN', '贝宁', 'Benin'], //
//   ['BM', 'BMU', '百慕大群岛', 'Bermuda'], //
//   ['BT', 'BTN', '不丹', 'Bhutan'], //
//   ['BO', 'BOL', '玻利维亚', 'Bolivia'], //
//   ['BA', 'BIH', '波斯尼亚和黑塞哥维那（波黑）', 'Bosnia and Herzegovina'], //
//   ['BW', 'BWA', '博茨瓦纳', 'Botswana'], //
//   ['BR', 'BRA', '巴西', 'Brazil'], //
//   ['BN', 'BRN', '文莱', 'Brunei'], //
//   ['BG', 'BGR', '保加利亚', 'Bulgaria'], //
//   ['BF', 'BFA', '布基纳法索', 'Burkina Faso'], //
//   ['BI', 'BDI', '布隆迪', 'Burundi'], //
//   ['KH', 'KHM', '柬埔寨', 'Cambodia'], //
//   ['CM', 'CMR', '喀麦隆', 'Cameroon'], //
//   ['CA', 'CAN', '加拿大', 'Canada'], //
//   ['CV', 'CPV', '佛得角', 'Cape Verde'], //
//   ['KY', 'CYM', '开曼群岛', 'Cayman Islands'], //
//   ['CF', 'CAF', '中非共和国', 'Central African Republic'], //
//   ['TD', 'TCD', '乍得', 'Chad'], //
//   ['CL', 'CHL', '智利', 'Chile'], //
//   ['HK', 'HKG', '中国，香港特别行政区', '(China)Hong Kong'], //
//   ['CN', 'CHN', '中国，中华人民共和国', 'China'], //
//   ['CC', 'CCK', '科科斯群岛', 'Cocos Islands'], //
//   ['CO', 'COL', '哥伦比亚', 'Colombia'], //
//   ['KM', 'COM', '科摩罗', 'Comoros'], //
//   ['CG', 'COG', '刚果共和国（布）', 'Republic of the Congo'], //
//   ['CD', 'COD', '刚果民主共和国（金）', 'Democratic Republic of the Congo'], //
//   ['CK', 'COK', '库克群岛', 'Cook Islands'], //
//   ['CR', 'CRI', '哥斯达黎加', 'Costa Rica'], //
//   ['HR', 'HRV', '克罗地亚', 'Croatia'], //
//   ['CU', 'CUB', '古巴', 'Cuba'], //
//   ['CY', 'CYP', '塞浦路斯', 'Cyprus'], //
//   ['CZ', 'CZE', '捷克共和国', 'Czech Republic'], //
//   ['SK', 'SVK', '斯洛伐克共和国', 'Slovakia Republic'], //
//   ['DK', 'DNK', '丹麦', 'Denmark'], //
//   ['DJ', 'DJI', '吉布提', 'Djibouti'], //
//   ['DM', 'DMA', '多米尼加', 'Dominica'], //
//   ['DO', 'DOM', '多明尼加共和国', 'Dominican Republic'], //
//   ['EC', 'ECU', '厄瓜多尔', 'Ecuador'], //
//   ['EG', 'EGY', '埃及', 'Egypt'], //
//   ['SV', 'SLV', '萨尔瓦多', 'El Salvador'], //
//   ['GQ', 'GNQ', '赤道几内亚', 'Equatorial Guinea'], //
//   ['ER', 'ERI', '厄立特里亚', 'Eritrea'], //
//   ['EE', 'EST', '爱沙尼亚', 'Estonia'], //
//   ['ET', 'ETH', '埃塞俄比亚', 'Ethiopia'], //
//   ['FK', 'FLK', '福克兰群岛（Malvinas）', 'Falkland Islands'], //
//   ['FO', 'FRO', '法罗群岛', 'Faroe Islands'], //
//   ['FJ', 'FJI', '斐济', 'Fiji'], //
//   ['FI', 'FIN', '芬兰', 'Finland'], //
//   ['FR', 'FRA', '法国', 'France'], //
//   ['PF', 'PYF', '法属波利尼西亚', 'French Polynesia'], //
//   ['GA', 'GAB', '加蓬', 'Gabon'], //
//   ['GM', 'GMB', '冈比亚', 'Gambia'], //
//   ['GE', 'GEO', '格鲁吉亚共和国', 'Georgia'], //
//   ['DE', 'DEU', '德国', 'Germany'], //
//   ['GH', 'GHA', '加纳', 'Ghana'], //
//   ['GI', 'GIB', '直布罗陀', 'Gibraltar'], //
//   ['GR', 'GRC', '希腊', 'Greece'], //
//   ['GL', 'GRL', '格陵兰岛', 'Greenland'], //
//   ['GD', 'GRD', '格林纳达', 'Grenada'], //
//   ['GT', 'GTM', '危地马拉', 'Guatemala'], //
//   ['GG', 'GGY', '根西岛', 'Guernsey'], //
//   ['GN', 'GIN', '几内亚', 'Guinea'], //
//   ['GW', 'GNB', '几内亚比绍', 'Guinea-Bissau'], //
//   ['GY', 'GUY', '圭亚那', 'Guyana'], //
//   ['HT', 'HTI', '海地', 'Haiti'], //
//   ['HN', 'HND', '洪都拉斯', 'Honduras'], //
//   ['HU', 'HUN', '匈牙利', 'Hungary'], //
//   ['IS', 'ISL', '冰岛', 'Iceland'], //
//   ['IN', 'IND', '印度', 'India'], //
//   ['ID', 'IDN', '印度尼西亚', 'Indonesia'], //
//   ['IR', 'IRN', '伊朗', 'Iran'], //
//   ['IQ', 'IRQ', '伊拉克', 'Iraq'], //
//   ['IE', 'IRL', '爱尔兰', 'Ireland'], //
//   ['IM', 'IMN', '马恩岛', 'Isle of Man'], //
//   ['IL', 'ISR', '以色列', 'Israel'], //
//   ['IT', 'ITA', '意大利', 'Italy'], //
//   ['CI', 'CIV', '科特迪瓦', "COTE D'LVOIRE(IVORY)"], // 象牙海岸
//   ['JM', 'JAM', '牙买加', 'Jamaica'], //
//   ['JP', 'JPN', '日本', 'Japan'], //
//   ['JE', 'JEY', '泽西', 'Jersey'], //
//   ['JO', 'JOR', '约旦', 'Jordan'], //
//   ['KZ', 'KAZ', '哈萨克斯坦', 'Kazakhstan'], //
//   ['KE', 'KEN', '肯尼亚', 'Kenya'], //
//   ['KI', 'KIR', '基里巴斯', 'Kiribati'], //
//   ['KW', 'KWT', '科威特', 'Kuwait'], //
//   ['KG', 'KGZ', '吉尔吉斯斯坦', 'Kyrgyzstan'], //
//   ['LA', 'LAO', '老挝', 'Laos'], //
//   ['LV', 'LVA', '拉脱维亚', 'Latvia'], //
//   ['LB', 'LBN', '黎巴嫩', 'Lebanon'], //
//   ['LS', 'LSO', '莱索托', 'Lesotho'], //
//   ['LR', 'LBR', '利比里亚', 'Liberia'], //
//   ['LY', 'LBY', '利比亚', 'Libya'], //
//   ['LI', 'LIE', '列支敦士登', 'Liechtenstein'], //
//   ['LT', 'LTU', '立陶宛', 'Lithuania'], //
//   ['LU', 'LUX', '卢森堡', 'Luxembourg'], //
//   ['MO', 'MAC', '澳门（中国）', '(China)Macau'], //
//   ['MK', 'MKD', '马其顿', 'Macedonia'], //
//   ['MG', 'MDG', '马达加斯加', 'Madagascar'], //
//   ['MW', 'MWI', '马拉维', 'Malawi'], //
//   ['MY', 'MYS', '马来西亚', 'Malaysia'], //
//   ['MV', 'MDV', '马尔代夫', 'Maldives'], //
//   ['ML', 'MLI', '马里', 'Mali'], //
//   ['MT', 'MLT', '马耳他', 'Malta'], //
//   ['MH', 'MHL', '马绍尔群岛', 'Marshall Islands'], //
//   ['MQ', 'MTQ', '马提尼克', 'Martinique'], //
//   ['MR', 'MRT', '毛里塔尼亚', 'Mauritania'], //
//   ['MU', 'MUS', '毛里求斯', 'Mauritius'], //
//   ['MX', 'MEX', '墨西哥', 'Mexico'], //
//   ['MD', 'MDA', '摩尔多瓦共和国', 'Moldova'], //
//   ['MC', 'MCO', '摩纳哥', 'Monaco'], //
//   ['MN', 'MNG', '蒙古', 'Mongolia'], //
//   ['ME', 'MNE', '黑山', 'Montenegro'], //
//   ['MS', 'MSR', '蒙特塞拉特岛', ''], //
//   ['MA', 'MAR', '摩洛哥', 'Morocco'], //
//   ['MZ', 'MOZ', '莫桑比克', 'Mozambique'], //
//   ['MM', 'MMR', '缅甸', 'Myanmar'], //
//   ['NA', 'NAM', '纳米比亚', 'Namibia'], //
//   ['NR', 'NRU', '瑙鲁', 'Nauru'], //
//   ['NP', 'NPL', '尼泊尔', 'Nepal'], //
//   ['NL', 'NLD', '荷兰', 'Netherlands'], //
//   ['NC', 'NCL', '新喀里多尼亚', 'New Caledonia'], //
//   ['NZ', 'NZL', '新西兰', 'New Zealand'], //
//   ['NI', 'NIC', '尼加拉瓜', 'Nicaragua'], //
//   ['NE', 'NER', '尼日尔', 'Niger'], //
//   ['NG', 'NGA', '尼日利亚', 'Nigeria'], //
//   ['KP', 'PRK', '朝鲜', 'North Korea'], //
//   ['MP', 'MNP', '北马里亚纳群岛', 'Northern Mariana Islands'], //
//   ['NO', 'NOR', '挪威', 'Norway'], //
//   ['OM', 'OMN', '阿曼', 'Oman'], //
//   ['PK', 'PAK', '巴基斯坦', 'Pakistan'], //
//   ['PW', 'PLW', '帕劳', 'Palau'], //
//   ['PA', 'PAN', '巴拿马', 'Panama'], //
//   ['PG', 'PNG', '巴布亚新几内亚', 'Papua New Guinea'], //
//   ['PY', 'PRY', '巴拉圭', 'Paraguay'], //
//   ['PE', 'PER', '秘鲁', 'Peru'], //
//   ['PH', 'PHL', '菲律宾', 'Philippines'], //
//   ['PL', 'POL', '波兰', 'Poland'], //
//   ['PT', 'PRT', '葡萄牙', 'Portugal'], //
//   ['PR', 'PRI', '波多黎各', 'Puerto Rico'], //

//   ['QA', 'QAT', '卡塔尔', 'Qatar'], //
//   ['RO', 'ROU', '罗马尼亚', 'Romania'], //
//   ['RU', 'RUS', '俄罗斯联邦', 'Russia'], //
//   ['RW', 'RWA', '卢旺达', 'Rwanda'], //
//   ['RE', 'REU', '留尼汪岛', 'Reunion Island'], //

//   ['SH', 'SHN', '圣赫勒拿岛', 'Saint Helena'], //
//   ['KN', 'KNA', '圣基茨和尼维斯', 'Saint Kitts and Nevis'], //
//   ['WS', 'WSM', '萨摩亚', 'Samoa'], //
//   ['SM', 'SMR', '圣马力诺', 'San Marino'], //
//   ['ST', 'STP', '圣多美和普林西比', 'Sao Tome and Principe'], //
//   ['SA', 'SAU', '沙特阿拉伯', 'Saudi Arabia'], //
//   ['SN', 'SEN', '塞内加尔', 'Senegal'], //
//   ['RS', 'SRB', '塞尔维亚', 'Serbia'], //
//   ['SC', 'SYC', '塞舌尔', 'Seychelles'], //
//   ['SL', 'SLE', '塞拉利昂', 'Sierra Leone'], //
//   ['SG', 'SGP', '新加坡', 'Singapore'], //
//   ['SI', 'SVN', '斯洛文尼亚', 'Slovenia'], //
//   ['SB', 'SLB', '所罗门群岛', 'Solomon Islands'], //
//   ['SO', 'SOM', '索马里', 'Somalia'], //
//   ['ZA', 'ZAF', '南非', 'South Africa'], //
//   ['KR', 'KOR', '韩国', 'South Korea'], //
//   ['ES', 'ESP', '西班牙', 'Spain'], //
//   ['LK', 'LKA', '斯里兰卡', 'Sri Lanka'], //
//   ['LC', 'LCA', '圣露西亚', 'Saint Lucia'], //
//   ['VC', 'VCT', '圣文森特和格林纳丁斯', 'Saint Vincent and the Grenadines'], //
//   ['SD', 'SDN', '苏丹', 'Sudan'], //
//   ['SR', 'SUR', '苏里南', 'Suriname'], //
//   ['SZ', 'SWZ', '斯威士兰', 'Swaziland'], //
//   ['SE', 'SWE', '瑞典', 'Sweden'], //
//   ['CH', 'CHE', '瑞士', 'Switzerland'], //
//   ['SY', 'SYR', '叙利亚', 'Syria'], //
//   ['TW', 'TWN', '台湾（中国）', '(China)Taiwan'], //
//   ['TJ', 'TJK', '塔吉克斯坦', 'Tajikistan'], //
//   ['TZ', 'TZA', '坦桑尼亚', 'Tanzania'], //
//   ['TH', 'THA', '泰国', 'Thailand'], //
//   ['TL', 'TLS', '东帝汶', 'East Timor'], //
//   ['TG', 'TGO', '多哥', 'Togo'], //
//   ['TO', 'TON', '汤加', 'Tonga'], //
//   ['TT', 'TTO', '特立尼达和多巴哥', 'Trinidad and Tobago'], //
//   ['TN', 'TUN', '突尼斯', 'Tunisia'], //
//   ['TR', 'TUR', '土耳其', 'Turkey'], //
//   ['TM', 'TKM', '土库曼斯坦', 'Turkmenistan'], //
//   ['TC', 'TCA', '土耳其和凯科斯群岛', 'Turks and Caicos Islands'], //
//   ['TV', 'TUV', '图瓦卢', 'Tuvalu'], //
//   ['UG', 'UGA', '乌干达', 'Uganda'], //
//   ['UA', 'UKR', '乌克兰', 'Ukraine'], //
//   ['GB', 'GBR', '英国', 'United Kingdom'], //
//   ['US', 'USA', '美国', 'United States'], //
//   ['UY', 'URY', '乌拉圭', 'Uruguay'], //
//   ['UZ', 'UZB', '乌兹别克斯坦', 'Uzbekistan'], //
//   ['VU', 'VUT', '瓦努阿图（新赫布里底群岛）', 'Vanuatu'], //
//   ['VA', 'VAT', '梵蒂冈城国（圣见）', 'Vatican'], //
//   ['VE', 'VEN', '委内瑞拉', 'Venezuela'], //
//   ['VN', 'VNM', '越南', 'Vietnam'], //
//   ['VG', 'VGB', '英属维尔京群岛', 'British Virgin Islands'], //
//   ['EH', 'ESH', '西撒哈拉', 'Western Sahara'], //
//   ['YE', 'YEM', '也门', 'Yemen'], //
//   ['ZM', 'ZMB', '赞比亚', 'Zambia'], //
//   ['ZW', 'ZWE', '津巴布韦', 'Zimbabwe'], //
// ];
