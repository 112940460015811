import { Pipe, PipeTransform } from '@angular/core';
import { CountryCode } from '../definition/CountryCode';
import { TranslateService } from '@ngx-translate/core';
import { PromiseUtil } from '../common/PromiseUtil';

@Pipe({
  name: 'countryName',
})
export class CountryNamePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: unknown, ...args: unknown[]) {
    return new Promise<string>(async (resolve, reject) => {
      if (value) {
        for (const [k, v] of Object.entries(CountryCode)) {
          if (k === value) {
            const name = await PromiseUtil.FromObservable(
              this.translate.get(`ConutryCode.${v}`)
            );
            return resolve(name);
          }
        }
      }
      resolve(`Unknown`);
    });
  }
}
