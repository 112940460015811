import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LanguageSelectorComponent } from '../dialog/language-selector/language-selector.component';
import { GoogleAuthComponent } from '../dialog/google-auth/google-auth.component';
import { RedirectPopupComponent } from '../dialog/redirect-popup/redirect-popup.component';
import { Router } from '@angular/router';
import { CurrencyMenuItem } from '../domain/interfaces';
import { CurrencySelectorComponent } from '../dialog/currency-selector/currency-selector.component';
import { QuantityComponent } from '../dialog/quantity/quantity.component';

export interface LanguageOption {
  code: string;
  name: string;
  selected?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog, private router: Router) {}

  // 顯示語言選擇
  public showLanguageSelect(language: string, list: LanguageOption[]) {
    list.forEach((item) => (item.selected = language == item.code));
    const dialogRef = this.dialog.open(LanguageSelectorComponent, {
      panelClass: 'custom-container',
      width: '80vw', // Set width to 60 percent of view port width
      height: 'auto',
      data: {
        currency: list,
      },
    });
    return dialogRef;
  }

  // 顯示貨幣選擇
  public showCurrencySelect(current: string, list: CurrencyMenuItem[]) {
    list.forEach((item) => (item.selected = current == item.code));

    const dialogRef = this.dialog.open(CurrencySelectorComponent, {
      panelClass: 'custom-container',
      width: '80vw', // Set width to 60 percent of view port width
      height: 'auto',
      data: {
        currency: list,
      },
    });
    return dialogRef;
  }

  // 顯示貨幣選擇 - 直接返回結果
  public showCurrencySelect2(current: string, list: CurrencyMenuItem[]) {
    return new Promise<string>((resolve, reject) => {
      list.forEach((item) => (item.selected = current == item.code));

      const dialogRef = this.dialog.open(CurrencySelectorComponent, {
        panelClass: 'custom-container',
        width: '80vw', // Set width to 60 percent of view port width
        height: 'auto',
        data: {
          currency: list,
        },
      });
      const sub = dialogRef.afterClosed().subscribe({
        next: (val) => {
          sub.unsubscribe();
          resolve(val ?? current);
        },
      });
    });
  }

  // 輸入篩選金額
  public showEnterQuantity(current: number) {
    return new Promise<string>((resolve, reject) => {
      const dialogRef = this.dialog.open(QuantityComponent, {
        panelClass: 'custom-container',
        width: '80vw', // Set width to 60 percent of view port width
        height: 'auto',
        data: {
          quantity: current,
        },
      });
      const sub = dialogRef.afterClosed().subscribe({
        next: (val) => {
          sub.unsubscribe();
          resolve(val ?? 0);
        },
      });
    });
  }

  // 輸入谷歌驗證碼
  public showGoogleVerify() {
    const dialogRef = this.dialog.open(GoogleAuthComponent, {
      panelClass: 'custom-container',
      width: '80vw', // Set width to 60 percent of view port width
      // height: '30vh',
      height: '230px',
      data: { name: '', animal: '' },
    });
    return dialogRef;
  }

  // 跳轉彈窗
  public showRedirectPopup(
    title: string,
    btnName: string,
    onSubmit: string[],
    onCancel: string[]
  ): void {
    const dialogRef = this.dialog.open(RedirectPopupComponent, {
      panelClass: 'custom-container',
      width: '80vw', // Set width to 60 percent of view port width
      // height: '30vh',
      data: { title, btnName },
    });
    const sub1 = dialogRef.afterClosed().subscribe((result) => {
      sub1.unsubscribe();
      if (result === true) {
        this.router.navigate(onSubmit);
      }
    });
    const sub2 = dialogRef.backdropClick().subscribe((result) => {
      sub2.unsubscribe();
      this.router.navigate(onCancel);
    });
  }
}
