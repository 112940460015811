import { Injectable } from '@angular/core';
import { SessionContext } from '../domain/types/session-context';

export interface EmailVerifyPair {
  token?: string;
  timeout: number;
}

declare global {
  interface Window {
    AddDevMode: any;
    RemoveDevMode: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class LocalService {
  constructor() {
    window.AddDevMode = (mode: string) => {
      this.DevMode(mode);
    };
    window.RemoveDevMode = (mode: string) => {
      this.DevMode(mode, true);
    };
  }

  // 設定語言
  public get Lang(): string | false {
    let lang = localStorage.getItem('Lang');
    return lang ?? false;
  }

  // 設定語言
  public set Lang(val: string) {
    localStorage.setItem('Lang', val);
  }

  public get DevModes(): string[] {
    let param = localStorage.getItem('DevMode');
    let modes = param ? param.split(/;/g) : [];
    return modes;
  }

  // 測試用登入資訊 account;password
  public get LoginPattern(): string[] | false {
    let param = localStorage.getItem('LoginPattern');
    if (!param) return false;
    let info = param.split(/;/g);
    return info;
  }

  public DevMode(val: string, remove: boolean = false) {
    let modes = this.DevModes;
    if (!modes.includes(val)) {
      if (!remove) {
        modes.push(val);
        const ser = modes.join(';');
        localStorage.setItem('DevMode', modes.join(';'));
        return ser;
      }
    } else {
      if (remove) {
        modes = modes.filter((v) => v !== val);
        const ser = modes.join(';');
        localStorage.setItem('DevMode', ser);
        return ser;
      }
    }
    return false;
  }

  public get Host(): string {
    let url = localStorage.getItem('Host');
    return url ? url : '';
  }

  public set Host(val: string) {
    localStorage.setItem('Host', val);
  }

  // 用戶 Account-ClientId 對照表
  public setUserClientId(account: string, clientId: string) {
    let raw = localStorage.getItem('UserClientIds') ?? '{}';
    let map = JSON.parse(raw);
    map[account] = clientId;
    localStorage.setItem('UserClientIds', JSON.stringify(map, null, 2));
  }

  // 用戶 Account-ClientId 對照表
  public getUSerClientId(account: string): string | false {
    let raw = localStorage.getItem('UserClientIds') ?? '{}';
    let map = JSON.parse(raw);
    if (account in map) {
      return map[account];
    }
    return false;
  }

  // 公告已讀列表
  public get NoticeReads(): string[] {
    let raw = localStorage.getItem('NoticeReads') ?? '';
    return raw.split(/,/g);
  }

  // 公告已讀列表
  public set NoticeReads(reads: string[]) {
    let word = reads.join(',');
    localStorage.setItem('NoticeReads', word);
  }

  // Session 資料
  public get SessionContext(): SessionContext | false {
    let raw = localStorage.getItem('session-context');
    if (!raw) return false;
    return JSON.parse(raw);
  }

  // Session 資料
  public set SessionContext(val: SessionContext | false) {
    if (val === false) {
      this.removeData('session-context');
      return;
    }
    this.saveData('session-context', JSON.stringify(val));
  }

  // Email 計時限制 (讀)
  public GetEmailVerifyLimiter1(email: string) {
    const raw = localStorage.getItem('email-limiter');
    if (!raw) return 0;
    const data = JSON.parse(raw);
    if (!data) return 0;
    if (email in data) {
      const t = data[email];
      if (Date.now() <= t) {
        return t;
      }
      // 過期刪除
      delete data[email];
      localStorage.setItem('email-limiter', JSON.stringify(data));
    }
    return 0;
  }

  // Email 計時限制 (設定)
  public SetEmailVerifyLimiter1(email: string, timeout: number) {
    let raw = localStorage.getItem('email-limiter');
    let data = raw ? JSON.parse(raw) : {};
    data[email] = timeout;
    localStorage.setItem('email-limiter', JSON.stringify(data));
  }

  // 設定 Email 驗證器
  public SetEmailVerifyToken(tag: string, timeout: number, token?: string) {
    let raw = localStorage.getItem('email-verify-token');
    let data = raw ? JSON.parse(raw) : {};
    if (timeout > 0) {
      data[tag] = { token, timeout };
    } else {
      delete data[tag];
    }
    localStorage.setItem('email-verify-token', JSON.stringify(data));
  }

  // 讀取 Email 驗證器
  public GetEmailVerifyToken(tag: string): EmailVerifyPair | null {
    const raw = localStorage.getItem('email-verify-token');
    if (!raw) return null;
    const data = JSON.parse(raw);
    if (!data) return null;
    if (tag in data) {
      const record = data[tag];
      if (Date.now() <= record.timeout) {
        return record;
      }
      // 過期刪除
      delete data[tag];
      localStorage.setItem('email-verify-token', JSON.stringify(data));
    }
    return null;
  }

  // 判斷是否第一次開啟 App
  public get isFirstTimeApp() {
    const v = this.getData('FirstTime');
    return v === null;
  }

  // 設定 app 已經啟用過, 不是第一次, 不顯示導引
  public setAppFired() {
    const v = this.saveData('FirstTime', `t=${Date.now()}`);
  }

  async setItem(key: string, v: any) {
    const jStr = JSON.stringify(v);
    await localStorage.setItem(key, jStr);
  }

  async getItem<T>(key: string) {
    const jStr: string | null = localStorage.getItem(key);
    if (jStr) {
      return JSON.parse(jStr) as T;
    }
    return null;
  }

  async removeItem(key: string) {
    await localStorage.removeItem(key);
  }

  private saveData(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  private getData(key: string) {
    return localStorage.getItem(key);
  }
  private removeData(key: string) {
    localStorage.removeItem(key);
  }

  public clearData() {
    localStorage.clear();
  }
}
