export class DateTimeUtil {
  public static getShiftDate(days: number, date?: Date) {
    const base = !date ? new Date() : new Date(date);
    base.setDate(base.getDate() + days);
    return base;
  }

  public static getSearchDate(d: Date): string {
    return d.toISOString().replace(/\.\d+Z$/, 'Z');
  }

  public static getSearchDateWithTimeZone(d?: Date): string {
    if (!d) d = new Date();
    return d.toISOString().replace(/\.\d+Z$/, '+0000');
  }

  public static getDate(d: string): string {
    return d.replace(/^(\d+)\-(\d+)\-(\d+)\s+.*$/, '$1-$2-$3');
  }

  // 2023-01-02
  public static getLocalDateString(d: Date = new Date()) {
    const dd = [
      d.getFullYear(),
      (d.getMonth() + 1).toString().padStart(2, '0'), // month: 0-11
      d.getDate().toString().padStart(2, '0'),
    ];
    return dd.join('-');
  }

  // 03:04:05
  public static getLocalTimeString(d: Date = new Date()) {
    const tt = [
      d.getHours().toString().padStart(2, '0'),
      d.getMinutes().toString().padStart(2, '0'),
      d.getSeconds().toString().padStart(2, '0'),
    ];
    return tt.join(':');
  }

  // 2023-01-02 03:04:05
  public static getLocalDateTime(d: Date = new Date()) {
    return this.getLocalDateString(d) + ' ' + this.getLocalTimeString(d);
  }

  // 取得精簡的 ISO-DateTime string
  public static getIsoDatetimeLit(d: Date = new Date()) {
    let iso = d.toISOString();
    iso = iso.replace(/(\.\d+)?Z$/, '+0000');
    return iso;
  }

  public static getDisplayDatetime(dt: string) {
    // [yyyy-MM-dd'T'HH:mm:ssZ][yyyy-MM-dd'T'HH:mm:ss+dddd] => yyyy-MM-dd HH:mm:ss
    return dt.replace(/^(\d+\-\d+\-\d+)T(\d+:\d+:[0-9]+).*/, '$1 $2');
  }

  public static convertToLocalTime(dt: string) {
    // [yyyy-MM-dd'T'HH:mm:ssZ][yyyy-MM-dd'T'HH:mm:ss+dddd] => yyyy-MM-dd HH:mm:ss
    // return dt.replace(/^(\d+\-\d+\-\d+)T(\d+:\d+:\d+)(Z|\+\d+)$/, '$1 $2');
    const date = new Date(dt);
    console.log(date.getTimezoneOffset());
    let d2 = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

    return d2.toISOString();
  }

  public static formatSeconds(sec: number) {
    let combine: string[] = [];
    for (let i = 0; i < 2; i++) {
      if (sec <= 0) break;
      combine.push(`${sec % 60}`);
      sec = Math.floor(sec / 60);
    }
    if (sec > 0 || combine.length < 2) {
      combine.push(sec.toString());
    }
    for (let i = combine.length - 2; i >= 0; i--) {
      if (combine[i].length < 2) {
        combine[i] = '0' + combine[i];
      }
    }
    return combine.reverse().join(':');
  }

  // 修改日期顯示樣式
  public static datetimeFormat(time: string) {
    // 2023-10-13T13:49:28+0800 -> 2023-10-13 13:49:28
    return time.replace('T', ' ').replace(/\+\d+$/, '');
  }
}
