import { Component } from '@angular/core';
import { ClientService } from '../../services/client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LinkSnackbarComponent } from '../../components/link-snackbar/link-snackbar.component';
import { EnvironmentUtil } from '../../common/EnvironmentUtil';
import { WindowService } from '../../services/window.service';

@Component({
  selector: 'app-startup',
  templateUrl: './startup.component.html',
  styleUrls: ['./startup.component.scss'],
})
export class StartupComponent {
  ready = false;

  snackBarTimer?: NodeJS.Timer;

  constructor(
    private client: ClientService,
    private router: Router,
    private snackBar: MatSnackBar,
    private window: WindowService
  ) {
    Promise.all([this.checkNetwork()])
      .then(() => {
        this.ready = true;
      })
      .catch((err) => {});
  }

  ngOnInit() {
    this.snackBarTimer = setTimeout(() => {
      this.snackBarTimer = undefined;
      this.dectetWebAppMode();
    }, 10000);
  }

  ngOnDestroy() {
    if (this.snackBarTimer) {
      clearTimeout(this.snackBarTimer as unknown as number);
    }
  }

  private dectetWebAppMode() {
    if (EnvironmentUtil.isIOS && !EnvironmentUtil.isStandalone) {
      let title = '安裝 WebClip (測試)';
      let url = '/assets/pay_tranxee_test.mobileconfig';
      if (EnvironmentUtil.isDevelopSite(this.window.location.href)) {
        title = '安裝 WebClip (本地)';
        url = '/assets/pay_tranxee_local.mobileconfig';
      }

      this.openInstallSnackBar(title, url);
    }
  }

  private checkNetwork(timeout: number = 5000) {
    return new Promise(async (resolve, reject) => {
      return resolve(true);
    });
  }

  // 開始使用 app
  clickNext() {
    if (!this.ready) {
      this.openSnackBar();
      return;
    }
    this.router.navigate(['/login'], { replaceUrl: true });
  }

  openSnackBar() {
    this.snackBar.open('連線失敗，請檢查網路', 'OK', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 1500,
    });
  }

  async openInstallSnackBar(name: string, url: string) {
    this.snackBar.openFromComponent(LinkSnackbarComponent, {
      duration: 5 * 1000,
      data: { url, name },
    });
  }
}
