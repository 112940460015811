import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyUtil } from '../common/CurrencyUtil';

@Pipe({
  name: 'walletCurrency',
})
export class WalletCurrencyPipe implements PipeTransform {
  transform(value: number, base: number = 100): string {
    // const negative = value < 0;
    // if (value < 0) {
    //   value *= -1;
    // }
    // const floating = (value % base).toString().padStart(2, '0'); // 0.00 ~ 0.99
    // let output = '.';
    // let vv = Math.floor(value / base).toString();
    // while (vv.length >= 3) {
    //   output = ',' + vv.substring(vv.length - 3) + output;
    //   vv = vv.substring(0, vv.length - 3);
    // }
    // if (vv.length > 0) {
    //   output = vv + output;
    // } else {
    //   output = output.substring(1);
    // }

    // output += floating;
    // if (negative) {
    //   output = '-' + output;
    // }
    // return output;
    return CurrencyUtil.amountFormat(value, base);
  }
}
