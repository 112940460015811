<div class="container">

    <div class="top">
        <div class="backBtn" (click)="onGoBack()">
            <img class="backBtnImg" alt="image" src="/assets/images/icons/arrow-down-sign-to-navigate.png" />
            <div class="backBtnTxt">{{'Recharge.Title'|translate}}</div>
        </div>

        <!-- <mat-chip-listbox style="display: flex;align-items: center;" (change)="demoSelected($event)">
            <mat-chip-option *ngFor="let chip of demo" [selected]="chip.selected" [color]="chip.color">
                {{chip.name}}
            </mat-chip-option>
        </mat-chip-listbox> -->

    </div>

    <div class="body">

        <!-- 選擇錢包 -->
        <div class="block-content" *ngIf="stage=='1'">

            <img class="banner-img" src="/assets/images/recharge/banner.png" />

            <div class="pay-box">
                <div class="pay-box-title">{{'Recharge.ChoseWallet'|translate}}</div>
                <div class="pay-box-row">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>{{'Recharge.ChoseWallet'|translate}}</mat-label>
                        <mat-select [(value)]="selectedCurrency" (selectionChange)="onCurrencySelected()">
                            <mat-option *ngFor="let currency of currencyList" [value]="currency.currency">{{currency.currency}} {{currency.name}}</mat-option>
                            <!-- <mat-option value="HKD">HKD</mat-option>
                            <mat-option value="USD">USD</mat-option> -->
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="pay-box-hint hint-color-blue">{{'Common.Balance'|translate}}：{{selectedBalance|walletCurrency}}</div>
            </div>

            <div class="pay-box pay-box-with-hit">
                <button mat-flat-button color="primary" class="next-btn" [disabled]="!selectedCurrency" (click)="onGetRechargeInfo()">下一步</button>
            </div>

        </div>

        <!-- 入金轉帳資訊頁 -->
        <div class="block-content" *ngIf="stage=='2'">

            <img class="banner-img" src="/assets/images/recharge/banner.png" />

            <div class="pay-box">
                <div class="pay-box-row justify-content-between">
                    <div class="tab-button" [ngClass]="{'selected': rechargeType=='LOCAL'}"
                        (click)="setRechargeType('LOCAL')" matRipple centered>
                        {{'Common.Method.Local'|translate}}
                    </div>
                    <div class="tab-button" [ngClass]="{'selected': rechargeType=='SWIFT'}"
                        (click)="setRechargeType('SWIFT')" matRipple centered>
                        {{'Common.Method.Swift'|translate}}
                    </div>
                </div>
            </div>

            <div class="pay-box" style="margin-top: 30px;margin-bottom: 5px;">
                <div class="row justify-content-between align-top">
                    <div class="style-4">{{'Recharge.RechargeWallet'|translate}}</div>
                    <div class="style-7 text-align-right">{{selectedCurrency}}</div>
                </div>
            </div>

            <!-- 無效充值資訊 -->
            <div class="pay-box error-block" style="margin-top: 20px;" *ngIf="rechargeInfo===false">
                {{'Recharge.CannotMatchAccount'|translate}}
            </div>

            <!-- SWIFT -->
            <div class="area" *ngIf="rechargeType == 'SWIFT' && rechargeInfo!==false">

                <!-- 帳戶名稱 -->
                <div class="pay-box" style="margin-top: 20px;">
                    <div class="style-4">{{'Recharge.AccountName'|translate}}</div>
                    <div class="display-box"> {{rechargeInfo.accountName}}
                        <div matRipple centered radius="60" class="copy" [cdkCopyToClipboard]="rechargeInfo.accountName">
                            <mat-icon>content_copy</mat-icon>
                        </div>
                    </div>
                </div>

                <!-- 銀行賬戶 -->
                <div class="pay-box" style="margin-top: 20px;" *ngIf="rechargeInfo.accountNumber">
                    <div class="style-4">{{'Recharge.RechargeAccount'|translate}}</div>
                    <div class="display-box"> {{rechargeInfo.accountNumber}}
                        <div matRipple centered radius="60" class="copy" [cdkCopyToClipboard]="rechargeInfo.accountNumber">
                            <mat-icon>content_copy</mat-icon>
                        </div>
                    </div>
                </div>

                <!-- 銀行名稱 -->
                <div class="pay-box" style="margin-top: 20px;" *ngIf="rechargeInfo.financialInstName">
                    <div class="style-4">{{'Recharge.BankBrand'|translate}}</div>
                    <div class="display-box"> {{rechargeInfo.financialInstName}}

                        <div matRipple centered radius="60" class="copy" [cdkCopyToClipboard]="rechargeInfo.financialInstName">
                            <mat-icon>content_copy</mat-icon>
                        </div>
                    </div>
                </div>

                <!-- 銀行地址 -->
                <div class="pay-box" style="margin-top: 20px;">
                    <div class="style-4">{{'Recharge.BankAddress'|translate}}</div>
                    <div class="display-box"> {{rechargeInfo.financialInstAddress}}
                        <div matRipple centered radius="60" class="copy" [cdkCopyToClipboard]="rechargeInfo.financialInstAddress">
                            <mat-icon>content_copy</mat-icon>
                        </div>
                    </div>
                </div>

                 <!-- 所在地 -->
                <div class="pay-box" style="margin-top: 20px;" *ngIf="rechargeInfo.region">
                    <div class="style-4">{{'Recharge.BankLocation'|translate}}</div>
                    <div class="display-box"> {{rechargeInfo.region}}
                        <div matRipple centered radius="60" class="copy" [cdkCopyToClipboard]="rechargeInfo.region">
                            <mat-icon>content_copy</mat-icon>
                        </div>
                    </div>
                </div>

                <!-- 清算系统类型 -->
                <div class="pay-box" style="margin-top: 20px;">
                    <div class="style-4">{{'Recharge.ClearSysType'|translate}}</div>
                    <div class="display-box"> {{rechargeInfo.clearingSysType}}
                        <div matRipple centered radius="60" class="copy" [cdkCopyToClipboard]="rechargeInfo.clearingSysType">
                            <mat-icon>content_copy</mat-icon>
                        </div>
                    </div>
                </div>

                <!-- SWIFT CODE -->
                <div class="pay-box" style="margin-top: 20px;">
                    <div class="style-4">{{'Common.SwiftCode'|translate}}</div>
                    <div class="display-box"> {{rechargeInfo.clearingSysNumber}}
                        <div matRipple centered radius="60" class="copy" [cdkCopyToClipboard]="rechargeInfo.clearingSysNumber">
                            <mat-icon>content_copy</mat-icon>
                        </div>
                    </div>
                </div>

                <!-- 交易備注(交易附言) -->
                <div class="pay-box" style="margin-top: 20px;">
                    <div class="style-4">{{'Recharge.TransComment'|translate}}</div>
                    <div class="display-box"> {{rechargeInfo.remittanceCode}}

                        <div matRipple centered radius="60" class="copy" [cdkCopyToClipboard]="rechargeInfo.remittanceCode">
                            <mat-icon>content_copy</mat-icon>
                        </div>
                    </div>
                </div>
            </div>

            <!-- LOCAL -->
            <div class="area" *ngIf="rechargeType == 'LOCAL' && rechargeInfo!==false">

                <!-- 帳戶名稱 -->
                <div class="pay-box" style="margin-top: 20px;">
                    <div class="style-4">{{'Recharge.AccountName'|translate}}</div>
                    <div class="display-box"> {{rechargeInfo.accountName}}
                        <div matRipple centered radius="60" class="copy" [cdkCopyToClipboard]="rechargeInfo.accountName">
                            <mat-icon>content_copy</mat-icon>
                        </div>
                    </div>
                </div>

                <!-- 充值帳號 -->
                <div class="pay-box" style="margin-top: 20px;" *ngIf="rechargeInfo.accountNumber">
                    <div class="style-4">{{'Recharge.RechargeAccount'|translate}}</div>
                    <div class="display-box"> {{rechargeInfo.accountNumber}}
                        <div matRipple centered radius="60" class="copy" [cdkCopyToClipboard]="rechargeInfo.accountNumber">
                            <mat-icon>content_copy</mat-icon>
                        </div>
                    </div>
                </div>

                <!-- 銀行名稱 -->
                <div class="pay-box" style="margin-top: 20px;" *ngIf="rechargeInfo.financialInstName">
                    <div class="style-4">{{'Recharge.BankBrand'|translate}}</div>
                    <div class="display-box"> {{rechargeInfo.financialInstName}}
                        <div matRipple centered radius="60" class="copy" [cdkCopyToClipboard]="rechargeInfo.financialInstName">
                            <mat-icon>content_copy</mat-icon>
                        </div>
                    </div>
                </div>

                <!-- 銀行地址 -->
                <div class="pay-box" style="margin-top: 20px;">
                    <div class="style-4">{{'Recharge.BankAddress'|translate}}</div>
                    <div class="display-box"> {{rechargeInfo.financialInstAddress}}
                        <div matRipple centered radius="60" class="copy" [cdkCopyToClipboard]="rechargeInfo.financialInstAddress">
                            <mat-icon>content_copy</mat-icon>
                        </div>
                    </div>
                </div>

                <!-- 所在地 -->
                <div class="pay-box" style="margin-top: 20px;" *ngIf="rechargeInfo.region">
                    <div class="style-4">{{'Recharge.BankLocation'|translate}}</div>
                    <div class="display-box"> {{rechargeInfo.region}}
                        <div matRipple centered radius="60" class="copy" [cdkCopyToClipboard]="rechargeInfo.region">
                            <mat-icon>content_copy</mat-icon>
                        </div>
                    </div>
                </div>

                <!-- 清算系统类型 -->
                <div class="pay-box" style="margin-top: 20px;">
                    <div class="style-4">{{'Recharge.LocalClearSysType'|translate}}</div>
                    <div class="display-box"> {{rechargeInfo.clearingSysType}}
                        <div matRipple centered radius="60" class="copy" [cdkCopyToClipboard]="rechargeInfo.clearingSysType">
                            <mat-icon>content_copy</mat-icon>
                        </div>
                    </div>
                </div>

                <!-- SWIFT CODE -->
                <div class="pay-box" style="margin-top: 20px;">
                    <div class="style-4">{{'Recharge.LocalClearSysCode'|translate}}</div>
                    <div class="display-box"> {{rechargeInfo.clearingSysNumber}}
                        <div matRipple centered radius="60" class="copy" [cdkCopyToClipboard]="rechargeInfo.clearingSysNumber">
                            <mat-icon>content_copy</mat-icon>
                        </div>
                    </div>
                </div>

                <!-- 交易備注 -->
                <div class="pay-box" style="margin-top: 20px;">
                    <div class="style-4">{{'Recharge.TransComment'|translate}}</div>
                    <div class="display-box"> {{rechargeInfo.remittanceCode}}
                        <div matRipple centered radius="60" class="copy" [cdkCopyToClipboard]="rechargeInfo.remittanceCode">
                            <mat-icon>content_copy</mat-icon>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tips" style="margin-top: 40px;">
                <mat-icon>info</mat-icon>
                <div>{{'Recharge.Tips-1'|translate}}</div>
            </div>
            <!-- <div class="tips">
                <mat-icon>info</mat-icon>
                <div>{{'Recharge.Tips-2'|translate}}</div>
            </div> -->
            <div class="tips">
                <mat-icon>info</mat-icon>
                <div>{{'Recharge.Tips-3'|translate}}</div>
            </div>
            <div class="tips" *ngIf="rechargeInfo && rechargeInfo.remitTips">
                <mat-icon>info</mat-icon>
                <div>{{rechargeInfo.remitTips}}</div>
            </div>

            <div class="pay-box pay-box-with-hit">
                <button mat-flat-button color="primary"
                    class="next-btn" [disabled]="rechargeInfo===false" (click)="onComplete()">{{'Recharge.ConfirmPayment'|translate}}</button>
            </div>

        </div>

        <!-- 完成畫面 -->
        <div class="block-content" *ngIf="stage=='3'">

            <img class="banner-img" src="/assets/images/recharge/banner.png" />

            <div class="pay-box">
                <div class="style-5" style="text-align: center; margin-top: 30px;">
                    {{'Recharge.CompleteTitle'|translate}}</div>
                <div class="style-3" style="text-align: center; margin-top: 30px;">
                    {{'Recharge.CompleteDesc-1'|translate}}<br />
                    {{'Recharge.CompleteDesc-2'|translate}}
                </div>
            </div>

            <div class="pay-box pay-box-with-hit" style="margin-top: 30px;">
                <button mat-flat-button color="primary" class="next-btn" (click)="onClose()">{{'Common.Confirm'|translate}}</button>
            </div>

        </div>

    </div>

</div>