<div class="container">

    <div class="top" style="justify-content: space-between;">
        <div class="backBtn" (click)="onBackToHome()">
            <img class="backBtnImg" alt="image" src="/assets/images/icons/arrow-down-sign-to-navigate.png" />
            <div class="backBtnTxt">{{'Deal.Title' | translate}}</div>
        </div>

        <div matRipple centered radius="60" (click)="onChangeCurrency()" class="currency-selector">{{currencySelected}} <mat-icon>unfold_more</mat-icon></div>

    </div>

    <div class="body">

        <div class="flex col" style="width: 100%;">
            <div class="currency-bar">
                <div class="flex row align-center">
                    <img style="width: 16px;height: 16px;" src="/assets/images/icons/cryptocurrency-color_usdt.png">
                    <div style="margin-left: 5px;">USDT</div>
                </div>
                 <div class="flex row align-center">
                    <div class="btn selected">買入</div>
                    <div class="line"></div>
                    <div class="btn">賣出</div>
                    
                 </div>
            </div>
            <div class="search-bar">
                <!-- <mat-form-field appearance="outline">
                    <input matInput type="number" min="0" placeholder="输入金额">
                </mat-form-field> -->

                <!-- <mat-icon style="margin-right: 15px;">sort</mat-icon> -->
                
                <!-- 金額篩選 -->
                <div matRipple  class="trade-limit" (click)="onChangeQuantityFilter()">
                    {{filterQuantity?filterQuantity:'金額'}}<mat-icon style="margin-left: 5px;">arrow_drop_down</mat-icon>
                </div>

                <!-- 排序條件 -->
                 <mat-form-field appearance="outline" style="width: 100px">
                    <mat-select class="selection" [(value)]="sortOption" (selectionChange)="onSortOptionSelect()">
                        <mat-option *ngFor="let item of sortOptionMenu" value="{{item.value}}">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                
                

                <!-- 金額篩選 -->
                <!-- <input class="input" matInput type="number" min="0" placeholder="筛选金额"> -->

            </div>
        </div>
       
        <div class="block-content">

            <div class="records">

                <div>

                    <!-- 查無數據 -->
                    <div class="record nodata info-style" *ngIf="!hasData">
                        {{'Dashboard.AnnoTab.Nodata'|translate}}
                    </div>

                    <!-- 數據列表 -->
                    <div class="record" *ngFor="let record of dealRecords">

                      <div class="flex row" style="align-items: center;">
                        <div class="name-tag">S</div>
                        <div class="name">sky********</div>
                      </div>
                      <div class="flex row-reverse" style="justify-content: space-between;    align-items: baseline;">
                        <div class="flex col" style="height: 100%;width: 80px;justify-content: end;align-items: center;">
                          <div class="complete">30日完成單 114</div>
                          <div matRipple centered radius="60" class="btn-buy">我要買</div>
                        </div>
                        <div class="flex col" style="width: calc(100% - 80px);">
                          <div class="flex row" style="align-items: baseline;"><div class="title">單價</div> <div class="price">7.12</div> <div class="currency">CNY</div></div>
                          <div class="flex row"><div class="title">數量</div> <div class="amount">123,456,66.00 USDT</div></div>
                          <div class="flex row"><div class="title">限额</div><div class="limit">CNY 2,000.00 - CNY 5,000,000,000.00</div></div>
                        </div>
                      </div>

                        <!-- <div class="flex row between">
                            <div class="flex col">
                                <div class="text-row">
                                    <div class="title">{{'Records.TxTime'|translate}}</div>
                                    <div class="info">{{record.transactionTime}}</div>
                                </div>
                            </div>
                            <div class="state recharge" *ngIf="record.transactionType==='TOP_UP'">{{'Records.TxnType.Recharge' | translate}}</div>
                            <div class="state payment" *ngIf="record.transactionType==='PAYMENT'">{{'Records.TxnType.Payment' | translate}}</div>
                            <div class="state conversion" *ngIf="record.transactionType==='CONVERSION'">{{'Records.TxnType.Conversion' | translate}}</div>
                            <div class="state internal" *ngIf="record.transactionType==='INTERNAL_TRANSFER'">{{'Records.TxnType.InnerTxn' | translate}}</div>
                        </div>
                        <div class="flex row">
                            <div class="title">{{'Records.Currency' | translate}}</div>
                            <div class="currency-info">{{ record.currency }}</div>
                        </div>
                        <div class="flex row-reverse between">
                            <div class="text-row">
                                <div class="title">{{'Records.TxBalance'|translate}}</div>
                                <div class="currency-2">{{record.remain| walletCurrency}}</div>
                            </div>
                            <div class="text-row">
                                <div class="title">{{'Records.TxAmount'|translate}}</div>
                                <div [ngClass]="{'currency-2':record.amount>0, 'currency-1':record.amount<=0}">{{record.amount | walletCurrency}}</div>
                            </div>
                        </div> -->
                    </div>

                    <!-- 沒有更多數據 -->
                    <div class="no-more-data info-style" *ngIf="hasData">{{'Records.NoMoreData'|translate}}</div>

                </div>

            </div>

        </div>

    </div>

</div>