import {
  Component,
  EventEmitter,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { NotifiySnackbarComponent } from '../notifiy-snackbar/notifiy-snackbar.component';
import { Router } from '@angular/router';
import { ClientService } from '../../services/client.service';
import { LocalService } from '../../services/local.service';
import { EventType, SessionService } from '../../services/session.service';
import { DialogService } from '../../services/dialog.service';

interface LanguageOption {
  code: string;
  name: string;
  selected?: boolean;
}

@Component({
  selector: 'app-dashboard-setting',
  templateUrl: './dashboard-setting.component.html',
  styleUrls: ['./dashboard-setting.component.scss'],
})
export class DashboardSettingComponent {
  @ViewChild('eref', { read: ViewContainerRef }) eref?: ViewContainerRef;
  @Output() changeTab = new EventEmitter<string>();

  principal: string = '';

  totpBindStatus = 0; // 0:上未初始化, 1: 尚未綁定, 2: 已綁定
  trxPasswdBindStatus = 0; // 0:上未初始化, 1: 尚未綁定, 2: 已綁定

  languageOptions: LanguageOption[] = [
    { code: 'zh-cn', name: '简体中文' },
    { code: 'zh-tw', name: '繁體中文' },
  ];

  constructor(
    private _snackBar: MatSnackBar,
    public dialog: DialogService,
    public translate: TranslateService,
    private router: Router,
    private client: ClientService,
    private local: LocalService,
    private session: SessionService
  ) {
    // setInterval(() => {
    //   this.totpBindStatus = Math.floor(Date.now() / 1000) % 3;
    //   console.log(`=>`, this.totpBindStatus);
    // }, 1000);
  }

  ngOnInit() {
    // this.principal = this.client.principal;
    this.principal = this.session.context!.principal!;
    this.init();
  }

  backToHome() {
    this.changeTab.emit('home');
  }

  onLogout() {
    this.session.emit(EventType.LOGOUT);
  }

  onChangeLanguage() {
    const lang = this.translate.getDefaultLang();
    const dialogRef = this.dialog.showLanguageSelect(
      lang,
      this.languageOptions
    );
    const sub = dialogRef.afterClosed().subscribe((result) => {
      sub.unsubscribe();
      if (result) {
        this.translate.setDefaultLang(result);
        this.local.Lang = result;
      }
    });
  }

  onSetTrxPasswd() {
    if (this.trxPasswdBindStatus > 0) {
      this.router.navigate(['trxpasswd']);
    }
  }

  onGoogleBind() {
    if (this.totpBindStatus > 0) {
      this.router.navigate(['verify', 'googleauth']);
    }
  }

  openSnackBar() {
    this._snackBar.openFromComponent(NotifiySnackbarComponent, {
      duration: 1000,
      viewContainerRef: this.eref,
    });
  }

  async init() {
    const r = await this.client.querySecurityStatus();
    this.totpBindStatus = r.totp == 0 ? 1 : 2; // 設定綁定狀態
    this.trxPasswdBindStatus = r.trxPasswd == 0 ? 1 : 2; // 設定綁定狀態

    // this.trxPasswdBindStatus = 0;
    // this.totpBindStatus = 0;
  }
}
