<div class="container">
  <div class="top">
    <div class="backBtn" routerLink="{{backBtn}}">
      <img class="backBtnImg" alt="image" src="/assets/images/icons/arrow-down-sign-to-navigate.png" />
      <div class="backBtnTxt">{{title | translate}}</div>
    </div>
  </div>

  <div class="body">
    <!-- 輸入 Email -->
    <div class="block-content" *ngIf="stage==0">
      <div class="subtitle" style="margin-top: 30px;">{{'ForgotPassword.EnterEmail'|translate}}</div>
      <form style="width: 85%; margin-top: 10px;">
        <div class="row">
            <mat-form-field appearance="outline" style="width: 100%;">
                <input matInput placeholder="{{'ForgotPassword.EnterEmail'|translate}}" [type]="'text'"
                    [formControl]="userEmail" required>
                <!-- <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon> -->
                <!-- <mat-hint *ngIf="!passwordInput?.value">Enter your password</mat-hint> -->
            </mat-form-field>
            <!-- <button mat-flat-button color="primary" class="sendCodeBtn" [disabled]="verifyCodeTimeout>0" (click)="onSendVerifyCode()">{{verifyCodeTimeout>0 ? (remainTime|CountdownTime) : ('Register.Send'|translate)}}</button> -->
        </div>
      </form>
      <div class="desc" style="margin-top: 30px;">{{'ForgotPassword.EmailDesc'|translate}}</div>
      <button mat-flat-button color="primary" class="verify-btn" [disabled]="verifyCodeTimeout>0 || userEmail.status !=='VALID'" (click)="onSendVerifyCode()">{{verifyCodeTimeout!=0 ? (remainTime|CountdownTime) : ('ForgotPassword.SendVerifyCode' | translate)}}</button>
    </div>

    <!-- 輸入驗證碼 -->
    <div class="block-content" *ngIf="stage==1">
      <div class="subtitle" style="margin-top: 30px;">{{'ForgotPassword.EnterVerifyCode' | translate}}</div>
      <form style="width: 85%; margin-top: 10px;">
        <div class="row">
            <mat-form-field appearance="outline" style="width: 100%;">
                <input matInput placeholder="{{'ForgotPassword.EnterVerifyCode' | translate}}" [type]="'text'"
                    [formControl]="verifyCode" [maxLength]="6" required>
                <!-- <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon> -->
                <!-- <mat-hint *ngIf="!passwordInput?.value">Enter your password</mat-hint> -->
            </mat-form-field>
            <button mat-flat-button color="primary" class="sendCodeBtn" [disabled]="verifyCodeTimeout>0" (click)="onSendVerifyCode()">{{verifyCodeTimeout>0 ? (remainTime|CountdownTime) : ('Register.Send' | translate)}}</button>
        </div>
      </form>
      <div class="desc" style="margin-top: 30px;">{{'ForgotPassword.EmailDesc-1' | translate}}</div>
      <div class="desc2" style="margin-top: 5px;">{{userEmail.value ?? ''}}</div>
      <div class="desc" style="margin-top: 20px;">{{'ForgotPassword.EmailDesc-2' | translate}}</div>

      <!-- 新密碼 -->
      <div class="subtitle" style="margin-top: 20px;">{{'ForgotPassword.EnterNewPassword' | translate}}</div>
      <form style="width: 85%; margin-top: 10px;">
        <div class="row">
            <mat-form-field appearance="outline" style="width: 100%;">
                <input matInput placeholder="{{'ForgotPassword.NewPassword' | translate}}" [type]="hide ? 'password' : 'text'"
                    [formControl]="newPasswd" required>
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </mat-form-field>
        </div>
      </form>

      <!-- 確認密碼 -->
      <div class="subtitle" style="margin-top: 20px;">{{'ForgotPassword.ConfirmPassword' | translate}}</div>
      <form style="width: 85%; margin-top: 10px;">
        <div class="row">
            <mat-form-field appearance="outline" style="width: 100%;">
                <input matInput placeholder="{{'ForgotPassword.ConfirmPassword' | translate}}" [type]="hide ? 'password' : 'text'"
                    [formControl]="confirmPasswd" required>
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </mat-form-field>
        </div>
      </form>

      <!-- 谷歌驗證碼 -->
      <div class="subtitle" style="margin-top: 20px;">{{'ForgotPassword.EnterOTP' | translate}}</div>
      <form style="width: 85%; margin-top: 10px;">
        <div class="row">
            <mat-form-field appearance="outline" style="width: 100%;">
                <input matInput placeholder="{{'ForgotPassword.GoogleOTP' | translate}}" [type]="'text'"
                    [formControl]="totpCode" [maxLength]="6" required>
            </mat-form-field>
        </div>
      </form>

      <button mat-flat-button color="primary" class="verify-btn" [disabled]="group.status!=='VALID' || !vToken" (click)="onVerify()">{{'ForgotPassword.ResetPassword' | translate}}</button>
    </div>
  </div>
</div>