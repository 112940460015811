<div class="top" #eref>
    <div class="backBtn" (click)="backToHome()">
        <img class="backBtnImg" alt="image" src="/assets/images/icons/arrow-down-sign-to-navigate-light.png" />
        <div class="backBtnTxt">{{'Dashboard.Setting'|translate}}</div>
    </div>


</div>

<div class="head-block" >
    <div class="background"></div>
    <div class="head">
        <img src="/assets/images/head.png" />
        <div class="name">{{principal | PrincipalMask}}</div>
    </div>
</div>

<div class="body">
    <div class="menu">
        <!-- 修改登入密碼 -->
        <div matRipple centered radius="60" class="menu-item" routerLink="/forgot/reset">
            <div>{{'Dashboard.SettingTab.LoginPassword'|translate}}</div>
            <img src="/assets/images/icons/arrow-right-gray.png">
        </div>
        <mat-divider></mat-divider>

        <!-- 設定交易密碼 -->
        <div matRipple centered radius="60" class="menu-item" [matRippleDisabled]="trxPasswdBindStatus==0" (click)="onSetTrxPasswd()">
            <div>{{(trxPasswdBindStatus==2?'Dashboard.SettingTab.ResetTradePassword':'Dashboard.SettingTab.TradePassword')|translate}}</div>
            <img src="/assets/images/icons/arrow-right-gray.png">
        </div>
        <mat-divider></mat-divider>

        <!-- 綁定 Google 驗證 -->
        <div matRipple centered radius="60" class="menu-item" [matRippleDisabled]="totpBindStatus==0" (click)="onGoogleBind()">
            <div style="display: flex;flex-direction: row;">{{(totpBindStatus==2?'Dashboard.SettingTab.ResetGoogleAuth':'Dashboard.SettingTab.GoogleAuth')|translate}}</div>
            <!-- <div *ngIf="totpBindStatus==2" style="color: #979797;">{{'Dashboard.SettingTab.BindReady'|translate}}</div> -->
            <img src="/assets/images/icons/arrow-right-gray.png">
        </div>
        <mat-divider></mat-divider>

        <!-- 綁定手機號 -->
        <!-- <div matRipple centered radius="60" class="menu-item">
            <div>{{'Dashboard.SettingTab.PhoneNumber'|translate}}</div>
            <img src="/assets/images/icons/arrow-right-gray.png">
        </div>
        <mat-divider></mat-divider> -->

        <!-- 綁定 Email -->
        <!-- <div matRipple centered radius="60" class="menu-item">
            <div>{{'Dashboard.SettingTab.Email'|translate}}</div>
            <img src="/assets/images/icons/arrow-right-gray.png">
        </div>
        <mat-divider></mat-divider> -->

        <!-- 設定語言 -->
        <div matRipple centered radius="60" class="menu-item" (click)="onChangeLanguage()">
            <div>{{'Dashboard.SettingTab.Lang'|translate}}</div>
            <img src="/assets/images/icons/arrow-right-gray.png">
        </div>
        <mat-divider></mat-divider>

        <!-- 應用程序資訊 -->
        <!-- <div matRipple centered radius="60" class="menu-item">
            <div>{{'Dashboard.SettingTab.AppInfo'|translate}}</div>
            <img src="/assets/images/icons/arrow-right-gray.png">
        </div>
        <mat-divider></mat-divider> -->

        <!-- 聯絡客服 -->
        <div matRipple centered radius="60" class="menu-item">
            <div>{{'Dashboard.SettingTab.ContactUs'|translate}}</div>
            <div><a href="https://t.me/PTcs001" target="_blank">@PTcs001</a></div>
        </div>
        <mat-divider></mat-divider>

         <div matRipple centered radius="60" class="menu-item-center" (click)="onLogout()">
            <div>{{'Dashboard.SettingTab.Logout'|translate}}</div>
        </div>

        <!-- 暫時填充底下空白用 -->
        <div class="menu-item" style="height: 50px;"></div>

        <mat-divider></mat-divider>

    </div>
    
</div>