import {
  Component,
  EventEmitter,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ClientService } from '../../services/client.service';
import { PromiseUtil } from '../../common/PromiseUtil';
import { NotifyService } from '../../services/notify.service';
import { Subscription } from 'rxjs';
import { DialogService } from '../../services/dialog.service';
import {
  CurrencyExchangePair,
  UserBalance,
} from '../../domain/network/response';
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'app-dashboard-home',
  templateUrl: './dashboard-home.component.html',
  styleUrls: ['./dashboard-home.component.scss'],
})
export class DashboardHomeComponent {
  @ViewChild('eref', { read: ViewContainerRef }) eref?: ViewContainerRef;

  @Output() changeTab = new EventEmitter<string>();

  refreshTimer?: NodeJS.Timer;
  destroy = false;

  notificationSubscription?: Subscription; // 訂閱
  notifyBadgeCount = 0;

  // 總資產功能
  fxCurrency: string = 'USD'; // 資產評估基準幣種
  fxCurrentMenu: { code: string; name: string; selected: boolean }[] = []; // 換匯幣種
  userBanlances: UserBalance[] = []; // 用戶資產
  fxPriceTable: CurrencyExchangePair[] = []; // 匯兌表
  totalByCurrency = 0; // 總資產, 以指定幣種計算
  totalByUSD = 0; // 總資產, 以美金計算

  animateFadeIn = false;
  animateFadeOut = false;
  announcementMarqueeContent = 'x'; // 公告跑馬燈

  announcementMarqueeContents: string[] = [];
  announcementIndex = 0;

  constructor(
    private snackBar: MatSnackBar,
    private dialog: DialogService,
    public translate: TranslateService,
    private client: ClientService,
    private notification: NotifyService,
    private session: SessionService
  ) {}

  ngOnInit() {
    // console.error('ngOnInit');
    this.destroy = false;
    // this.refreshInfo(true);

    // 訂閱通知
    this.notificationSubscription = this.notification.stateChanges.subscribe({
      next: (n) => this.onNotificationChanges(),
    });
    this.notifyBadgeCount = this.notification.unreadList.length;

    this.onInit();

    // 啟動跑馬燈 Timmer
    this.marquee();
  }

  ngOnDestroy() {
    this.destroy = true;
    // 取消訂閱通知
    if (this.notificationSubscription) {
      this.notificationSubscription.unsubscribe();
    }
  }

  // 異步初始化
  async onInit() {
    // 等一下, 不然很快爆掉
    // await PromiseUtil.wait(100);

    // 讀取匯率表
    this.fxPriceTable = await this.client.getFxPrice();

    this.updateCBalanceInfo();

    // 讀取公告
    try {
      const notices = await this.client.getSystemNotice();
      this.announcementMarqueeContents = [];
      notices.rows.forEach((item) => {
        this.announcementMarqueeContents.push(item.noticeTitle);
      });
    } catch (err) {}
  }

  // 跑馬燈
  async marquee(duration: number = 3000) {
    this.announcementIndex = -1;
    while (true) {
      // 沒有公告就跳過先
      if (this.announcementMarqueeContents.length <= 0) {
        await PromiseUtil.wait(100);
        continue;
      }

      // 切換下一則公告
      this.announcementIndex++;
      if (this.announcementIndex >= this.announcementMarqueeContents.length) {
        this.announcementIndex = 0;
      }

      this.announcementMarqueeContent =
        this.announcementMarqueeContents[this.announcementIndex];

      // 只有一筆公告不需要動畫
      if (this.announcementMarqueeContents.length == 1) {
        this.animateFadeIn = false;
        this.animateFadeOut = false;
        await PromiseUtil.wait(duration);
        continue;
      }

      this.animateFadeIn = true;
      this.animateFadeOut = false;

      await PromiseUtil.wait(duration + 250); // 動畫 250ms
      this.animateFadeIn = false;
      this.animateFadeOut = true;
      await PromiseUtil.wait(250);
    }
  }

  // 更新資產
  async updateCBalanceInfo() {
    const currencies: string[] = [];

    this.userBanlances = (await this.client.balanceList()).filter(
      (item) => item.updateTime !== null
    );

    this.userBanlances.forEach((item) => currencies.push(item.currency));
    this.fxPriceTable.forEach((item) => {
      if (!currencies.includes(item.targetCur)) {
        currencies.push(item.targetCur);
      }
    });

    // 翻譯
    const trans = await PromiseUtil.FromObservable(
      this.translate.get(currencies.map((c) => `Currency.${c}`))
    );
    // 建立清單
    this.fxCurrentMenu = [];
    currencies.forEach((currency) => {
      this.fxCurrentMenu.push({
        code: currency,
        name: trans[`Currency.${currency}`] ?? currency,
        selected: currency === this.fxCurrency,
      });
    });

    this.updateTotalBalance();
  }

  // 更新總資產計算
  updateTotalBalance() {
    this.totalByCurrency = 0;
    this.totalByUSD = 0;
    this.userBanlances.forEach((record) => {
      // 以 USD 進行總資產估價
      if (record.currency === 'USD') {
        this.totalByUSD += record.balance;
      } else {
        this.totalByUSD += this.fxConvert(
          record.currency,
          record.balance,
          'USD'
        );
      }
      // 以指定幣別進行總資產估價
      if (record.currency === this.fxCurrency) {
        this.totalByCurrency += record.balance;
      } else {
        this.totalByCurrency += this.fxConvert(
          record.currency,
          record.balance,
          this.fxCurrency
        );
      }
    });
  }

  // 換匯計算
  fxConvert(sourceCurrency: string, balance: number, targetCurrency: string) {
    const fxRecord = this.fxPriceTable.find((v) => {
      return v.sourceCur == sourceCurrency && v.targetCur === targetCurrency;
    });
    if (!fxRecord) {
      return 0; // 沒找到匯率
    }

    let val = 0;
    if (fxRecord.curPair.startsWith(fxRecord.sourceCur)) {
      val = balance * fxRecord.rate;
    } else {
      val = balance / fxRecord.rate;
    }
    val = Math.floor(val);

    // console.log(
    //   `換匯 ${balance} ${sourceCurrency} => ${val} ${targetCurrency} (rate: ${fxRecord.rate})`
    // );
    return val;
  }

  onChangeTab(name: string) {
    console.log(`切換:`, name);
    this.changeTab.emit(name);
  }

  async openNotImplementSnackBar() {
    // this.snackBar.openFromComponent(NotifiySnackbarComponent, {
    //   duration: 1000,
    //   viewContainerRef: this.eref,
    // });

    const msg = await PromiseUtil.FromObservable(
      this.translate.get('SnackBar.NotImplement')
    );
    this.snackBar.open(msg, 'OK', {
      duration: 1000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  // 收到通知列表異動
  onNotificationChanges() {
    this.notifyBadgeCount = this.notification.unreadList.length;
  }

  showCurrencySelect() {
    // const list = [
    //   { code: 'VND', name: '越南盾', selected: true },
    //   { code: 'NTD', name: '新台幣', selected: false },
    //   { code: 'HKD', name: '港幣', selected: false },
    //   { code: 'USD', name: '美金', selected: false },
    // ];
    // list.forEach((item) => (item.selected = current == item.code));

    const ref = this.dialog.showCurrencySelect(
      this.fxCurrency,
      this.fxCurrentMenu
    );
    const sub = ref.afterClosed().subscribe({
      next: (val) => {
        sub.unsubscribe();
        if (val && val !== this.fxCurrency) {
          this.fxCurrency = val;
          this.updateTotalBalance();
        }
      },
    });
  }

  private async refreshInfo(auto: boolean = false) {
    console.error(`%%`, new Date());
    const resp = await this.client.balanceList();
    if (this.destroy) return;
    this.refreshTimer = setTimeout(() => {
      this.refreshInfo(true);
    }, 5000);
  }
}
