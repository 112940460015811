import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipListboxChange } from '@angular/material/chips';

@Component({
  selector: 'app-binding-mobile',
  templateUrl: './binding-mobile.component.html',
  styleUrls: ['./binding-mobile.component.scss']
})
export class BindingMobileComponent {

  demoPage = '1';
  demo: { name: string, color?: string, selected?: boolean }[] = [
    { name: '1' },
    { name: '2' },
    { name: '3' },
  ];

  // 更換 Demo 分頁
  demoSelected(event: MatChipListboxChange) {
    this.demoPage = event.value;
    this.demo.forEach(e => {
      e.selected = (e.name == event.value);
    });
  }

  constructor() {
    this.demo.forEach(e => {
      e.selected = (e.name == this.demoPage);
    })
  }


  hideRequiredControl = new FormControl(false);
  setTransPassword: FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.min(3)]),
    hideRequired: this.hideRequiredControl,
  });
  hide = true;
  get emailInput() { return this.setTransPassword.get('email'); }
  get passwordInput() { return this.setTransPassword.get('password'); }
}
