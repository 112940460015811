import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface CurrencyDialogData {
  currency: {
    code: string;
    name: string;
    selected: boolean;
  }[];
}

@Component({
  selector: 'app-currency-selector',
  templateUrl: './currency-selector.component.html',
  styleUrls: ['./currency-selector.component.scss'],
})
export class CurrencySelectorComponent {
  constructor(
    public dialogRef: MatDialogRef<CurrencySelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CurrencyDialogData
  ) {
    // console.log(`Dialog Data:`, data);
  }

  currencyList = [
    { code: 'VND', name: '越南盾1', selected: true },
    { code: 'NTD', name: '越南盾', selected: false },
    { code: 'HKD', name: '越南盾', selected: false },
    { code: 'USD', name: '越南盾', selected: false },
    // { code: 'VND', name: '越南盾', selected: false },
    // { code: 'NTD', name: '越南盾', selected: false },
    // { code: 'HKD', name: '越南盾', selected: false },
    // { code: 'USD', name: '越南盾1', selected: true },
    // { code: 'VND', name: '越南盾', selected: false },
    // { code: 'NTD', name: '越南盾', selected: false },
    // { code: 'HKD', name: '越南盾', selected: false },
    // { code: 'USD', name: '越南盾', selected: false },
    // { code: 'VND', name: '越南盾1', selected: true },
    // { code: 'NTD', name: '越南盾', selected: false },
    // { code: 'HKD', name: '越南盾', selected: false },
    // { code: 'USD', name: '越南盾', selected: false },
  ];

  onSelect(code: string): void {
    this.dialogRef.close(code);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
