import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datePruner',
})
export class DatePrunerPipe implements PipeTransform {
  transform(value?: string, ...args: unknown[]): string {
    if (!value) return '';
    return value.replace(/^(\d{4}-\d{2}-\d{2}).*$/, '$1');
  }
}
