import { Observable } from 'rxjs';

export class PromiseUtil {
  static FromObservable<T>(ob: Observable<T>) {
    return new Promise<T>((resolve, reject) => {
      ob.subscribe({
        next: (val) => resolve(val),
        error: (err) => reject(err),
      });
    });
  }

  static wait(ts: number) {
    return new Promise<void>((resolve, reject) => {
      setTimeout(() => resolve(), ts);
    });
  }
}
