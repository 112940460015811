<h1 mat-dialog-title class="title">{{'Dialog.SelectLanguage' | translate}}</h1>
<div mat-dialog-content>
    <div class="currency-list">
        <div *ngFor="let currency of data.currency">
            <button mat-button class="currency-item" [ngClass]="{'selected': currency.selected}"
                (click)="onSelect(currency.code)">
                {{currency.name}}
                <mat-icon [style]="{'visibility':currency.selected?'visible':'hidden'}">check</mat-icon>
            </button>
        </div>
    </div>

</div>
<button class="dialog-close-mini-btn" mat-button (click)="onNoClick()">X</button>