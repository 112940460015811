import { Injectable } from '@angular/core';

interface CacheRecord {
  data: any;
  create: number;
  last_read: number;
  last_write: number;
  expire_time: number; // 設定時間
  expire_at: number; // 計算用過期時間
}

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  dataMap: { [key: string]: CacheRecord } = {};

  constructor() {}

  put(key: string, data: any, expire: number = 0) {
    const item = this.renew(key, false, true);
    const now = Date.now();
    if (item) {
      item.data = data;
      item.expire_time = expire;
      if (expire > 0) {
        item.expire_at = now + expire;
      }
      return;
    }
    this.dataMap[key] = {
      data: data,
      create: now,
      last_read: 0,
      last_write: now,
      expire_time: expire, // 設定時間
      expire_at: now + expire, // 計算用過期時間
    };
  }

  get(key: string, default_value: any = undefined) {
    const item = this.renew(key, true);
    if (!item) return default_value;
    return item.data;
  }

  // 刪除資料
  del(key: string) {
    if (key in this.dataMap) {
      delete this.dataMap[key];
      return true;
    }
    return false;
  }

  // 展延快取時效
  renew(key: string, read = false, write = false) {
    if (key in this.dataMap) {
      const now = Date.now();
      const item = this.dataMap[key];

      if (item.expire_at > 0 && now > item.expire_at) {
        delete this.dataMap[key];
        return false;
      }

      if (item.expire_time > 0) {
        item.expire_at = now + item.expire_time;
      }
      if (read) {
        item.last_read = now;
      }
      if (write) {
        item.last_write = now;
      }
      return item;
    }
    return false;
  }
}
