// import { uuid as uuidv4 } from 'uuidv4';
import { v4 as uuidv4 } from 'uuid';
import { Component } from '@angular/core';
import { MatChipListboxChange } from '@angular/material/chips';
import { ClientService, PaymentMethod } from '../../services/client.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserBalance, VirtualBankInfo } from '../../domain/network/response';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { RandomUtil } from '../../common/RandomUtil';
import { PromiseUtil } from '../../common/PromiseUtil';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { DialogService } from '../../services/dialog.service';

interface PaymentInfo {
  swift: false | VirtualBankInfo;
  local: false | VirtualBankInfo;
}

@Component({
  selector: 'app-recharge',
  templateUrl: './recharge.component.html',
  styleUrls: ['./recharge.component.scss'],
})
export class RechargeComponent {
  stage = '1';
  demo: { name: string; color?: string; selected?: boolean }[] = [
    { name: '1', color: '' },
    { name: '2', color: '' },
    { name: '3', color: '' },
    { name: '4', color: '' },
  ];

  stageStack: string[] = [];

  defaultCurrency?: string;

  selectedCurrency = 'USD';
  selectedBalance = 0;

  rechargeType = 'SWIFT';
  rechargeInfo: VirtualBankInfo | false = false;

  paymentInfo?: PaymentInfo;

  userBalance?: UserBalance[]; // 用戶帳戶餘額

  currencyList = [
    // { currency: 'CNY', name: '人民币' },
    // { currency: 'HKD', name: '港元' },
    { currency: 'USD', name: '美元' },
    // { currency: 'EUR', name: '欧元' },
    // { currency: 'GBP', name: '英镑' },
    // { currency: 'CAD', name: '加拿大元' },
    // { currency: 'JPY', name: '日元' },
    // { currency: 'AUD', name: '澳元' },
    // { currency: 'KRW', name: '韩元' },
    // { currency: 'THB', name: '泰铢' },
  ];

  constructor(
    private route: ActivatedRoute,
    private client: ClientService,
    private snackBar: MatSnackBar,
    private router: Router,
    private location: Location,
    private dialog: DialogService,
    private translate: TranslateService
  ) {
    this.demo.forEach((e) => {
      e.selected = e.name == this.stage;
    });
  }

  ngOnInit() {
    // 初始化參數
    this.route.params.subscribe((params: Params) => {
      if (params['currency']) {
        this.location.replaceState('/recharge');
        this.selectedCurrency = params['currency'];
        this.defaultCurrency = params['currency'];
        this.updateSelectedBalance();
      }
    });

    this.onInit();
  }

  async onInit() {
    try {
      this.userBalance = await this.client.balanceList();
      this.userBalance = this.userBalance.sort((a, b) => b.balance - a.balance);
      this.selectedCurrency =
        this.defaultCurrency || this.userBalance[0].currency;
      let trans = [];
      for (let item of this.userBalance) {
        trans.push(`Currency.${item.currency}`);
      }
      const transResult = await PromiseUtil.FromObservable(
        this.translate.get(trans)
      );
      this.currencyList = [];
      for (let item of this.userBalance) {
        const key = `Currency.${item.currency}`;
        const name = transResult[key].startsWith('Currency.')
          ? ''
          : transResult[key];
        this.currencyList.push({
          currency: item.currency,
          name: name,
        });
      }
      this.updateSelectedBalance();

      // 檢查綁定狀態
      const r = await this.client.querySecurityStatus();
      if (r.trxPasswd == 0) {
        // 尚未設置交易密碼
        this.dialog.showRedirectPopup(
          'Dialog.SetTrxPasswd',
          'Dialog.Goto',
          ['trxpasswd'],
          ['dashboard', 'home']
        );
      } else if (r.totp == 0) {
        // 尚未設置谷歌密碼
        this.dialog.showRedirectPopup(
          'Dialog.SetOTPasswd',
          'Dialog.Goto',
          ['verify', 'googleauth'],
          ['dashboard', 'home']
        );
      }
    } catch (err) {}
  }

  // 更換 Demo 分頁
  demoSelected(event: MatChipListboxChange) {
    this.stage = event.value;
    this.demo.forEach((e) => {
      e.selected = e.name == event.value;
    });
  }

  // 點擊返回
  onGoBack() {
    if (this.stageStack.length == 0) {
      this.router.navigate(['/dashboard']);
      return;
    }
    this.stage = this.stageStack.pop()!;
  }

  setRechargeType(type: string) {
    this.rechargeType = type;

    if (!this.paymentInfo) {
      this.rechargeInfo = false;
      return;
    }

    switch (this.rechargeType) {
      case 'SWIFT': {
        this.rechargeInfo = this.paymentInfo.swift;
        break;
      }
      case 'LOCAL': {
        this.rechargeInfo = this.paymentInfo.local;
        break;
      }
    }
  }

  // 第一步 - 選擇下一步, 請求匯款資料
  async onGetRechargeInfo() {
    this.paymentInfo = await this.getRechargeInfo();
    this.showRechargeInfo();
  }

  async onComplete() {
    this.stageStack.push(this.stage);
    this.stage = '3';
  }

  async onClose() {
    this.router.navigate(['/dashboard'], { replaceUrl: true });
  }

  showRechargeInfo() {
    // 模擬有的沒資料
    // if (RandomUtil.between(1, 2) == 1) {
    //   this.paymentInfo!.local = false;
    // }
    // if (RandomUtil.between(1, 2) == 1) {
    //   this.paymentInfo!.swift = false;
    // }

    console.log(this.paymentInfo);
    if (!this.paymentInfo) {
      return;
    }

    // 設定預設值
    this.rechargeType = 'SWIFT';
    this.rechargeInfo = this.paymentInfo.swift;
    if (!this.paymentInfo?.swift && this.paymentInfo.local) {
      this.rechargeType = 'LOCAL';
      this.rechargeInfo = this.paymentInfo.local;
    }

    this.stageStack.push(this.stage);
    this.stage = '2';
  }

  // 通知訊息
  openNoticeSnackBar(msg: string, duration: number = 5000) {
    this.snackBar.open(msg, 'OK', {
      duration: duration,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  // 選擇
  onCurrencySelected() {
    console.log(`切換幣別`, this.selectedCurrency);
    this.updateSelectedBalance();
  }

  private updateSelectedBalance() {
    this.selectedBalance = 0;
    if (!this.userBalance || this.userBalance.length == 0) {
      return;
    }
    for (let item of this.userBalance) {
      if (item.currency === this.selectedCurrency) {
        this.selectedBalance = item.balance;
        return;
      }
    }
  }

  // 取得匯款資料
  private async getRechargeInfo() {
    const payment: PaymentInfo = {
      swift: false,
      local: false,
    };

    // 撈資料
    try {
      let resp = await this.client.getRechargeBankInfo(this.selectedCurrency);
      // console.error(`有資料: `, resp);
      if (resp && resp.length > 0) {
        for (let vba of resp) {
          for (let info of vba.infos) {
            if (info.clearingSysType === 'SWIFT') {
              if (!payment.swift) {
                payment.swift = info;
              }
            } else {
              if (!payment.local) {
                payment.local = info;
              }
            }
          }
          return payment;
        }
      }
    } catch (err) {
      // if (err instanceof Error) {
      //   console.error(
      //     `查詢可用的虛擬帳號 ${this.selectedCurrency} => ${err.message}`
      //   );
      // } else {
      //   console.error(`查詢可用的虛擬帳號 ${this.selectedCurrency} => ${err}`);
      // }
    }

    // 創建資料
    try {
      const uuid = `${Date.now()}`;
      let vba = await this.client.requireRechargeBankInfo(
        uuid,
        this.selectedCurrency,
        'SWIFT'
      );
      if (vba) {
        for (let info of vba.infos) {
          if (info.clearingSysType === 'SWIFT') {
            if (!payment.swift) {
              payment.swift = info;
            }
          } else {
            if (!payment.local) {
              payment.local = info;
            }
          }
        }
      }
    } catch (err) {}

    return payment;
  }
}
