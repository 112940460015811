import { Component } from '@angular/core';
import { MatChipListboxChange } from '@angular/material/chips';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ClientService } from '../../services/client.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../services/session.service';
import { PromiseUtil } from '../../common/PromiseUtil';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ApiErrorResponse } from '../../domain/network/response';
import { environment } from 'projects/h5app2/src/environments/environment';

@Component({
  selector: 'app-authenticator-bind',
  templateUrl: './authenticator-bind.component.html',
  styleUrls: ['./authenticator-bind.component.scss'],
})
export class AuthenticatorBindComponent {
  stage = '1';
  demo: { name: string; color?: string; selected?: boolean }[] = [
    { name: '1' },
    { name: '2' },
  ];

  qrcodeUrl = '現在時間是 2028/22/33';

  hidePasswd = true;

  verifyCodeCtl: FormControl;
  passwordCtl: FormControl;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private client: ClientService,
    private snackBar: MatSnackBar,
    private session: SessionService,
    private translate: TranslateService,
    private location: Location
  ) {
    this.demo.forEach((e) => {
      e.selected = e.name == this.stage;
    });

    this.verifyCodeCtl = new FormControl('', [
      Validators.required,
      Validators.pattern(/^[0-9]{6}$/),
    ]);
    this.verifyCodeCtl.statusChanges.subscribe({ next: (valid) => {} });

    this.passwordCtl = new FormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]);
  }

  ngOnInit() {
    // 初始化參數
    this.route.params.subscribe((params: Params) => {
      this.qrcodeUrl = params['totp'];
      this.location.replaceState('/totp-bind'); // 清掉 url 上的 qrcode 資料
      if (!this.qrcodeUrl) {
        this.router.navigate(['verify', 'googleauth'], { replaceUrl: true });
      }

      // 測試環境就調整一下文字顯示
      if (!environment.production) {
        this.qrcodeUrl = this.qrcodeUrl.replace(
          /Paytranxee/g,
          'Paytranxee(Test)'
        );
      }
    });
  }

  async onCopy() {
    const msg = await PromiseUtil.FromObservable(
      this.translate.get('AuthenticatorBind.Copied')
    );
    this.snackBar.open(msg, 'OK', {
      duration: 1000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  async onBind() {
    if (this.verifyCodeCtl.status !== 'VALID') {
      return;
    }
    if (this.passwordCtl.status !== 'VALID') {
      return;
    }
    if (!this.session.context) {
      this.snackBar.open('Invalid session');
      return;
    }

    try {
      const result = await this.client.bindTotp(
        this.session.context.principal!,
        this.passwordCtl.value,
        this.verifyCodeCtl.value
      );
      if (result === true) {
        this.stage = '2'; // 切換完成畫面
      }
    } catch (err) {
      let error_msg = `${err}`;
      if (err instanceof ApiErrorResponse) {
        error_msg = err.msg;
      } else if (err instanceof Error) {
        error_msg = err.message;
      }
      this.snackBar.open(error_msg, 'OK', {
        duration: 1000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  // 更換 Demo 分頁
  demoSelected(event: MatChipListboxChange) {
    this.stage = event.value;
    this.demo.forEach((e) => {
      e.selected = e.name == event.value;
    });
  }
}
