import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  quantity: number;
}

@Component({
  selector: 'app-quantity',
  templateUrl: './quantity.component.html',
  styleUrls: ['./quantity.component.scss'],
})
export class QuantityComponent {
  quantityCtl: FormControl;

  constructor(
    public dialogRef: MatDialogRef<QuantityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.quantityCtl = new FormControl('', [
      Validators.pattern(/^([0-9]+(\.[0-9]+)?)?$/),
    ]);
  }

  onNoClick(): void {
    this.dialogRef.close(this.quantityCtl.value);
  }
}
